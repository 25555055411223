"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let WasteBinReadingModal = class WasteBinReadingModal extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['formId', 'item', 'ux', 'b_5534025912102772_auto', 'b_5430362791568289_variantMap'];
    }
    beforeCreate() {
    }
    onObject_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("objectId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('waste_bins/get_reading', { parameters: { id: this.objectId } }).then(d => new this.$fn.Resource(d));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            item: null,
            ux: null,
            b_5534025912102772_auto: false,
            b_5430362791568289_variantMap: {
                empty: "success",
                full: "warning",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Waste bin reading"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1594_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_2286348834130966_setData_1600_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event && $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_2286348834130966_request_1598_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "waste_bins/update_reading",
                data: {
                    id: this.item.id,
                    state: this.item.state == 'full' ? 'empty' : 'full'
                },
            };
        });
    }
    evh_2286348834130966_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2286348834130966_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1594 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1594_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1593 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1594,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1593],
        });
        const act_2248226175642056_closeComponent_1596 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_1595 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_1596,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1595],
        });
        const act_2286348834130966_setData_1600 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2286348834130966_setData_1600_getActionArgs,
            events: [],
        });
        const evh_2286348834130966_success_1599 = new core_1.actions.EventHandlerImpl({
            action: act_2286348834130966_setData_1600,
            event: "success",
            displayName: "setData",
        });
        const act_2286348834130966_request_1598 = new core_1.actions.RequestAction({
            actionArgs: this.act_2286348834130966_request_1598_getActionArgs,
            displayName: "toggleState",
            events: [evh_2286348834130966_success_1599],
        });
        const evh_2286348834130966_clickPreventStop_1597 = new core_1.actions.EventHandlerImpl({
            action: act_2286348834130966_request_1598,
            event: "click.prevent.stop",
            displayName: "toggleState",
        });
        const evh_2286348834130966_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2286348834130966_clickPreventStop_1597],
        });
        const act_1548630417156826_closeComponent_1602 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_1601 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_1602,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_1601],
        });
        return {
            act_7315092382398562_closeModal_1594,
            evh_7315092382398562_close_1593,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_1596,
            evh_2248226175642056_close_1595,
            evh_2248226175642056_close,
            act_2286348834130966_setData_1600,
            evh_2286348834130966_success_1599,
            act_2286348834130966_request_1598,
            evh_2286348834130966_clickPreventStop_1597,
            evh_2286348834130966_clickPreventStop,
            act_1548630417156826_closeComponent_1602,
            evh_1548630417156826_clickPreventStop_1601,
            evh_1548630417156826_clickPreventStop,
            toggleState: act_2286348834130966_request_1598,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
    }),
    __metadata("design:type", typeof (_a = typeof int !== "undefined" && int) === "function" ? _a : Object)
], WasteBinReadingModal.prototype, "objectId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('objectId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WasteBinReadingModal.prototype, "onObject_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], WasteBinReadingModal.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WasteBinReadingModal.prototype, "onSize", null);
WasteBinReadingModal = __decorate([
    (0, vue_property_decorator_1.Component)()
], WasteBinReadingModal);
exports.default = WasteBinReadingModal;
vue_property_decorator_1.Vue.component("WasteBinReadingModal", WasteBinReadingModal);
