import { render, staticRenderFns } from "./BtnSyncResource.yaml?vue&type=template&id=7ad4c600&component"
import script from "./BtnSyncResource.yaml?vue&type=script&lang=ts&component"
export * from "./BtnSyncResource.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports