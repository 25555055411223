<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceEditor"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="invoice"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <UcInvoiceEditorFieldset
          :invoice="editor"
          :formId="formId"
          :target="target"
        >
        </UcInvoiceEditorFieldset>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.invoice || !forms.invoice.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args
    const fields = this.target && this.targetConfig[this.target].fieldsToSubmit
    return fields ? _.pick(data, fields) : data;
  }

  @Prop({
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  editor!: any;

  async $$load_editor() {
    return this.invoice.editor()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'editor', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("Invoice", "Edit Invoice"),
        targetConfig: {
          header: {
            title: this.$fn.pgettext("Invoice", "Edit Invoice Header"),
            fieldsToSubmit: ["id"
              ,
              "revision_id"
              ,
              "etag"
              ,
              "company"
              ,
            ]
            ,
          }
          ,
        }
        ,
        editor: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2482_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2482, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_2488_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_2488, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_2486_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_2486, alias=save
    return {
      objectType: "invoice",
      op: "upsert",
      data: this.getPayload(this.editor),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_2490_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_2490, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2482: actions.CloseModalAction;
    evh_7315092382398562_close_2481: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2484: actions.CloseComponentAction;
    evh_2248226175642056_close_2483: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_2488: actions.CloseModalAction;
    evh_1040167445267876_success_2487: actions.EventHandlerImpl;
    act_1040167445267876_crud_2486: actions.CRUDAction;
    evh_1040167445267876_submit_2485: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_2490: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_2489: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2482 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2482_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2481 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2482,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2481],
      }
    );
    const act_2248226175642056_closeComponent_2484 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2483 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2484,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2483],
      }
    );
    const act_1040167445267876_closeModal_2488 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_2488_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2487 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_2488,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_2486 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_2486_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_2487],
      }
    );
    const evh_1040167445267876_submit_2485 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_2486,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2485],
      }
    );
    const act_1419464017721962_closeModal_2490 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_2490_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2489 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_2490,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2489],
      }
    );
    return {
      act_7315092382398562_closeModal_2482,
      evh_7315092382398562_close_2481,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2484,
      evh_2248226175642056_close_2483,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_2488,
      evh_1040167445267876_success_2487,
      act_1040167445267876_crud_2486,
      evh_1040167445267876_submit_2485,
      evh_1040167445267876_submit,
      act_1419464017721962_closeModal_2490,
      evh_1419464017721962_clickPreventStop_2489,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_2486,
    }
  }
}

Vue.component("InvoiceEditor", InvoiceEditor);

</script>