<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="name"
    :label="'Type'|pgettext('Product')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect
      name="product_type"
      v-model="product.product_type"
      required
      :options="productTypes"
      autofocus
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="number"
    :label="'Item number'|pgettext('Product')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="number"
      v-model="product.number"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="name"
    :label="'Name'|pgettext('Product')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="name"
      v-model="product.name"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Description'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="description"
      v-model="product.description"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="unit"
    :label="'Unit'|pgettext('Product')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="unit"
      v-model="product.unit"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    labelColsMd="4"
    name="sales_vat_code"
    :label="'Sales tax code'|pgettext('Product')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect
      name="sales_vat_code"
      v-model="product.sales_vat_code"
      :options="taxCodes"
      required
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    name="sales_vat_code"
    :label="'Purchase tax code'|pgettext('Product')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect
      name="purchase_vat_code"
      v-model="product.purchase_vat_code"
      :options="taxCodes"
      required
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    name="intermediated_service"
    :label="'Intermediated service'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormCheckbox
      name="intermediated_service"
      v-model="product.intermediated_service"
    >
    </ItpFormCheckbox>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="invoice_group_id"
    :label="'Invoice Group'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="invoice_group_id"
      v-model="product.invoice_group_id"
      :multiple="b_3573211234170528_multiple"
      :options="invoiceGroups"
      valueField="id"
      textField="name"
      clearable
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <hr>
  </hr>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="groups"
    :label="'Groups'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="groups"
      v-model="product.groups"
      multiple
      :options="productGroups"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="customs_tariff_no"
    :label="'Customs tariff no.'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="customs_tariff_no"
      v-model="product.customs_tariff_no"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="hu_teszor_code"
    :label="'TESZOR code (HU)'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="hu_teszor_code"
      v-model="product.hu_teszor_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="eu_ewc_code"
    :label="'EWC code (EU)'|pgettext('Product')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="eu_ewc_code"
      v-model="product.eu_ewc_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetProductGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  product!: object;

  @Watch('product')
  onProduct(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("product")
  }

  labelAlign!: any;
  productTypes!: any;

  async $$load_productTypes() {
    return this.$fn.getEnumValuesFromSchema('ProductTypes')
  }

  productGroups!: any;

  async $$load_productGroups() {
    return this.$fn.fetch('list_group').then(data => data.data.map(p => p.name))
  }

  taxCodes!: any;

  async $$load_taxCodes() {
    return this.$fn.fetch('list_tax_code').then(data => data.map(p => p.id))
  }

  invoiceGroups!: any;

  async $$load_invoiceGroups() {
    return this.$fn.fetch('list_product_invoice_group')
  }

  ux!: any;
  b_3573211234170528_multiple!: any;
  dataMembers = ['labelAlign', 'productTypes', 'productGroups', 'taxCodes', 'invoiceGroups', 'ux', 'b_3573211234170528_multiple'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        productTypes: null,
        productGroups: null,
        taxCodes: null,
        invoiceGroups: null,
        ux: null,
        b_3573211234170528_multiple: false,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5784: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5783: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5784 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5783 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5784,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5783],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5784,
      evh_7315092382398562_reload_5783,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetProductGeneral", FieldsetProductGeneral);

</script>