<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="RentalService"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      :schema="oid == null ? 'CreateRentalService' : 'UpdateRentalService'"
      :id="formId"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <DIV
        v-if="assignToRental"
      >
        <ItpFormGroup
          labelColsMd="4"
          contentColsMd="8"
          name="rental_id"
          :label="'Rental'|pgettext('RentalService')"
          required
        >
          <ItpFormSelect2
            name="rental_id"
            v-model="item.rental_id"
            :initialOptions="item.rental ? [item.rental] : []"
            :dataSource="b_5731695935601903_dataSource"
            valueField="id"
            textField="name"
            use-option-template-for-selected-option
            optionTemplate="<UcSuggestRentalOption :option=&quot;option&quot;></UcSuggestRentalOption>"
            required
            :readonly="isReadOnly || preventEditRental"
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <hr>
        </hr>
      </DIV>
      <ItpFormGroup
        name="meter_id"
        :label="'Meter'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="meter_id"
          v-model="item.meter_id"
          :dataSource="b_2070266589801014_dataSource"
          :initialOptions="item.meter ? [item.meter] : []"
          valueField="id"
          textField="serial_number"
          use-option-template-for-selected-option
          optionTemplate="<UcSuggestMeterOption :option=&quot;option&quot;></UcSuggestMeterOption>"
          required
          :readonly="preventEditMeter || isReadOnly"
          @optionSelected="evh_2070266589801014_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="meter_factor"
        :label="'Meter factor'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          type="number"
          name="meter_factor"
          v-model="item.meter_factor"
          :readonly="isReadOnly"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="service_id"
        :label="'Service'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="service"
          v-model="item.service"
          :dataSource="b_5074589820235598_dataSource"
          bind-object
          valueField="id"
          textField="name"
          :readonly="isReadOnly"
          required
          @optionSelected="evh_5074589820235598_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="billing_unit"
        :label="'Billing unit'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="3"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="billing_unit"
          v-model="item.billing_unit"
          :options="units"
          valueField="symbol"
          textField="symbol"
          :readonly="isReadOnly"
          required
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="non_billable"
        :label="'Non billable'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="non_billable"
          inline
          v-model="item.non_billable"
          :readonly="isReadOnly"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        name="valid_from"
        :label="'Validity'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        contentClass="form-row"
      >
        <div
          class="col"
        >
          <ItpFormDatetime
            type="date"
            name="valid_from"
            :placeholder="'Valid from'|pgettext('InputPlaceholder')"
            v-model="item.valid_from"
            :readonly="isReadOnly"
          >
          </ItpFormDatetime>
        </div>
        <div
          class="col"
        >
          <ItpFormDatetime
            type="date"
            name="valid_to"
            :placeholder="'Valid to'|pgettext('InputPlaceholder')"
            v-model="item.valid_to"
            :readonly="isReadOnly"
          >
          </ItpFormDatetime>
        </div>
      </ItpFormGroup>
      <ItpFormGroup
        name="inactive"
        :label="'Inactive'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="inactive"
          inline
          v-model="item.inactive"
          :readonly="isReadOnly"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        name="notes"
        :label="'Notes'|pgettext('RentalService')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="note"
          v-model="item.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpBox
      class="mr-auto"
      v-if="oid && !item.locked"
    >
      <BtnDeleteResource
        :resource="item"
        :disabled="actions.submit.isRunning"
        @success="evh_2140198529698299_success($event, {})"
      >
      </BtnDeleteResource>
      <BtnLockResource
        :resource="item"
        :disabled="actions.submit.isRunning"
        class="ml-3"
        @success="evh_2434514588044777_success($event, {})"
      >
      </BtnLockResource>
    </ItpBox>
    <ItpButton
      :text="'Save'|pgettext('ModalButton')"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      v-if="!item.locked"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|pgettext('ModalButton')"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class RentalService extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Object,
    default: null,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  @Prop({
    type: Object,
    default: null,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  preventEditRental!: boolean;

  @Watch('preventEditRental')
  onPrevent_edit_rental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("preventEditRental")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  preventEditMeter!: boolean;

  @Watch('preventEditMeter')
  onPrevent_edit_meter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("preventEditMeter")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  assignToRental!: boolean;

  @Watch('assignToRental')
  onAssign_to_rental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("assignToRental")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  assignToMeter!: boolean;

  @Watch('assignToMeter')
  onAssign_to_meter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("assignToMeter")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.oid
      ? this.$fn.fetch('read_rental_service', { parameters: { id: this.oid } })
      : this.$fn.fetch('get_rental_service_defaults', {
        parameters: {
          meter_id: this.meter ? this.meter.id : null,
          rental_id: this.rental ? this.rental.id : null,
        }
      })
  }

  units!: any;

  async $$load_units() {
    return this.$fn.fetch('list_unit').then(d => _.orderBy(d, [p => p.symbol.toLowerCase()]))
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  isReadOnly!: any;

  async $$load_isReadOnly() {
    return this.item.locked
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5731695935601903_dataSource!: any;
  b_2070266589801014_dataSource!: any;
  b_5074589820235598_dataSource!: any;
  dataMembers = ['item', 'units', 'formName', 'formId', 'labelAlign', 'isReadOnly', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource', 'b_2070266589801014_dataSource', 'b_5074589820235598_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        units: null,
        formName: "rentalService",
        formId: null,
        labelAlign: "right",
        isReadOnly: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_5731695935601903_dataSource: {
          name: "suggest_rental",
        }
        ,
        b_2070266589801014_dataSource: {
          name: "suggest_meter",
        }
        ,
        b_5074589820235598_dataSource: {
          name: "suggest_product",
          parameters: {
            product_types: "service",
          }
          ,
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid ? this.$fn.gettext('Edit Rental Service') : this.$fn.gettext('Add Rental Service'),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_7048_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_7048, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_7054_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_7054, alias=undefined
    return {
      name: this.modalName,
      result: {
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_request_7052_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_7052, alias=submit
    return {
      operation: this.item.id ? 'update_rental_service' : 'create_rental_service',
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_2070266589801014_script_7058_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2070266589801014_script_7058, alias=undefined
    _.extend(this.item, $event.data.response.data)
  }

  async act_2070266589801014_script_7058_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2070266589801014_script_7058, alias=undefined
    return {
      value: () => this.act_2070266589801014_script_7058_getActionArgs_value($event),
    }
  }

  async act_2070266589801014_request_7056_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2070266589801014_request_7056, alias=getDefaultsOnMeterSelected
    return {
      operation: "get_rental_service_defaults",
      data: {
        rental_id: this.rental ? this.rental.id : this.item.rental_id,
        meter_id: this.item.meter_id,
      }
      ,
    }
  }

  async evh_2070266589801014_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2070266589801014_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_5074589820235598_setData_7060_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5074589820235598_setData_7060, alias=undefined
    return {
      path: "item.service_id",
      value: this.item.service.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5074589820235598_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5074589820235598_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_2140198529698299_closeModal_7062_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2140198529698299_closeModal_7062, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_2140198529698299_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2140198529698299_success.executeFromDOM(this, event, scope);
  }

  async act_2434514588044777_closeModal_7064_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2434514588044777_closeModal_7064, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_2434514588044777_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2434514588044777_success.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_closeModal_7066_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2186679009640457_closeModal_7066, alias=undefined
    return {
      name: this.modalName,
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_7048: actions.CloseModalAction;
    evh_7315092382398562_close_7047: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_7050: actions.CloseComponentAction;
    evh_2248226175642056_close_7049: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_7054: actions.CloseModalAction;
    evh_1040167445267876_success_7053: actions.EventHandlerImpl;
    act_1040167445267876_request_7052: actions.RequestAction;
    evh_1040167445267876_submit_7051: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_2070266589801014_script_7058: actions.ScriptAction;
    evh_2070266589801014_success_7057: actions.EventHandlerImpl;
    act_2070266589801014_request_7056: actions.RequestAction;
    evh_2070266589801014_optionSelected_7055: actions.EventHandlerImpl;
    evh_2070266589801014_optionSelected: actions.EventHandlerGroup;
    act_5074589820235598_setData_7060: actions.SetDataAction;
    evh_5074589820235598_optionSelected_7059: actions.EventHandlerImpl;
    evh_5074589820235598_optionSelected: actions.EventHandlerGroup;
    act_2140198529698299_closeModal_7062: actions.CloseModalAction;
    evh_2140198529698299_success_7061: actions.EventHandlerImpl;
    evh_2140198529698299_success: actions.EventHandlerGroup;
    act_2434514588044777_closeModal_7064: actions.CloseModalAction;
    evh_2434514588044777_success_7063: actions.EventHandlerImpl;
    evh_2434514588044777_success: actions.EventHandlerGroup;
    act_2186679009640457_closeModal_7066: actions.CloseModalAction;
    evh_2186679009640457_clickPreventStop_7065: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_7048 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_7048_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_7047 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_7048,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_7047],
      }
    );
    const act_2248226175642056_closeComponent_7050 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_7049 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_7050,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_7049],
      }
    );
    const act_1040167445267876_closeModal_7054 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_7054_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_7053 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_7054,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_7052 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_7052_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_7053],
      }
    );
    const evh_1040167445267876_submit_7051 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_7052,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_7051],
      }
    );
    const act_2070266589801014_script_7058 = new actions.ScriptAction(
      {
        actionArgs: this.act_2070266589801014_script_7058_getActionArgs,
        events: [],
      }
    );
    const evh_2070266589801014_success_7057 = new actions.EventHandlerImpl(
      {
        action: act_2070266589801014_script_7058,
        event: "success",
        displayName: "script",
      }
    );
    const act_2070266589801014_request_7056 = new actions.RequestAction(
      {
        actionArgs: this.act_2070266589801014_request_7056_getActionArgs,
        displayName: "getDefaultsOnMeterSelected",
        events: [evh_2070266589801014_success_7057],
      }
    );
    const evh_2070266589801014_optionSelected_7055 = new actions.EventHandlerImpl(
      {
        action: act_2070266589801014_request_7056,
        event: "optionSelected",
        displayName: "getDefaultsOnMeterSelected",
      }
    );
    const evh_2070266589801014_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_2070266589801014_optionSelected_7055],
      }
    );
    const act_5074589820235598_setData_7060 = new actions.SetDataAction(
      {
        actionArgs: this.act_5074589820235598_setData_7060_getActionArgs,
        events: [],
      }
    );
    const evh_5074589820235598_optionSelected_7059 = new actions.EventHandlerImpl(
      {
        action: act_5074589820235598_setData_7060,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_5074589820235598_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5074589820235598_optionSelected_7059],
      }
    );
    const act_2140198529698299_closeModal_7062 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2140198529698299_closeModal_7062_getActionArgs,
        events: [],
      }
    );
    const evh_2140198529698299_success_7061 = new actions.EventHandlerImpl(
      {
        action: act_2140198529698299_closeModal_7062,
        event: "success",
        displayName: "closeModal",
      }
    );
    const evh_2140198529698299_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_2140198529698299_success_7061],
      }
    );
    const act_2434514588044777_closeModal_7064 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2434514588044777_closeModal_7064_getActionArgs,
        events: [],
      }
    );
    const evh_2434514588044777_success_7063 = new actions.EventHandlerImpl(
      {
        action: act_2434514588044777_closeModal_7064,
        event: "success",
        displayName: "closeModal",
      }
    );
    const evh_2434514588044777_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_2434514588044777_success_7063],
      }
    );
    const act_2186679009640457_closeModal_7066 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2186679009640457_closeModal_7066_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_7065 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeModal_7066,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_7065],
      }
    );
    return {
      act_7315092382398562_closeModal_7048,
      evh_7315092382398562_close_7047,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_7050,
      evh_2248226175642056_close_7049,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_7054,
      evh_1040167445267876_success_7053,
      act_1040167445267876_request_7052,
      evh_1040167445267876_submit_7051,
      evh_1040167445267876_submit,
      act_2070266589801014_script_7058,
      evh_2070266589801014_success_7057,
      act_2070266589801014_request_7056,
      evh_2070266589801014_optionSelected_7055,
      evh_2070266589801014_optionSelected,
      act_5074589820235598_setData_7060,
      evh_5074589820235598_optionSelected_7059,
      evh_5074589820235598_optionSelected,
      act_2140198529698299_closeModal_7062,
      evh_2140198529698299_success_7061,
      evh_2140198529698299_success,
      act_2434514588044777_closeModal_7064,
      evh_2434514588044777_success_7063,
      evh_2434514588044777_success,
      act_2186679009640457_closeModal_7066,
      evh_2186679009640457_clickPreventStop_7065,
      evh_2186679009640457_clickPreventStop,
      submit: act_1040167445267876_request_7052,
      getDefaultsOnMeterSelected: act_2070266589801014_request_7056,
    }
  }
}

Vue.component("RentalService", RentalService);

</script>