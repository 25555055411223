"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let LetterRegisterEntry = class LetterRegisterEntry extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['container', 'formId', 'formName', 'resource', 'editor', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onRegister(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("register");
    }
    onCompany_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("companyId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_container() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                self: this.$fn.urlFor('create_letter_register_entry'),
                object: 'list',
                data: []
            };
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_resource() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid
                ? this.$fn.fetch('read_letter_register_entry', { parameters: { id: this.oid }, asResource: true })
                : this.$fn.schemaDefaults('CreateLetterRegisterEntryCommand', {
                    id: null,
                    register_name: this.register.id,
                    company_id: this.companyId,
                    entry_date: this.$fn.today()
                });
        });
    }
    $$load_editor() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.resource.editor();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            container: null,
            formId: null,
            formName: "letterRegisterEntry",
            resource: null,
            editor: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get isDeletable() {
        if (this.loaded) {
            return this.oid != null;
        }
        return null;
    }
    get creatingTitle() {
        if (this.loaded) {
            return this.$fn.pgettext("LetterRegisterEntry", "Create new entry");
        }
        return null;
    }
    get editingTitle() {
        if (this.loaded) {
            return '#' + this.resource.entry_number;
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.resource.isNew ? this.creatingTitle : this.editingTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2796_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_2804_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_emit_2808_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data,
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_2810_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "letterRegister",
            };
        });
    }
    act_1040167445267876_rest_2806_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.editor,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_script_2812_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            (this.setData('resource', this.resource.clone({
                id: null,
                rev: null,
                entry_number: null,
                subject: null,
                links: null,
                etag: null,
                locked: null,
            })),
                this.setData('editor', this.resource.editor()));
        });
    }
    act_1548630417156826_script_2812_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1548630417156826_script_2812_getActionArgs_value($event),
            };
        });
    }
    evh_1548630417156826_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2796 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2796_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2795 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2796,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2795],
        });
        const act_2248226175642056_closeComponent_2798 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2797 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2798,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2797],
        });
        const act_8564662037462133_reloadComponentData_2800 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_2799 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_2800,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_2799],
        });
        const act_8564662037462133_closeModal_2802 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_8564662037462133_deleted_2801 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_2802,
            event: "deleted",
            displayName: "closeModal",
        });
        const act_8564662037462133_reloadSlickgrid_2804 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_2804_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_2803 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_2804,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_2801, evh_8564662037462133_deleted_2803],
        });
        const act_1040167445267876_emit_2808 = new core_1.actions.EmitAction({
            actionArgs: this.act_1040167445267876_emit_2808_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_2807 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_emit_2808,
            event: "saved",
            displayName: "emit",
        });
        const act_1040167445267876_reloadSlickgrid_2810 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_2810_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_2809 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_2810,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_rest_2806 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_2806_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_saved_2807, evh_1040167445267876_saved_2809],
        });
        const evh_1040167445267876_submit_2805 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_2806,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2805],
        });
        const act_1548630417156826_script_2812 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1548630417156826_script_2812_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_click_2811 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_script_2812,
            event: "click",
            displayName: "script",
        });
        const evh_1548630417156826_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_click_2811],
        });
        const act_1419464017721962_closeComponent_2814 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_2813 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_2814,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2813],
        });
        return {
            act_7315092382398562_closeModal_2796,
            evh_7315092382398562_close_2795,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2798,
            evh_2248226175642056_close_2797,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_2800,
            evh_8564662037462133_reload_2799,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_2802,
            evh_8564662037462133_deleted_2801,
            act_8564662037462133_reloadSlickgrid_2804,
            evh_8564662037462133_deleted_2803,
            evh_8564662037462133_deleted,
            act_1040167445267876_emit_2808,
            evh_1040167445267876_saved_2807,
            act_1040167445267876_reloadSlickgrid_2810,
            evh_1040167445267876_saved_2809,
            act_1040167445267876_rest_2806,
            evh_1040167445267876_submit_2805,
            evh_1040167445267876_submit,
            act_1548630417156826_script_2812,
            evh_1548630417156826_click_2811,
            evh_1548630417156826_click,
            act_1419464017721962_closeComponent_2814,
            evh_1419464017721962_clickPreventStop_2813,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_rest_2806,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], LetterRegisterEntry.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegisterEntry.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], LetterRegisterEntry.prototype, "register", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('register'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegisterEntry.prototype, "onRegister", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], LetterRegisterEntry.prototype, "companyId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('companyId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegisterEntry.prototype, "onCompany_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], LetterRegisterEntry.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], LetterRegisterEntry.prototype, "onSize", null);
LetterRegisterEntry = __decorate([
    (0, vue_property_decorator_1.Component)()
], LetterRegisterEntry);
exports.default = LetterRegisterEntry;
vue_property_decorator_1.Vue.component("LetterRegisterEntry", LetterRegisterEntry);
