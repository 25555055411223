<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <FieldsetPropertyGeneralData
    :property="property"
    :labelAlign="labelAlign"
    v-if="!target || target === 'general'"
  >
  </FieldsetPropertyGeneralData>
  <hr
    v-if="!target"
  >
  </hr>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPropertyEditorFieldset extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  property!: object;

  @Watch('property')
  onProperty(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("property")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  labelAlign!: any;
  ux!: any;
  dataMembers = ['labelAlign', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_3382: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_3381: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_3382 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_3381 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_3382,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_3381],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_3382,
      evh_7315092382398562_reload_3381,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcPropertyEditorFieldset", UcPropertyEditorFieldset);

</script>