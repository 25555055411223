<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceRegister"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="invoiceRegister"
    :headerTitle="'Invoice register'|pgettext('Invoice register')"
    :items="$fn.slickDataSource('list_invoice_register_entries', {parameters: getQueryParameters})"
    export-to-csv
    :exportToCsvFilename="'invoice-register'|pgettext('filename')"
    footer-totals
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @command="evh_2248226175642056_command($event, {})"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormSelect2
        name="register_name"
        v-model="selectedRegister"
        bind-object
        valueField="id"
        textField="name"
        :options="registers"
        class="w-25 mx-3"
        @input="evh_6466705138206980_input($event, {})"
      >
      </ItpFormSelect2>
      <ItpButton
        :icon="$config.invoiceRegister.createEntry"
        :text="'Add entry...'|pgettext('InvoiceRegister')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <b-dropdown
        size="sm"
        variant="light"
        toggle-class="itp-button border"
        :tooltip="'Set status to selected items'|pgettext('InvoiceRegister')"
        :disabled="!selectedItems || !selectedItems.length || (
    actions.setStatus && actions.setStatus.isRunning
  )"
        class="mx-2"
        v-if="statuses && statuses.length"
      >
        <span
          slot="button-content"
        >
          <b-spinner
            small
            type="grow"
            class="mr-1"
            v-if="actions.setStatus.isRunning"
          >
          </b-spinner>
          <ItpText
            :text="'Set status'|pgettext('InvoiceRegister')"
            class="mx-1"
          >
          </ItpText>
        </span>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          :key="status"
          v-for="status in statuses"
          @click.prevent.stop="evh_5382094220310827_clickPreventStop($event, {status})"
        >
          <ItpText
            :text="status"
          >
          </ItpText>
        </b-dropdown-item-button>
        <hr>
        </hr>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          @click.prevent.stop="evh_8704926098283402_clickPreventStop($event, {})"
        >
          <ItpText
            :text="'Clear status'|pgettext('InvoiceRegister')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoiceRegister",
    path: "/invoice-register",
    component: "InvoiceRegister"
  }
)

@Component()
export default class InvoiceRegister extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedRegisterId = new ComponentValueCache(this, "selectedRegisterId", "InvoiceRegister.selectedRegisterId");
  }

  getQueryParameters(...args: any[]) {
    return {
      sort_mode: 'desc',
      register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
    }
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  registers!: any;

  async $$load_registers() {
    return this.$fn.fetch('list_register')
      .then(data => _.filter(data, d => _.includes(d.register_type, 'incoming_invoice')))
  }

  selectedRegisterId!: any;
  selectedRegister!: any;

  async $$load_selectedRegister() {
    return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0]
  }

  selectedItems!: any;
  statuses!: any;

  async $$load_statuses() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name))
  }

  selectedStatus!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "entry_number",
      name: this.$fn.pgettext("Invoice register", "No."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "ref_entry_number",
      name: this.$fn.pgettext("Invoice register", "Ref."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "invoice_number",
      name: this.$fn.pgettext("Invoice register", "Invoice number"),
      formatter: [{
        name: "chain",
        formatters: [{
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "invoice_id",
          }
          ,
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'bg-yellow-200': row.invoice_id != null && row.external_key == null,
            'bg-red-200': row.invoice_id == null,
            'font-weight-bold': row.invoice_id != null
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "received_date",
      name: this.$fn.pgettext("Invoice register", "Received date"),
      type: "date",
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("Invoice register", "Invoice type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "status",
      name: this.$fn.pgettext("Invoice register", "Status"),
    }
      ,
    {
      field: "categories",
      name: this.$fn.pgettext("Invoice register", "Category"),
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("Invoice register", "Vendor"),
      formatter: {
        name: "routeLink",
        to: "client",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("Invoice register", "Subject"),
    }
      ,
    {
      field: "notes",
      name: this.$fn.pgettext("Invoice register", "Notes"),
    }
      ,
    {
      field: "amount",
      name: this.$fn.pgettext("Invoice register", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("Invoice register", "Currency"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("Invoice register", "Fulfillment date"),
      type: "date",
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("Invoice register", "Due date"),
      type: "date",
    }
      ,
    ]
  }

  dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'statuses', 'selectedStatus', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        registers: null,
        selectedRegisterId: null,
        selectedRegister: null,
        selectedItems: [
        ]
        ,
        statuses: null,
        selectedStatus: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Invoice register", "Invoice register"),
        icon: this.$config.invoiceRegister.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2574_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2574, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_2578_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2578, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2580_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2580, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_2579_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2579, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2576_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2576, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2575_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2575, alias=undefined
    return $event.data.column.id === 'entry_number'
  }

  async act_2248226175642056_reloadSlickgrid_2584_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2584, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2586_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2586, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_2585_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2585, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2582_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2582, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.ref_entry_id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2581_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2581, alias=undefined
    return $event.data.column.id === 'ref_entry_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2588_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2588, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_reloadSlickgrid_2590_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6466705138206980_reloadSlickgrid_2590, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_6466705138206980_setData_2592_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_2592, alias=updateSelectedRegisterId
    return {
      path: "selectedRegisterId",
      value: $event.data.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_reloadSlickgrid_2596_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2596, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8320016629450276_reloadSlickgrid_2598_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2598, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_8320016629450276_close_2597_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_2597, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_2594_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2594, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        register: this.selectedRegister,
        companyId: this.selectedRegister.company_id,
      }
      ,
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  async act_5382094220310827_setData_2600_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5382094220310827_setData_2600, alias=undefined
    return {
      path: "selectedStatus",
      value: $event.scope.status,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5382094220310827_reloadSlickgrid_2604_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_5382094220310827_reloadSlickgrid_2604, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_5382094220310827_request_2602_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5382094220310827_request_2602, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_5382094220310827_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5382094220310827_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8704926098283402_setData_2606_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8704926098283402_setData_2606, alias=undefined
    return {
      path: "selectedStatus",
      value: null,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8704926098283402_reloadSlickgrid_2610_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8704926098283402_reloadSlickgrid_2610, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8704926098283402_request_2608_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8704926098283402_request_2608, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_8704926098283402_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8704926098283402_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2574: actions.CloseModalAction;
    evh_7315092382398562_close_2573: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_2578: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2577: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2580: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2579: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2576: actions.ShowModalAction;
    evh_2248226175642056_command_2575: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2584: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2583: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2586: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2585: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2582: actions.ShowModalAction;
    evh_2248226175642056_command_2581: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_2248226175642056_setData_2588: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2587: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_reloadSlickgrid_2590: actions.ReloadSlickgridAction;
    evh_6466705138206980_input_2589: actions.EventHandlerImpl;
    act_6466705138206980_setData_2592: actions.SetDataAction;
    evh_6466705138206980_input_2591: actions.EventHandlerImpl;
    evh_6466705138206980_input: actions.EventHandlerGroup;
    act_8320016629450276_reloadSlickgrid_2596: actions.ReloadSlickgridAction;
    evh_8320016629450276_saved_2595: actions.EventHandlerImpl;
    act_8320016629450276_reloadSlickgrid_2598: actions.ReloadSlickgridAction;
    evh_8320016629450276_close_2597: actions.EventHandlerImpl;
    act_8320016629450276_showModal_2594: actions.ShowModalAction;
    evh_8320016629450276_click_2593: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
    act_5382094220310827_setData_2600: actions.SetDataAction;
    evh_5382094220310827_clickPreventStop_2599: actions.EventHandlerImpl;
    act_5382094220310827_reloadSlickgrid_2604: actions.ReloadSlickgridAction;
    evh_5382094220310827_success_2603: actions.EventHandlerImpl;
    act_5382094220310827_request_2602: actions.RequestAction;
    evh_5382094220310827_clickPreventStop_2601: actions.EventHandlerImpl;
    evh_5382094220310827_clickPreventStop: actions.EventHandlerGroup;
    act_8704926098283402_setData_2606: actions.SetDataAction;
    evh_8704926098283402_clickPreventStop_2605: actions.EventHandlerImpl;
    act_8704926098283402_reloadSlickgrid_2610: actions.ReloadSlickgridAction;
    evh_8704926098283402_success_2609: actions.EventHandlerImpl;
    act_8704926098283402_request_2608: actions.RequestAction;
    evh_8704926098283402_clickPreventStop_2607: actions.EventHandlerImpl;
    evh_8704926098283402_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2574 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2574_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2573 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2574,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2573],
      }
    );
    const act_2248226175642056_reloadSlickgrid_2578 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2578_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_2577 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2578,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2580 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2580_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_2579 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2580,
        event: "close",
        when: this.evh_2248226175642056_close_2579_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2576 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2576_getActionArgs,
        events: [evh_2248226175642056_saved_2577, evh_2248226175642056_close_2579],
      }
    );
    const evh_2248226175642056_command_2575 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2576,
        event: "command",
        when: this.evh_2248226175642056_command_2575_getWhen,
        displayName: "showModal",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2584 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2584_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_2583 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2584,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2586 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2586_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_2585 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2586,
        event: "close",
        when: this.evh_2248226175642056_close_2585_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2582 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2582_getActionArgs,
        events: [evh_2248226175642056_saved_2583, evh_2248226175642056_close_2585],
      }
    );
    const evh_2248226175642056_command_2581 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2582,
        event: "command",
        when: this.evh_2248226175642056_command_2581_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_2575, evh_2248226175642056_command_2581],
      }
    );
    const act_2248226175642056_setData_2588 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2588_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2587 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2588,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2587],
      }
    );
    const act_6466705138206980_reloadSlickgrid_2590 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6466705138206980_reloadSlickgrid_2590_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_6466705138206980_input_2589 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadSlickgrid_2590,
        event: "input",
        displayName: "reloadSlickgrid",
      }
    );
    const act_6466705138206980_setData_2592 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_2592_getActionArgs,
        displayName: "updateSelectedRegisterId",
        events: [],
      }
    );
    const evh_6466705138206980_input_2591 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_2592,
        event: "input",
        displayName: "updateSelectedRegisterId",
      }
    );
    const evh_6466705138206980_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_input_2589, evh_6466705138206980_input_2591],
      }
    );
    const act_8320016629450276_reloadSlickgrid_2596 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2596_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_saved_2595 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2596,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_reloadSlickgrid_2598 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2598_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_close_2597 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2598,
        event: "close",
        when: this.evh_8320016629450276_close_2597_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_showModal_2594 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2594_getActionArgs,
        events: [evh_8320016629450276_saved_2595, evh_8320016629450276_close_2597],
      }
    );
    const evh_8320016629450276_click_2593 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2594,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_2593],
      }
    );
    const act_5382094220310827_setData_2600 = new actions.SetDataAction(
      {
        actionArgs: this.act_5382094220310827_setData_2600_getActionArgs,
        events: [],
      }
    );
    const evh_5382094220310827_clickPreventStop_2599 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_setData_2600,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_5382094220310827_reloadSlickgrid_2604 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_5382094220310827_reloadSlickgrid_2604_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_5382094220310827_success_2603 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_reloadSlickgrid_2604,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_5382094220310827_request_2602 = new actions.RequestAction(
      {
        actionArgs: this.act_5382094220310827_request_2602_getActionArgs,
        displayName: "setStatus",
        events: [evh_5382094220310827_success_2603],
      }
    );
    const evh_5382094220310827_clickPreventStop_2601 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_request_2602,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_5382094220310827_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5382094220310827_clickPreventStop_2599, evh_5382094220310827_clickPreventStop_2601],
      }
    );
    const act_8704926098283402_setData_2606 = new actions.SetDataAction(
      {
        actionArgs: this.act_8704926098283402_setData_2606_getActionArgs,
        events: [],
      }
    );
    const evh_8704926098283402_clickPreventStop_2605 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_setData_2606,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_8704926098283402_reloadSlickgrid_2610 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8704926098283402_reloadSlickgrid_2610_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8704926098283402_success_2609 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_reloadSlickgrid_2610,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8704926098283402_request_2608 = new actions.RequestAction(
      {
        actionArgs: this.act_8704926098283402_request_2608_getActionArgs,
        displayName: "setStatus",
        events: [evh_8704926098283402_success_2609],
      }
    );
    const evh_8704926098283402_clickPreventStop_2607 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_request_2608,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_8704926098283402_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8704926098283402_clickPreventStop_2605, evh_8704926098283402_clickPreventStop_2607],
      }
    );
    return {
      act_7315092382398562_closeModal_2574,
      evh_7315092382398562_close_2573,
      evh_7315092382398562_close,
      act_2248226175642056_reloadSlickgrid_2578,
      evh_2248226175642056_saved_2577,
      act_2248226175642056_reloadSlickgrid_2580,
      evh_2248226175642056_close_2579,
      act_2248226175642056_showModal_2576,
      evh_2248226175642056_command_2575,
      act_2248226175642056_reloadSlickgrid_2584,
      evh_2248226175642056_saved_2583,
      act_2248226175642056_reloadSlickgrid_2586,
      evh_2248226175642056_close_2585,
      act_2248226175642056_showModal_2582,
      evh_2248226175642056_command_2581,
      evh_2248226175642056_command,
      act_2248226175642056_setData_2588,
      evh_2248226175642056_selectedRowsChanged_2587,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_reloadSlickgrid_2590,
      evh_6466705138206980_input_2589,
      act_6466705138206980_setData_2592,
      evh_6466705138206980_input_2591,
      evh_6466705138206980_input,
      act_8320016629450276_reloadSlickgrid_2596,
      evh_8320016629450276_saved_2595,
      act_8320016629450276_reloadSlickgrid_2598,
      evh_8320016629450276_close_2597,
      act_8320016629450276_showModal_2594,
      evh_8320016629450276_click_2593,
      evh_8320016629450276_click,
      act_5382094220310827_setData_2600,
      evh_5382094220310827_clickPreventStop_2599,
      act_5382094220310827_reloadSlickgrid_2604,
      evh_5382094220310827_success_2603,
      act_5382094220310827_request_2602,
      evh_5382094220310827_clickPreventStop_2601,
      evh_5382094220310827_clickPreventStop,
      act_8704926098283402_setData_2606,
      evh_8704926098283402_clickPreventStop_2605,
      act_8704926098283402_reloadSlickgrid_2610,
      evh_8704926098283402_success_2609,
      act_8704926098283402_request_2608,
      evh_8704926098283402_clickPreventStop_2607,
      evh_8704926098283402_clickPreventStop,
      reloadSlickgrid: act_8704926098283402_reloadSlickgrid_2610,
      updateSelectedRegisterId: act_6466705138206980_setData_2592,
      setStatus: act_8704926098283402_request_2608,
    }
  }
}

Vue.component("InvoiceRegister", InvoiceRegister);

</script>