<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <span
    :class="['mr-2', dotClass]"
  >
    ⬤
  </span>
  <component
    :is="strong ? 'strong' : 'span'"
  >
    {{ value|pgettext('WorksheetStatus', 'Sentence') }}
  </component>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcWorksheetStatus extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  value!: string;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  @Prop({
    type: Boolean,
  })
  strong!: boolean;

  @Watch('strong')
  onStrong(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("strong")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get dotClass() {
    if (this.loaded) {

      return 'text-' + this.$config.statuses.colors[this.value]
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6012: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6011: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6012 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6011 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6012,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6011],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6012,
      evh_7315092382398562_reload_6011,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcWorksheetStatus", UcWorksheetStatus);

</script>