<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    name="contract_number"
    :label="'Contract number'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    required
    description="Bármilyen formátum megengedett, de a szerződésszámnak egyedinek kell kell lennie."
  >
    <ItpFormInput
      name="contract_number"
      v-model="contract.contract_number"
      required
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="description"
    :label="'Description'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="description"
      v-model="contract.extras.description"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="reference_number"
    :label="'Reference number'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="5"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="reference_number"
      v-model="contract.extras.reference_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    name="folder_number"
    :label="'Folder number'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="3"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="folder_number"
      v-model="contract.extras.folder_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractNumbersData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6526: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6525: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6526 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6525 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6526,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6525],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6526,
      evh_7315092382398562_reload_6525,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetContractNumbersData", FieldsetContractNumbersData);

</script>