"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MRentalGeneralDataEditor = class MRentalGeneralDataEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['formId', 'ux'];
    }
    beforeCreate() {
    }
    onRental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rental");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            ux: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Rental", "Edit General Information"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6930_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_6936_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_6934_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "rental",
                op: "upsert",
                data: this.rental,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeComponent_6938_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    cancel: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6930 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6930_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6929 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6930,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6929],
        });
        const act_2248226175642056_closeComponent_6932 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6931 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6932,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6931],
        });
        const act_1040167445267876_closeModal_6936 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6936_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6935 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6936,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_crud_6934 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6934_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6935],
        });
        const evh_1040167445267876_submit_6933 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6934,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6933],
        });
        const act_1419464017721962_closeComponent_6938 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1419464017721962_closeComponent_6938_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_6937 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_6938,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6937],
        });
        return {
            act_7315092382398562_closeModal_6930,
            evh_7315092382398562_close_6929,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6932,
            evh_2248226175642056_close_6931,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_6936,
            evh_1040167445267876_success_6935,
            act_1040167445267876_crud_6934,
            evh_1040167445267876_submit_6933,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_6938,
            evh_1419464017721962_clickPreventStop_6937,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_crud_6934,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], MRentalGeneralDataEditor.prototype, "rental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MRentalGeneralDataEditor.prototype, "onRental", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], MRentalGeneralDataEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MRentalGeneralDataEditor.prototype, "onSize", null);
MRentalGeneralDataEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], MRentalGeneralDataEditor);
exports.default = MRentalGeneralDataEditor;
vue_property_decorator_1.Vue.component("MRentalGeneralDataEditor", MRentalGeneralDataEditor);
