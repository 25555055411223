<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <b-navbar
    type="light"
    variant="default"
  >
    <b-navbar-brand
      href="#/"
    >
      {{ $app.spec.brand }}
<ItpBadge v-if=displayTestBadge variant="danger" class="mx-2">Test</ItpBadge>
<ItpBadge v-if=displayDevBadge variant="warning" class="mx-2">Dev</ItpBadge>

    </b-navbar-brand>
    <b-collapse
      is-nav
    >
      <b-navbar-nav>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Finance'|pgettext('MainMenu') }}
          </template>
          <ItpDropdownText>
            {{ 'Register'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/letter-register"
            :icon="$config.letterRegister.icon"
            :text="'Letter register'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/invoice-register"
            :icon="$config.invoiceRegister.icon"
            :text="'Invoice register'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Customer invoices'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/invoices?kind=outgoing"
            :icon="$config.invoice.customerInvoiceIcon"
            :text="'Customer invoices'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Customer Invoice...'|pgettext('MainMenu')"
            :icon="$config.invoice.customerInvoiceIcon"
            dropdown-item
            @click.prevent="evh_8512678877908410_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            to="/draft-invoices"
            :icon="$config.invoice.draftInvoicesIcon"
            :text="'Invoice Generation'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Incoming invoices'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/invoices?kind=incoming"
            :icon="$config.invoice.vendorInvoiceIcon"
            :text="'Incoming invoices'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Incoming Invoice...'|pgettext('MainMenu')"
            :icon="$config.invoice.icon"
            dropdown-item
            @click.prevent="evh_5794187517758435_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Payments'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/payments"
            :icon="$config.payment.icon"
            :text="'Payments'|pgettext('PaymentsView')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/accounts"
            :icon="$config.account.icon"
            :text="'Cash and Bank Accounts'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/accounts-daily-flow"
            :icon="$config.accountsDailyFlow.icon"
            :text="'Accounts Daily Flow'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Cash In...'|pgettext('Create Payment')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_6201675214105229_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Cash Out...'|pgettext('Create Payment')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_6373990564425123_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Banking...'|pgettext('Create Payment')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_8819772728062230_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Account statement report...'|pgettext('MeinMenu')"
            :icon="$config.payment.icon"
            dropdown-item
            @click.prevent="evh_2424316887484278_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Claims management'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/claims"
            :icon="$config.claim.icon"
            :text="'Claims'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/dunning-letters"
            :icon="$config.dunningLetter.icon"
            :text="'Dunning letters'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Assets'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/fixed-assets"
            :icon="$config.fixedAsset.icon"
            :text="'Fixed Assets'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Create Asset...'|pgettext('MainMenu')"
            :icon="$config.fixedAsset.icon"
            dropdown-item
            @click.prevent="evh_1886107574314164_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpNavItem
            to="/waste-bins"
            :icon="$config.wasteBin.icon"
            :text="'Waste Bins'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            to="/waste-bin-readings"
            :icon="$config.wasteBin.icon"
            :text="'Waste Bin Readings'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Create Waste Bin...'|pgettext('MainMenu')"
            :icon="$config.wasteBin.createWasteBinIcon"
            dropdown-item
            @click.prevent="evh_7260361660686930_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpDropdownText>
            {{ 'Meters'|pgettext('MainMenu') }}
          </ItpDropdownText>
          <ItpNavItem
            to="/meters"
            :icon="$config.meter.icon"
            :text="'Meters'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Add Meter...'|pgettext('MainMenu')"
            :icon="$config.meter.addMeterIcon"
            dropdown-item
            @click.prevent="evh_5449511595610967_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            to="/auto-readings"
            :icon="$config.meterAutoReading.icon"
            :text="'Auto Readings'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            href="#"
            icon="fa-upload"
            :text="'Meter Data Exchange...'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_8050584411731670_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            href="#"
            :icon="$config.meterReplacement.icon"
            :text="'Replace meter...'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_7807097906137782_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Contracts'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/contracts"
            :icon="$config.contract.icon"
            :text="'Contracts'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Create Contract...'|pgettext('MainMenu')"
            :icon="$config.contract.icon"
            dropdown-item
            @click.prevent="evh_2338624680052783_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpNavItem
            to="/overlapping-revisions"
            :icon="$config.contract.views.overlappingRevisions.icon"
            :text="'Overlapping Revisions'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Rentals'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/rentals"
            :icon="$config.rental.icon"
            :text="'Rentals'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Add Rental...'|pgettext('MainMenu')"
            :icon="$config.rental.addRentalIcon"
            dropdown-item
            @click.prevent="evh_2596033535867385_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpDropdownDivider>
          </ItpDropdownDivider>
          <ItpNavItem
            to="/properties"
            :icon="$config.property.icon"
            :text="'Locations'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'Add Property...'|pgettext('MainMenu')"
            :icon="$config.property.addPropertyIcon"
            dropdown-item
            @click.prevent="evh_6794737306373342_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Clients'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/clients"
            :icon="$config.client.icon"
            :text="'Clients'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New client...'|pgettext('MainMenu')"
            :icon="$config.client.createClientIcon"
            dropdown-item
            @click.prevent="evh_2660996057968802_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Products'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/products"
            :icon="$config.product.icon"
            :text="'Products'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New product/service...'|pgettext('MainMenu')"
            :icon="$config.product.addProductIcon"
            dropdown-item
            @click.prevent="evh_7567745526909371_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown>
          <template
            slot="button-content"
          >
            {{ 'Worksheets'|pgettext('MainMenu') }}
          </template>
          <ItpNavItem
            to="/worksheets"
            :icon="$config.worksheet.icon"
            :text="'Worksheets'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            :text="'New Worksheet...'|pgettext('MainMenu')"
            :icon="$config.worksheet.createWorksheetIcon"
            dropdown-item
            @click.prevent="evh_7228767045058026_clickPrevent($event, {})"
          >
          </ItpNavItem>
          <ItpNavItem
            to="/worksheets-work-summary"
            :icon="$config.worksheet.icon"
            :text="'Work summary'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <Charts>
        </Charts>
      </b-navbar-nav>
      <b-navbar-nav
        class="ml-auto"
      >
        <b-nav-item-dropdown
          right
          class="mx-3"
        >
          <template
            slot="button-content"
          >
            <ItpIcon
              name="fa-user-circle"
              class="mr-1"
            >
            </ItpIcon>
            <span>
              {{ $app.auth.user.fullName || $app.auth.user.userName }}
            </span>
          </template>
          <ItpNavItem
            :href="$app.auth.accountUrl"
            target="_blank"
            icon="fa-user-circle"
            :text="'Profile'|pgettext('MainMenu')"
            dropdown-item
          >
          </ItpNavItem>
          <ItpNavItem
            icon="fa-globe"
          >
            <ItpLocaleSelector
              class="nav-item-content"
            >
            </ItpLocaleSelector>
          </ItpNavItem>
          <b-dropdown-divider>
          </b-dropdown-divider>
          <ItpNavItem
            icon="line-md:confirm-circle"
          >
            <BFormCheckbox
              v-model="$app.confirmPageClose"
              switch
              class="nav-item-content"
              @change="evh_298118714321807_change($event, {})"
            >
              <ItpText
                :text="'Confirm tab close'|pgettext('MainMenu')"
                class="user-select-none"
              >
              </ItpText>
            </BFormCheckbox>
          </ItpNavItem>
          <ItpNavItem
            href="#"
            icon="fa-sign-out-alt"
            :text="'Sign out'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_7106235911078350_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
        >
          <ItpIcon
            name="fa-bars"
            slot="button-content"
          >
          </ItpIcon>
          <ItpNavItem
            dropdown-item
            to="/jobs"
            icon="fa-cogs"
            :text="'Jobs'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <ItpNavItem
            dropdown-item
            to="/jobs-v2"
            icon="fa-cogs"
            :text="'Jobs (v2)'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <ItpNavItem
            dropdown-item
            to="/templates"
            icon="fa-file-invoice"
            :text="'Templates'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <b-dropdown-divider>
          </b-dropdown-divider>
          <ItpNavItem
            dropdown-item
            to="/settings"
            icon="fa-cog"
            :text="'Settings'|pgettext('MainMenu')"
          >
          </ItpNavItem>
          <ItpNavItem
            href="#"
            icon="fa-question-circle"
            :text="'About Integro'|pgettext('MainMenu')"
            dropdown-item
            @click.prevent="evh_5361165673534032_clickPrevent($event, {})"
          >
          </ItpNavItem>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MainMenu extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get displayTestBadge() {
    if (this.loaded) {

      return _.includes(this.$app.document.location.hostname, 'test')
    }
    return null;
  }

  get displayDevBadge() {
    if (this.loaded) {

      return _.includes(this.$app.document.location.hostname, 'local')
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8512678877908410_showModal_1292_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8512678877908410_showModal_1292, alias=undefined
    return {
      name: "CreateInvoice",
      props: {
        invoiceKind: "outgoing",
      }
      ,
    }
  }

  async evh_8512678877908410_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8512678877908410_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_5794187517758435_showModal_1294_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5794187517758435_showModal_1294, alias=undefined
    return {
      name: "CreateInvoice",
      props: {
        invoiceKind: "incoming",
      }
      ,
    }
  }

  async evh_5794187517758435_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5794187517758435_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_6201675214105229_showModal_1296_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6201675214105229_showModal_1296, alias=undefined
    return {
      name: "Payment",
      props: {
        transactionType: "deposit",
      }
      ,
    }
  }

  async evh_6201675214105229_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6201675214105229_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_6373990564425123_showModal_1298_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6373990564425123_showModal_1298, alias=undefined
    return {
      name: "Payment",
      props: {
        transactionType: "payment",
      }
      ,
    }
  }

  async evh_6373990564425123_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6373990564425123_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_8819772728062230_showModal_1300_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8819772728062230_showModal_1300, alias=undefined
    return {
      name: "Payment",
      props: {
        transactionType: "banking",
      }
      ,
    }
  }

  async evh_8819772728062230_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8819772728062230_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2424316887484278_showModal_1302_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2424316887484278_showModal_1302, alias=undefined
    return {
      name: "AccountStatementReport",
    }
  }

  async evh_2424316887484278_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2424316887484278_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_1886107574314164_showModal_1304_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1886107574314164_showModal_1304, alias=undefined
    return {
      name: "MCreateFixedAsset",
      props: {
        assetType: "general",
      }
      ,
    }
  }

  async evh_1886107574314164_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1886107574314164_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7260361660686930_showModal_1306_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7260361660686930_showModal_1306, alias=undefined
    return {
      name: "MCreateFixedAsset",
      props: {
        assetType: "waste_bin",
      }
      ,
    }
  }

  async evh_7260361660686930_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7260361660686930_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_5449511595610967_showModal_1308_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5449511595610967_showModal_1308, alias=undefined
    return {
      name: "CreateMeter",
    }
  }

  async evh_5449511595610967_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5449511595610967_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_8050584411731670_showModal_1310_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8050584411731670_showModal_1310, alias=undefined
    return {
      name: "MeterDataExchange",
    }
  }

  async evh_8050584411731670_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8050584411731670_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7807097906137782_showModal_1312_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7807097906137782_showModal_1312, alias=undefined
    return {
      name: "ReplaceMeter",
    }
  }

  async evh_7807097906137782_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7807097906137782_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2338624680052783_showModal_1314_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2338624680052783_showModal_1314, alias=undefined
    return {
      name: "CreateContract",
    }
  }

  async evh_2338624680052783_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2338624680052783_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2596033535867385_showModal_1316_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2596033535867385_showModal_1316, alias=undefined
    return {
      name: "CreateRental",
    }
  }

  async evh_2596033535867385_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2596033535867385_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_6794737306373342_showModal_1318_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6794737306373342_showModal_1318, alias=undefined
    return {
      name: "CreateProperty",
    }
  }

  async evh_6794737306373342_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6794737306373342_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2660996057968802_showModal_1320_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2660996057968802_showModal_1320, alias=undefined
    return {
      name: "CreateClient",
    }
  }

  async evh_2660996057968802_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2660996057968802_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7567745526909371_showModal_1322_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7567745526909371_showModal_1322, alias=undefined
    return {
      name: "CreateProduct",
    }
  }

  async evh_7567745526909371_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7567745526909371_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7228767045058026_showModal_1324_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7228767045058026_showModal_1324, alias=undefined
    return {
      name: "Worksheet",
      props: {
        size: "lg",
      }
      ,
    }
  }

  async evh_7228767045058026_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7228767045058026_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_298118714321807_script_1326_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_298118714321807_script_1326, alias=undefined
    this.$app.toggleConfirmPageClose($event)
  }

  async act_298118714321807_script_1326_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_298118714321807_script_1326, alias=undefined
    return {
      value: () => this.act_298118714321807_script_1326_getActionArgs_value($event),
    }
  }

  async evh_298118714321807_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_298118714321807_change.executeFromDOM(this, event, scope);
  }

  async act_7106235911078350_logout_1328_getActionArgs($event: actions.ActionEvent): Promise<actions.LogoutActionArgs> {
    // parameterResolver name=act_7106235911078350_logout_1328, alias=undefined
    return {
    }
  }

  async evh_7106235911078350_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7106235911078350_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_5361165673534032_showModal_1330_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5361165673534032_showModal_1330, alias=undefined
    return {
      name: "AboutModal",
    }
  }

  async evh_5361165673534032_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5361165673534032_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1290: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1289: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8512678877908410_showModal_1292: actions.ShowModalAction;
    evh_8512678877908410_clickPrevent_1291: actions.EventHandlerImpl;
    evh_8512678877908410_clickPrevent: actions.EventHandlerGroup;
    act_5794187517758435_showModal_1294: actions.ShowModalAction;
    evh_5794187517758435_clickPrevent_1293: actions.EventHandlerImpl;
    evh_5794187517758435_clickPrevent: actions.EventHandlerGroup;
    act_6201675214105229_showModal_1296: actions.ShowModalAction;
    evh_6201675214105229_clickPrevent_1295: actions.EventHandlerImpl;
    evh_6201675214105229_clickPrevent: actions.EventHandlerGroup;
    act_6373990564425123_showModal_1298: actions.ShowModalAction;
    evh_6373990564425123_clickPrevent_1297: actions.EventHandlerImpl;
    evh_6373990564425123_clickPrevent: actions.EventHandlerGroup;
    act_8819772728062230_showModal_1300: actions.ShowModalAction;
    evh_8819772728062230_clickPrevent_1299: actions.EventHandlerImpl;
    evh_8819772728062230_clickPrevent: actions.EventHandlerGroup;
    act_2424316887484278_showModal_1302: actions.ShowModalAction;
    evh_2424316887484278_clickPrevent_1301: actions.EventHandlerImpl;
    evh_2424316887484278_clickPrevent: actions.EventHandlerGroup;
    act_1886107574314164_showModal_1304: actions.ShowModalAction;
    evh_1886107574314164_clickPrevent_1303: actions.EventHandlerImpl;
    evh_1886107574314164_clickPrevent: actions.EventHandlerGroup;
    act_7260361660686930_showModal_1306: actions.ShowModalAction;
    evh_7260361660686930_clickPrevent_1305: actions.EventHandlerImpl;
    evh_7260361660686930_clickPrevent: actions.EventHandlerGroup;
    act_5449511595610967_showModal_1308: actions.ShowModalAction;
    evh_5449511595610967_clickPrevent_1307: actions.EventHandlerImpl;
    evh_5449511595610967_clickPrevent: actions.EventHandlerGroup;
    act_8050584411731670_showModal_1310: actions.ShowModalAction;
    evh_8050584411731670_clickPrevent_1309: actions.EventHandlerImpl;
    evh_8050584411731670_clickPrevent: actions.EventHandlerGroup;
    act_7807097906137782_showModal_1312: actions.ShowModalAction;
    evh_7807097906137782_clickPrevent_1311: actions.EventHandlerImpl;
    evh_7807097906137782_clickPrevent: actions.EventHandlerGroup;
    act_2338624680052783_showModal_1314: actions.ShowModalAction;
    evh_2338624680052783_clickPrevent_1313: actions.EventHandlerImpl;
    evh_2338624680052783_clickPrevent: actions.EventHandlerGroup;
    act_2596033535867385_showModal_1316: actions.ShowModalAction;
    evh_2596033535867385_clickPrevent_1315: actions.EventHandlerImpl;
    evh_2596033535867385_clickPrevent: actions.EventHandlerGroup;
    act_6794737306373342_showModal_1318: actions.ShowModalAction;
    evh_6794737306373342_clickPrevent_1317: actions.EventHandlerImpl;
    evh_6794737306373342_clickPrevent: actions.EventHandlerGroup;
    act_2660996057968802_showModal_1320: actions.ShowModalAction;
    evh_2660996057968802_clickPrevent_1319: actions.EventHandlerImpl;
    evh_2660996057968802_clickPrevent: actions.EventHandlerGroup;
    act_7567745526909371_showModal_1322: actions.ShowModalAction;
    evh_7567745526909371_clickPrevent_1321: actions.EventHandlerImpl;
    evh_7567745526909371_clickPrevent: actions.EventHandlerGroup;
    act_7228767045058026_showModal_1324: actions.ShowModalAction;
    evh_7228767045058026_clickPrevent_1323: actions.EventHandlerImpl;
    evh_7228767045058026_clickPrevent: actions.EventHandlerGroup;
    act_298118714321807_script_1326: actions.ScriptAction;
    evh_298118714321807_change_1325: actions.EventHandlerImpl;
    evh_298118714321807_change: actions.EventHandlerGroup;
    act_7106235911078350_logout_1328: actions.LogoutAction;
    evh_7106235911078350_clickPrevent_1327: actions.EventHandlerImpl;
    evh_7106235911078350_clickPrevent: actions.EventHandlerGroup;
    act_5361165673534032_showModal_1330: actions.ShowModalAction;
    evh_5361165673534032_clickPrevent_1329: actions.EventHandlerImpl;
    evh_5361165673534032_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1290 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1289 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1290,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1289],
      }
    );
    const act_8512678877908410_showModal_1292 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8512678877908410_showModal_1292_getActionArgs,
        events: [],
      }
    );
    const evh_8512678877908410_clickPrevent_1291 = new actions.EventHandlerImpl(
      {
        action: act_8512678877908410_showModal_1292,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8512678877908410_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8512678877908410_clickPrevent_1291],
      }
    );
    const act_5794187517758435_showModal_1294 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5794187517758435_showModal_1294_getActionArgs,
        events: [],
      }
    );
    const evh_5794187517758435_clickPrevent_1293 = new actions.EventHandlerImpl(
      {
        action: act_5794187517758435_showModal_1294,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_5794187517758435_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5794187517758435_clickPrevent_1293],
      }
    );
    const act_6201675214105229_showModal_1296 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6201675214105229_showModal_1296_getActionArgs,
        events: [],
      }
    );
    const evh_6201675214105229_clickPrevent_1295 = new actions.EventHandlerImpl(
      {
        action: act_6201675214105229_showModal_1296,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_6201675214105229_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_6201675214105229_clickPrevent_1295],
      }
    );
    const act_6373990564425123_showModal_1298 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6373990564425123_showModal_1298_getActionArgs,
        events: [],
      }
    );
    const evh_6373990564425123_clickPrevent_1297 = new actions.EventHandlerImpl(
      {
        action: act_6373990564425123_showModal_1298,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_6373990564425123_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_6373990564425123_clickPrevent_1297],
      }
    );
    const act_8819772728062230_showModal_1300 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8819772728062230_showModal_1300_getActionArgs,
        events: [],
      }
    );
    const evh_8819772728062230_clickPrevent_1299 = new actions.EventHandlerImpl(
      {
        action: act_8819772728062230_showModal_1300,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8819772728062230_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8819772728062230_clickPrevent_1299],
      }
    );
    const act_2424316887484278_showModal_1302 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2424316887484278_showModal_1302_getActionArgs,
        events: [],
      }
    );
    const evh_2424316887484278_clickPrevent_1301 = new actions.EventHandlerImpl(
      {
        action: act_2424316887484278_showModal_1302,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2424316887484278_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2424316887484278_clickPrevent_1301],
      }
    );
    const act_1886107574314164_showModal_1304 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1886107574314164_showModal_1304_getActionArgs,
        events: [],
      }
    );
    const evh_1886107574314164_clickPrevent_1303 = new actions.EventHandlerImpl(
      {
        action: act_1886107574314164_showModal_1304,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_1886107574314164_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_1886107574314164_clickPrevent_1303],
      }
    );
    const act_7260361660686930_showModal_1306 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7260361660686930_showModal_1306_getActionArgs,
        events: [],
      }
    );
    const evh_7260361660686930_clickPrevent_1305 = new actions.EventHandlerImpl(
      {
        action: act_7260361660686930_showModal_1306,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7260361660686930_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7260361660686930_clickPrevent_1305],
      }
    );
    const act_5449511595610967_showModal_1308 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5449511595610967_showModal_1308_getActionArgs,
        events: [],
      }
    );
    const evh_5449511595610967_clickPrevent_1307 = new actions.EventHandlerImpl(
      {
        action: act_5449511595610967_showModal_1308,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_5449511595610967_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5449511595610967_clickPrevent_1307],
      }
    );
    const act_8050584411731670_showModal_1310 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8050584411731670_showModal_1310_getActionArgs,
        events: [],
      }
    );
    const evh_8050584411731670_clickPrevent_1309 = new actions.EventHandlerImpl(
      {
        action: act_8050584411731670_showModal_1310,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8050584411731670_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8050584411731670_clickPrevent_1309],
      }
    );
    const act_7807097906137782_showModal_1312 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7807097906137782_showModal_1312_getActionArgs,
        events: [],
      }
    );
    const evh_7807097906137782_clickPrevent_1311 = new actions.EventHandlerImpl(
      {
        action: act_7807097906137782_showModal_1312,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7807097906137782_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7807097906137782_clickPrevent_1311],
      }
    );
    const act_2338624680052783_showModal_1314 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2338624680052783_showModal_1314_getActionArgs,
        events: [],
      }
    );
    const evh_2338624680052783_clickPrevent_1313 = new actions.EventHandlerImpl(
      {
        action: act_2338624680052783_showModal_1314,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2338624680052783_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2338624680052783_clickPrevent_1313],
      }
    );
    const act_2596033535867385_showModal_1316 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2596033535867385_showModal_1316_getActionArgs,
        events: [],
      }
    );
    const evh_2596033535867385_clickPrevent_1315 = new actions.EventHandlerImpl(
      {
        action: act_2596033535867385_showModal_1316,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2596033535867385_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2596033535867385_clickPrevent_1315],
      }
    );
    const act_6794737306373342_showModal_1318 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6794737306373342_showModal_1318_getActionArgs,
        events: [],
      }
    );
    const evh_6794737306373342_clickPrevent_1317 = new actions.EventHandlerImpl(
      {
        action: act_6794737306373342_showModal_1318,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_6794737306373342_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_6794737306373342_clickPrevent_1317],
      }
    );
    const act_2660996057968802_showModal_1320 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2660996057968802_showModal_1320_getActionArgs,
        events: [],
      }
    );
    const evh_2660996057968802_clickPrevent_1319 = new actions.EventHandlerImpl(
      {
        action: act_2660996057968802_showModal_1320,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2660996057968802_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2660996057968802_clickPrevent_1319],
      }
    );
    const act_7567745526909371_showModal_1322 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7567745526909371_showModal_1322_getActionArgs,
        events: [],
      }
    );
    const evh_7567745526909371_clickPrevent_1321 = new actions.EventHandlerImpl(
      {
        action: act_7567745526909371_showModal_1322,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7567745526909371_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7567745526909371_clickPrevent_1321],
      }
    );
    const act_7228767045058026_showModal_1324 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7228767045058026_showModal_1324_getActionArgs,
        events: [],
      }
    );
    const evh_7228767045058026_clickPrevent_1323 = new actions.EventHandlerImpl(
      {
        action: act_7228767045058026_showModal_1324,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7228767045058026_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7228767045058026_clickPrevent_1323],
      }
    );
    const act_298118714321807_script_1326 = new actions.ScriptAction(
      {
        actionArgs: this.act_298118714321807_script_1326_getActionArgs,
        events: [],
      }
    );
    const evh_298118714321807_change_1325 = new actions.EventHandlerImpl(
      {
        action: act_298118714321807_script_1326,
        event: "change",
        displayName: "script",
      }
    );
    const evh_298118714321807_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_298118714321807_change_1325],
      }
    );
    const act_7106235911078350_logout_1328 = new actions.LogoutAction(
      {
        actionArgs: this.act_7106235911078350_logout_1328_getActionArgs,
        events: [],
      }
    );
    const evh_7106235911078350_clickPrevent_1327 = new actions.EventHandlerImpl(
      {
        action: act_7106235911078350_logout_1328,
        event: "click.prevent",
        displayName: "logout",
      }
    );
    const evh_7106235911078350_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7106235911078350_clickPrevent_1327],
      }
    );
    const act_5361165673534032_showModal_1330 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5361165673534032_showModal_1330_getActionArgs,
        events: [],
      }
    );
    const evh_5361165673534032_clickPrevent_1329 = new actions.EventHandlerImpl(
      {
        action: act_5361165673534032_showModal_1330,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_5361165673534032_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_5361165673534032_clickPrevent_1329],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1290,
      evh_7315092382398562_reload_1289,
      evh_7315092382398562_reload,
      act_8512678877908410_showModal_1292,
      evh_8512678877908410_clickPrevent_1291,
      evh_8512678877908410_clickPrevent,
      act_5794187517758435_showModal_1294,
      evh_5794187517758435_clickPrevent_1293,
      evh_5794187517758435_clickPrevent,
      act_6201675214105229_showModal_1296,
      evh_6201675214105229_clickPrevent_1295,
      evh_6201675214105229_clickPrevent,
      act_6373990564425123_showModal_1298,
      evh_6373990564425123_clickPrevent_1297,
      evh_6373990564425123_clickPrevent,
      act_8819772728062230_showModal_1300,
      evh_8819772728062230_clickPrevent_1299,
      evh_8819772728062230_clickPrevent,
      act_2424316887484278_showModal_1302,
      evh_2424316887484278_clickPrevent_1301,
      evh_2424316887484278_clickPrevent,
      act_1886107574314164_showModal_1304,
      evh_1886107574314164_clickPrevent_1303,
      evh_1886107574314164_clickPrevent,
      act_7260361660686930_showModal_1306,
      evh_7260361660686930_clickPrevent_1305,
      evh_7260361660686930_clickPrevent,
      act_5449511595610967_showModal_1308,
      evh_5449511595610967_clickPrevent_1307,
      evh_5449511595610967_clickPrevent,
      act_8050584411731670_showModal_1310,
      evh_8050584411731670_clickPrevent_1309,
      evh_8050584411731670_clickPrevent,
      act_7807097906137782_showModal_1312,
      evh_7807097906137782_clickPrevent_1311,
      evh_7807097906137782_clickPrevent,
      act_2338624680052783_showModal_1314,
      evh_2338624680052783_clickPrevent_1313,
      evh_2338624680052783_clickPrevent,
      act_2596033535867385_showModal_1316,
      evh_2596033535867385_clickPrevent_1315,
      evh_2596033535867385_clickPrevent,
      act_6794737306373342_showModal_1318,
      evh_6794737306373342_clickPrevent_1317,
      evh_6794737306373342_clickPrevent,
      act_2660996057968802_showModal_1320,
      evh_2660996057968802_clickPrevent_1319,
      evh_2660996057968802_clickPrevent,
      act_7567745526909371_showModal_1322,
      evh_7567745526909371_clickPrevent_1321,
      evh_7567745526909371_clickPrevent,
      act_7228767045058026_showModal_1324,
      evh_7228767045058026_clickPrevent_1323,
      evh_7228767045058026_clickPrevent,
      act_298118714321807_script_1326,
      evh_298118714321807_change_1325,
      evh_298118714321807_change,
      act_7106235911078350_logout_1328,
      evh_7106235911078350_clickPrevent_1327,
      evh_7106235911078350_clickPrevent,
      act_5361165673534032_showModal_1330,
      evh_5361165673534032_clickPrevent_1329,
      evh_5361165673534032_clickPrevent,
    }
  }
}

Vue.component("MainMenu", MainMenu);

</script>