"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Charts = class Charts extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['dashboards', 'ux'];
    }
    beforeCreate() {
    }
    openDashboard(...args) {
        const item = args[0];
        item.loading = true;
        this.$fn
            .fetch('get_superset_dashboard_token', { parameters: { id: item.id } })
            .then(resp => window.open(resp.url, "_blank").focus())
            .finally(() => {
            item.loading = false;
        });
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_dashboards() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.$fn.fetch('list_superset_dashboards')
                    .then(p => resolve(_.map(p.result, pp => (Object.assign(Object.assign({}, pp), { loading: false })))))
                    .catch(err => {
                    resolve({
                        error: { message: _.get(err, 'response.data.message', err.toJSON().message) }
                    });
                });
            });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            dashboards: null,
            ux: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Charts|Title", "Reports"),
                icon: "akar-icons:statistic-up",
            };
        }
        return null;
    }
    act_8358196963355853_script_1650_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.openDashboard($event.scope.item);
        });
    }
    act_8358196963355853_script_1650_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8358196963355853_script_1650_getActionArgs_value($event),
            };
        });
    }
    evh_8358196963355853_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8358196963355853_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_8358196963355853_script_1650 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8358196963355853_script_1650_getActionArgs,
            events: [],
        });
        const evh_8358196963355853_clickPrevent_1649 = new core_1.actions.EventHandlerImpl({
            action: act_8358196963355853_script_1650,
            event: "click.prevent",
            displayName: "script",
        });
        const evh_8358196963355853_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8358196963355853_clickPrevent_1649],
        });
        return {
            act_8358196963355853_script_1650,
            evh_8358196963355853_clickPrevent_1649,
            evh_8358196963355853_clickPrevent,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Charts.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Charts.prototype, "onSize", null);
Charts = __decorate([
    (0, vue_property_decorator_1.Component)()
], Charts);
exports.default = Charts;
vue_property_decorator_1.Vue.component("Charts", Charts);
