<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WorkSummaryView"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="workSummary"
    :headerTitle="'Work summary'|pgettext('WorkSummaryView')"
    :items="$fn.slickDataSource('query_worksheets_work_summary', {
    parameters: () => params,
    assignUniqueId: true
  })"
    checkbox-row-selection
    footer-totals
    header-show-column-selector-button
    :headerShowReloadButton="b_2248226175642056_headerShowReloadButton"
    export-to-csv
    :exportToCsvFilename="'work-summary'|pgettext('ExportFilename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <DateRangeSelector
        name="paymentFilters"
        :from="params.date_from"
        :to="params.date_to"
        class="mr-2"
        :style="b_6466705138206980_style"
        @changed="evh_6466705138206980_changed($event, {})"
      >
      </DateRangeSelector>
      <DIV>
        <ItpButton
          variant="light"
          :icon="$config.reloadIcon"
          :title="'Load data'|pgettext('WorkSummaryViewFilters')"
          :spinning="actions && actions.load && actions.load.isRunning"
          @click.prevent.stop="evh_4440454779651433_clickPreventStop($event, {})"
        >
        </ItpButton>
      </DIV>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "workSummary",
    path: "/worksheets-work-summary",
    component: "WorkSummaryView"
  }
)

@Component()
export default class WorkSummaryView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_params = new ComponentValueCache(this, "params", "WorkSummaryViewFilter");
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  params!: any;

  async $$load_params() {
    return {
      date_from: this.$fn.today(),
      date_to: null,
    }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_headerShowReloadButton!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  b_6466705138206980_style!: any;
  dataMembers = ['selectedItems', 'params', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowReloadButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_6466705138206980_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        params: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_headerShowReloadButton: false,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "service_name",
          name: this.$fn.pgettext("WorkSummaryView", "Service name"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "product",
            navigateOnIconClick: true,
            params: {
              id: "service_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "unit",
          name: this.$fn.pgettext("WorkSummaryView", "unit"),
        }
          ,
        {
          field: "quantity",
          name: this.$fn.pgettext("WorkSummaryView", "Quantity"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "net_amount",
          name: this.$fn.pgettext("WorkSummaryView", "Net"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "vat_amount",
          name: this.$fn.pgettext("WorkSummaryView", "VAT"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "amount",
          name: this.$fn.pgettext("WorkSummaryView", "Amount"),
          type: "number",
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "rental_name",
          name: this.$fn.pgettext("WorkSummaryView", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            navigateOnIconClick: true,
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "company_name",
          name: this.$fn.pgettext("WorkSummaryView", "Company"),
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "company_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "client_name",
          width: "200px",
          name: this.$fn.pgettext("WorkSummaryView", "Client"),
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "contractor_name",
          width: "200px",
          name: this.$fn.pgettext("WorkSummaryView", "Contractor"),
          formatter: {
            name: "routeLink",
            to: "client",
            navigateOnIconClick: true,
            params: {
              id: "contractor_id",
            }
            ,
          }
          ,
        }
          ,
        ]
        ,
        b_6466705138206980_style: {
          width: "500px",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("WorkSummaryView", "Work summary"),
        icon: this.$config.worksheet.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5978_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5978, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5980_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5980, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_5982_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_5982, alias=undefined
    return {
      name: "Payment",
      props: {
        modal: true,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_5981_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_5981, alias=undefined
    return $event.data.column.id === 'payment_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_setData_5984_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_5984, alias=undefined
    return {
      path: "params.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_6466705138206980_setData_5986_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_5986, alias=undefined
    return {
      path: "params.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_changed.executeFromDOM(this, event, scope);
  }

  async act_4440454779651433_script_5988_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4440454779651433_script_5988, alias=undefined
    this.$refs.grid.reload()
  }

  async act_4440454779651433_script_5988_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4440454779651433_script_5988, alias=undefined
    return {
      value: () => this.act_4440454779651433_script_5988_getActionArgs_value($event),
    }
  }

  async evh_4440454779651433_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4440454779651433_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5978: actions.CloseModalAction;
    evh_7315092382398562_close_5977: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_5980: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_5979: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_showModal_5982: actions.ShowModalAction;
    evh_2248226175642056_command_5981: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_6466705138206980_setData_5984: actions.SetDataAction;
    evh_6466705138206980_changed_5983: actions.EventHandlerImpl;
    act_6466705138206980_setData_5986: actions.SetDataAction;
    evh_6466705138206980_changed_5985: actions.EventHandlerImpl;
    evh_6466705138206980_changed: actions.EventHandlerGroup;
    act_4440454779651433_script_5988: actions.ScriptAction;
    evh_4440454779651433_clickPreventStop_5987: actions.EventHandlerImpl;
    evh_4440454779651433_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5978 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5978_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5977 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5978,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5977],
      }
    );
    const act_2248226175642056_setData_5980 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5980_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_5979 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5980,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_5979],
      }
    );
    const act_2248226175642056_showModal_5982 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_5982_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_command_5981 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_5982,
        event: "command",
        when: this.evh_2248226175642056_command_5981_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_5981],
      }
    );
    const act_6466705138206980_setData_5984 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_5984_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_changed_5983 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_5984,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_6466705138206980_setData_5986 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_5986_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_changed_5985 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_5986,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_6466705138206980_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_changed_5983, evh_6466705138206980_changed_5985],
      }
    );
    const act_4440454779651433_script_5988 = new actions.ScriptAction(
      {
        actionArgs: this.act_4440454779651433_script_5988_getActionArgs,
        events: [],
      }
    );
    const evh_4440454779651433_clickPreventStop_5987 = new actions.EventHandlerImpl(
      {
        action: act_4440454779651433_script_5988,
        event: "click.prevent.stop",
        displayName: "script",
      }
    );
    const evh_4440454779651433_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4440454779651433_clickPreventStop_5987],
      }
    );
    return {
      act_7315092382398562_closeModal_5978,
      evh_7315092382398562_close_5977,
      evh_7315092382398562_close,
      act_2248226175642056_setData_5980,
      evh_2248226175642056_selectedRowsChanged_5979,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_showModal_5982,
      evh_2248226175642056_command_5981,
      evh_2248226175642056_command,
      act_6466705138206980_setData_5984,
      evh_6466705138206980_changed_5983,
      act_6466705138206980_setData_5986,
      evh_6466705138206980_changed_5985,
      evh_6466705138206980_changed,
      act_4440454779651433_script_5988,
      evh_4440454779651433_clickPreventStop_5987,
      evh_4440454779651433_clickPreventStop,
    }
  }
}

Vue.component("WorkSummaryView", WorkSummaryView);

</script>