"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcRentalServices = class UcRentalServices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux'];
    }
    beforeCreate() {
    }
    sortItems(...args) {
        return _.sortBy(args[0], p => (!this.isServiceStale(p) ? "_" + p.service.name : p.service.name).toLowerCase() + p.id);
    }
    isServiceStale(...args) {
        const [service] = args;
        return service.inactive ||
            (service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today);
    }
    onRental(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rental");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_rental_service', { parameters: { rental_id: this.rental.id } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
        });
    }
    act_3343864469950044_emit_7130_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3343864469950044_close_7129_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_3343864469950044_showModal_7128_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalService",
                props: {
                    rental: this.rental,
                    assignToRental: false,
                    preventEditMeter: false,
                },
            };
        });
    }
    evh_3343864469950044_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8855792642156263_emit_7134_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8855792642156263_close_7133_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_8855792642156263_showModal_7132_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalService",
                props: {
                    oid: $event.scope.item.id,
                    mode: "edit",
                    rental: this.rental,
                    preventEditRental: true,
                    preventEditMeter: true,
                },
            };
        });
    }
    evh_8855792642156263_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8855792642156263_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_3343864469950044_emit_7130 = new core_1.actions.EmitAction({
            actionArgs: this.act_3343864469950044_emit_7130_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_close_7129 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_emit_7130,
            event: "close",
            when: this.evh_3343864469950044_close_7129_getWhen,
            displayName: "emit",
        });
        const act_3343864469950044_showModal_7128 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_7128_getActionArgs,
            events: [evh_3343864469950044_close_7129],
        });
        const evh_3343864469950044_clickPreventStop_7127 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_7128,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3343864469950044_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_clickPreventStop_7127],
        });
        const act_8855792642156263_emit_7134 = new core_1.actions.EmitAction({
            actionArgs: this.act_8855792642156263_emit_7134_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_8855792642156263_close_7133 = new core_1.actions.EventHandlerImpl({
            action: act_8855792642156263_emit_7134,
            event: "close",
            when: this.evh_8855792642156263_close_7133_getWhen,
            displayName: "emitReload",
        });
        const act_8855792642156263_showModal_7132 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8855792642156263_showModal_7132_getActionArgs,
            events: [evh_8855792642156263_close_7133],
        });
        const evh_8855792642156263_clickPreventStop_7131 = new core_1.actions.EventHandlerImpl({
            action: act_8855792642156263_showModal_7132,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8855792642156263_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8855792642156263_clickPreventStop_7131],
        });
        return {
            act_3343864469950044_emit_7130,
            evh_3343864469950044_close_7129,
            act_3343864469950044_showModal_7128,
            evh_3343864469950044_clickPreventStop_7127,
            evh_3343864469950044_clickPreventStop,
            act_8855792642156263_emit_7134,
            evh_8855792642156263_close_7133,
            act_8855792642156263_showModal_7132,
            evh_8855792642156263_clickPreventStop_7131,
            evh_8855792642156263_clickPreventStop,
            emitReload: act_8855792642156263_emit_7134,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcRentalServices.prototype, "rental", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rental'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcRentalServices.prototype, "onRental", null);
UcRentalServices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcRentalServices);
exports.default = UcRentalServices;
vue_property_decorator_1.Vue.component("UcRentalServices", UcRentalServices);
