"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let PaymentsViewFilters = class PaymentsViewFilters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['accounts', 'item', 'ux', 'b_8564662037462133_style', 'b_1889980785424776_style'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "PaymentsViewFilters");
    }
    $$load_accounts() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_account')
                .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']));
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: null,
                date_to: null,
                account: this.accounts ? this.accounts[0].id : null,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            accounts: null,
            item: null,
            ux: null,
            b_8564662037462133_style: {
                width: "300px",
            },
            b_1889980785424776_style: {
                width: "500px",
            },
        });
    }
    get selectedAccountType() {
        if (this.loaded) {
            return this.item.account && this.item.account.account_type;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_emit_5766_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "submit",
                value: Object.assign(Object.assign({}, this.item), { account: this.item.account.id }),
            };
        });
    }
    evh_6466705138206980_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_8169383091859921_showModal_5768_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    accountId: this.item.account ? this.item.account.id : null,
                    paymentType: "credit",
                    transactionType: "deposit",
                },
            };
        });
    }
    evh_8169383091859921_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8169383091859921_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7640643585580915_showModal_5770_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    accountId: this.item.account ? this.item.account.id : null,
                    transactionType: "payment",
                },
            };
        });
    }
    evh_7640643585580915_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7640643585580915_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2857579216270778_showModal_5772_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    accountId: this.item.account ? this.item.account.id : null,
                    transactionType: this.selectedAccountType === 'bank_account' ? 'banking' : this.selectedAccountType,
                },
            };
        });
    }
    evh_2857579216270778_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2857579216270778_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8518026866742051_showModal_5774_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ImportBankStatement",
            };
        });
    }
    evh_8518026866742051_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8518026866742051_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_1889980785424776_setData_5776_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1889980785424776_setData_5778_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1889980785424776_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1889980785424776_changed.executeFromDOM(this, event, scope);
        });
    }
    act_4892266742011185_emit_5780_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "submit",
                value: Object.assign(Object.assign({}, this.item), { account: this.item.account.id }),
            };
        });
    }
    evh_4892266742011185_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4892266742011185_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5764 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5763 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5764,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5763],
        });
        const act_6466705138206980_emit_5766 = new core_1.actions.EmitAction({
            actionArgs: this.act_6466705138206980_emit_5766_getActionArgs,
            displayName: "load",
            events: [],
        });
        const evh_6466705138206980_optionSelected_5765 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_emit_5766,
            event: "optionSelected",
            displayName: "load",
        });
        const evh_6466705138206980_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_optionSelected_5765],
        });
        const act_8169383091859921_showModal_5768 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8169383091859921_showModal_5768_getActionArgs,
            events: [],
        });
        const evh_8169383091859921_clickPrevent_5767 = new core_1.actions.EventHandlerImpl({
            action: act_8169383091859921_showModal_5768,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8169383091859921_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8169383091859921_clickPrevent_5767],
        });
        const act_7640643585580915_showModal_5770 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7640643585580915_showModal_5770_getActionArgs,
            events: [],
        });
        const evh_7640643585580915_clickPrevent_5769 = new core_1.actions.EventHandlerImpl({
            action: act_7640643585580915_showModal_5770,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7640643585580915_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7640643585580915_clickPrevent_5769],
        });
        const act_2857579216270778_showModal_5772 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2857579216270778_showModal_5772_getActionArgs,
            events: [],
        });
        const evh_2857579216270778_clickPrevent_5771 = new core_1.actions.EventHandlerImpl({
            action: act_2857579216270778_showModal_5772,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2857579216270778_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2857579216270778_clickPrevent_5771],
        });
        const act_8518026866742051_showModal_5774 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8518026866742051_showModal_5774_getActionArgs,
            events: [],
        });
        const evh_8518026866742051_clickPrevent_5773 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_showModal_5774,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8518026866742051_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8518026866742051_clickPrevent_5773],
        });
        const act_1889980785424776_setData_5776 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1889980785424776_setData_5776_getActionArgs,
            events: [],
        });
        const evh_1889980785424776_changed_5775 = new core_1.actions.EventHandlerImpl({
            action: act_1889980785424776_setData_5776,
            event: "changed",
            displayName: "setData",
        });
        const act_1889980785424776_setData_5778 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1889980785424776_setData_5778_getActionArgs,
            events: [],
        });
        const evh_1889980785424776_changed_5777 = new core_1.actions.EventHandlerImpl({
            action: act_1889980785424776_setData_5778,
            event: "changed",
            displayName: "setData",
        });
        const evh_1889980785424776_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1889980785424776_changed_5775, evh_1889980785424776_changed_5777],
        });
        const act_4892266742011185_emit_5780 = new core_1.actions.EmitAction({
            actionArgs: this.act_4892266742011185_emit_5780_getActionArgs,
            displayName: "load",
            events: [],
        });
        const evh_4892266742011185_clickPreventStop_5779 = new core_1.actions.EventHandlerImpl({
            action: act_4892266742011185_emit_5780,
            event: "click.prevent.stop",
            displayName: "load",
        });
        const evh_4892266742011185_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4892266742011185_clickPreventStop_5779],
        });
        return {
            act_7315092382398562_reloadComponentData_5764,
            evh_7315092382398562_reload_5763,
            evh_7315092382398562_reload,
            act_6466705138206980_emit_5766,
            evh_6466705138206980_optionSelected_5765,
            evh_6466705138206980_optionSelected,
            act_8169383091859921_showModal_5768,
            evh_8169383091859921_clickPrevent_5767,
            evh_8169383091859921_clickPrevent,
            act_7640643585580915_showModal_5770,
            evh_7640643585580915_clickPrevent_5769,
            evh_7640643585580915_clickPrevent,
            act_2857579216270778_showModal_5772,
            evh_2857579216270778_clickPrevent_5771,
            evh_2857579216270778_clickPrevent,
            act_8518026866742051_showModal_5774,
            evh_8518026866742051_clickPrevent_5773,
            evh_8518026866742051_clickPrevent,
            act_1889980785424776_setData_5776,
            evh_1889980785424776_changed_5775,
            act_1889980785424776_setData_5778,
            evh_1889980785424776_changed_5777,
            evh_1889980785424776_changed,
            act_4892266742011185_emit_5780,
            evh_4892266742011185_clickPreventStop_5779,
            evh_4892266742011185_clickPreventStop,
            load: act_4892266742011185_emit_5780,
        };
    }
};
PaymentsViewFilters = __decorate([
    (0, vue_property_decorator_1.Component)()
], PaymentsViewFilters);
exports.default = PaymentsViewFilters;
vue_property_decorator_1.Vue.component("PaymentsViewFilters", PaymentsViewFilters);
