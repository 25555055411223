<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AddFixedAssetToRentalModal"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="addFixedAssetToRental"
      :id="formId"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol
        :auto="b_5534025912102772_auto"
      >
        <ItpFormGroup
          labelColsMd="3"
          contentColsMd="9"
          name="rental_id"
          :label="'Rental'|pgettext('AddFixedAssetToRentalModal')"
        >
          <ItpFormSelect2
            name="parent_id"
            v-model="item.rental_id"
            :dataSource="b_5731695935601903_dataSource"
            valueField="id"
            textField="name"
            use-option-template-for-selected-option
            optionTemplate="<UcSuggestRentalOption :option=&quot;option&quot;></UcSuggestRentalOption>"
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          labelColsMd="3"
          contentColsMd="9"
          name="valid_from"
          :label="'Valid From'|pgettext('AddFixedAssetToRentalModal')"
        >
          <DatePicker
            inputClass="form-control form-control-md"
            name="valid_from"
            v-model="item.valid_from"
            format="YYYY-MM-DD"
            valueType="format"
          >
          </DatePicker>
        </ItpFormGroup>
        <ItpFormGroup
          labelColsMd="3"
          contentColsMd="9"
          name="valid_to"
          :label="'Valid To'|pgettext('AddFixedAssetToRentalModal')"
        >
          <DatePicker
            inputClass="form-control form-control-md"
            name="valid_from"
            v-model="item.valid_to"
            format="YYYY-MM-DD"
            valueType="format"
          >
          </DatePicker>
        </ItpFormGroup>
        <ItpFormGroup
          labelColsMd="3"
          contentColsMd="9"
          name="valid_to"
          :label="'Notes'|pgettext('AddFixedAssetToRentalModal')"
        >
          <ItpFormTextarea
            name="notes"
            v-model="item.notes"
          >
          </ItpFormTextarea>
        </ItpFormGroup>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      type="submit"
      :text="'Add'|pgettext('AddFixedAssetToRentalModal')"
      :form="forms.addFixedAssetToRental"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class AddFixedAssetToRentalModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  asset!: object;

  @Watch('asset')
  onAsset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("asset")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateRentalAsset', { asset_id: this.asset.id, valid_from: this.$fn.today() })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5534025912102772_auto!: any;
  b_5731695935601903_dataSource!: any;
  dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_5534025912102772_auto', 'b_5731695935601903_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_5534025912102772_auto: false,
        b_5731695935601903_dataSource: {
          name: "suggest_rental",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("AddFixedAssetToRentalModal", "Add to Rental"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1462_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1462, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_1468_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1468, alias=undefined
    return {
      result: {
        close: true,
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_request_1466_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_1466, alias=submit
    return {
      operation: "create_rental_asset",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1462: actions.CloseModalAction;
    evh_7315092382398562_close_1461: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1464: actions.CloseModalAction;
    evh_2248226175642056_close_1463: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_1468: actions.CloseModalAction;
    evh_1040167445267876_success_1467: actions.EventHandlerImpl;
    act_1040167445267876_request_1466: actions.RequestAction;
    evh_1040167445267876_submit_1465: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_1470: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_1469: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1462 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1462_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1461 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1462,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1461],
      }
    );
    const act_2248226175642056_closeModal_1464 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1463 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1464,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1463],
      }
    );
    const act_1040167445267876_closeModal_1468 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1468_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1467 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1468,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_1466 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_1466_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_1467],
      }
    );
    const evh_1040167445267876_submit_1465 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_1466,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1465],
      }
    );
    const act_1419464017721962_closeComponent_1470 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1469 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_1470,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1469],
      }
    );
    return {
      act_7315092382398562_closeModal_1462,
      evh_7315092382398562_close_1461,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1464,
      evh_2248226175642056_close_1463,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_1468,
      evh_1040167445267876_success_1467,
      act_1040167445267876_request_1466,
      evh_1040167445267876_submit_1465,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_1470,
      evh_1419464017721962_clickPreventStop_1469,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_1466,
    }
  }
}

Vue.component("AddFixedAssetToRentalModal", AddFixedAssetToRentalModal);

</script>