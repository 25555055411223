<template>
<div>
  <ItpButton
    :text="text"
    :icon="icon"
    :variant="variant"
    :spinning="spinning"
    :disabled="disabled"
    :size="size"
    :tooltip="tooltip"
    :block="block"
    v-if="loaded && !jobData"
    @click.prevent.stop="evh_7315092382398562_clickPreventStop($event, {})"
  >
  </ItpButton>
  <div
    class="input-group"
  >
    <b-dropdown
      split
      :size="size"
      :title="tooltip"
      :variant="variant"
      :disabled="jobData ? false : disabled"
      split-class="border"
      toggle-class="border"
      v-if="loaded && jobData"
      @click.prevent.stop="evh_96700093353179_clickPreventStop($event, {})"
      @bv::dropdown::hide="evh_96700093353179_bvDropdownHide($event, {})"
      @bv::dropdown::show="evh_96700093353179_bvDropdownShow($event, {})"
    >
      <template
        slot="button-content"
      >
        <b-spinner
          small
          type="grow"
          class="mr-1"
          v-if="spinning"
        >
        </b-spinner>
        <ItpIcon
          :name="icon"
          class="mr-1"
          v-if="icon"
        >
        </ItpIcon>
        <span
          v-if="text"
        >
          {{ text }}
        </span>
        <UcStatusBadge
          :status="jobData.status"
          class="ml-2"
          v-if="jobData.status"
        >
        </UcStatusBadge>
      </template>
      <b-dropdown-item
        @click.prevent.stop="evh_302930856768608_clickPreventStop($event, {})"
      >
        {{ messages.showJobTooltip }}...
      </b-dropdown-item>
      <b-dropdown-item
        @click.prevent.stop="evh_939987670462915_clickPreventStop($event, {})"
      >
        {{ messages.reloadJobData }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class JobHandlerControl extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  beforeDestroy(...args: any[]) {
    { { this.jobPoller && this.jobPoller.stop() } }
  }

  loadJob(...args: any[]) {
    return this.$fn
      .fetch('read_a_job', { parameters: { id: this.jobData.id } })
      .then(data => {
        this.setData('jobData', data);
        this.setData('status', data.status);
        this.maybeStopPoll();
      })
      .catch(error => this.handleError(error))
  }

  onStatusReceived(...args: any[]) {
    const [{ status }] = args;

    if (status && this.status != status) {
      this.setData('jobData.status', status);
      this.setData('status', status);
      this.$emit(status);

      if (status !== 'running') {
        return this.loadJob();
      }
    }
  }

  onSubmit(...args: any[]) {
    const val = _.isFunction(this.payload) ? this.payload() : this.payload;

    if (this.isRunning) {
      return;
    }

    Promise
      .resolve(val)
      .then(payload => {

        if (!payload || payload.cancel) {
          return;
        }

        this.$fn.crud('a_job')
          .create(payload, { parameters: { job_type: this.jobType } })
          .then(resource => {
            this.setData('jobData', resource);
            this.setData('status', resource.status);
          })
          .then(() => this.maybeStartPoll())
          .catch(error => this.handleError(error))
      })
      .catch(error => this.handleError(error))
  }

  checkStatus(...args: any[]) {
    if (!this.jobData) {
      return false;
    }

    const status = this.jobData.status;

    if (status && this.status != status) {
      // status change
      this.$emit(status);
      this.status = status;
    }

    if (status !== 'pending' && status !== 'running') {
      console.log(`JobStatus is ${status}, stop polling.`, this.jobPoller);
      this.jobPoller.stop();
      this.$emit('success');
      return;
    }

    return this.$fn
      .fetch('read_a_job_status', { parameters: { id: this.jobData.id } })
      .then(data => { this.onStatusReceived(data) });
  }

  maybeStartPoll(...args: any[]) {
    const status = _.get(this, 'jobData.status');

    if (['completed', 'failed', 'cancelled', 'deleted'].indexOf(status) > -1) {
      return;
    }

    if (!this.jobPoller.isRunning) {
      return this.jobPoller.start()
    }
  }

  maybeStopPoll(...args: any[]) {
    const status = _.get(this, 'jobData.status');

    if (['pending', 'running'].indexOf(status) > -1) {
      return;
    }

    if (this.jobPoller.isRunning) {
      return this.jobPoller.stop()
    }
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  block!: boolean;

  @Watch('block')
  onBlock(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("block")
  }

  @Prop({
    required: true,
    type: String,
  })
  jobType!: string;

  @Watch('jobType')
  onJob_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("jobType")
  }

  @Prop({
    required: true,
    type: String,
  })
  text!: string;

  @Watch('text')
  onText(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("text")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  loadCurrent!: boolean;

  @Watch('loadCurrent')
  onLoad_current(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("loadCurrent")
  }

  @Prop({
    required: true,
  })
  payload!: Function;

  @Watch('payload')
  onPayload(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payload")
  }

  @Prop({
    type: String,
  })
  jobId!: string;

  @Watch('jobId')
  onJob_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("jobId")
  }

  @Prop({
    type: String,
  })
  icon!: string;

  @Watch('icon')
  onIcon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("icon")
  }

  @Prop({
    type: String,
    default: "light",
  })
  variant!: string;

  @Watch('variant')
  onVariant(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("variant")
  }

  @Prop({
    type: Boolean,
  })
  disabled!: boolean;

  @Watch('disabled')
  onDisabled(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("disabled")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  @Prop({
    type: String,
  })
  tooltip!: string;

  @Watch('tooltip')
  onTooltip(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("tooltip")
  }

  @Prop({
    type: Number,
    default: 1000,
  })
  pollingInterval!: number;

  @Watch('pollingInterval')
  onPolling_interval(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("pollingInterval")
  }

  messages!: any;
  jobData!: any;

  async $$load_jobData() {
    return this.loadCurrent
      ? this.$fn.fetch('read_a_job', { parameters: { id: this.jobID || 'current', job_type: this.jobType }, asResource: true })
      : null
  }

  jobPoller!: any;

  async $$load_jobPoller() {
    return this.$fn.poller({
      id: 'Job',
      callback: this.checkStatus,
      interval: this.pollingInterval,
    })
  }

  status!: any;
  ux!: any;
  dataMembers = ['messages', 'jobData', 'jobPoller', 'status', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          showJobTooltip: this.$fn.pgettext("JobHandler|Command", "Open job details"),
          reloadJobData: this.$fn.pgettext("JobHandler|Command", "Reload job status"),
        }
        ,
        jobData: null,
        jobPoller: null,
        status: null,
        ux: null,
      },
    }
  }

  get isRunning() {
    if (this.loaded) {

      return this.jobData && this.jobData.status === 'running'
    }
    return null;
  }

  get isPending() {
    if (this.loaded) {

      return this.jobData && this.jobData.status === 'pending'
    }
    return null;
  }

  get spinning() {
    if (this.loaded) {

      return this.isRunning || this.isPending || (this.actions && this.actions.reloadJobData && this.actions.reloadJobData.isRunning)
    }
    return null;
  }

  async act_7315092382398562_script_5540_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_5540, alias=submit
    this.onSubmit()
  }

  async act_7315092382398562_script_5540_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_5540, alias=submit
    return {
      value: () => this.act_7315092382398562_script_5540_getActionArgs_value($event),
    }
  }

  async act_7315092382398562_script_5540_getWhen($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_5540, alias=submit
    return !this.disabled
  }

  async evh_7315092382398562_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_96700093353179_script_5542_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_96700093353179_script_5542, alias=submit
    this.onSubmit()
  }

  async act_96700093353179_script_5542_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_96700093353179_script_5542, alias=submit
    return {
      value: () => this.act_96700093353179_script_5542_getActionArgs_value($event),
    }
  }

  async act_96700093353179_script_5542_getWhen($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_96700093353179_script_5542, alias=submit
    return !this.disabled
  }

  async evh_96700093353179_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_96700093353179_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_96700093353179_script_5544_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_96700093353179_script_5544, alias=undefined
    $event.stop()
  }

  async act_96700093353179_script_5544_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_96700093353179_script_5544, alias=undefined
    return {
      value: () => this.act_96700093353179_script_5544_getActionArgs_value($event),
    }
  }

  async evh_96700093353179_bvDropdownHide(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_96700093353179_bvDropdownHide.executeFromDOM(this, event, scope);
  }

  async act_96700093353179_script_5546_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_96700093353179_script_5546, alias=undefined
    console.log($event)
  }

  async act_96700093353179_script_5546_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_96700093353179_script_5546, alias=undefined
    return {
      value: () => this.act_96700093353179_script_5546_getActionArgs_value($event),
    }
  }

  async evh_96700093353179_bvDropdownShow(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_96700093353179_bvDropdownShow.executeFromDOM(this, event, scope);
  }

  async act_302930856768608_showModal_5548_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_302930856768608_showModal_5548, alias=showJob
    return {
      name: "Job",
      props: {
        oid: this.jobData.id,
      }
      ,
    }
  }

  async evh_302930856768608_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_302930856768608_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_939987670462915_setData_5552_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_939987670462915_setData_5552, alias=undefined
    return {
      path: "jobData",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_939987670462915_setData_5552_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_939987670462915_setData_5552, alias=undefined
    return $event.data.response.data
  }

  async act_939987670462915_request_5550_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_939987670462915_request_5550, alias=reloadJobData
    return {
      operation: "read_a_job",
      parameters: {
        id: this.jobData.id,
      }
      ,
    }
  }

  async evh_939987670462915_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_939987670462915_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_script_5540: actions.ScriptAction;
    evh_7315092382398562_clickPreventStop_5539: actions.EventHandlerImpl;
    evh_7315092382398562_clickPreventStop: actions.EventHandlerGroup;
    act_96700093353179_script_5542: actions.ScriptAction;
    evh_96700093353179_clickPreventStop_5541: actions.EventHandlerImpl;
    evh_96700093353179_clickPreventStop: actions.EventHandlerGroup;
    act_96700093353179_script_5544: actions.ScriptAction;
    evh_96700093353179_bvDropdownHide_5543: actions.EventHandlerImpl;
    evh_96700093353179_bvDropdownHide: actions.EventHandlerGroup;
    act_96700093353179_script_5546: actions.ScriptAction;
    evh_96700093353179_bvDropdownShow_5545: actions.EventHandlerImpl;
    evh_96700093353179_bvDropdownShow: actions.EventHandlerGroup;
    act_302930856768608_showModal_5548: actions.ShowModalAction;
    evh_302930856768608_clickPreventStop_5547: actions.EventHandlerImpl;
    evh_302930856768608_clickPreventStop: actions.EventHandlerGroup;
    act_939987670462915_setData_5552: actions.SetDataAction;
    evh_939987670462915_success_5551: actions.EventHandlerImpl;
    act_939987670462915_request_5550: actions.RequestAction;
    evh_939987670462915_clickPreventStop_5549: actions.EventHandlerImpl;
    evh_939987670462915_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_script_5540 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_5540_getActionArgs,
        when: this.act_7315092382398562_script_5540_getWhen,
        displayName: "submit",
        events: [],
      }
    );
    const evh_7315092382398562_clickPreventStop_5539 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_5540,
        event: "click.prevent.stop",
        displayName: "submit",
      }
    );
    const evh_7315092382398562_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_clickPreventStop_5539],
      }
    );
    const act_96700093353179_script_5542 = new actions.ScriptAction(
      {
        actionArgs: this.act_96700093353179_script_5542_getActionArgs,
        when: this.act_96700093353179_script_5542_getWhen,
        displayName: "submit",
        events: [],
      }
    );
    const evh_96700093353179_clickPreventStop_5541 = new actions.EventHandlerImpl(
      {
        action: act_96700093353179_script_5542,
        event: "click.prevent.stop",
        displayName: "submit",
      }
    );
    const evh_96700093353179_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_96700093353179_clickPreventStop_5541],
      }
    );
    const act_96700093353179_script_5544 = new actions.ScriptAction(
      {
        actionArgs: this.act_96700093353179_script_5544_getActionArgs,
        events: [],
      }
    );
    const evh_96700093353179_bvDropdownHide_5543 = new actions.EventHandlerImpl(
      {
        action: act_96700093353179_script_5544,
        event: "bv::dropdown::hide",
        displayName: "script",
      }
    );
    const evh_96700093353179_bvDropdownHide = new actions.EventHandlerGroup(
      {
        handlers: [evh_96700093353179_bvDropdownHide_5543],
      }
    );
    const act_96700093353179_script_5546 = new actions.ScriptAction(
      {
        actionArgs: this.act_96700093353179_script_5546_getActionArgs,
        events: [],
      }
    );
    const evh_96700093353179_bvDropdownShow_5545 = new actions.EventHandlerImpl(
      {
        action: act_96700093353179_script_5546,
        event: "bv::dropdown::show",
        displayName: "script",
      }
    );
    const evh_96700093353179_bvDropdownShow = new actions.EventHandlerGroup(
      {
        handlers: [evh_96700093353179_bvDropdownShow_5545],
      }
    );
    const act_302930856768608_showModal_5548 = new actions.ShowModalAction(
      {
        actionArgs: this.act_302930856768608_showModal_5548_getActionArgs,
        displayName: "showJob",
        events: [],
      }
    );
    const evh_302930856768608_clickPreventStop_5547 = new actions.EventHandlerImpl(
      {
        action: act_302930856768608_showModal_5548,
        event: "click.prevent.stop",
        displayName: "showJob",
      }
    );
    const evh_302930856768608_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_302930856768608_clickPreventStop_5547],
      }
    );
    const act_939987670462915_setData_5552 = new actions.SetDataAction(
      {
        actionArgs: this.act_939987670462915_setData_5552_getActionArgs,
        when: this.act_939987670462915_setData_5552_getWhen,
        events: [],
      }
    );
    const evh_939987670462915_success_5551 = new actions.EventHandlerImpl(
      {
        action: act_939987670462915_setData_5552,
        event: "success",
        displayName: "setData",
      }
    );
    const act_939987670462915_request_5550 = new actions.RequestAction(
      {
        actionArgs: this.act_939987670462915_request_5550_getActionArgs,
        displayName: "reloadJobData",
        events: [evh_939987670462915_success_5551],
      }
    );
    const evh_939987670462915_clickPreventStop_5549 = new actions.EventHandlerImpl(
      {
        action: act_939987670462915_request_5550,
        event: "click.prevent.stop",
        displayName: "reloadJobData",
      }
    );
    const evh_939987670462915_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_939987670462915_clickPreventStop_5549],
      }
    );
    return {
      act_7315092382398562_script_5540,
      evh_7315092382398562_clickPreventStop_5539,
      evh_7315092382398562_clickPreventStop,
      act_96700093353179_script_5542,
      evh_96700093353179_clickPreventStop_5541,
      evh_96700093353179_clickPreventStop,
      act_96700093353179_script_5544,
      evh_96700093353179_bvDropdownHide_5543,
      evh_96700093353179_bvDropdownHide,
      act_96700093353179_script_5546,
      evh_96700093353179_bvDropdownShow_5545,
      evh_96700093353179_bvDropdownShow,
      act_302930856768608_showModal_5548,
      evh_302930856768608_clickPreventStop_5547,
      evh_302930856768608_clickPreventStop,
      act_939987670462915_setData_5552,
      evh_939987670462915_success_5551,
      act_939987670462915_request_5550,
      evh_939987670462915_clickPreventStop_5549,
      evh_939987670462915_clickPreventStop,
      submit: act_96700093353179_script_5542,
      showJob: act_302930856768608_showModal_5548,
      reloadJobData: act_939987670462915_request_5550,
    }
  }
}

Vue.component("JobHandlerControl", JobHandlerControl);

</script>