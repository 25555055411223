<template>
<ItpBox
  alignItems="center"
  class="w-100"
  v-if="loaded"
>
  <ItpText
    muted
    :text="resourceTitle"
    class="mb-0 mx-2"
  >
  </ItpText>
  <ItpText
    tag="h5"
    :text="resourceKey"
    class="mb-0 mx-2"
  >
  </ItpText>
  <UcJobNameBadge
    :value="item.name"
    class="mx-2"
  >
  </UcJobNameBadge>
  <UcJobStatusBadge
    :value="item.status"
    class="mx-2"
  >
  </UcJobStatusBadge>
  <ItpText
    strong
    class="mx-2"
  >
    {{ item.submit_time|datetime }}
  </ItpText>
  <ItpText
    strong
    no-wrap
    class="mx-2 text-truncate"
    :style="b_1382594685840889_style"
  >
    {{ item.created_by|username }}
  </ItpText>
  <b-button-group
    class="ml-auto"
  >
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto"
      @click.prevent.stop="evh_2754826507016131_clickPreventStop($event, {})"
    >
    </ItpButton>
  </b-button-group>
  <b-dropdown
    right
    variant="light"
    class="border"
  >
    <ItpIcon
      fa="bars"
      class="mx-1"
      slot="button-content"
    >
    </ItpIcon>
    <ItpButton
      dropdown-item
      :text="'Retry'|pgettext('Job')"
      size="sm"
      class="mr-2"
      v-if="item.links.retry"
      @click.prevent.stop="evh_7463771596263925_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      dropdown-item
      :text="'Cancel'|pgettext('Job')"
      size="sm"
      class="mr-2"
      v-if="item.links.cancel"
      @click.prevent.stop="evh_7763705103899431_clickPreventStop($event, {})"
    >
    </ItpButton>
  </b-dropdown>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class JobHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  messages!: any;
  ux!: any;
  b_1382594685840889_style!: any;
  dataMembers = ['messages', 'ux', 'b_1382594685840889_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          title: this.$fn.pgettext("Job|ModalHeader", "Job"),
        }
        ,
        ux: null,
        b_1382594685840889_style: {
          maxWidth: "80px",
        }
        ,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.$fn.pgettext("Job|ModalHeader", "Job")
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return '#' + this.item.number
    }
    return null;
  }

  async act_2754826507016131_emit_5564_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2754826507016131_emit_5564, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_2754826507016131_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2754826507016131_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7463771596263925_emit_5568_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7463771596263925_emit_5568, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_7463771596263925_request_5566_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7463771596263925_request_5566, alias=retryJob
    return {
      url: this.item.links.retry,
      method: "PATCH",
      headers: {
        "If-Match": this.item.etag,
      }
      ,
    }
  }

  async evh_7463771596263925_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7463771596263925_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7763705103899431_emit_5572_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7763705103899431_emit_5572, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_7763705103899431_request_5570_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7763705103899431_request_5570, alias=cancelJob
    return {
      url: this.item.links.cancel,
      method: "PATCH",
      headers: {
        "If-Match": this.item.etag,
      }
      ,
    }
  }

  async evh_7763705103899431_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7763705103899431_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_2754826507016131_emit_5564: actions.EmitAction;
    evh_2754826507016131_clickPreventStop_5563: actions.EventHandlerImpl;
    evh_2754826507016131_clickPreventStop: actions.EventHandlerGroup;
    act_7463771596263925_emit_5568: actions.EmitAction;
    evh_7463771596263925_success_5567: actions.EventHandlerImpl;
    act_7463771596263925_request_5566: actions.RequestAction;
    evh_7463771596263925_clickPreventStop_5565: actions.EventHandlerImpl;
    evh_7463771596263925_clickPreventStop: actions.EventHandlerGroup;
    act_7763705103899431_emit_5572: actions.EmitAction;
    evh_7763705103899431_success_5571: actions.EventHandlerImpl;
    act_7763705103899431_request_5570: actions.RequestAction;
    evh_7763705103899431_clickPreventStop_5569: actions.EventHandlerImpl;
    evh_7763705103899431_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_2754826507016131_emit_5564 = new actions.EmitAction(
      {
        actionArgs: this.act_2754826507016131_emit_5564_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_2754826507016131_clickPreventStop_5563 = new actions.EventHandlerImpl(
      {
        action: act_2754826507016131_emit_5564,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_2754826507016131_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2754826507016131_clickPreventStop_5563],
      }
    );
    const act_7463771596263925_emit_5568 = new actions.EmitAction(
      {
        actionArgs: this.act_7463771596263925_emit_5568_getActionArgs,
        events: [],
      }
    );
    const evh_7463771596263925_success_5567 = new actions.EventHandlerImpl(
      {
        action: act_7463771596263925_emit_5568,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7463771596263925_request_5566 = new actions.RequestAction(
      {
        actionArgs: this.act_7463771596263925_request_5566_getActionArgs,
        displayName: "retryJob",
        events: [evh_7463771596263925_success_5567],
      }
    );
    const evh_7463771596263925_clickPreventStop_5565 = new actions.EventHandlerImpl(
      {
        action: act_7463771596263925_request_5566,
        event: "click.prevent.stop",
        displayName: "retryJob",
      }
    );
    const evh_7463771596263925_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7463771596263925_clickPreventStop_5565],
      }
    );
    const act_7763705103899431_emit_5572 = new actions.EmitAction(
      {
        actionArgs: this.act_7763705103899431_emit_5572_getActionArgs,
        events: [],
      }
    );
    const evh_7763705103899431_success_5571 = new actions.EventHandlerImpl(
      {
        action: act_7763705103899431_emit_5572,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7763705103899431_request_5570 = new actions.RequestAction(
      {
        actionArgs: this.act_7763705103899431_request_5570_getActionArgs,
        displayName: "cancelJob",
        events: [evh_7763705103899431_success_5571],
      }
    );
    const evh_7763705103899431_clickPreventStop_5569 = new actions.EventHandlerImpl(
      {
        action: act_7763705103899431_request_5570,
        event: "click.prevent.stop",
        displayName: "cancelJob",
      }
    );
    const evh_7763705103899431_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7763705103899431_clickPreventStop_5569],
      }
    );
    return {
      act_2754826507016131_emit_5564,
      evh_2754826507016131_clickPreventStop_5563,
      evh_2754826507016131_clickPreventStop,
      act_7463771596263925_emit_5568,
      evh_7463771596263925_success_5567,
      act_7463771596263925_request_5566,
      evh_7463771596263925_clickPreventStop_5565,
      evh_7463771596263925_clickPreventStop,
      act_7763705103899431_emit_5572,
      evh_7763705103899431_success_5571,
      act_7763705103899431_request_5570,
      evh_7763705103899431_clickPreventStop_5569,
      evh_7763705103899431_clickPreventStop,
      reload: act_2754826507016131_emit_5564,
      retryJob: act_7463771596263925_request_5566,
      cancelJob: act_7763705103899431_request_5570,
    }
  }
}

Vue.component("JobHeader", JobHeader);

</script>