"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcWorksheetEditorFieldset = class UcWorksheetEditorFieldset extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['labelAlign', 'statuses', 'ux', 'b_8680084583475136_dataSource', 'b_3573211234170528_dataSource', 'b_6639903447389385_dataSource'];
    }
    beforeCreate() {
    }
    onRentalSelected(...args) {
        const { option, value } = args[0];
        if (option && option.client_id && option.client_name) {
            this.worksheet.client = {
                id: option.client_id,
                name: option.client_name
            };
        }
    }
    onWorksheet(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("worksheet");
    }
    $$load_statuses() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('WorksheetStatus', { sort: false });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            labelAlign: "right",
            statuses: null,
            ux: null,
            b_8680084583475136_dataSource: {
                name: "suggest_rental",
            },
            b_3573211234170528_dataSource: {
                name: "suggest_client",
            },
            b_6639903447389385_dataSource: {
                name: "suggest_client",
                params: {
                    contractors_only: true,
                },
            },
        });
    }
    get isReadonly() {
        if (this.loaded) {
            return this.worksheet === 'locked';
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8680084583475136_script_5892_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.onRentalSelected($event.data);
        });
    }
    act_8680084583475136_script_5892_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8680084583475136_script_5892_getActionArgs_value($event),
            };
        });
    }
    evh_8680084583475136_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8680084583475136_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5890 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5889 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5890,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5889],
        });
        const act_8680084583475136_script_5892 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8680084583475136_script_5892_getActionArgs,
            events: [],
        });
        const evh_8680084583475136_optionSelected_5891 = new core_1.actions.EventHandlerImpl({
            action: act_8680084583475136_script_5892,
            event: "optionSelected",
            displayName: "script",
        });
        const evh_8680084583475136_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8680084583475136_optionSelected_5891],
        });
        return {
            act_7315092382398562_reloadComponentData_5890,
            evh_7315092382398562_reload_5889,
            evh_7315092382398562_reload,
            act_8680084583475136_script_5892,
            evh_8680084583475136_optionSelected_5891,
            evh_8680084583475136_optionSelected,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcWorksheetEditorFieldset.prototype, "worksheet", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('worksheet'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcWorksheetEditorFieldset.prototype, "onWorksheet", null);
UcWorksheetEditorFieldset = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcWorksheetEditorFieldset);
exports.default = UcWorksheetEditorFieldset;
vue_property_decorator_1.Vue.component("UcWorksheetEditorFieldset", UcWorksheetEditorFieldset);
