<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Rental"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="page--v2 d-flex flex-column"
    v-if="loaded"
  >
    <MaterialTabs
      :tabManager="tabManager"
    >
      <ItpBox
        alignItems="center"
        class="mr-3"
        slot="tabs-start"
      >
        <b-breadcrumb
          class="m-0 bg-white"
        >
          <b-breadcrumb-item
            :text="'Rentals'|gettext"
            :to="b_2285343489561581_to"
          >
          </b-breadcrumb-item>
          <b-breadcrumb-item
            :text="item.snapshot('name')"
            active
          >
          </b-breadcrumb-item>
        </b-breadcrumb>
        <ItpButton
          variant="default"
          icon="fa-sync-alt"
          :text="'Reload'|gettext"
          :spinning="actions.reload.isRunning"
          class="px-3"
          @click.prevent.stop="evh_2095089031469157_clickPreventStop($event, {})"
        >
        </ItpButton>
      </ItpBox>
      <b-tab
        :title="'General'|gettext"
        class="container float-left"
      >
        <ItpRow
          v-if="tabManager.visited(0)"
        >
          <ItpCol
            w="6"
          >
            <UcRentalGeneralPanel
              :rental="item"
              :reload="lastLoaded"
              class="pb-5"
              @reload="evh_2679161326024595_reload($event, {})"
            >
            </UcRentalGeneralPanel>
            <UcRentalFormContracts
              :rental="item"
              :reload="lastLoaded"
              class="pb-5"
              v-if="item"
              @reload="evh_7987652045662005_reload($event, {})"
            >
            </UcRentalFormContracts>
            <UcRentalFormProperties
              :rentalId="item.id"
              :reload="lastLoaded"
              class="pb-5"
              v-if="item"
              @reload="evh_6490121676898844_reload($event, {})"
            >
            </UcRentalFormProperties>
          </ItpCol>
          <ItpCol
            w="6"
          >
            <UcCommentsList
              :parent="item"
              :reload="lastLoaded"
              class="pb-5"
            >
            </UcCommentsList>
            <UcAttachmentsList
              :parent="item"
              :reload="lastLoaded"
              class="pb-5"
            >
            </UcAttachmentsList>
          </ItpCol>
        </ItpRow>
      </b-tab>
      <b-tab
        :title="'Services'|gettext"
        class="container-fluid h-100"
      >
        <UcRentalServices
          :rental="item"
          :reload="lastLoaded"
          v-if="tabManager.visited(1)"
          @reload="evh_8697745105058001_reload($event, {})"
        >
        </UcRentalServices>
      </b-tab>
      <b-tab
        :title="'Fixed Assets'|gettext"
        class="container-fluid h-100"
      >
        <UcRentalFormFixedAssets
          :rentalId="item.id"
          :editable="!item.locked"
          :reload="lastLoaded"
          v-if="tabManager.visited(2)"
        >
        </UcRentalFormFixedAssets>
      </b-tab>
      <b-tab
        :title="'Invoices'|pgettext('Rental')"
        class="container-fluid h-100"
      >
        <UcRentalInvoices
          :rentalId="item.id"
          :reload="lastLoaded"
          class="h-100"
          v-if="tabManager.visited(3)"
        >
        </UcRentalInvoices>
      </b-tab>
    </MaterialTabs>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "rental",
    path: "/rentals/:id",
    component: "Rental",
    params: [{
      name: "id",
      prop: "rentalId",
    }
      ,
    ]

  }
)

@Component()
export default class Rental extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  rentalId!: string;

  @Watch('rentalId')
  onRental_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rentalId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  tabManager!: any;

  async $$load_tabManager() {
    return new this.$app.utils.LazyTabManager()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_rental', { asResource: true, parameters: { id: this.rentalId } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2285343489561581_to!: any;
  dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        tabManager: null,
        item: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2285343489561581_to: {
          name: "app.rentals",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.item.snapshot('name')
          ,
        this.$fn.gettext("Rental")
          ,
        ]
        ,
        icon: this.$config.rental.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3460_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3460, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2095089031469157_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_2679161326024595_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
  }

  async evh_7987652045662005_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7987652045662005_reload.executeFromDOM(this, event, scope);
  }

  async evh_6490121676898844_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6490121676898844_reload.executeFromDOM(this, event, scope);
  }

  async evh_8697745105058001_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8697745105058001_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3460: actions.CloseModalAction;
    evh_7315092382398562_close_3459: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_3462: actions.ReloadComponentDataAction;
    evh_2095089031469157_clickPreventStop_3461: actions.EventHandlerImpl;
    evh_2095089031469157_clickPreventStop: actions.EventHandlerGroup;
    act_2679161326024595_reloadComponentData_3464: actions.ReloadComponentDataAction;
    evh_2679161326024595_reload_3463: actions.EventHandlerImpl;
    evh_2679161326024595_reload: actions.EventHandlerGroup;
    act_7987652045662005_reloadComponentData_3466: actions.ReloadComponentDataAction;
    evh_7987652045662005_reload_3465: actions.EventHandlerImpl;
    evh_7987652045662005_reload: actions.EventHandlerGroup;
    act_6490121676898844_reloadComponentData_3468: actions.ReloadComponentDataAction;
    evh_6490121676898844_reload_3467: actions.EventHandlerImpl;
    evh_6490121676898844_reload: actions.EventHandlerGroup;
    act_8697745105058001_reloadComponentData_3470: actions.ReloadComponentDataAction;
    evh_8697745105058001_reload_3469: actions.EventHandlerImpl;
    evh_8697745105058001_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3460 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3460_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3459 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3460,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3459],
      }
    );
    const act_2095089031469157_reloadComponentData_3462 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_2095089031469157_clickPreventStop_3461 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_3462,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_2095089031469157_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_clickPreventStop_3461],
      }
    );
    const act_2679161326024595_reloadComponentData_3464 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2679161326024595_reload_3463 = new actions.EventHandlerImpl(
      {
        action: act_2679161326024595_reloadComponentData_3464,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2679161326024595_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2679161326024595_reload_3463],
      }
    );
    const act_7987652045662005_reloadComponentData_3466 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7987652045662005_reload_3465 = new actions.EventHandlerImpl(
      {
        action: act_7987652045662005_reloadComponentData_3466,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7987652045662005_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7987652045662005_reload_3465],
      }
    );
    const act_6490121676898844_reloadComponentData_3468 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6490121676898844_reload_3467 = new actions.EventHandlerImpl(
      {
        action: act_6490121676898844_reloadComponentData_3468,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_6490121676898844_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6490121676898844_reload_3467],
      }
    );
    const act_8697745105058001_reloadComponentData_3470 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8697745105058001_reload_3469 = new actions.EventHandlerImpl(
      {
        action: act_8697745105058001_reloadComponentData_3470,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8697745105058001_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8697745105058001_reload_3469],
      }
    );
    return {
      act_7315092382398562_closeModal_3460,
      evh_7315092382398562_close_3459,
      evh_7315092382398562_close,
      act_2095089031469157_reloadComponentData_3462,
      evh_2095089031469157_clickPreventStop_3461,
      evh_2095089031469157_clickPreventStop,
      act_2679161326024595_reloadComponentData_3464,
      evh_2679161326024595_reload_3463,
      evh_2679161326024595_reload,
      act_7987652045662005_reloadComponentData_3466,
      evh_7987652045662005_reload_3465,
      evh_7987652045662005_reload,
      act_6490121676898844_reloadComponentData_3468,
      evh_6490121676898844_reload_3467,
      evh_6490121676898844_reload,
      act_8697745105058001_reloadComponentData_3470,
      evh_8697745105058001_reload_3469,
      evh_8697745105058001_reload,
      reload: act_2095089031469157_reloadComponentData_3462,
    }
  }
}

Vue.component("Rental", Rental);

</script>