<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Invoices'|pgettext('Rental') }}
      </strong>
      <strong
        class="mx-2"
        v-if="invoices && invoices.length"
      >
        ({{ invoices.length }})
      </strong>
    </ItpBox>
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        v-if="!invoices.length"
      >
        <span>
          {{ 'No related invoices.'|pgettext('Rental') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="invoice.id"
        class="d-flex flex-column"
        v-for="invoice in filterItems(invoices, displayAll, displayCount)"
      >
        <table
          class="table table-borderless table-sm m-0"
        >
          <tbody>
            <tr>
              <td
                colspan="3"
              >
                <ItpLink
                  :text="invoice.client_data.name"
                  :to="{
    name: 'app.client',
    params: {id: invoice.client_data.id}
  }"
                  v-if="invoice.client_data"
                >
                </ItpLink>
              </td>
            </tr>
            <tr>
              <td>
                <ItpLink
                  :text="invoice.invoice_number"
                  :to="{
    name: 'app.invoice',
    params: {id: invoice.id}
  }"
                >
                </ItpLink>
                <UcInvoiceStatusBadge
                  :value="invoice.invoice_status"
                  class="mt-2"
                  v-if="invoice.invoice_status === 'draft'"
                >
                </UcInvoiceStatusBadge>
              </td>
              <td>
                <ItpText
                  muted
                  spacing="mr-2"
                >
                  {{'Net'|pgettext('InvoiceRental')}}:

                </ItpText>
                <ItpText
                  strong
                  spacing="mr-3"
                >
                  {{invoice.net_total|number }} {{ invoice.currency}}

                </ItpText>
              </td>
              <td>
                <ItpText
                  muted
                  spacing="px-1"
                >
                  {{ 'Total'|pgettext('InvoiceRental') }}:

                </ItpText>
                <ItpText
                  strong
                  spacing="px-2"
                >
                  {{ invoice.total|number }} {{ invoice.currency }}

                </ItpText>
              </td>
            </tr>
            <tr>
              <td>
                <ItpText
                  muted
                  strong
                  spacing="mr-4"
                >
                  {{ invoice.invoice_date|date }}
                </ItpText>
              </td>
              <td>
                <ItpText
                  :tooltip="'Billing Period'|pgettext('InvoiceRental')"
                  v-if="invoice.billing_period_start"
                >
                  {{ invoice.billing_period_start|date }} - {{ invoice.billing_period_end|date }}

                </ItpText>
              </td>
            </tr>
          </tbody>
        </table>
      </BListGroupItem>
      <BListGroupItem
        v-if="canHideItems || canDisplayMoreItems"
      >
        <ItpBox
          justifyContent="center"
        >
          <ItpLink
            :text="'Show less'|gettext"
            v-if="canHideItems"
            @click.prevent.stop="evh_7736732573189513_clickPreventStop($event, {})"
          >
          </ItpLink>
          <ItpLink
            :text="'Show more'|gettext"
            v-if="canDisplayMoreItems"
            @click.prevent.stop="evh_2912679705172878_clickPreventStop($event, {})"
          >
          </ItpLink>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcRentalInvoicesPanel extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_displayAll = new ComponentValueCache(this, "displayAll", "Rental.Invoices.displayAll");
  }

  filterItems(...args: any[]) {
    const [invoices, displayAll, displayCount] = args;
    if (displayAll || displayCount == invoices.length) {
      return invoices;
    }
    return _.take(invoices, displayCount)
  }

  @Prop({
    required: true,
    type: String,
  })
  rentalId!: string;

  @Watch('rentalId')
  onRental_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rentalId")
  }

  invoices!: any;

  async $$load_invoices() {
    return this.$fn.fetch('list_invoice', { parameters: { rental_id: this.rentalId } })
  }

  displayAll!: any;
  displayCount!: any;
  ux!: any;
  dataMembers = ['invoices', 'displayAll', 'displayCount', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        invoices: null,
        displayAll: false,
        displayCount: 5,
        ux: null,
      },
    }
  }

  get canDisplayMoreItems() {
    if (this.loaded) {

      return !this.displayAll && this.invoices && this.displayCount < this.invoices.length
    }
    return null;
  }

  get canHideItems() {
    if (this.loaded) {

      return this.displayAll && this.invoices && this.displayCount < this.invoices.length
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_7736732573189513_setData_6968_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7736732573189513_setData_6968, alias=undefined
    return {
      path: "displayAll",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7736732573189513_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7736732573189513_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2912679705172878_setData_6970_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2912679705172878_setData_6970, alias=undefined
    return {
      path: "displayAll",
      value: true,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2912679705172878_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2912679705172878_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6966: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6965: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_7736732573189513_setData_6968: actions.SetDataAction;
    evh_7736732573189513_clickPreventStop_6967: actions.EventHandlerImpl;
    evh_7736732573189513_clickPreventStop: actions.EventHandlerGroup;
    act_2912679705172878_setData_6970: actions.SetDataAction;
    evh_2912679705172878_clickPreventStop_6969: actions.EventHandlerImpl;
    evh_2912679705172878_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6966 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6965 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6966,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6965],
      }
    );
    const act_7736732573189513_setData_6968 = new actions.SetDataAction(
      {
        actionArgs: this.act_7736732573189513_setData_6968_getActionArgs,
        events: [],
      }
    );
    const evh_7736732573189513_clickPreventStop_6967 = new actions.EventHandlerImpl(
      {
        action: act_7736732573189513_setData_6968,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_7736732573189513_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7736732573189513_clickPreventStop_6967],
      }
    );
    const act_2912679705172878_setData_6970 = new actions.SetDataAction(
      {
        actionArgs: this.act_2912679705172878_setData_6970_getActionArgs,
        events: [],
      }
    );
    const evh_2912679705172878_clickPreventStop_6969 = new actions.EventHandlerImpl(
      {
        action: act_2912679705172878_setData_6970,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_2912679705172878_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2912679705172878_clickPreventStop_6969],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6966,
      evh_7315092382398562_reload_6965,
      evh_7315092382398562_reload,
      act_7736732573189513_setData_6968,
      evh_7736732573189513_clickPreventStop_6967,
      evh_7736732573189513_clickPreventStop,
      act_2912679705172878_setData_6970,
      evh_2912679705172878_clickPreventStop_6969,
      evh_2912679705172878_clickPreventStop,
    }
  }
}

Vue.component("UcRentalInvoicesPanel", UcRentalInvoicesPanel);

</script>