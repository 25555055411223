<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateContractRevision"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
    >
      <ItpFormGroup
        name="revision_number"
        :label="'Revision Number'|pgettext('Contract')"
        labelColsMd="4"
        contentColsMd="2"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          type="number"
          name="revision_number"
          v-model="item.revision_number"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="status"
        :label="'Status'|pgettext('Contract')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="status"
          v-model="item.status"
          valueField="value"
          textField="text"
          :options="statuses"
          required
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="revision_date"
        :label="'Revision Date'|pgettext('Contract')"
        labelColsMd="4"
        contentColsMd="4"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          type="date"
          name="revision_date"
          v-model="item.revision_date"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="valid_from"
        :label="'Validity'|pgettext('Contract')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
        contentClass="form-row"
        required
      >
        <DIV
          class="col"
        >
          <ItpFormDatetime
            name="valid_from"
            v-model="item.valid_from"
            required
          >
          </ItpFormDatetime>
        </DIV>
        <DIV
          class="col"
        >
          <ItpFormDatetime
            name="valid_to"
            v-model="item.valid_to"
            required
          >
          </ItpFormDatetime>
        </DIV>
      </ItpFormGroup>
      <HR>
      </HR>
      <ItpAlert
        variant="info"
        show
      >
        <p>
          <ItpIcon
            fa="info-circle"
          >
          </ItpIcon>
          <ItpText
            wrap
          >
            {{ 'The new revision will be created based on the opened revision. Select the following options to copy services and/or fees to the new revision.'|pgettext('CreateContractRevision') }}
          </ItpText>
        </p>
      </ItpAlert>
      <ItpFormGroup
        name="options"
        :label="'Options'|pgettext('CreateContractRevision')"
        labelColsMd="4"
        contentColsMd="8"
        :labelAlign="labelAlign"
      >
        <ItpBox
          direction="column"
          class="pl-1"
        >
          <ItpFormCheckbox
            name="copy_services"
            :label="'Copy services'|pgettext('CreateContractRevision')"
            inline
            v-model="options.copy_services"
          >
          </ItpFormCheckbox>
          <ItpFormCheckbox
            name="copy_service_fees"
            :label="'Copy service fees'|pgettext('CreateContractRevision')"
            inline
            v-model="options.copy_service_fees"
          >
          </ItpFormCheckbox>
          <ItpFormCheckbox
            name="skip_outdated_services"
            :label="'Skip outdated services'|pgettext('CreateContractRevision')"
            inline
            v-model="options.skip_outdated_services"
          >
          </ItpFormCheckbox>
          <ItpFormCheckbox
            name="skip_outdated_service_fees"
            :label="'Skip outdated service fees'|pgettext('CreateContractRevision')"
            inline
            v-model="options.skip_outdated_service_fees"
          >
          </ItpFormCheckbox>
        </ItpBox>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :text="'Create revision'|pgettext('Contract')"
      :spinning="actions.save.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      class="ml-3"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="default"
      :text="'Close'|gettext"
      :disabled="actions.save.isRunning"
      class="ml-3"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateContractRevision extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_options = new ComponentValueCache(this, "options", "CreateContractRevision.createRevisionOptions");
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return {
      id: this.contract.id,
      base_revision_id: this.contract.revision_id,
      revision_number: this.contract.revision_number + 1,
      revision_date: this.$fn.today(),
      valid_from: this.contract.lifecycle.valid_to ? this.$fn.dateAdd(this.contract.lifecycle.valid_to, { days: 1 }) : this.$fn.today(),
      valid_to: null,
      status: 'draft'
    }
  }

  statuses!: any;

  async $$load_statuses() {
    return this.$fn.getEnumValuesFromSchema('ContractStatus')
  }

  options!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formName', 'formId', 'labelAlign', 'item', 'statuses', 'options', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formName: "contract",
        formId: null,
        labelAlign: "right",
        item: null,
        statuses: null,
        options: {
          copy_services: true,
          copy_service_fees: true,
          skip_outdated_services: true,
          skip_outdated_service_fees: true,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Create Contract Revision"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2190_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2190, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_reloadSlickgrid_2196_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1548630417156826_reloadSlickgrid_2196, alias=undefined
    return {
      grid: "contracts",
    }
  }

  async act_1548630417156826_closeComponent_2198_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1548630417156826_closeComponent_2198, alias=undefined
    return {
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1548630417156826_navigate_2200_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1548630417156826_navigate_2200, alias=undefined
    return {
      location: {
        name: "app.contract",
        params: {
          id: $event.data.response.data.id,
          rev: $event.data.response.data.revision_number,
        }
        ,
      }
      ,
    }
  }

  async act_1548630417156826_request_2194_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1548630417156826_request_2194, alias=save
    return {
      operation: "create_contract_revision",
      data: { ...this.item, ...this.options },
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2190: actions.CloseModalAction;
    evh_7315092382398562_close_2189: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2192: actions.CloseComponentAction;
    evh_2248226175642056_close_2191: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1548630417156826_reloadSlickgrid_2196: actions.ReloadSlickgridAction;
    evh_1548630417156826_success_2195: actions.EventHandlerImpl;
    act_1548630417156826_closeComponent_2198: actions.CloseComponentAction;
    evh_1548630417156826_success_2197: actions.EventHandlerImpl;
    act_1548630417156826_navigate_2200: actions.NavigateAction;
    evh_1548630417156826_success_2199: actions.EventHandlerImpl;
    act_1548630417156826_request_2194: actions.RequestAction;
    evh_1548630417156826_clickPreventStop_2193: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_2202: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_2201: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2190 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2190_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2189 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2190,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2189],
      }
    );
    const act_2248226175642056_closeComponent_2192 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2191 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2192,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2191],
      }
    );
    const act_1548630417156826_reloadSlickgrid_2196 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1548630417156826_reloadSlickgrid_2196_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_2195 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_reloadSlickgrid_2196,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1548630417156826_closeComponent_2198 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1548630417156826_closeComponent_2198_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_2197 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_2198,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1548630417156826_navigate_2200 = new actions.NavigateAction(
      {
        actionArgs: this.act_1548630417156826_navigate_2200_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_2199 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_navigate_2200,
        event: "success",
        displayName: "navigate",
      }
    );
    const act_1548630417156826_request_2194 = new actions.RequestAction(
      {
        actionArgs: this.act_1548630417156826_request_2194_getActionArgs,
        displayName: "save",
        events: [evh_1548630417156826_success_2195, evh_1548630417156826_success_2197, evh_1548630417156826_success_2199],
      }
    );
    const evh_1548630417156826_clickPreventStop_2193 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_request_2194,
        event: "click.prevent.stop",
        displayName: "save",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_2193],
      }
    );
    const act_1419464017721962_closeModal_2202 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2201 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_2202,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2201],
      }
    );
    return {
      act_7315092382398562_closeModal_2190,
      evh_7315092382398562_close_2189,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2192,
      evh_2248226175642056_close_2191,
      evh_2248226175642056_close,
      act_1548630417156826_reloadSlickgrid_2196,
      evh_1548630417156826_success_2195,
      act_1548630417156826_closeComponent_2198,
      evh_1548630417156826_success_2197,
      act_1548630417156826_navigate_2200,
      evh_1548630417156826_success_2199,
      act_1548630417156826_request_2194,
      evh_1548630417156826_clickPreventStop_2193,
      evh_1548630417156826_clickPreventStop,
      act_1419464017721962_closeModal_2202,
      evh_1419464017721962_clickPreventStop_2201,
      evh_1419464017721962_clickPreventStop,
      save: act_1548630417156826_request_2194,
    }
  }
}

Vue.component("CreateContractRevision", CreateContractRevision);

</script>