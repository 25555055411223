<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    v-if="loaded"
  >
    <DIV
      class="mr-2"
      :style="b_8564662037462133_style"
    >
      <ItpFormSelect2
        name="accounts"
        v-model="item.account"
        bind-object
        textField="name,currency,account_number"
        :options="accounts"
        use-option-template-for-selected-option
        optionTemplate="<UcAccountSelectOption :payload=option></UcAccountSelectOption>"
        @optionSelected="evh_6466705138206980_optionSelected($event, {})"
      >
      </ItpFormSelect2>
    </DIV>
    <DIV
      class="mr-3"
    >
      <b-dropdown
        :text="'New transaction'|pgettext('Payments')"
        toggle-class="border"
        variant="light"
        v-if="selectedAccountType && selectedAccountType == 'cash_book'"
      >
        <ItpButton
          :text="'Add deposit'|pgettext('Payments')"
          :icon="$config.payment.icon"
          dropdown-item
          variant="secondary"
          @click.prevent="evh_8169383091859921_clickPrevent($event, {})"
        >
        </ItpButton>
        <ItpButton
          :text="'New payment'|pgettext('Payments')"
          :icon="$config.payment.icon"
          dropdown-item
          variant="secondary"
          @click.prevent="evh_7640643585580915_clickPrevent($event, {})"
        >
        </ItpButton>
      </b-dropdown>
      <ItpButton
        :text="'New transaction...'|pgettext('Payments')"
        :icon="$config.payment.icon"
        variant="light"
        v-if="selectedAccountType && selectedAccountType !== 'cash_book'"
        @click.prevent="evh_2857579216270778_clickPrevent($event, {})"
      >
      </ItpButton>
      <ItpButton
        :text="'Import Bank Statement...'|pgettext('Payments')"
        :icon="$config.uploadIcon"
        variant="light"
        @click.prevent="evh_8518026866742051_clickPrevent($event, {})"
      >
      </ItpButton>
    </DIV>
    <DateRangeSelector
      name="paymentFilters"
      :from="item.date_from"
      :to="item.date_to"
      class="mr-2"
      :style="b_1889980785424776_style"
      @changed="evh_1889980785424776_changed($event, {})"
    >
    </DateRangeSelector>
    <ItpButton
      variant="light"
      :icon="$config.reloadIcon"
      :text="'Load'|gettext"
      :title="'Load data'|pgettext('PaymentsViewFilters')"
      :spinning="actions && actions.load && actions.load.isRunning"
      @click.prevent.stop="evh_4892266742011185_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class PaymentsViewFilters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "PaymentsViewFilters");
  }

  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']))
  }

  item!: any;

  async $$load_item() {
    return {
      date_from: null,
      date_to: null,
      account: this.accounts ? this.accounts[0].id : null,
    }
  }

  ux!: any;
  b_8564662037462133_style!: any;
  b_1889980785424776_style!: any;
  dataMembers = ['accounts', 'item', 'ux', 'b_8564662037462133_style', 'b_1889980785424776_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        accounts: null,
        item: null,
        ux: null,
        b_8564662037462133_style: {
          width: "300px",
        }
        ,
        b_1889980785424776_style: {
          width: "500px",
        }
        ,
      },
    }
  }

  get selectedAccountType() {
    if (this.loaded) {

      return this.item.account && this.item.account.account_type
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_emit_5766_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6466705138206980_emit_5766, alias=load
    return {
      event: "submit",
      value: { ...this.item, account: this.item.account.id },
    }
  }

  async evh_6466705138206980_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_8169383091859921_showModal_5768_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8169383091859921_showModal_5768, alias=undefined
    return {
      name: "Payment",
      props: {
        accountId: this.item.account ? this.item.account.id : null,
        paymentType: "credit",
        transactionType: "deposit",
      }
      ,
    }
  }

  async evh_8169383091859921_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8169383091859921_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_7640643585580915_showModal_5770_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7640643585580915_showModal_5770, alias=undefined
    return {
      name: "Payment",
      props: {
        accountId: this.item.account ? this.item.account.id : null,
        transactionType: "payment",
      }
      ,
    }
  }

  async evh_7640643585580915_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7640643585580915_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2857579216270778_showModal_5772_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2857579216270778_showModal_5772, alias=undefined
    return {
      name: "Payment",
      props: {
        accountId: this.item.account ? this.item.account.id : null,
        transactionType: this.selectedAccountType === 'bank_account' ? 'banking' : this.selectedAccountType,
      }
      ,
    }
  }

  async evh_2857579216270778_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2857579216270778_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_8518026866742051_showModal_5774_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8518026866742051_showModal_5774, alias=undefined
    return {
      name: "ImportBankStatement",
    }
  }

  async evh_8518026866742051_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8518026866742051_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_1889980785424776_setData_5776_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1889980785424776_setData_5776, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1889980785424776_setData_5778_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1889980785424776_setData_5778, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1889980785424776_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1889980785424776_changed.executeFromDOM(this, event, scope);
  }

  async act_4892266742011185_emit_5780_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4892266742011185_emit_5780, alias=load
    return {
      event: "submit",
      value: { ...this.item, account: this.item.account.id },
    }
  }

  async evh_4892266742011185_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4892266742011185_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5764: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5763: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_6466705138206980_emit_5766: actions.EmitAction;
    evh_6466705138206980_optionSelected_5765: actions.EventHandlerImpl;
    evh_6466705138206980_optionSelected: actions.EventHandlerGroup;
    act_8169383091859921_showModal_5768: actions.ShowModalAction;
    evh_8169383091859921_clickPrevent_5767: actions.EventHandlerImpl;
    evh_8169383091859921_clickPrevent: actions.EventHandlerGroup;
    act_7640643585580915_showModal_5770: actions.ShowModalAction;
    evh_7640643585580915_clickPrevent_5769: actions.EventHandlerImpl;
    evh_7640643585580915_clickPrevent: actions.EventHandlerGroup;
    act_2857579216270778_showModal_5772: actions.ShowModalAction;
    evh_2857579216270778_clickPrevent_5771: actions.EventHandlerImpl;
    evh_2857579216270778_clickPrevent: actions.EventHandlerGroup;
    act_8518026866742051_showModal_5774: actions.ShowModalAction;
    evh_8518026866742051_clickPrevent_5773: actions.EventHandlerImpl;
    evh_8518026866742051_clickPrevent: actions.EventHandlerGroup;
    act_1889980785424776_setData_5776: actions.SetDataAction;
    evh_1889980785424776_changed_5775: actions.EventHandlerImpl;
    act_1889980785424776_setData_5778: actions.SetDataAction;
    evh_1889980785424776_changed_5777: actions.EventHandlerImpl;
    evh_1889980785424776_changed: actions.EventHandlerGroup;
    act_4892266742011185_emit_5780: actions.EmitAction;
    evh_4892266742011185_clickPreventStop_5779: actions.EventHandlerImpl;
    evh_4892266742011185_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5764 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5763 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5764,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5763],
      }
    );
    const act_6466705138206980_emit_5766 = new actions.EmitAction(
      {
        actionArgs: this.act_6466705138206980_emit_5766_getActionArgs,
        displayName: "load",
        events: [],
      }
    );
    const evh_6466705138206980_optionSelected_5765 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_emit_5766,
        event: "optionSelected",
        displayName: "load",
      }
    );
    const evh_6466705138206980_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_optionSelected_5765],
      }
    );
    const act_8169383091859921_showModal_5768 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8169383091859921_showModal_5768_getActionArgs,
        events: [],
      }
    );
    const evh_8169383091859921_clickPrevent_5767 = new actions.EventHandlerImpl(
      {
        action: act_8169383091859921_showModal_5768,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8169383091859921_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8169383091859921_clickPrevent_5767],
      }
    );
    const act_7640643585580915_showModal_5770 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7640643585580915_showModal_5770_getActionArgs,
        events: [],
      }
    );
    const evh_7640643585580915_clickPrevent_5769 = new actions.EventHandlerImpl(
      {
        action: act_7640643585580915_showModal_5770,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_7640643585580915_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_7640643585580915_clickPrevent_5769],
      }
    );
    const act_2857579216270778_showModal_5772 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2857579216270778_showModal_5772_getActionArgs,
        events: [],
      }
    );
    const evh_2857579216270778_clickPrevent_5771 = new actions.EventHandlerImpl(
      {
        action: act_2857579216270778_showModal_5772,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2857579216270778_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2857579216270778_clickPrevent_5771],
      }
    );
    const act_8518026866742051_showModal_5774 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8518026866742051_showModal_5774_getActionArgs,
        events: [],
      }
    );
    const evh_8518026866742051_clickPrevent_5773 = new actions.EventHandlerImpl(
      {
        action: act_8518026866742051_showModal_5774,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8518026866742051_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8518026866742051_clickPrevent_5773],
      }
    );
    const act_1889980785424776_setData_5776 = new actions.SetDataAction(
      {
        actionArgs: this.act_1889980785424776_setData_5776_getActionArgs,
        events: [],
      }
    );
    const evh_1889980785424776_changed_5775 = new actions.EventHandlerImpl(
      {
        action: act_1889980785424776_setData_5776,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_1889980785424776_setData_5778 = new actions.SetDataAction(
      {
        actionArgs: this.act_1889980785424776_setData_5778_getActionArgs,
        events: [],
      }
    );
    const evh_1889980785424776_changed_5777 = new actions.EventHandlerImpl(
      {
        action: act_1889980785424776_setData_5778,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_1889980785424776_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_1889980785424776_changed_5775, evh_1889980785424776_changed_5777],
      }
    );
    const act_4892266742011185_emit_5780 = new actions.EmitAction(
      {
        actionArgs: this.act_4892266742011185_emit_5780_getActionArgs,
        displayName: "load",
        events: [],
      }
    );
    const evh_4892266742011185_clickPreventStop_5779 = new actions.EventHandlerImpl(
      {
        action: act_4892266742011185_emit_5780,
        event: "click.prevent.stop",
        displayName: "load",
      }
    );
    const evh_4892266742011185_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4892266742011185_clickPreventStop_5779],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5764,
      evh_7315092382398562_reload_5763,
      evh_7315092382398562_reload,
      act_6466705138206980_emit_5766,
      evh_6466705138206980_optionSelected_5765,
      evh_6466705138206980_optionSelected,
      act_8169383091859921_showModal_5768,
      evh_8169383091859921_clickPrevent_5767,
      evh_8169383091859921_clickPrevent,
      act_7640643585580915_showModal_5770,
      evh_7640643585580915_clickPrevent_5769,
      evh_7640643585580915_clickPrevent,
      act_2857579216270778_showModal_5772,
      evh_2857579216270778_clickPrevent_5771,
      evh_2857579216270778_clickPrevent,
      act_8518026866742051_showModal_5774,
      evh_8518026866742051_clickPrevent_5773,
      evh_8518026866742051_clickPrevent,
      act_1889980785424776_setData_5776,
      evh_1889980785424776_changed_5775,
      act_1889980785424776_setData_5778,
      evh_1889980785424776_changed_5777,
      evh_1889980785424776_changed,
      act_4892266742011185_emit_5780,
      evh_4892266742011185_clickPreventStop_5779,
      evh_4892266742011185_clickPreventStop,
      load: act_4892266742011185_emit_5780,
    }
  }
}

Vue.component("PaymentsViewFilters", PaymentsViewFilters);

</script>