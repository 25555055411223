<template>
<ItpBox
  direction="column"
  alignItems="stretch"
>
  <ItpBox
    class="pb-2"
  >
    <strong>
      {{ 'Invoices'|pgettext('Worksheet') }}
    </strong>
  </ItpBox>
  <ItpDataTable
    rowKey="id"
    tableId="WorksheetInvoiceLineAccountings"
    :rowHeader="b_5962862420439144_rowHeader"
    :paging="b_5962862420439144_paging"
    :sortable="b_5962862420439144_sortable"
    :editable="b_5962862420439144_editable"
    :value="items"
    variant="details"
    :rowTemplate="{ worksheet_id: worksheet.id }"
    :columns="b_5962862420439144_columns"
    class="h-100"
    v-if="loaded"
    @save="evh_5962862420439144_save($event, {})"
    @delete="evh_5962862420439144_delete($event, {})"
  >
  </ItpDataTable>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcWorksheetInvoiceLineAccountings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  @Prop({
    type: Boolean,
  })
  isReadonly!: boolean;

  @Watch('isReadonly')
  onIs_readonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("isReadonly")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('query_invoice_line_accounting_for_worksheet', { parameters: { id: this.worksheet.id } })
  }

  ux!: any;
  b_5962862420439144_rowHeader!: any;
  b_5962862420439144_paging!: any;
  b_5962862420439144_sortable!: any;
  b_5962862420439144_editable!: any;
  b_5962862420439144_columns!: any;
  dataMembers = ['items', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_editable', 'b_5962862420439144_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          errorHandling: "local",
          initialLoadSpinner: true,
        }
        ,
        b_5962862420439144_rowHeader: false,
        b_5962862420439144_paging: false,
        b_5962862420439144_sortable: false,
        b_5962862420439144_editable: false,
        b_5962862420439144_columns: [{
          name: "invoice_number",
          header: this.$fn.gettext("Invoice Number"),
          formatter: {
            name: "template",
            value: "<div v-if=\"value\" class=\"text-left\">\n\
  <ItpLink :to=\"{ name: 'app.invoice', params: {id: row.invoice_id}}\">\n\
    {{ value }}\n\
  </ItpLink>\n\
</div>\n\
",
          }
          ,
        }
          ,
        {
          name: "product_description",
          header: this.$fn.gettext("Description"),
          formatter: {
            name: "template",
            value: "<div v-if=\"value\" class=\"text-left\">\n\
  <ItpLink :to=\"{ name: 'app.product', params: {id: row.product_id}}\">\n\
    {{ value }}\n\
  </ItpLink>\n\
</div>\n\
",
          }
          ,
        }
          ,
        {
          name: "quantity",
          type: "number",
          header: this.$fn.gettext("Quantity"),
          width: "150px",
        }
          ,
        {
          name: "unit",
          header: this.$fn.pgettext("abbreviated", "Unit"),
        }
          ,
        {
          name: "unit_price",
          header: this.$fn.gettext("Unit Price"),
          type: "number",
        }
          ,
        {
          name: "amount",
          header: this.$fn.gettext("Amount"),
          type: "number",
        }
          ,
        {
          name: "expense_type",
          header: this.$fn.gettext("Expense Type"),
          formatter: {
            name: "template",
            value: "<div v-if=\"value\">\n\
  {{ value|pgettext('ExpenseType', 'Sentence') }}\n\
</div>\n\
",
          }
          ,
        }
          ,
        {
          name: "cost_center",
          header: this.$fn.gettext("Cost Center"),
        }
          ,
        {
          name: "handover_date",
          header: this.$fn.gettext("Handover Date"),
          type: "date",
        }
          ,
        {
          name: "notes",
          header: this.$fn.gettext("Notes"),
        }
          ,
        ]
        ,
      },
    }
  }

  async act_5962862420439144_script_5918_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_5918, alias=undefined
    this.worksheet.id
      ? $event.data.waitFor(this.$fn.crud('work_item').upsert($event.data.row))
      : this.worksheet.work_items.push($event.data.row)
  }

  async act_5962862420439144_script_5918_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_5918, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_5918_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_5920_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_5920, alias=undefined
    this.worksheet.id
      ? $event.data.waitFor(this.$fn.crud('work_item').delete($event.data.row))
      : _.remove(this.worksheet.work_items, $event.data.row)
  }

  async act_5962862420439144_script_5920_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_5920, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_5920_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_5962862420439144_script_5918: actions.ScriptAction;
    evh_5962862420439144_save_5917: actions.EventHandlerImpl;
    evh_5962862420439144_save: actions.EventHandlerGroup;
    act_5962862420439144_script_5920: actions.ScriptAction;
    evh_5962862420439144_delete_5919: actions.EventHandlerImpl;
    evh_5962862420439144_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_5962862420439144_script_5918 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_5918_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_save_5917 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_5918,
        event: "save",
        displayName: "script",
      }
    );
    const evh_5962862420439144_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_save_5917],
      }
    );
    const act_5962862420439144_script_5920 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_5920_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_delete_5919 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_5920,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_5962862420439144_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_delete_5919],
      }
    );
    return {
      act_5962862420439144_script_5918,
      evh_5962862420439144_save_5917,
      evh_5962862420439144_save,
      act_5962862420439144_script_5920,
      evh_5962862420439144_delete_5919,
      evh_5962862420439144_delete,
    }
  }
}

Vue.component("UcWorksheetInvoiceLineAccountings", UcWorksheetInvoiceLineAccountings);

</script>