"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let NumberingSelector = class NumberingSelector extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['internalValue', 'items', 'ux', 'b_7315092382398562_filterable', 'b_7315092382398562_searchable'];
    }
    beforeCreate() {
    }
    onObject_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("objectType");
    }
    onName(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("name");
    }
    onValue(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("value");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_internalValue() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.value;
        });
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_numbering', { parameters: { resource_type: this.objectType } })
                .then(d => [{ id: null, description: this.$fn.gettext('Manual') }].concat(d));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            internalValue: null,
            items: null,
            ux: null,
            b_7315092382398562_filterable: false,
            b_7315092382398562_searchable: false,
        });
    }
    act_7315092382398562_emit_3094_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "input",
                value: $event.originalEvent,
            };
        });
    }
    evh_7315092382398562_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_input.executeFromDOM(this, event, scope);
        });
    }
    act_3343864469950044_register_3098_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "resultID",
                value: $event.data.modalResult && $event.data.modalResult.ok && $event.data.modalResult.value.id,
            };
        });
    }
    evh_3343864469950044_close_3099_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.resultID;
        });
    }
    act_3343864469950044_setData_3102_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "internalValue",
                value: this.resultID,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_3343864469950044_close_3101_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.resultID;
        });
    }
    act_3343864469950044_showModal_3096_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Numbering",
                props: {
                    objectType: this.objectType,
                },
            };
        });
    }
    evh_3343864469950044_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_emit_3094 = new core_1.actions.EmitAction({
            actionArgs: this.act_7315092382398562_emit_3094_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_input_3093 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_emit_3094,
            event: "input",
            displayName: "emit",
        });
        const evh_7315092382398562_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_input_3093],
        });
        const act_3343864469950044_register_3098 = new core_1.actions.RegisterAction({
            actionArgs: this.act_3343864469950044_register_3098_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_close_3097 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_register_3098,
            event: "close",
            displayName: "register",
        });
        const act_3343864469950044_reloadComponentData_3100 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_3343864469950044_close_3099 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_reloadComponentData_3100,
            event: "close",
            when: this.evh_3343864469950044_close_3099_getWhen,
            displayName: "reloadComponentData",
        });
        const act_3343864469950044_setData_3102 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3343864469950044_setData_3102_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_close_3101 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_setData_3102,
            event: "close",
            when: this.evh_3343864469950044_close_3101_getWhen,
            displayName: "setData",
        });
        const act_3343864469950044_showModal_3096 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_3096_getActionArgs,
            events: [evh_3343864469950044_close_3097, evh_3343864469950044_close_3099, evh_3343864469950044_close_3101],
        });
        const evh_3343864469950044_click_3095 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_3096,
            event: "click",
            displayName: "showModal",
        });
        const evh_3343864469950044_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_click_3095],
        });
        return {
            act_7315092382398562_emit_3094,
            evh_7315092382398562_input_3093,
            evh_7315092382398562_input,
            act_3343864469950044_register_3098,
            evh_3343864469950044_close_3097,
            act_3343864469950044_reloadComponentData_3100,
            evh_3343864469950044_close_3099,
            act_3343864469950044_setData_3102,
            evh_3343864469950044_close_3101,
            act_3343864469950044_showModal_3096,
            evh_3343864469950044_click_3095,
            evh_3343864469950044_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], NumberingSelector.prototype, "objectType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('objectType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], NumberingSelector.prototype, "onObject_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], NumberingSelector.prototype, "name", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('name'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], NumberingSelector.prototype, "onName", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
    }),
    __metadata("design:type", Object)
], NumberingSelector.prototype, "value", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], NumberingSelector.prototype, "onValue", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], NumberingSelector.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], NumberingSelector.prototype, "onReadonly", null);
NumberingSelector = __decorate([
    (0, vue_property_decorator_1.Component)()
], NumberingSelector);
exports.default = NumberingSelector;
vue_property_decorator_1.Vue.component("NumberingSelector", NumberingSelector);
