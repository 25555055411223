<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    spacing="pr-3 py-1"
    alignItems="center"
    v-if="loaded"
  >
    <ItpBadge
      :text="resource.inactive ? gettext('Inactive') : gettext('Active')"
      :variant="resource.inactive ? 'light' : 'primary'"
      no-box-shadow
    >
    </ItpBadge>
    <ItpButton
      size="sm"
      variant="light"
      :text="'Activate'|pgettext('UcResourceStatusCard')"
      class="mx-3 border"
      v-if="!resource.locked && resource.inactive"
      @click.stop.prevent="evh_3343864469950044_clickStopPrevent($event, {})"
    >
    </ItpButton>
    <ItpButton
      size="sm"
      variant="light"
      :text="'Inactivate'|pgettext('UcResourceStatusCard')"
      class="mx-3 border"
      v-if="!resource.locked && !resource.inactive"
      @click.stop.prevent="evh_1889980785424776_clickStopPrevent($event, {})"
    >
    </ItpButton>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcResourceStatusCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  resource!: object;

  @Watch('resource')
  onResource(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("resource")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_emit_1914_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3343864469950044_emit_1914, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_3343864469950044_request_1912_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3343864469950044_request_1912, alias=toggleState
    return {
      url: this.resource.links.self,
      method: "patch",
      data: {
        etag: this.resource.etag,
        inactive: !this.resource.inactive,
      },
    }
  }

  async evh_3343864469950044_clickStopPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_clickStopPrevent.executeFromDOM(this, event, scope);
  }

  async act_1889980785424776_emit_1918_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1889980785424776_emit_1918, alias=undefined
    return {
      event: "reload",
    }
  }

  async act_1889980785424776_request_1916_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1889980785424776_request_1916, alias=toggleState
    return {
      url: this.resource.links.self,
      method: "patch",
      data: {
        etag: this.resource.etag,
        inactive: !this.resource.inactive,
      },
    }
  }

  async evh_1889980785424776_clickStopPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1889980785424776_clickStopPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_1910: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_1909: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3343864469950044_emit_1914: actions.EmitAction;
    evh_3343864469950044_success_1913: actions.EventHandlerImpl;
    act_3343864469950044_request_1912: actions.RequestAction;
    evh_3343864469950044_clickStopPrevent_1911: actions.EventHandlerImpl;
    evh_3343864469950044_clickStopPrevent: actions.EventHandlerGroup;
    act_1889980785424776_emit_1918: actions.EmitAction;
    evh_1889980785424776_success_1917: actions.EventHandlerImpl;
    act_1889980785424776_request_1916: actions.RequestAction;
    evh_1889980785424776_clickStopPrevent_1915: actions.EventHandlerImpl;
    evh_1889980785424776_clickStopPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_1910 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_1909 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_1910,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_1909],
      }
    );
    const act_3343864469950044_emit_1914 = new actions.EmitAction(
      {
        actionArgs: this.act_3343864469950044_emit_1914_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_success_1913 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_emit_1914,
        event: "success",
        displayName: "emit",
      }
    );
    const act_3343864469950044_request_1912 = new actions.RequestAction(
      {
        actionArgs: this.act_3343864469950044_request_1912_getActionArgs,
        displayName: "toggleState",
        events: [evh_3343864469950044_success_1913],
      }
    );
    const evh_3343864469950044_clickStopPrevent_1911 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_request_1912,
        event: "click.stop.prevent",
        displayName: "toggleState",
      }
    );
    const evh_3343864469950044_clickStopPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_clickStopPrevent_1911],
      }
    );
    const act_1889980785424776_emit_1918 = new actions.EmitAction(
      {
        actionArgs: this.act_1889980785424776_emit_1918_getActionArgs,
        events: [],
      }
    );
    const evh_1889980785424776_success_1917 = new actions.EventHandlerImpl(
      {
        action: act_1889980785424776_emit_1918,
        event: "success",
        displayName: "emit",
      }
    );
    const act_1889980785424776_request_1916 = new actions.RequestAction(
      {
        actionArgs: this.act_1889980785424776_request_1916_getActionArgs,
        displayName: "toggleState",
        events: [evh_1889980785424776_success_1917],
      }
    );
    const evh_1889980785424776_clickStopPrevent_1915 = new actions.EventHandlerImpl(
      {
        action: act_1889980785424776_request_1916,
        event: "click.stop.prevent",
        displayName: "toggleState",
      }
    );
    const evh_1889980785424776_clickStopPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_1889980785424776_clickStopPrevent_1915],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_1910,
      evh_7315092382398562_reload_1909,
      evh_7315092382398562_reload,
      act_3343864469950044_emit_1914,
      evh_3343864469950044_success_1913,
      act_3343864469950044_request_1912,
      evh_3343864469950044_clickStopPrevent_1911,
      evh_3343864469950044_clickStopPrevent,
      act_1889980785424776_emit_1918,
      evh_1889980785424776_success_1917,
      act_1889980785424776_request_1916,
      evh_1889980785424776_clickStopPrevent_1915,
      evh_1889980785424776_clickStopPrevent,
      toggleState: act_1889980785424776_request_1916,
    }
  }
}

Vue.component("UcResourceStatusCard", UcResourceStatusCard);

</script>