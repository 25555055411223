<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Payment"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcPaymentHeader
      :payment="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcPaymentHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="payment"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetPaymentGeneral
        :payment="item"
        :formId="formId"
      >
      </FieldsetPaymentGeneral>
    </ItpForm>
    <HR>
    </HR>
    <UcPaymentInvoices
      :payment="item"
      @recalculate="evh_3083451129821185_recalculate($event, {})"
    >
    </UcPaymentInvoices>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save changes'|pgettext('Button')"
      :disabled="!forms.payment || !forms.payment.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="!isNew && !item.isReadonly"
    >
    </ItpButton>
    <ItpButton
      :text="'Complete'|pgettext('Command')"
      :disabled="!isNew && item.readonly"
      variant="success"
      icon="fa-check"
      class="mr-2"
      v-if="!isNew && !item.isReadonly"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Create payment'|pgettext('Button')"
      :disabled="!forms.payment || !forms.payment.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="isNew"
    >
    </ItpButton>
    <ItpButton
      :text="'Create another payment'|pgettext('Button')"
      :disabled="!forms.payment || !forms.payment.submittable"
      variant="light"
      icon="mdi:forward"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="!isNew"
      @click.prevent.stop="evh_5566617329548203_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Payment extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  recalculateAmountFromLines(...args: any[]) {
    const item = this.item;
    const [invoiceData] = args;

    console.log('recalculateAmountFromLines', args, invoiceData);

    if (invoiceData) {
      const idx = _.findIndex(item.invoices.data, { invoice_id: invoiceData.invoice_id });
      if (idx > -1) {
        _.extend(item.invoices.data[idx], invoiceData);
      }
    }

    item.amount = _.sumBy(item.invoices.data, p => _.toNumber(p.amount))
    if (this.isPettyCashPayment) {
      item.amount = Math.abs(item.amount);
    }
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Array,
    default: null,
  })
  invoices!: array;

  @Watch('invoices')
  onInvoices(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoices")
  }

  @Prop({
    type: String,
  })
  accountId!: string;

  @Watch('accountId')
  onAccount_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("accountId")
  }

  @Prop({
    type: String,
    default: null,
  })
  transactionType!: string;

  @Watch('transactionType')
  onTransaction_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("transactionType")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.oid == null
      ? this.$fn.fetch(
        'get_payment_template', {
        asResource: true,
        parameters: {
          TransactionType: this.transactionType,
          invoices: this.invoices ? this.invoices.join(',') : null,
          account_id: this.accountId
        }
      }
      )
      : this.$fn.fetch('read_payment', { asResource: true, parameters: { id: this.oid } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get isDeletable() {
    if (this.loaded) {

      return this.oid != null
    }
    return null;
  }

  get isNew() {
    if (this.loaded) {

      return this.item && this.item.id == null
    }
    return null;
  }

  get isPettyCashPayment() {
    if (this.loaded) {

      return this.item.account && this.item.account.account_type === 'cash_book'
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3170_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3170, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_3178_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_3178, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_3182_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_3182, alias=undefined
    return {
      path: "item",
      value: $event && $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_setData_3182_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_3182, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_reloadSlickgrid_3184_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3184, alias=undefined
    return {
      grid: "payments",
    }
  }

  async act_1040167445267876_reloadSlickgrid_3184_getWhen($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3184, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_crud_3180_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_3180, alias=save
    return {
      objectType: "payment",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_3083451129821185_script_3186_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3083451129821185_script_3186, alias=undefined
    this.recalculateAmountFromLines($event.data)
  }

  async act_3083451129821185_script_3186_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3083451129821185_script_3186, alias=undefined
    return {
      value: () => this.act_3083451129821185_script_3186_getActionArgs_value($event),
    }
  }

  async evh_3083451129821185_recalculate(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3083451129821185_recalculate.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_setData_3190_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1419464017721962_setData_3190, alias=undefined
    return {
      path: "item",
      value: $event && $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1419464017721962_success_3189_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1419464017721962_success_3189, alias=undefined
    return !!$event.data
  }

  async act_1419464017721962_reloadSlickgrid_3192_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1419464017721962_reloadSlickgrid_3192, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_1419464017721962_success_3191_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1419464017721962_success_3191, alias=undefined
    return !!$event.data
  }

  async act_1419464017721962_rest_3188_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1419464017721962_rest_3188, alias=complete
    return {
      method: "save",
      container: {
        self: this.item.links.parent,
        object: 'list',
        data: []
      },
      resource: this.item,
      data: {
        etag: this.item.etag,
        complete: true,
      },
    }
  }

  async act_1419464017721962_rest_3188_getConfirm($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1419464017721962_rest_3188, alias=complete
    return {
      header: this.dialogHeader,
      message: this.$fn.gettext("Completing prevents further modifications. Please confirm this operation."),
      btnOk: this.$fn.gettext("Complete"),
      severity: "warning",
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5566617329548203_setData_3194_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5566617329548203_setData_3194, alias=undefined
    return {
      path: "item",
      value: this.item.clone({
        id: null,
        rev: null,
        payment_number: this.isPettyCashPayment ? null : this.item.payment_number,
        amount: 0,
        links: null,
        etag: null,
        invoices: { data: [] },
        preview_url: null,
        locked: false,
        notes: null,
        internal_notes: null,
      }),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5566617329548203_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3170: actions.CloseModalAction;
    evh_7315092382398562_close_3169: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_3172: actions.CloseModalAction;
    evh_2248226175642056_close_3171: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_3174: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_3173: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_3176: actions.CloseModalAction;
    evh_8564662037462133_deleted_3175: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_3178: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_3177: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_setData_3182: actions.SetDataAction;
    evh_1040167445267876_success_3181: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_3184: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_3183: actions.EventHandlerImpl;
    act_1040167445267876_crud_3180: actions.CRUDAction;
    evh_1040167445267876_submit_3179: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_3083451129821185_script_3186: actions.ScriptAction;
    evh_3083451129821185_recalculate_3185: actions.EventHandlerImpl;
    evh_3083451129821185_recalculate: actions.EventHandlerGroup;
    act_1419464017721962_setData_3190: actions.SetDataAction;
    evh_1419464017721962_success_3189: actions.EventHandlerImpl;
    act_1419464017721962_reloadSlickgrid_3192: actions.ReloadSlickgridAction;
    evh_1419464017721962_success_3191: actions.EventHandlerImpl;
    act_1419464017721962_rest_3188: actions.RestAction;
    evh_1419464017721962_clickPreventStop_3187: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
    act_5566617329548203_setData_3194: actions.SetDataAction;
    evh_5566617329548203_clickPreventStop_3193: actions.EventHandlerImpl;
    evh_5566617329548203_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3170 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3170_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3169 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3170,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3169],
      }
    );
    const act_2248226175642056_closeModal_3172 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3171 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_3172,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3171],
      }
    );
    const act_8564662037462133_reloadComponentData_3174 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_3173 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_3174,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_3173],
      }
    );
    const act_8564662037462133_closeModal_3176 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_3175 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_3176,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const act_8564662037462133_reloadSlickgrid_3178 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_3178_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_3177 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_3178,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_3175, evh_8564662037462133_deleted_3177],
      }
    );
    const act_1040167445267876_setData_3182 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_3182_getActionArgs,
        when: this.act_1040167445267876_setData_3182_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_3181 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_3182,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_reloadSlickgrid_3184 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_3184_getActionArgs,
        when: this.act_1040167445267876_reloadSlickgrid_3184_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_3183 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_3184,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_crud_3180 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_3180_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_3181, evh_1040167445267876_success_3183],
      }
    );
    const evh_1040167445267876_submit_3179 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_3180,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3179],
      }
    );
    const act_3083451129821185_script_3186 = new actions.ScriptAction(
      {
        actionArgs: this.act_3083451129821185_script_3186_getActionArgs,
        events: [],
      }
    );
    const evh_3083451129821185_recalculate_3185 = new actions.EventHandlerImpl(
      {
        action: act_3083451129821185_script_3186,
        event: "recalculate",
        displayName: "script",
      }
    );
    const evh_3083451129821185_recalculate = new actions.EventHandlerGroup(
      {
        handlers: [evh_3083451129821185_recalculate_3185],
      }
    );
    const act_1419464017721962_setData_3190 = new actions.SetDataAction(
      {
        actionArgs: this.act_1419464017721962_setData_3190_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_3189 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_setData_3190,
        event: "success",
        when: this.evh_1419464017721962_success_3189_getWhen,
        displayName: "setData",
      }
    );
    const act_1419464017721962_reloadSlickgrid_3192 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1419464017721962_reloadSlickgrid_3192_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_3191 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_reloadSlickgrid_3192,
        event: "success",
        when: this.evh_1419464017721962_success_3191_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_1419464017721962_rest_3188 = new actions.RestAction(
      {
        actionArgs: this.act_1419464017721962_rest_3188_getActionArgs,
        confirm: this.act_1419464017721962_rest_3188_getConfirm,
        displayName: "complete",
        events: [evh_1419464017721962_success_3189, evh_1419464017721962_success_3191],
      }
    );
    const evh_1419464017721962_clickPreventStop_3187 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_rest_3188,
        event: "click.prevent.stop",
        displayName: "complete",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3187],
      }
    );
    const act_5566617329548203_setData_3194 = new actions.SetDataAction(
      {
        actionArgs: this.act_5566617329548203_setData_3194_getActionArgs,
        events: [],
      }
    );
    const evh_5566617329548203_clickPreventStop_3193 = new actions.EventHandlerImpl(
      {
        action: act_5566617329548203_setData_3194,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_5566617329548203_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5566617329548203_clickPreventStop_3193],
      }
    );
    return {
      act_7315092382398562_closeModal_3170,
      evh_7315092382398562_close_3169,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_3172,
      evh_2248226175642056_close_3171,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_3174,
      evh_8564662037462133_reload_3173,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_3176,
      evh_8564662037462133_deleted_3175,
      act_8564662037462133_reloadSlickgrid_3178,
      evh_8564662037462133_deleted_3177,
      evh_8564662037462133_deleted,
      act_1040167445267876_setData_3182,
      evh_1040167445267876_success_3181,
      act_1040167445267876_reloadSlickgrid_3184,
      evh_1040167445267876_success_3183,
      act_1040167445267876_crud_3180,
      evh_1040167445267876_submit_3179,
      evh_1040167445267876_submit,
      act_3083451129821185_script_3186,
      evh_3083451129821185_recalculate_3185,
      evh_3083451129821185_recalculate,
      act_1419464017721962_setData_3190,
      evh_1419464017721962_success_3189,
      act_1419464017721962_reloadSlickgrid_3192,
      evh_1419464017721962_success_3191,
      act_1419464017721962_rest_3188,
      evh_1419464017721962_clickPreventStop_3187,
      evh_1419464017721962_clickPreventStop,
      act_5566617329548203_setData_3194,
      evh_5566617329548203_clickPreventStop_3193,
      evh_5566617329548203_clickPreventStop,
      save: act_1040167445267876_crud_3180,
      complete: act_1419464017721962_rest_3188,
    }
  }
}

Vue.component("Payment", Payment);

</script>