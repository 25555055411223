<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <FieldsetInvoiceHeader
    :invoice="invoice"
    :labelAlign="labelAlign"
    v-if="!target || target === 'header'"
  >
  </FieldsetInvoiceHeader>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceEditorFieldset extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  labelAlign!: any;
  ux!: any;
  dataMembers = ['labelAlign', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2502: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2501: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2502 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2501 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2502,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2501],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2502,
      evh_7315092382398562_reload_2501,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcInvoiceEditorFieldset", UcInvoiceEditorFieldset);

</script>