<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="name"
    :label="'Asset Type'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpBadge
      variant="secondary"
      class="px-2 shadow-none"
    >
      <SPAN>
        {{ fixedAssetTypes[fixedAsset.asset_type] }}
      </SPAN>
    </ItpBadge>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="name"
    :label="'Name'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="name"
      v-model="fixedAsset.name"
      required
      autofocus
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="quantity"
    :label="'Quantity'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      type="number"
      name="quantity"
      size="md"
      v-model="fixedAsset.quantity"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="inventory_no"
    :label="'Inventory Number'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="inventory_no"
      size="md"
      v-model="fixedAsset.inventory_no"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="sku"
    :label="'SKU'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="sku"
      size="md"
      v-model="fixedAsset.sku"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Description'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="description"
      size="md"
      v-model="fixedAsset.description"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="type_no"
    :label="'Type Number'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="type_no"
      size="md"
      v-model="fixedAsset.type_no"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="cost"
    :label="'Cost'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="cost"
      size="md"
      v-model="fixedAsset.cost"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="gl_code"
    :label="'General Ledger Code'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="gl_code"
      size="md"
      v-model="fixedAsset.gl_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="year_of_production"
    :label="'Year of Production'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      type="number"
      name="year_of_production"
      size="md"
      v-model="fixedAsset.year_of_production"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="manufacturer"
    :label="'Manufacturer'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="manufacturer"
      size="md"
      v-model="fixedAsset.manufacturer"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="serial_no"
    :label="'Serial Number'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="serial_no"
      size="md"
      v-model="fixedAsset.serial_no"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="category"
    :label="'Category'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="category"
      size="md"
      v-model="fixedAsset.category"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="groups"
    :label="'Groups'|pgettext('FixedAsset')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect2
      name="groups"
      size="md"
      v-model="fixedAsset.groups"
      multiple
      :options="fixedAssetGroups"
      valueField="id"
      textField="name"
    >
    </ItpFormSelect2>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetFixedAssetGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  fixedAssetGroups!: any;

  async $$load_fixedAssetGroups() {
    return this.$fn.fetch('list_group', { parameters: { type: 'fixed_asset' } })
  }

  currencies!: any;
  units!: any;
  fixedAssetTypes!: any;
  ux!: any;
  dataMembers = ['fixedAssetGroups', 'currencies', 'units', 'fixedAssetTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        fixedAssetGroups: null,
        currencies: ["HUF"
          ,
          "EUR"
          ,
          "USD"
          ,
        ]
        ,
        units: ["ha"
          ,
          "m2"
          ,
        ]
        ,
        fixedAssetTypes: {
          general: this.$fn.pgettext("FixedAssetTypes", "General"),
          waste_bin: this.$fn.pgettext("FixedAssetTypes", "Waste Bin"),
        }
        ,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4650: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4649: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4650 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4649 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4650,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4649],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4650,
      evh_7315092382398562_reload_4649,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetFixedAssetGeneral", FieldsetFixedAssetGeneral);

</script>