"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InflationIndexedPriceChange = class InflationIndexedPriceChange extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['labelAlign', 'item', 'payload', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onId(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("id");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_notification', { parameters: { id: this.id }, asResource: true });
        });
    }
    $$load_payload() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                price: this.item.data.new_price,
                valid_from: this.item.data.source_object.valid_to || this.$fn.today(),
                valid_to: null,
                notes: null
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            labelAlign: "right",
            item: null,
            payload: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create new price"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2214_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_2220_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_1040167445267876_request_2218_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_notification",
                data: {
                    id: this.item.id,
                    $etag: this.item.$etag,
                    state: 'handled',
                    result_notes: this.payload.notes,
                    result_data: this.payload
                },
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_2222_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2214 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2214_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2213 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2214,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2213],
        });
        const act_2248226175642056_closeComponent_2216 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2215 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2216,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2215],
        });
        const act_1040167445267876_closeModal_2220 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_2220_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_2219 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_2220,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_request_2218 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_2218_getActionArgs,
            displayName: "update",
            events: [evh_1040167445267876_success_2219],
        });
        const evh_1040167445267876_submit_2217 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_2218,
            event: "submit",
            displayName: "update",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2217],
        });
        const act_1419464017721962_closeModal_2222 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_2222_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_2221 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_2222,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2221],
        });
        return {
            act_7315092382398562_closeModal_2214,
            evh_7315092382398562_close_2213,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2216,
            evh_2248226175642056_close_2215,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_2220,
            evh_1040167445267876_success_2219,
            act_1040167445267876_request_2218,
            evh_1040167445267876_submit_2217,
            evh_1040167445267876_submit,
            act_1419464017721962_closeModal_2222,
            evh_1419464017721962_clickPreventStop_2221,
            evh_1419464017721962_clickPreventStop,
            update: act_1040167445267876_request_2218,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], InflationIndexedPriceChange.prototype, "id", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('id'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InflationIndexedPriceChange.prototype, "onId", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], InflationIndexedPriceChange.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InflationIndexedPriceChange.prototype, "onSize", null);
InflationIndexedPriceChange = __decorate([
    (0, vue_property_decorator_1.Component)()
], InflationIndexedPriceChange);
exports.default = InflationIndexedPriceChange;
vue_property_decorator_1.Vue.component("InflationIndexedPriceChange", InflationIndexedPriceChange);
