<template>
<ItpPage
  card
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceLogMessageViewer"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="item"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="item"
  >
    <CodeMirror
      mode="string"
      :options="b_1040167445267876_options"
      v-model="item.log_messages"
    >
    </CodeMirror>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="item"
  >
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoice",
    path: "/invoices/:id",
    component: "Invoice",
    params: [{
      name: "id",
      prop: "oid",
    }
      ,
    ]

  }
)

addRoute(
  {
    name: "incomingInvoiceCreate",
    path: "/invoices-in/create",
    component: "Invoice",
    componentProps: [{
      name: "newInvoiceKind",
      value: "incoming",
    }
      ,
    ]

  }
)

addRoute(
  {
    name: "outgoingInvoiceCreate",
    path: "/invoices-out/create",
    component: "Invoice",
    componentProps: [{
      name: "newInvoiceKind",
      value: "outgoing",
    }
      ,
    ]

  }
)

@Component()
export default class InvoiceLogMessageViewer extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
  })
  invoiceId!: string;

  @Watch('invoiceId')
  onInvoice_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceId")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_invoice', { parameters: { id: this.invoiceId } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_1040167445267876_options!: any;
  dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_1040167445267876_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          noPrimaryButton: true,
          closeButtonText: this.$fn.gettext("Close"),
          size: "full-screen",
        }
        ,
        b_1040167445267876_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.item ? this.item.invoice_number : '-',
        icon: this.$config.invoice.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2496_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2496, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2496: actions.CloseModalAction;
    evh_7315092382398562_close_2495: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_2498: actions.CloseModalAction;
    evh_2248226175642056_close_2497: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2496 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2496_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2495 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2496,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2495],
      }
    );
    const act_2248226175642056_closeModal_2498 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2497 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_2498,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2497],
      }
    );
    return {
      act_7315092382398562_closeModal_2496,
      evh_7315092382398562_close_2495,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_2498,
      evh_2248226175642056_close_2497,
      evh_2248226175642056_close,
    }
  }
}

Vue.component("InvoiceLogMessageViewer", InvoiceLogMessageViewer);

</script>