<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="PropertyBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="properties"
    :headerTitle="'Locations'|gettext"
    :items="$fn.slickDataSource('list_property')"
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        :icon="$config.property.addPropertyIcon"
        :text="'New location...'|gettext"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/real-property-units/real-property-units.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "properties",
    path: "/properties",
    component: "PropertyBrowser"
  }
)

@Component()
export default class PropertyBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "type",
          name: this.$fn.pgettext("PropertyBrowser", "Type"),
        }
          ,
        {
          field: "name",
          name: this.$fn.pgettext("PropertyBrowser", "Name"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "property",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "description",
          name: this.$fn.pgettext("PropertyBrowser", "Description"),
        }
          ,
        {
          field: "area",
          name: this.$fn.pgettext("PropertyBrowser", "Area"),
        }
          ,
        {
          field: "unit_of_area",
          name: this.$fn.pgettext("PropertyBrowser", "Unit"),
        }
          ,
        {
          field: "location_number",
          name: this.$fn.pgettext("PropertyBrowser", "Location number"),
        }
          ,
        {
          field: "inactive",
          name: this.$fn.gettext("Inactive"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        {
          field: "locked",
          name: this.$fn.gettext("Locked"),
          textAlign: "center",
          formatter: {
            name: "checkbox",
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Locations"),
        icon: this.$config.realPropertyUnits.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3374_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3374, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_3376_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_3376, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_3378_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_3378, alias=undefined
    return {
      name: "CreateProperty",
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3374: actions.CloseModalAction;
    evh_7315092382398562_close_3373: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_3376: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_3375: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_3378: actions.ShowModalAction;
    evh_6466705138206980_click_3377: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3374 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3374_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3373 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3374,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3373],
      }
    );
    const act_2248226175642056_setData_3376 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_3376_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_3375 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_3376,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_3375],
      }
    );
    const act_6466705138206980_showModal_3378 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_3378_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_3377 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_3378,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_3377],
      }
    );
    return {
      act_7315092382398562_closeModal_3374,
      evh_7315092382398562_close_3373,
      evh_7315092382398562_close,
      act_2248226175642056_setData_3376,
      evh_2248226175642056_selectedRowsChanged_3375,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_showModal_3378,
      evh_6466705138206980_click_3377,
      evh_6466705138206980_click,
    }
  }
}

Vue.component("PropertyBrowser", PropertyBrowser);

</script>