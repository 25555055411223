<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Product') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup
      flush
    >
      <ItpLabeledListGroupItem
        :label="'Type'|pgettext('Product')"
        :content="product.product_type | pgettext('ProductTypes', 'Sentence')"
        v-if="product.product_type"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Number'|pgettext('Product')"
        :content="product.number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Name'|pgettext('Product')"
        :content="product.name"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('Product')"
        :content="product.description"
        v-if="product.description"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Unit'|pgettext('Product')"
        v-if="product.unit"
      >
        <ItpText>
          {{ product.unit }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'VAT codes'|pgettext('Product')"
      >
        <DIV>
          <ItpText muted>{{ 'Sales VAT'|pgettext('Product') }}:</ItpText> {{ product.sales_vat_code }}

        </DIV>
        <DIV>
          <ItpText muted>{{ 'Purchase VAT'|pgettext('Product') }}:</ItpText> {{ product.purchase_vat_code }}

        </DIV>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Invoice group'|pgettext('Product')"
        v-if="product.invoice_group_id"
      >
        <UcInvoiceGroup
          :groupId="product.invoice_group_id"
        >
        </UcInvoiceGroup>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Intermediated service'|pgettext('Product')"
        :content="'Yes'|gettext"
        v-if="product.intermediated_service"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Customs tariff no.'|pgettext('Product')"
        :content="product.customs_tariff_no"
        v-if="product.customs_tariff_no"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'TESZOR code (HU)'|pgettext('Product')"
        :content="product.hu_teszor_code"
        v-if="product.hu_teszor_code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'EWC code (EU)'|pgettext('Product')"
        :content="product.eu_ewc_code"
        v-if="product.eu_ewc_code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'External key'|pgettext('Product')"
        :content="product.external_key"
        v-if="product.external_key"
      >
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcProductGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  product!: object;

  @Watch('product')
  onProduct(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("product")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_5796_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_5796, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_5795_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_5795, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_5794_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_5794, alias=undefined
    return {
      name: "ProductEditor",
      props: {
        target: "general",
        product: _.cloneDeep(this.product),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5792: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5791: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_5796: actions.EmitAction;
    evh_8320016629450276_close_5795: actions.EventHandlerImpl;
    act_8320016629450276_showModal_5794: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_5793: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5792 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5791 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5792,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5791],
      }
    );
    const act_8320016629450276_emit_5796 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_5796_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_5795 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_5796,
        event: "close",
        when: this.evh_8320016629450276_close_5795_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_5794 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_5794_getActionArgs,
        events: [evh_8320016629450276_close_5795],
      }
    );
    const evh_8320016629450276_clickPreventStop_5793 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_5794,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_5793],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5792,
      evh_7315092382398562_reload_5791,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_5796,
      evh_8320016629450276_close_5795,
      act_8320016629450276_showModal_5794,
      evh_8320016629450276_clickPreventStop_5793,
      evh_8320016629450276_clickPreventStop,
    }
  }
}

Vue.component("UcProductGeneral", UcProductGeneral);

</script>