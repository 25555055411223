"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let WContactModal = class WContactModal extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['resource', 'editor', 'createTitle', 'editTitle', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onParent(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("parent");
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_resource() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item || this.$fn.schemaDefaults('Create' + _.upperFirst(this.parent.object) + 'ContactCommand');
        });
    }
    $$load_editor() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.resource.editor();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            resource: null,
            editor: null,
            createTitle: this.$fn.pgettext("Contactes", "Add contact"),
            editTitle: this.$fn.pgettext("Contactes", "Edit contact"),
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.item == null ? this.createTitle : this.editTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2032_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeComponent_2038_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_closeComponent_2040_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {},
            };
        });
    }
    act_1040167445267876_rest_2036_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.parent.contacts,
                resource: this.editor,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2032 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2032_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2031 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2032,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2031],
        });
        const act_2248226175642056_closeComponent_2034 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2033 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2034,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2033],
        });
        const act_1040167445267876_closeComponent_2038 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_2038_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_2037 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_2038,
            event: "saved",
            displayName: "closeComponent",
        });
        const act_1040167445267876_closeComponent_2040 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_2040_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_deleted_2039 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_2040,
            event: "deleted",
            displayName: "closeComponent",
        });
        const act_1040167445267876_rest_2036 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_2036_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_saved_2037, evh_1040167445267876_deleted_2039],
        });
        const evh_1040167445267876_submit_2035 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_2036,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2035],
        });
        const act_1419464017721962_closeComponent_2042 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_2041 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_2042,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_2041],
        });
        return {
            act_7315092382398562_closeModal_2032,
            evh_7315092382398562_close_2031,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2034,
            evh_2248226175642056_close_2033,
            evh_2248226175642056_close,
            act_1040167445267876_closeComponent_2038,
            evh_1040167445267876_saved_2037,
            act_1040167445267876_closeComponent_2040,
            evh_1040167445267876_deleted_2039,
            act_1040167445267876_rest_2036,
            evh_1040167445267876_submit_2035,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_2042,
            evh_1419464017721962_clickPreventStop_2041,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_rest_2036,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], WContactModal.prototype, "parent", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('parent'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WContactModal.prototype, "onParent", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], WContactModal.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WContactModal.prototype, "onItem", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], WContactModal.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WContactModal.prototype, "onSize", null);
WContactModal = __decorate([
    (0, vue_property_decorator_1.Component)()
], WContactModal);
exports.default = WContactModal;
vue_property_decorator_1.Vue.component("WContactModal", WContactModal);
