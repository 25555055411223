"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcMeterCharts = class UcMeterCharts extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['stats', 'ux', 'b_7789867021953041_options', 'b_2070266589801014_options', 'b_4186377094414664_editable', 'b_4186377094414664_columns'];
    }
    beforeCreate() {
    }
    mapResponseData(...args) {
        const data = args[0];
        if (!data.series.values.length) {
            return;
        }
        let columns = data.series.columns, tableData = _.map(data.series.values, p => _.extend({ id: p[1] }, _.zipObject(columns, p)));
        tableData = _.orderBy(tableData, ['time']);
        const times = _.map(tableData, p => p.time), raw = _.map(tableData, p => p.raw), values = _.map(tableData, p => p.value), offsets = _.map(tableData, p => p.offset), diffs = _.map(tableData, p => p.difference);
        return {
            tableData: _.orderBy(tableData, ['time'], ['desc']),
            heatmapData: tableData,
            readings: [times, raw, values],
            consumptions: [times, diffs]
        };
    }
    onMeter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    }
    $$load_stats() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('meters/get_daily_stats', {
                parameters: {
                    serial: this.meter.serial_number,
                    time_from: '2020-01-01'
                }
            }).then(d => this.mapResponseData(d));
        });
    }
    $$load_b_7789867021953041_options() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                title: this.$fn.pgettext("MeterCharts", "Values"),
                id: "meter-readings",
                class: "my-chart",
                width: 500,
                height: 300,
                series: [{
                        label: this.$fn.pgettext("MeterCharts", "Time"),
                    },
                    {
                        label: this.$fn.pgettext('MeterCharts', 'Raw') + ' (' + this.meter.unit + ')',
                        stroke: "blue",
                        fill: "#00437a22",
                    },
                    {
                        label: this.$fn.pgettext('MeterCharts', 'Calculated') + ' (' + this.meter.unit + ')',
                        stroke: "magenta",
                        fill: "#00437a22",
                    },
                ],
            };
        });
    }
    $$load_b_2070266589801014_options() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                title: this.$fn.pgettext("MeterCharts", "Consumption"),
                id: "consumptions",
                class: "my-chart",
                width: 500,
                height: 300,
                series: [{
                        label: this.$fn.pgettext("MeterCharts", "Time"),
                    },
                    {
                        label: this.meter.unit,
                        stroke: "#00437a",
                        fill: "#00437a22",
                        stepped: {
                            align: -1,
                        },
                    },
                ],
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            stats: null,
            ux: null,
            b_7789867021953041_options: null,
            b_2070266589801014_options: null,
            b_4186377094414664_editable: false,
            b_4186377094414664_columns: [{
                    name: "day",
                    header: this.$fn.pgettext("MeterCharts", "Date"),
                    width: "120px",
                    formatter: {
                        name: "template",
                        value: "<span\n\
  class=\"text-nowrap\"\n\
  :class=\"{'text-red-500': $fn.isWeekend(value)}\"\n\
>\n\
  {{ value | date }}\n\
</span>\n\
",
                    },
                },
                {
                    name: "raw",
                    header: this.$fn.pgettext("MeterCharts", "Raw Value"),
                    type: "number",
                    width: "100px",
                    textAlign: "right",
                    cellClass: "text-muted",
                    formatter: {
                        name: "template",
                        value: "<span>{{ value|number }} {{ row.unit }}</span>",
                    },
                },
                {
                    name: "offset",
                    header: this.$fn.pgettext("MeterCharts", "Offset"),
                    type: "number",
                    width: "100px",
                    cellClass: "text-muted",
                },
                {
                    name: "value",
                    header: this.$fn.pgettext("MeterCharts", "Offset Value"),
                    width: "120px",
                    textAlign: "right",
                    cellClass: "font-weight-bolder",
                    formatter: {
                        name: "template",
                        value: "<span>{{ value|number }} {{ row.unit }}</span>",
                    },
                },
                {
                    name: "difference",
                    header: this.$fn.pgettext("MeterCharts", "Consumption"),
                    width: "120px",
                    textAlign: "right",
                    cellClass: "font-weight-bolder",
                    formatter: {
                        name: "template",
                        value: "<span>{{ value|number }} {{ row.unit }}</span>",
                    },
                },
            ],
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6658 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6657 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6658,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6657],
        });
        return {
            act_7315092382398562_reloadComponentData_6658,
            evh_7315092382398562_reload_6657,
            evh_7315092382398562_reload,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcMeterCharts.prototype, "meter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcMeterCharts.prototype, "onMeter", null);
UcMeterCharts = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcMeterCharts);
exports.default = UcMeterCharts;
vue_property_decorator_1.Vue.component("UcMeterCharts", UcMeterCharts);
