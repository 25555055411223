var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CreateProduct",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              dialogName: _vm.resourceTitle,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  attrs: {
                    name: _vm.formName,
                    schema: "CreateProduct",
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c("FieldsetProductGeneral", {
                    attrs: { product: _vm.editor }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c(
                "ItpBox",
                { attrs: { alignItems: "center" } },
                [
                  _c(
                    "ItpFormGroup",
                    { attrs: { labelColsMd: "4", name: "sync" } },
                    [
                      _c("ItpFormCheckbox", {
                        attrs: {
                          name: "sync",
                          label: _vm._f("pgettext")(
                            "IntegroRent sync",
                            "Product"
                          )
                        },
                        model: {
                          value: _vm.editor.sync,
                          callback: function($$v) {
                            _vm.$set(_vm.editor, "sync", $$v)
                          },
                          expression: "editor.sync"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Save", "Button"),
                  type: "submit",
                  form: _vm.forms[_vm.formName],
                  spinning: _vm.actions.create.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.create.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2186679009640457_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }