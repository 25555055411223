var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      !_vm.isEditing
        ? _c(
            "DIV",
            {
              class: { "bg-gray-200": _vm.isMouseOver },
              on: {
                dblclick: function($event) {
                  return _vm.evh_2248226175642056_dblclick($event, {})
                },
                mouseover: function($event) {
                  return _vm.evh_2248226175642056_mouseover($event, {})
                },
                mouseleave: function($event) {
                  return _vm.evh_2248226175642056_mouseleave($event, {})
                }
              }
            },
            [_c("ItpText", { attrs: { text: _vm.textValue, pre: "" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isEditing
        ? _c(
            "DIV",
            [
              _c("ItpFormTextarea", {
                attrs: { name: "notes", pre: "" },
                model: {
                  value: _vm.textValue,
                  callback: function($$v) {
                    _vm.textValue = $$v
                  },
                  expression: "textValue"
                }
              }),
              _vm._v(" "),
              _c(
                "ItpBox",
                { staticClass: "mt-1 mb-2", attrs: { alignItems: "end" } },
                [
                  _c("ItpButton", {
                    staticClass: "ml-auto mr-1",
                    attrs: {
                      text: _vm._f("gettext")("Save"),
                      variant: "primary",
                      spinning: _vm.saving,
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        return _vm.evh_1468474219929533_click($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    attrs: { text: _vm._f("gettext")("Cancel"), size: "sm" },
                    on: {
                      click: function($event) {
                        return _vm.evh_3601807637515735_click($event, {})
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }