<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Property') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Type'|pgettext('Property')"
        :content="property.property_type"
        v-if="property.property_type"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Name'|pgettext('Property')"
        :content="property.name"
        v-if="property.name"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Code'|pgettext('Property')"
        :content="property.code"
        v-if="property.code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('Property')"
        :content="property.description"
        v-if="property.description"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Area'|pgettext('Property')"
        v-if="property.area"
      >
        <ItpText>
          {{ property.area }} {{ property.unit_of_area }}
        </ItpText>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Location'|pgettext('Property')"
        :content="property.location_code"
        v-if="property.location_code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Validity'|pgettext('Property')"
      >
        <UcPropertyValidityCard
          :item="property"
        >
        </UcPropertyValidityCard>
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPropertyGeneralData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  property!: object;

  @Watch('property')
  onProperty(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("property")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_5852_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_5852, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_5851_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_5851, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_5850_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_5850, alias=undefined
    return {
      name: "PropertyEditor",
      props: {
        target: "general",
        property: _.cloneDeep(this.property),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5848: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5847: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_5852: actions.EmitAction;
    evh_8320016629450276_close_5851: actions.EventHandlerImpl;
    act_8320016629450276_showModal_5850: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_5849: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5848 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5847 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5848,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5847],
      }
    );
    const act_8320016629450276_emit_5852 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_5852_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_5851 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_5852,
        event: "close",
        when: this.evh_8320016629450276_close_5851_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_5850 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_5850_getActionArgs,
        events: [evh_8320016629450276_close_5851],
      }
    );
    const evh_8320016629450276_clickPreventStop_5849 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_5850,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_5849],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5848,
      evh_7315092382398562_reload_5847,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_5852,
      evh_8320016629450276_close_5851,
      act_8320016629450276_showModal_5850,
      evh_8320016629450276_clickPreventStop_5849,
      evh_8320016629450276_clickPreventStop,
    }
  }
}

Vue.component("UcPropertyGeneralData", UcPropertyGeneralData);

</script>