<template>
<ItpModal
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ReplaceMeter"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="replaceMeter"
      :id="formId"
      ref="form"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpAlert
        show
      >
        <DIV>
          Az óracsere művelet a következő lépéseket végzi el automatikusan:
        </DIV>
        <ul>
          <li
            v-for="step in workflowStepsInfo.steps"
          >
            {{ step }}
          </li>
        </ul>
      </ItpAlert>
      <HR>
      </HR>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="3"
        name="replacement_time"
        :label="'Replacement time'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpFormDatetime
          type="datetime"
          name="replacement_time"
          v-model="item.replacement_time"
          required
          :readonly="isReadOnly"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <HR>
      </HR>
      <DIV
        class="form-group"
      >
        <ItpText
          strong
          muted
          :text="'Old device'|pgettext('ReplaceMeterModal')"
        >
        </ItpText>
      </DIV>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="format"
        :label="'Meter'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpFormSelect2
          name="old_meter"
          required
          :readonly="isReadOnly"
          v-model="item.old_meter.id"
          :dataSource="b_4963030162390099_dataSource"
          valueField="id"
          textField="serial_number"
          use-option-template-for-selected-option
          optionTemplate="<UcSuggestMeterOption :option=option></UcSuggestMeterOption>"
          @optionSelected="evh_4963030162390099_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="old_reading_value"
        :label="'Reading value'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpBox>
          <ItpFormInput
            type="number"
            class="col-3"
            name="old_reading_value"
            v-model="item.old_meter.reading_value"
            required
            :readonly="isReadOnly"
          >
          </ItpFormInput>
          <ItpFormSelect2
            class="col-4"
            name="old_reading_unit"
            v-model="item.old_meter.unit"
            :options="oldMeterReadingUnitOptions"
            required
            :readonly="isReadOnly"
          >
          </ItpFormSelect2>
        </ItpBox>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="old_reading_image"
        :label="'Photo'|pgettext('ReplaceMeter')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormFile
          v-model="old_reading_image"
          name="old_reading_image"
          :placeholder="'The photo of the gauge if available.'|pgettext('ReplaceMeter')"
          lang="hu"
          @changed="evh_3855552110905499_changed($event, {})"
        >
        </ItpFormFile>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="old_notes"
        :label="'Notes'|pgettext('ReplaceMeterModal')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormTextarea
          name="old_notes"
          v-model="item.old_meter.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <HR>
      </HR>
      <DIV
        class="form-group"
      >
        <ItpText
          strong
          muted
          :text="'New device'|pgettext('ReplaceMeterModal')"
        >
        </ItpText>
      </DIV>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_serial"
        :label="'Device'|pgettext('ReplaceMeterModal')"
      >
        <ItpFormSelect2
          name="new_meter"
          required
          :readonly="isReadOnly"
          v-model="item.new_meter.serial"
          :dataSource="b_4246589045129675_dataSource"
          valueField="serial_number"
          textField="serial_number"
          taggable
          clearable
          optionTemplate="<UcSuggestMeterOption :option=option></UcSuggestMeterOption>"
          @optionSelected="evh_4246589045129675_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_serial"
        :label="'Serial number'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpFormInput
          name="new_serial"
          v-model="item.new_meter.serial"
          required
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_deveui"
        :label="'DevEUI'|pgettext('ReplaceMeterModal')"
      >
        <ItpFormInput
          name="new_deveui"
          v-model="item.new_meter.deveui"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_tag_id"
        :label="'Location Code'|pgettext('ReplaceMeterModal')"
      >
        <ItpFormInput
          name="new_tag_id"
          v-model="item.new_meter.tag_id"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        label-cols-md="3"
        content-cols-md="9"
        name="new_reading_value"
        :label="'Reading value'|pgettext('ReplaceMeterModal')"
        required
      >
        <ItpBox>
          <ItpFormInput
            type="number"
            class="col-3"
            name="new_reading_value"
            v-model="item.new_meter.reading_value"
            required
            :readonly="isReadOnly"
          >
          </ItpFormInput>
          <ItpFormSelect2
            class="col-4"
            name="new_reading_unit"
            v-model="item.new_meter.unit"
            :options="oldMeterReadingUnitOptions"
            required
            :readonly="isReadOnly"
          >
          </ItpFormSelect2>
        </ItpBox>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="new_reading_image"
        :label="'Photo'|pgettext('ReplaceMeter')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormFile
          v-model="new_reading_image"
          name="new_reading_image"
          :placeholder="'The photo of the gauge if available.'|pgettext('ReplaceMeter')"
          lang="hu"
        >
        </ItpFormFile>
      </ItpFormGroup>
      <ItpFormGroup
        :labelAlign="labelAlign"
        name="new_notes"
        :label="'Notes'|pgettext('ReplaceMeterModal')"
        label-cols-md="3"
        content-cols-md="9"
      >
        <ItpFormTextarea
          name="new_notes"
          v-model="item.new_meter.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <HR>
      </HR>
      <DIV
        class="row mb-3"
      >
        <DIV
          class="col-md-9 offset-md-3"
        >
          <ItpButton
            :form="formId"
            :text="'Replace Meter'|pgettext('ReplaceMeterModal')"
            type="submit"
            variant="primary"
            :icon="$config.meterReplacement.icon"
            :spinning="actions.submit.isRunning"
            :disabled="!!(!forms.replaceMeter || !forms.replaceMeter.submittable || actions.submit.isRunning || responseData)"
          >
          </ItpButton>
        </DIV>
      </DIV>
      <ItpAlert
        type="success"
        show
        v-if="responseData"
      >
        <H4
          class="alert-heading"
        >
          Sikeres óracsere
        </H4>
        <ul>
          <li v-for="step in responseData.value.result">
  {{ step.message }}
</li>

        </ul>
        <DIV
          class="text-center"
        >
          <ItpButton
            :text="'Close'|gettext"
            variant="primary"
            :disabled="actions.submit.isRunning"
            @click.prevent.stop="evh_353388685162594_clickPreventStop($event, {})"
          >
          </ItpButton>
        </DIV>
      </ItpAlert>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ReplaceMeter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;
  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('ReplaceMeter')
  }

  responseData!: any;
  workflowStepsInfo!: any;
  units!: any;

  async $$load_units() {
    return this.$fn.fetch('get_meter_type_units')
  }

  selectedOldMeter!: any;
  old_reading_image!: any;
  new_reading_image!: any;
  ux!: any;
  b_4963030162390099_dataSource!: any;
  b_4246589045129675_dataSource!: any;
  dataMembers = ['formId', 'labelAlign', 'item', 'responseData', 'workflowStepsInfo', 'units', 'selectedOldMeter', 'old_reading_image', 'new_reading_image', 'ux', 'b_4963030162390099_dataSource', 'b_4246589045129675_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        labelAlign: "right",
        item: null,
        responseData: null,
        workflowStepsInfo: {
          steps: ["rögzíti a leszerelt mérőóra állását"
            ,
            "inaktiválja a leszerelt órát"
            ,
            "inaktiválja a kapcsolódó bérleménynél beállított mért szolgáltatást"
            ,
            "létrehoz egy új mérőórát a megadott sorozatszámmal"
            ,
            "rögzíti az induló mérőállást"
            ,
            "a kapcsolódó bérleménynél a leszerelt mérőóra helyett beállít egy mért szolgáltatást az új mérőórával"
            ,
          ]
          ,
        }
        ,
        units: null,
        selectedOldMeter: null,
        old_reading_image: null,
        new_reading_image: null,
        ux: null,
        b_4963030162390099_dataSource: {
          name: "suggest_meter",
          parameters: {
            exclude_types: ["pulse"
              ,
            ]
            ,
          }
          ,
        }
        ,
        b_4246589045129675_dataSource: {
          name: "suggest_meter",
          parameters: {
            exclude_types: ["pulse"
              ,
            ]
            ,
          }
          ,
        }
        ,
      },
    }
  }

  get isReadOnly() {
    if (this.loaded) {

      return !!(this.responseData || (this.actions.submit && this.actions.submit.isRunning))
    }
    return null;
  }

  get oldMeterReadingUnitOptions() {
    if (this.loaded) {

      return this.selectedOldMeter ? this.units[this.selectedOldMeter.meter_type] : []
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("ReplaceMeterModal.Title", "Replace Meter"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2960_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2960, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_2966_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_2966, alias=undefined
    return {
      path: "responseData",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_request_2964_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_2964, alias=submit
    return {
      operation: "create_meter_operation",
      data: this.item,
      parameters: {
        op: "replacement",
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_4963030162390099_setData_2968_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4963030162390099_setData_2968, alias=undefined
    return {
      path: "item.old_meter.unit",
      value: $event.data.option.unit,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4963030162390099_setData_2970_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4963030162390099_setData_2970, alias=undefined
    return {
      path: "selectedOldMeter",
      value: $event.data.option,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_4963030162390099_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4963030162390099_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_3855552110905499_setData_2972_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3855552110905499_setData_2972, alias=undefined
    return {
      path: "item.old_meter.image",
      value: this.$fn.imageData($event.value),
      resolve: true,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_3855552110905499_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3855552110905499_changed.executeFromDOM(this, event, scope);
  }

  async act_4246589045129675_setData_2974_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_2974, alias=undefined
    return {
      path: "item.new_meter.serial",
      value: $event.data.option.serial_number,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4246589045129675_setData_2974_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_2974, alias=undefined
    return $event.data.option
  }

  async act_4246589045129675_setData_2976_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_2976, alias=undefined
    return {
      path: "item.new_meter.id",
      value: $event.data.option.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4246589045129675_setData_2976_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_2976, alias=undefined
    return $event.data.option
  }

  async act_4246589045129675_setData_2978_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_2978, alias=undefined
    return {
      path: "item.new_meter.unit",
      value: $event.data.option.unit,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4246589045129675_setData_2978_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4246589045129675_setData_2978, alias=undefined
    return $event.data.option
  }

  async evh_4246589045129675_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4246589045129675_optionSelected.executeFromDOM(this, event, scope);
  }

  async evh_353388685162594_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_353388685162594_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2960: actions.CloseModalAction;
    evh_7315092382398562_close_2959: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2962: actions.CloseComponentAction;
    evh_2248226175642056_close_2961: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_setData_2966: actions.SetDataAction;
    evh_1040167445267876_success_2965: actions.EventHandlerImpl;
    act_1040167445267876_request_2964: actions.RequestAction;
    evh_1040167445267876_submit_2963: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_4963030162390099_setData_2968: actions.SetDataAction;
    evh_4963030162390099_optionSelected_2967: actions.EventHandlerImpl;
    act_4963030162390099_setData_2970: actions.SetDataAction;
    evh_4963030162390099_optionSelected_2969: actions.EventHandlerImpl;
    evh_4963030162390099_optionSelected: actions.EventHandlerGroup;
    act_3855552110905499_setData_2972: actions.SetDataAction;
    evh_3855552110905499_changed_2971: actions.EventHandlerImpl;
    evh_3855552110905499_changed: actions.EventHandlerGroup;
    act_4246589045129675_setData_2974: actions.SetDataAction;
    evh_4246589045129675_optionSelected_2973: actions.EventHandlerImpl;
    act_4246589045129675_setData_2976: actions.SetDataAction;
    evh_4246589045129675_optionSelected_2975: actions.EventHandlerImpl;
    act_4246589045129675_setData_2978: actions.SetDataAction;
    evh_4246589045129675_optionSelected_2977: actions.EventHandlerImpl;
    evh_4246589045129675_optionSelected: actions.EventHandlerGroup;
    act_353388685162594_closeComponent_2980: actions.CloseComponentAction;
    evh_353388685162594_clickPreventStop_2979: actions.EventHandlerImpl;
    evh_353388685162594_clickPreventStop: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_2982: actions.CloseComponentAction;
    evh_1548630417156826_clickPreventStop_2981: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2960 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2960_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2959 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2960,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2959],
      }
    );
    const act_2248226175642056_closeComponent_2962 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2961 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2962,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2961],
      }
    );
    const act_1040167445267876_setData_2966 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_2966_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_2965 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_2966,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_request_2964 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_2964_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_2965],
      }
    );
    const evh_1040167445267876_submit_2963 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_2964,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2963],
      }
    );
    const act_4963030162390099_setData_2968 = new actions.SetDataAction(
      {
        actionArgs: this.act_4963030162390099_setData_2968_getActionArgs,
        events: [],
      }
    );
    const evh_4963030162390099_optionSelected_2967 = new actions.EventHandlerImpl(
      {
        action: act_4963030162390099_setData_2968,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_4963030162390099_setData_2970 = new actions.SetDataAction(
      {
        actionArgs: this.act_4963030162390099_setData_2970_getActionArgs,
        events: [],
      }
    );
    const evh_4963030162390099_optionSelected_2969 = new actions.EventHandlerImpl(
      {
        action: act_4963030162390099_setData_2970,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_4963030162390099_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_4963030162390099_optionSelected_2967, evh_4963030162390099_optionSelected_2969],
      }
    );
    const act_3855552110905499_setData_2972 = new actions.SetDataAction(
      {
        actionArgs: this.act_3855552110905499_setData_2972_getActionArgs,
        events: [],
      }
    );
    const evh_3855552110905499_changed_2971 = new actions.EventHandlerImpl(
      {
        action: act_3855552110905499_setData_2972,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_3855552110905499_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_3855552110905499_changed_2971],
      }
    );
    const act_4246589045129675_setData_2974 = new actions.SetDataAction(
      {
        actionArgs: this.act_4246589045129675_setData_2974_getActionArgs,
        when: this.act_4246589045129675_setData_2974_getWhen,
        events: [],
      }
    );
    const evh_4246589045129675_optionSelected_2973 = new actions.EventHandlerImpl(
      {
        action: act_4246589045129675_setData_2974,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_4246589045129675_setData_2976 = new actions.SetDataAction(
      {
        actionArgs: this.act_4246589045129675_setData_2976_getActionArgs,
        when: this.act_4246589045129675_setData_2976_getWhen,
        events: [],
      }
    );
    const evh_4246589045129675_optionSelected_2975 = new actions.EventHandlerImpl(
      {
        action: act_4246589045129675_setData_2976,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_4246589045129675_setData_2978 = new actions.SetDataAction(
      {
        actionArgs: this.act_4246589045129675_setData_2978_getActionArgs,
        when: this.act_4246589045129675_setData_2978_getWhen,
        events: [],
      }
    );
    const evh_4246589045129675_optionSelected_2977 = new actions.EventHandlerImpl(
      {
        action: act_4246589045129675_setData_2978,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_4246589045129675_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_4246589045129675_optionSelected_2973, evh_4246589045129675_optionSelected_2975, evh_4246589045129675_optionSelected_2977],
      }
    );
    const act_353388685162594_closeComponent_2980 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_353388685162594_clickPreventStop_2979 = new actions.EventHandlerImpl(
      {
        action: act_353388685162594_closeComponent_2980,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_353388685162594_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_353388685162594_clickPreventStop_2979],
      }
    );
    const act_1548630417156826_closeComponent_2982 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_2981 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_2982,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_2981],
      }
    );
    return {
      act_7315092382398562_closeModal_2960,
      evh_7315092382398562_close_2959,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2962,
      evh_2248226175642056_close_2961,
      evh_2248226175642056_close,
      act_1040167445267876_setData_2966,
      evh_1040167445267876_success_2965,
      act_1040167445267876_request_2964,
      evh_1040167445267876_submit_2963,
      evh_1040167445267876_submit,
      act_4963030162390099_setData_2968,
      evh_4963030162390099_optionSelected_2967,
      act_4963030162390099_setData_2970,
      evh_4963030162390099_optionSelected_2969,
      evh_4963030162390099_optionSelected,
      act_3855552110905499_setData_2972,
      evh_3855552110905499_changed_2971,
      evh_3855552110905499_changed,
      act_4246589045129675_setData_2974,
      evh_4246589045129675_optionSelected_2973,
      act_4246589045129675_setData_2976,
      evh_4246589045129675_optionSelected_2975,
      act_4246589045129675_setData_2978,
      evh_4246589045129675_optionSelected_2977,
      evh_4246589045129675_optionSelected,
      act_353388685162594_closeComponent_2980,
      evh_353388685162594_clickPreventStop_2979,
      evh_353388685162594_clickPreventStop,
      act_1548630417156826_closeComponent_2982,
      evh_1548630417156826_clickPreventStop_2981,
      evh_1548630417156826_clickPreventStop,
      submit: act_1040167445267876_request_2964,
    }
  }
}

Vue.component("ReplaceMeter", ReplaceMeter);

</script>