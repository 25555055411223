"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoicePayments = class UcInvoicePayments extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['triggerButtonID', 'ux', 'b_6466705138206980_style'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onReload(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("reload");
    }
    $$load_triggerButtonID() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            triggerButtonID: null,
            ux: null,
            b_6466705138206980_style: {
                "min-width": "150px",
            },
        });
    }
    get items() {
        if (this.loaded) {
            return this.invoice.payments.data;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4592790460410433_showModal_5412_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    oid: $event.scope.item.payment_id,
                },
            };
        });
    }
    evh_4592790460410433_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4592790460410433_click.executeFromDOM(this, event, scope);
        });
    }
    act_322074727583344_resource_5414_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "patch",
                target: this.invoice,
                data: {
                    no_payment_required: $event.data.checked ? 'true' : null,
                },
            };
        });
    }
    evh_322074727583344_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_322074727583344_change.executeFromDOM(this, event, scope);
        });
    }
    act_5807883900791941_resource_5416_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "patch",
                target: this.invoice,
                data: {
                    uncollectible: $event.data.checked ? 'true' : null,
                },
            };
        });
    }
    evh_5807883900791941_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5807883900791941_change.executeFromDOM(this, event, scope);
        });
    }
    act_1984986731969919_emit_5420_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_1984986731969919_close_5419_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_1984986731969919_showModal_5418_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: this.invoice.invoice_kind == 'incoming' ? 'payment' : 'deposit',
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_1984986731969919_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1984986731969919_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_298505142589676_emit_5424_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_298505142589676_close_5423_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_298505142589676_showModal_5422_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: this.invoice.invoice_kind == 'incoming' ? 'debit' : 'credit',
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_298505142589676_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_298505142589676_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5738488038990219_emit_5428_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5738488038990219_close_5427_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_5738488038990219_showModal_5426_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "reconciliation_of_invoices",
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_5738488038990219_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5738488038990219_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7481595298497917_emit_5432_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7481595298497917_close_5431_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.ok;
        });
    }
    act_7481595298497917_showModal_5430_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "bad_debt_journal",
                    invoices: [this.invoice.id],
                },
            };
        });
    }
    evh_7481595298497917_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7481595298497917_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5410 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5409 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5410,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5409],
        });
        const act_4592790460410433_showModal_5412 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4592790460410433_showModal_5412_getActionArgs,
            displayName: "OpenPayment",
            events: [],
        });
        const evh_4592790460410433_click_5411 = new core_1.actions.EventHandlerImpl({
            action: act_4592790460410433_showModal_5412,
            event: "click",
            displayName: "OpenPayment",
        });
        const evh_4592790460410433_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4592790460410433_click_5411],
        });
        const act_322074727583344_resource_5414 = new core_1.actions.ResourceAction({
            actionArgs: this.act_322074727583344_resource_5414_getActionArgs,
            events: [],
        });
        const evh_322074727583344_change_5413 = new core_1.actions.EventHandlerImpl({
            action: act_322074727583344_resource_5414,
            event: "change",
            displayName: "resource",
        });
        const evh_322074727583344_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_322074727583344_change_5413],
        });
        const act_5807883900791941_resource_5416 = new core_1.actions.ResourceAction({
            actionArgs: this.act_5807883900791941_resource_5416_getActionArgs,
            events: [],
        });
        const evh_5807883900791941_change_5415 = new core_1.actions.EventHandlerImpl({
            action: act_5807883900791941_resource_5416,
            event: "change",
            displayName: "resource",
        });
        const evh_5807883900791941_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5807883900791941_change_5415],
        });
        const act_1984986731969919_emit_5420 = new core_1.actions.EmitAction({
            actionArgs: this.act_1984986731969919_emit_5420_getActionArgs,
            events: [],
        });
        const evh_1984986731969919_close_5419 = new core_1.actions.EventHandlerImpl({
            action: act_1984986731969919_emit_5420,
            event: "close",
            when: this.evh_1984986731969919_close_5419_getWhen,
            displayName: "emit",
        });
        const act_1984986731969919_showModal_5418 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1984986731969919_showModal_5418_getActionArgs,
            displayName: "createCashPayment",
            events: [evh_1984986731969919_close_5419],
        });
        const evh_1984986731969919_clickPrevent_5417 = new core_1.actions.EventHandlerImpl({
            action: act_1984986731969919_showModal_5418,
            event: "click.prevent",
            displayName: "createCashPayment",
        });
        const evh_1984986731969919_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1984986731969919_clickPrevent_5417],
        });
        const act_298505142589676_emit_5424 = new core_1.actions.EmitAction({
            actionArgs: this.act_298505142589676_emit_5424_getActionArgs,
            events: [],
        });
        const evh_298505142589676_close_5423 = new core_1.actions.EventHandlerImpl({
            action: act_298505142589676_emit_5424,
            event: "close",
            when: this.evh_298505142589676_close_5423_getWhen,
            displayName: "emit",
        });
        const act_298505142589676_showModal_5422 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_298505142589676_showModal_5422_getActionArgs,
            displayName: "createBankingPayment",
            events: [evh_298505142589676_close_5423],
        });
        const evh_298505142589676_clickPrevent_5421 = new core_1.actions.EventHandlerImpl({
            action: act_298505142589676_showModal_5422,
            event: "click.prevent",
            displayName: "createBankingPayment",
        });
        const evh_298505142589676_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298505142589676_clickPrevent_5421],
        });
        const act_5738488038990219_emit_5428 = new core_1.actions.EmitAction({
            actionArgs: this.act_5738488038990219_emit_5428_getActionArgs,
            events: [],
        });
        const evh_5738488038990219_close_5427 = new core_1.actions.EventHandlerImpl({
            action: act_5738488038990219_emit_5428,
            event: "close",
            when: this.evh_5738488038990219_close_5427_getWhen,
            displayName: "emit",
        });
        const act_5738488038990219_showModal_5426 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5738488038990219_showModal_5426_getActionArgs,
            displayName: "createReconciliation",
            events: [evh_5738488038990219_close_5427],
        });
        const evh_5738488038990219_clickPrevent_5425 = new core_1.actions.EventHandlerImpl({
            action: act_5738488038990219_showModal_5426,
            event: "click.prevent",
            displayName: "createReconciliation",
        });
        const evh_5738488038990219_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5738488038990219_clickPrevent_5425],
        });
        const act_7481595298497917_emit_5432 = new core_1.actions.EmitAction({
            actionArgs: this.act_7481595298497917_emit_5432_getActionArgs,
            events: [],
        });
        const evh_7481595298497917_close_5431 = new core_1.actions.EventHandlerImpl({
            action: act_7481595298497917_emit_5432,
            event: "close",
            when: this.evh_7481595298497917_close_5431_getWhen,
            displayName: "emit",
        });
        const act_7481595298497917_showModal_5430 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7481595298497917_showModal_5430_getActionArgs,
            displayName: "createBadDebt",
            events: [evh_7481595298497917_close_5431],
        });
        const evh_7481595298497917_clickPrevent_5429 = new core_1.actions.EventHandlerImpl({
            action: act_7481595298497917_showModal_5430,
            event: "click.prevent",
            displayName: "createBadDebt",
        });
        const evh_7481595298497917_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7481595298497917_clickPrevent_5429],
        });
        return {
            act_7315092382398562_reloadComponentData_5410,
            evh_7315092382398562_reload_5409,
            evh_7315092382398562_reload,
            act_4592790460410433_showModal_5412,
            evh_4592790460410433_click_5411,
            evh_4592790460410433_click,
            act_322074727583344_resource_5414,
            evh_322074727583344_change_5413,
            evh_322074727583344_change,
            act_5807883900791941_resource_5416,
            evh_5807883900791941_change_5415,
            evh_5807883900791941_change,
            act_1984986731969919_emit_5420,
            evh_1984986731969919_close_5419,
            act_1984986731969919_showModal_5418,
            evh_1984986731969919_clickPrevent_5417,
            evh_1984986731969919_clickPrevent,
            act_298505142589676_emit_5424,
            evh_298505142589676_close_5423,
            act_298505142589676_showModal_5422,
            evh_298505142589676_clickPrevent_5421,
            evh_298505142589676_clickPrevent,
            act_5738488038990219_emit_5428,
            evh_5738488038990219_close_5427,
            act_5738488038990219_showModal_5426,
            evh_5738488038990219_clickPrevent_5425,
            evh_5738488038990219_clickPrevent,
            act_7481595298497917_emit_5432,
            evh_7481595298497917_close_5431,
            act_7481595298497917_showModal_5430,
            evh_7481595298497917_clickPrevent_5429,
            evh_7481595298497917_clickPrevent,
            OpenPayment: act_4592790460410433_showModal_5412,
            createCashPayment: act_1984986731969919_showModal_5418,
            createBankingPayment: act_298505142589676_showModal_5422,
            createReconciliation: act_5738488038990219_showModal_5426,
            createBadDebt: act_7481595298497917_showModal_5430,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoicePayments.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoicePayments.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        default: null,
    }),
    __metadata("design:type", Object)
], UcInvoicePayments.prototype, "reload", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('reload'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoicePayments.prototype, "onReload", null);
UcInvoicePayments = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoicePayments);
exports.default = UcInvoicePayments;
vue_property_decorator_1.Vue.component("UcInvoicePayments", UcInvoicePayments);
