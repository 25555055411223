<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    direction="column"
  >
    <ItpBox
      spacing="mb-2"
    >
      <ItpText
        :text="item.account_number"
      >
      </ItpText>
      <ItpBadge
        :text="'Default'|gettext"
        class="mx-4"
        v-if="item.is_default"
      >
      </ItpBadge>
    </ItpBox>
    <UcValidityPeriod
      :from="item.valid_from"
      :to="item.valid_to"
      class="mb-2"
      v-if="item.valid_from || item.valid_to"
    >
    </UcValidityPeriod>
    <ItpText
      :text="item.description"
      pre
      spacing="mb-2"
      v-if="item.description"
    >
    </ItpText>
    <ItpText
      :title="'Bank'|pgettext('BankAccount')"
      :text="item.bank"
      spacing="mb-2"
      v-if="item.bank"
    >
    </ItpText>
    <ItpText
      :title="'IBAN'|pgettext('BankAccount')"
      :text="item.iban"
      spacing="mb-2"
      v-if="item.iban"
    >
    </ItpText>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcClientBankAccountCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6174: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6173: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6174 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6173 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6174,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6173],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6174,
      evh_7315092382398562_reload_6173,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcClientBankAccountCard", UcClientBankAccountCard);

</script>