<template>
<ItpBox
  alignItems="center"
  v-if="loaded"
>
  <ItpFormSelect
    name="date_type"
    v-model="item.date_type"
    :placeholder="'date filter type'|pgettext('Invoices')"
    :options="b_2248226175642056_options"
    class="mx-2 my-1 w-auto"
  >
  </ItpFormSelect>
  <DateRangeSelector
    name="invoiceFilters"
    :from="item.date_from"
    :to="item.date_to"
    class="mx-2 my-1"
    @changed="evh_5962862420439144_changed($event, {})"
  >
    <ItpText
      :text="'Unpaid'|pgettext('Invoices')"
    >
    </ItpText>
  </DateRangeSelector>
  <ItpFormCheckbox
    name="unpaid"
    inline
    v-model="item.unpaid"
    class="mx-2 my-1"
  >
    <ItpText
      :text="'Unpaid'|pgettext('Invoices')"
    >
    </ItpText>
  </ItpFormCheckbox>
  <ItpButton
    :icon="$config.reloadIcon"
    :text="'Load'|gettext"
    variant="light"
    class="mx-2 my-1"
    @click.prevent.stop="evh_1048678296148443_clickPreventStop($event, {})"
  >
  </ItpButton>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoicesViewFilters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "InvoicesViewFilters");
  }

  item!: any;

  async $$load_item() {
    return {
      date_from: null,
      date_to: null,
      unpaid: false,
      kind: this.kind,
      date_type: "invoice_date",
    }
  }

  ux!: any;
  b_2248226175642056_options!: any;
  dataMembers = ['item', 'ux', 'b_2248226175642056_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: null,
        b_2248226175642056_options: [{
          value: "invoice_date",
          text: this.$fn.pgettext("Invoice", "Invoice date"),
        }
          ,
        {
          value: "fulfillment_date",
          text: this.$fn.pgettext("Invoice", "Fulfillment date"),
        }
          ,
        {
          value: "due_date",
          text: this.$fn.pgettext("Invoice", "Due date"),
        }
          ,
        ]
        ,
      },
    }
  }

  async act_5962862420439144_setData_5476_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5962862420439144_setData_5476, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5962862420439144_setData_5478_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5962862420439144_setData_5478, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5962862420439144_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_changed.executeFromDOM(this, event, scope);
  }

  async act_1048678296148443_emit_5480_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1048678296148443_emit_5480, alias=undefined
    return {
      event: "submit",
      value: { ...this.item, kind: this.kind },
    }
  }

  async evh_1048678296148443_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1048678296148443_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_5962862420439144_setData_5476: actions.SetDataAction;
    evh_5962862420439144_changed_5475: actions.EventHandlerImpl;
    act_5962862420439144_setData_5478: actions.SetDataAction;
    evh_5962862420439144_changed_5477: actions.EventHandlerImpl;
    evh_5962862420439144_changed: actions.EventHandlerGroup;
    act_1048678296148443_emit_5480: actions.EmitAction;
    evh_1048678296148443_clickPreventStop_5479: actions.EventHandlerImpl;
    evh_1048678296148443_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_5962862420439144_setData_5476 = new actions.SetDataAction(
      {
        actionArgs: this.act_5962862420439144_setData_5476_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_changed_5475 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_setData_5476,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_5962862420439144_setData_5478 = new actions.SetDataAction(
      {
        actionArgs: this.act_5962862420439144_setData_5478_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_changed_5477 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_setData_5478,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_5962862420439144_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_changed_5475, evh_5962862420439144_changed_5477],
      }
    );
    const act_1048678296148443_emit_5480 = new actions.EmitAction(
      {
        actionArgs: this.act_1048678296148443_emit_5480_getActionArgs,
        events: [],
      }
    );
    const evh_1048678296148443_clickPreventStop_5479 = new actions.EventHandlerImpl(
      {
        action: act_1048678296148443_emit_5480,
        event: "click.prevent.stop",
        displayName: "emit",
      }
    );
    const evh_1048678296148443_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1048678296148443_clickPreventStop_5479],
      }
    );
    return {
      act_5962862420439144_setData_5476,
      evh_5962862420439144_changed_5475,
      act_5962862420439144_setData_5478,
      evh_5962862420439144_changed_5477,
      evh_5962862420439144_changed,
      act_1048678296148443_emit_5480,
      evh_1048678296148443_clickPreventStop_5479,
      evh_1048678296148443_clickPreventStop,
    }
  }
}

Vue.component("InvoicesViewFilters", InvoicesViewFilters);

</script>