"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "invoiceRegister",
    path: "/invoice-register",
    component: "InvoiceRegister"
});
let InvoiceRegister = class InvoiceRegister extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'statuses', 'selectedStatus', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];
    }
    beforeCreate() {
        this.$$cache_selectedRegisterId = new core_1.ComponentValueCache(this, "selectedRegisterId", "InvoiceRegister.selectedRegisterId");
    }
    getQueryParameters(...args) {
        return {
            sort_mode: 'desc',
            register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
        };
    }
    invoiceTypeBadgeClass(...args) {
        const [row] = args;
        const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
        return `bg-${cc.bg} text-${cc.text}`;
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_registers() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_register')
                .then(data => _.filter(data, d => _.includes(d.register_type, 'incoming_invoice')));
        });
    }
    $$load_selectedRegister() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0];
        });
    }
    $$load_statuses() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name));
        });
    }
    $$load_b_2248226175642056_columns() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    field: "entry_number",
                    name: this.$fn.pgettext("Invoice register", "No."),
                    type: "command",
                    formatter: [{
                            name: "chain",
                            formatters: [{
                                    name: "link",
                                    href: "#",
                                },
                                {
                                    name: "cellclass",
                                    value: (row) => ({
                                        'font-weight-bold': true,
                                        'bg-yellow-200': !row.locked
                                    }),
                                },
                            ],
                        },
                    ],
                },
                {
                    field: "ref_entry_number",
                    name: this.$fn.pgettext("Invoice register", "Ref."),
                    type: "command",
                    formatter: [{
                            name: "chain",
                            formatters: [{
                                    name: "link",
                                    href: "#",
                                },
                            ],
                        },
                    ],
                },
                {
                    field: "invoice_number",
                    name: this.$fn.pgettext("Invoice register", "Invoice number"),
                    formatter: [{
                            name: "chain",
                            formatters: [{
                                    name: "routeLink",
                                    to: "invoice",
                                    navigateOnIconClick: true,
                                    params: {
                                        id: "invoice_id",
                                    },
                                },
                                {
                                    name: "cellclass",
                                    value: (row) => ({
                                        'bg-yellow-200': row.invoice_id != null && row.external_key == null,
                                        'bg-red-200': row.invoice_id == null,
                                        'font-weight-bold': row.invoice_id != null
                                    }),
                                },
                            ],
                        },
                    ],
                },
                {
                    field: "received_date",
                    name: this.$fn.pgettext("Invoice register", "Received date"),
                    type: "date",
                },
                {
                    field: "invoice_type",
                    name: this.$fn.pgettext("Invoice register", "Invoice type"),
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "gettext",
                                context: "InvoiceType",
                                conversion: "StartCase",
                                filterInput: true,
                            },
                            {
                                name: "badge",
                                dot: true,
                                pill: true,
                                badgeClass: this.invoiceTypeBadgeClass,
                            },
                        ],
                    },
                },
                {
                    field: "status",
                    name: this.$fn.pgettext("Invoice register", "Status"),
                },
                {
                    field: "categories",
                    name: this.$fn.pgettext("Invoice register", "Category"),
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("Invoice register", "Vendor"),
                    formatter: {
                        name: "routeLink",
                        to: "client",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "description",
                    name: this.$fn.pgettext("Invoice register", "Subject"),
                },
                {
                    field: "notes",
                    name: this.$fn.pgettext("Invoice register", "Notes"),
                },
                {
                    field: "amount",
                    name: this.$fn.pgettext("Invoice register", "Amount"),
                    type: "number",
                    formatter: {
                        name: "localestring",
                        locale: "hu-HU",
                    },
                },
                {
                    field: "currency",
                    name: this.$fn.pgettext("Invoice register", "Currency"),
                },
                {
                    field: "fulfillment_date",
                    name: this.$fn.pgettext("Invoice register", "Fulfillment date"),
                    type: "date",
                },
                {
                    field: "due_date",
                    name: this.$fn.pgettext("Invoice register", "Due date"),
                    type: "date",
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            registers: null,
            selectedRegisterId: null,
            selectedRegister: null,
            selectedItems: [],
            statuses: null,
            selectedStatus: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Invoice register", "Invoice register"),
                icon: this.$config.invoiceRegister.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2574_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_reloadSlickgrid_2578_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_2248226175642056_reloadSlickgrid_2580_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    evh_2248226175642056_close_2579_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_2248226175642056_showModal_2576_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceRegisterEntry",
                props: {
                    oid: $event.data.data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_2575_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'entry_number';
        });
    }
    act_2248226175642056_reloadSlickgrid_2584_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_2248226175642056_reloadSlickgrid_2586_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    evh_2248226175642056_close_2585_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_2248226175642056_showModal_2582_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceRegisterEntry",
                props: {
                    oid: $event.data.data.ref_entry_id,
                },
            };
        });
    }
    evh_2248226175642056_command_2581_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'ref_entry_number';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_2588_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_reloadSlickgrid_2590_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_6466705138206980_setData_2592_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedRegisterId",
                value: $event.data.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_6466705138206980_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_reloadSlickgrid_2596_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_8320016629450276_reloadSlickgrid_2598_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    evh_8320016629450276_close_2597_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_8320016629450276_showModal_2594_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceRegisterEntry",
                props: {
                    register: this.selectedRegister,
                    companyId: this.selectedRegister.company_id,
                },
            };
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    act_5382094220310827_setData_2600_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedStatus",
                value: $event.scope.status,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_5382094220310827_reloadSlickgrid_2604_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_5382094220310827_request_2602_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "bulk_update_invoice_register_entry",
                data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
            };
        });
    }
    evh_5382094220310827_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5382094220310827_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8704926098283402_setData_2606_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedStatus",
                value: null,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_8704926098283402_reloadSlickgrid_2610_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "invoiceRegister",
            };
        });
    }
    act_8704926098283402_request_2608_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "bulk_update_invoice_register_entry",
                data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
            };
        });
    }
    evh_8704926098283402_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8704926098283402_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2574 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2574_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2573 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2574,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2573],
        });
        const act_2248226175642056_reloadSlickgrid_2578 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2578_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_2248226175642056_saved_2577 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2578,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_reloadSlickgrid_2580 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2580_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_2248226175642056_close_2579 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2580,
            event: "close",
            when: this.evh_2248226175642056_close_2579_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_showModal_2576 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2576_getActionArgs,
            events: [evh_2248226175642056_saved_2577, evh_2248226175642056_close_2579],
        });
        const evh_2248226175642056_command_2575 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2576,
            event: "command",
            when: this.evh_2248226175642056_command_2575_getWhen,
            displayName: "showModal",
        });
        const act_2248226175642056_reloadSlickgrid_2584 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2584_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_2248226175642056_saved_2583 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2584,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_reloadSlickgrid_2586 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_2586_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_2248226175642056_close_2585 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_2586,
            event: "close",
            when: this.evh_2248226175642056_close_2585_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_showModal_2582 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_2582_getActionArgs,
            events: [evh_2248226175642056_saved_2583, evh_2248226175642056_close_2585],
        });
        const evh_2248226175642056_command_2581 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_2582,
            event: "command",
            when: this.evh_2248226175642056_command_2581_getWhen,
            displayName: "showModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_2575, evh_2248226175642056_command_2581],
        });
        const act_2248226175642056_setData_2588 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_2588_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_2587 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_2588,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_2587],
        });
        const act_6466705138206980_reloadSlickgrid_2590 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_6466705138206980_reloadSlickgrid_2590_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_6466705138206980_input_2589 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_reloadSlickgrid_2590,
            event: "input",
            displayName: "reloadSlickgrid",
        });
        const act_6466705138206980_setData_2592 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_2592_getActionArgs,
            displayName: "updateSelectedRegisterId",
            events: [],
        });
        const evh_6466705138206980_input_2591 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_2592,
            event: "input",
            displayName: "updateSelectedRegisterId",
        });
        const evh_6466705138206980_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_input_2589, evh_6466705138206980_input_2591],
        });
        const act_8320016629450276_reloadSlickgrid_2596 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8320016629450276_reloadSlickgrid_2596_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_8320016629450276_saved_2595 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadSlickgrid_2596,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_8320016629450276_reloadSlickgrid_2598 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8320016629450276_reloadSlickgrid_2598_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_8320016629450276_close_2597 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_reloadSlickgrid_2598,
            event: "close",
            when: this.evh_8320016629450276_close_2597_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_8320016629450276_showModal_2594 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_2594_getActionArgs,
            events: [evh_8320016629450276_saved_2595, evh_8320016629450276_close_2597],
        });
        const evh_8320016629450276_click_2593 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_2594,
            event: "click",
            displayName: "showModal",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_2593],
        });
        const act_5382094220310827_setData_2600 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5382094220310827_setData_2600_getActionArgs,
            events: [],
        });
        const evh_5382094220310827_clickPreventStop_2599 = new core_1.actions.EventHandlerImpl({
            action: act_5382094220310827_setData_2600,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const act_5382094220310827_reloadSlickgrid_2604 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_5382094220310827_reloadSlickgrid_2604_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_5382094220310827_success_2603 = new core_1.actions.EventHandlerImpl({
            action: act_5382094220310827_reloadSlickgrid_2604,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_5382094220310827_request_2602 = new core_1.actions.RequestAction({
            actionArgs: this.act_5382094220310827_request_2602_getActionArgs,
            displayName: "setStatus",
            events: [evh_5382094220310827_success_2603],
        });
        const evh_5382094220310827_clickPreventStop_2601 = new core_1.actions.EventHandlerImpl({
            action: act_5382094220310827_request_2602,
            event: "click.prevent.stop",
            displayName: "setStatus",
        });
        const evh_5382094220310827_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5382094220310827_clickPreventStop_2599, evh_5382094220310827_clickPreventStop_2601],
        });
        const act_8704926098283402_setData_2606 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8704926098283402_setData_2606_getActionArgs,
            events: [],
        });
        const evh_8704926098283402_clickPreventStop_2605 = new core_1.actions.EventHandlerImpl({
            action: act_8704926098283402_setData_2606,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const act_8704926098283402_reloadSlickgrid_2610 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8704926098283402_reloadSlickgrid_2610_getActionArgs,
            displayName: "reloadSlickgrid",
            events: [],
        });
        const evh_8704926098283402_success_2609 = new core_1.actions.EventHandlerImpl({
            action: act_8704926098283402_reloadSlickgrid_2610,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_8704926098283402_request_2608 = new core_1.actions.RequestAction({
            actionArgs: this.act_8704926098283402_request_2608_getActionArgs,
            displayName: "setStatus",
            events: [evh_8704926098283402_success_2609],
        });
        const evh_8704926098283402_clickPreventStop_2607 = new core_1.actions.EventHandlerImpl({
            action: act_8704926098283402_request_2608,
            event: "click.prevent.stop",
            displayName: "setStatus",
        });
        const evh_8704926098283402_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8704926098283402_clickPreventStop_2605, evh_8704926098283402_clickPreventStop_2607],
        });
        return {
            act_7315092382398562_closeModal_2574,
            evh_7315092382398562_close_2573,
            evh_7315092382398562_close,
            act_2248226175642056_reloadSlickgrid_2578,
            evh_2248226175642056_saved_2577,
            act_2248226175642056_reloadSlickgrid_2580,
            evh_2248226175642056_close_2579,
            act_2248226175642056_showModal_2576,
            evh_2248226175642056_command_2575,
            act_2248226175642056_reloadSlickgrid_2584,
            evh_2248226175642056_saved_2583,
            act_2248226175642056_reloadSlickgrid_2586,
            evh_2248226175642056_close_2585,
            act_2248226175642056_showModal_2582,
            evh_2248226175642056_command_2581,
            evh_2248226175642056_command,
            act_2248226175642056_setData_2588,
            evh_2248226175642056_selectedRowsChanged_2587,
            evh_2248226175642056_selectedRowsChanged,
            act_6466705138206980_reloadSlickgrid_2590,
            evh_6466705138206980_input_2589,
            act_6466705138206980_setData_2592,
            evh_6466705138206980_input_2591,
            evh_6466705138206980_input,
            act_8320016629450276_reloadSlickgrid_2596,
            evh_8320016629450276_saved_2595,
            act_8320016629450276_reloadSlickgrid_2598,
            evh_8320016629450276_close_2597,
            act_8320016629450276_showModal_2594,
            evh_8320016629450276_click_2593,
            evh_8320016629450276_click,
            act_5382094220310827_setData_2600,
            evh_5382094220310827_clickPreventStop_2599,
            act_5382094220310827_reloadSlickgrid_2604,
            evh_5382094220310827_success_2603,
            act_5382094220310827_request_2602,
            evh_5382094220310827_clickPreventStop_2601,
            evh_5382094220310827_clickPreventStop,
            act_8704926098283402_setData_2606,
            evh_8704926098283402_clickPreventStop_2605,
            act_8704926098283402_reloadSlickgrid_2610,
            evh_8704926098283402_success_2609,
            act_8704926098283402_request_2608,
            evh_8704926098283402_clickPreventStop_2607,
            evh_8704926098283402_clickPreventStop,
            reloadSlickgrid: act_8704926098283402_reloadSlickgrid_2610,
            updateSelectedRegisterId: act_6466705138206980_setData_2592,
            setStatus: act_8704926098283402_request_2608,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], InvoiceRegister.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceRegister.prototype, "onSize", null);
InvoiceRegister = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceRegister);
exports.default = InvoiceRegister;
vue_property_decorator_1.Vue.component("InvoiceRegister", InvoiceRegister);
