<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="HandoverProtocol"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcContractProtocolHeader
      :protocol="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcContractProtocolHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      :name="formName"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetHandoverProtocol
        :item="item"
        :form="forms[formName]"
        @reload="evh_5534025912102772_reload($event, {})"
        @close="evh_5534025912102772_close($event, {})"
        @changed="evh_5534025912102772_changed($event, {})"
      >
      </FieldsetHandoverProtocol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      variant="warning"
      icon="fa-times"
      :text="'Cancel protocol'|pgettext('HandoverProtocol')"
      :spinning="actions.cancel.isRunning"
      v-if="loaded && !item.isNew && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :form="formId"
      variant="success"
      icon="fa-save"
      :text="'Complete'|gettext"
      :spinning="actions.complete.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      class="ml-auto"
      v-if="loaded && !item.isNew && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :text="'Save'|gettext"
      :spinning="actions.save.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      v-if="loaded && item.protocol_status === 'draft'"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="default"
      :text="'Close'|gettext"
      :disabled="actions.save.isRunning"
      class="ml-3"
      @click.prevent.stop="evh_5566617329548203_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class HandoverProtocol extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
  })
  contractId!: string;

  @Watch('contractId')
  onContract_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contractId")
  }

  @Prop({
    type: String,
  })
  revisionId!: string;

  @Watch('revisionId')
  onRevision_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("revisionId")
  }

  @Prop({
    type: String,
  })
  handoverType!: string;

  @Watch('handoverType')
  onHandover_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("handoverType")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.oid ?
      this.$fn.fetch('read_contract_protocol', {
        asResource: true,
        parameters: { id: this.oid }
      }) :
      this.$fn.fetch('get_contract_protocol_template', {
        asResource: true,
        parameters: {
          contract_id: this.contractId,
          revision_id: this.revisionId,
          handover_type: this.handoverType,
        }
      })
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  handoverTypes!: any;

  async $$load_handoverTypes() {
    return this.$fn.getEnumValuesFromSchema('HandoverType')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['item', 'formName', 'formId', 'labelAlign', 'handoverTypes', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formName: "handoverProtocol",
        formId: null,
        labelAlign: "left",
        handoverTypes: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.$fn.pgettext("Contract", "Handover protocol")
          ,
        this.$fn.pgettext("HandoverType", this.item.handover_type, "Sentence")
          ,
        ]
        ,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6444_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6444, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_closeModal_6450_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_8564662037462133_closeModal_6450, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_script_6454_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1040167445267876_script_6454, alias=undefined
    this.item.reset($event.data)
  }

  async act_1040167445267876_script_6454_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1040167445267876_script_6454, alias=undefined
    return {
      value: () => this.act_1040167445267876_script_6454_getActionArgs_value($event),
    }
  }

  async evh_1040167445267876_success_6453_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_success_6453, alias=undefined
    return $event.data
  }

  async act_1040167445267876_crud_6452_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_6452, alias=save
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
  }

  async evh_5534025912102772_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_emit_6460_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5534025912102772_emit_6460, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async evh_5534025912102772_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeComponent_6464_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1548630417156826_closeComponent_6464, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1548630417156826_crud_6462_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1548630417156826_crud_6462, alias=cancel
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: { ...this.item, protocol_status: 'cancelled' },
    }
  }

  async act_1548630417156826_crud_6462_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1548630417156826_crud_6462, alias=cancel
    return {
      header: "Jegyőkönyv érvénytelenítése",
      message: "A jegyzőkönyv érvénytelenítés után már nem módosítható.",
      btnOk: "Érvénytelenítés",
      severity: "warning",
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeComponent_6468_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1419464017721962_closeComponent_6468, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1419464017721962_crud_6466_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1419464017721962_crud_6466, alias=complete
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: { ...this.item, protocol_status: 'completed' },
    }
  }

  async act_1419464017721962_crud_6466_getConfirm($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1419464017721962_crud_6466, alias=complete
    return {
      header: "Jegyőkönyv véglegesítése",
      message: "A jegyzőkönyv véglegesítés után már nem módosítható.",
      btnOk: "Véglegesítés",
      severity: "warning",
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_script_6472_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2186679009640457_script_6472, alias=undefined
    this.item.reset($event.data)
  }

  async act_2186679009640457_script_6472_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2186679009640457_script_6472, alias=undefined
    return {
      value: () => this.act_2186679009640457_script_6472_getActionArgs_value($event),
    }
  }

  async evh_2186679009640457_success_6471_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2186679009640457_success_6471, alias=undefined
    return $event.data
  }

  async act_2186679009640457_crud_6470_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_2186679009640457_crud_6470, alias=save
    return {
      objectType: "contract_protocol",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5566617329548203_closeComponent_6474_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_5566617329548203_closeComponent_6474, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_5566617329548203_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6444: actions.CloseModalAction;
    evh_7315092382398562_close_6443: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_6446: actions.CloseModalAction;
    evh_2248226175642056_close_6445: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_6448: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_6447: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_6450: actions.CloseModalAction;
    evh_8564662037462133_deleted_6449: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_script_6454: actions.ScriptAction;
    evh_1040167445267876_success_6453: actions.EventHandlerImpl;
    act_1040167445267876_crud_6452: actions.CRUDAction;
    evh_1040167445267876_submit_6451: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5534025912102772_reloadComponentData_6456: actions.ReloadComponentDataAction;
    evh_5534025912102772_reload_6455: actions.EventHandlerImpl;
    evh_5534025912102772_reload: actions.EventHandlerGroup;
    act_5534025912102772_closeComponent_6458: actions.CloseComponentAction;
    evh_5534025912102772_close_6457: actions.EventHandlerImpl;
    evh_5534025912102772_close: actions.EventHandlerGroup;
    act_5534025912102772_emit_6460: actions.EmitAction;
    evh_5534025912102772_changed_6459: actions.EventHandlerImpl;
    evh_5534025912102772_changed: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_6464: actions.CloseComponentAction;
    evh_1548630417156826_success_6463: actions.EventHandlerImpl;
    act_1548630417156826_crud_6462: actions.CRUDAction;
    evh_1548630417156826_clickPreventStop_6461: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_6468: actions.CloseComponentAction;
    evh_1419464017721962_success_6467: actions.EventHandlerImpl;
    act_1419464017721962_crud_6466: actions.CRUDAction;
    evh_1419464017721962_clickPreventStop_6465: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
    act_2186679009640457_script_6472: actions.ScriptAction;
    evh_2186679009640457_success_6471: actions.EventHandlerImpl;
    act_2186679009640457_crud_6470: actions.CRUDAction;
    evh_2186679009640457_clickPreventStop_6469: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
    act_5566617329548203_closeComponent_6474: actions.CloseComponentAction;
    evh_5566617329548203_clickPreventStop_6473: actions.EventHandlerImpl;
    evh_5566617329548203_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6444 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6444_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6443 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6444,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6443],
      }
    );
    const act_2248226175642056_closeModal_6446 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6445 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_6446,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6445],
      }
    );
    const act_8564662037462133_reloadComponentData_6448 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_6447 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_6448,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_6447],
      }
    );
    const act_8564662037462133_closeModal_6450 = new actions.CloseModalAction(
      {
        actionArgs: this.act_8564662037462133_closeModal_6450_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_6449 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_6450,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_6449],
      }
    );
    const act_1040167445267876_script_6454 = new actions.ScriptAction(
      {
        actionArgs: this.act_1040167445267876_script_6454_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6453 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_script_6454,
        event: "success",
        when: this.evh_1040167445267876_success_6453_getWhen,
        displayName: "script",
      }
    );
    const act_1040167445267876_crud_6452 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_6452_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_6453],
      }
    );
    const evh_1040167445267876_submit_6451 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_6452,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6451],
      }
    );
    const act_5534025912102772_reloadComponentData_6456 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_reload_6455 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_reloadComponentData_6456,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5534025912102772_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_reload_6455],
      }
    );
    const act_5534025912102772_closeComponent_6458 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_5534025912102772_close_6457 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_closeComponent_6458,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_5534025912102772_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_close_6457],
      }
    );
    const act_5534025912102772_emit_6460 = new actions.EmitAction(
      {
        actionArgs: this.act_5534025912102772_emit_6460_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_changed_6459 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_emit_6460,
        event: "changed",
        displayName: "emit",
      }
    );
    const evh_5534025912102772_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_changed_6459],
      }
    );
    const act_1548630417156826_closeComponent_6464 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1548630417156826_closeComponent_6464_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6463 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_6464,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1548630417156826_crud_6462 = new actions.CRUDAction(
      {
        actionArgs: this.act_1548630417156826_crud_6462_getActionArgs,
        confirm: this.act_1548630417156826_crud_6462_getConfirm,
        displayName: "cancel",
        events: [evh_1548630417156826_success_6463],
      }
    );
    const evh_1548630417156826_clickPreventStop_6461 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_crud_6462,
        event: "click.prevent.stop",
        displayName: "cancel",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6461],
      }
    );
    const act_1419464017721962_closeComponent_6468 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1419464017721962_closeComponent_6468_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_6467 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_6468,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1419464017721962_crud_6466 = new actions.CRUDAction(
      {
        actionArgs: this.act_1419464017721962_crud_6466_getActionArgs,
        confirm: this.act_1419464017721962_crud_6466_getConfirm,
        displayName: "complete",
        events: [evh_1419464017721962_success_6467],
      }
    );
    const evh_1419464017721962_clickPreventStop_6465 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_crud_6466,
        event: "click.prevent.stop",
        displayName: "complete",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6465],
      }
    );
    const act_2186679009640457_script_6472 = new actions.ScriptAction(
      {
        actionArgs: this.act_2186679009640457_script_6472_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_success_6471 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_script_6472,
        event: "success",
        when: this.evh_2186679009640457_success_6471_getWhen,
        displayName: "script",
      }
    );
    const act_2186679009640457_crud_6470 = new actions.CRUDAction(
      {
        actionArgs: this.act_2186679009640457_crud_6470_getActionArgs,
        displayName: "save",
        events: [evh_2186679009640457_success_6471],
      }
    );
    const evh_2186679009640457_clickPreventStop_6469 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_crud_6470,
        event: "click.prevent.stop",
        displayName: "save",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_6469],
      }
    );
    const act_5566617329548203_closeComponent_6474 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_5566617329548203_closeComponent_6474_getActionArgs,
        events: [],
      }
    );
    const evh_5566617329548203_clickPreventStop_6473 = new actions.EventHandlerImpl(
      {
        action: act_5566617329548203_closeComponent_6474,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_5566617329548203_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5566617329548203_clickPreventStop_6473],
      }
    );
    return {
      act_7315092382398562_closeModal_6444,
      evh_7315092382398562_close_6443,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_6446,
      evh_2248226175642056_close_6445,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_6448,
      evh_8564662037462133_reload_6447,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_6450,
      evh_8564662037462133_deleted_6449,
      evh_8564662037462133_deleted,
      act_1040167445267876_script_6454,
      evh_1040167445267876_success_6453,
      act_1040167445267876_crud_6452,
      evh_1040167445267876_submit_6451,
      evh_1040167445267876_submit,
      act_5534025912102772_reloadComponentData_6456,
      evh_5534025912102772_reload_6455,
      evh_5534025912102772_reload,
      act_5534025912102772_closeComponent_6458,
      evh_5534025912102772_close_6457,
      evh_5534025912102772_close,
      act_5534025912102772_emit_6460,
      evh_5534025912102772_changed_6459,
      evh_5534025912102772_changed,
      act_1548630417156826_closeComponent_6464,
      evh_1548630417156826_success_6463,
      act_1548630417156826_crud_6462,
      evh_1548630417156826_clickPreventStop_6461,
      evh_1548630417156826_clickPreventStop,
      act_1419464017721962_closeComponent_6468,
      evh_1419464017721962_success_6467,
      act_1419464017721962_crud_6466,
      evh_1419464017721962_clickPreventStop_6465,
      evh_1419464017721962_clickPreventStop,
      act_2186679009640457_script_6472,
      evh_2186679009640457_success_6471,
      act_2186679009640457_crud_6470,
      evh_2186679009640457_clickPreventStop_6469,
      evh_2186679009640457_clickPreventStop,
      act_5566617329548203_closeComponent_6474,
      evh_5566617329548203_clickPreventStop_6473,
      evh_5566617329548203_clickPreventStop,
      save: act_2186679009640457_crud_6470,
      cancel: act_1548630417156826_crud_6462,
      complete: act_1419464017721962_crud_6466,
    }
  }
}

Vue.component("HandoverProtocol", HandoverProtocol);

</script>