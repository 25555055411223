var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    [
      _c("template", { slot: "button-content" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("Reports", "MainMenu")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _vm.dashboards && _vm.dashboards.error && _vm.dashboards.error.message
        ? _c(
            "ItpNavItem",
            [
              _c("ItpText", {
                attrs: {
                  wrap: "",
                  title: _vm.dashboards.error.message,
                  text: _vm.dashboards.error.message
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.dashboards, function(item) {
        return _vm._.isArray(_vm.dashboards)
          ? _c("ItpNavItem", {
              key: item.id,
              attrs: {
                href: "#",
                text: item.dashboard_title,
                spinning: item.loading,
                "dropdown-item": ""
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.evh_8358196963355853_clickPrevent($event, {
                    item: item
                  })
                }
              }
            })
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }