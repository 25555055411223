var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Tasks",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _c("H3", { staticClass: "m-3" }, [
        _vm._v("\n    " + _vm._s(_vm._f("gettext")("Tasks")) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "DIV",
        { staticClass: "h-100 overflow-auto px-3" },
        [
          _c(
            "DIV",
            { staticClass: "card my-3" },
            [
              _c(
                "DIV",
                { staticClass: "card-body" },
                [
                  _c("H5", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("gettext")("Expired contracts")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("P", { staticClass: "card-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("gettext")(
                            "This task searches for contracts expired within X days and creates notifications if founds any."
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("JobHandlerControl", {
                    attrs: {
                      jobType: "contracts:check_expiring_contracts",
                      "load-current": "",
                      payload: function() {
                        return {}
                      },
                      text: _vm._f("pgettext")(
                        "Check expiring contracts",
                        "Tasks|Command"
                      ),
                      icon: "mdi:cog"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "card my-3" },
            [
              _c(
                "DIV",
                { staticClass: "card-body" },
                [
                  _c("H5", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("gettext")("Inflation indexed fees")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("P", { staticClass: "card-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("gettext")(
                            "This task searches for fees subject to inflation indexed price change and creates notifications if founds any."
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("JobHandlerControl", {
                    attrs: {
                      jobType: "contracts:inflation_indexing",
                      "load-current": "",
                      payload: function() {
                        return {}
                      },
                      text: _vm._f("pgettext")(
                        "Check inflation indexed fees.",
                        "Tasks|Command"
                      ),
                      icon: "mdi:cog"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "DIV",
            { staticClass: "card my-3" },
            [
              _c(
                "DIV",
                { staticClass: "card-body" },
                [
                  _c("H5", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("gettext")("NAV online invoice interface")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("P", { staticClass: "card-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("pgettext")(
                            "Import invoces from NAV online invoices service.",
                            "Tasks"
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    [
                      _c("ItpButton", {
                        attrs: {
                          icon: "mdi:cog",
                          text: _vm._f("pgettext")("Configure", "Tasks|Command")
                        },
                        on: {
                          click: function($event) {
                            return _vm.evh_2359849487788347_click($event, {})
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("JobHandlerControl", {
                        staticClass: "ml-2",
                        attrs: {
                          jobType: "invoices:nav:pull-invoices",
                          "load-current": "",
                          payload: function() {
                            return {}
                          },
                          text: _vm._f("pgettext")(
                            "Pull new invoices from NAV services.",
                            "Tasks|Command"
                          ),
                          icon: _vm.$config.pullDataIcon
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }