<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AccountStatementReport"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    stretch
    v-if="loaded"
  >
    <ItpBox
      direction="column"
      class="h-100 w-100"
    >
      <ItpForm
        :id="formId"
        name="payment"
        class="pb-3"
        ref="mainForm"
      >
        <ItpBox>
          <DIV
            class="mr-2"
            :style="b_5731695935601903_style"
          >
            <ItpFormSelect2
              name="accounts"
              v-model="item.account"
              bind-object
              textField="name,currency,account_number"
              :options="accounts"
              use-option-template-for-selected-option
              optionTemplate="<UcAccountSelectOption :payload=option></UcAccountSelectOption>"
            >
            </ItpFormSelect2>
          </DIV>
          <DateRangeSelector
            name="paymentFilters"
            :from="item.date_from"
            :to="item.date_to"
            class="mr-2"
            @changed="evh_1747314907724257_changed($event, {})"
          >
          </DateRangeSelector>
          <DIV>
            <ItpButton
              :text="'Display...'|pgettext('Button')"
              size="sm"
              icon="ri:barcode-box-line"
              :disabled="connector.loading"
              :spinning="connector.loading"
              @click="evh_2421697723438155_click($event, {})"
            >
            </ItpButton>
          </DIV>
        </ItpBox>
      </ItpForm>
      <ItpPdfViewer
        :file="file && file.file"
        class="flex-fill"
      >
      </ItpPdfViewer>
    </ItpBox>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class AccountStatementReport extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "AccountStatementReport.item");
    this.$$cache_responseType = new ComponentValueCache(this, "responseType", "AccountStatementReport.responseType");
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Array,
    default: null,
  })
  invoices!: array;

  @Watch('invoices')
  onInvoices(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoices")
  }

  @Prop({
    type: String,
    default: null,
  })
  transactionType!: string;

  @Watch('transactionType')
  onTransaction_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("transactionType")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']))
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  connector!: any;

  async $$load_connector() {
    return this.$fn.resourceProxy({
      url: () => this.$fn.urlFor('report_account_statement', { ...this.item, account: this.item.account.id }),
      useAuth: true,
    }).on('file', args => this.setData('file', args))
  }

  item!: any;

  async $$load_item() {
    return {
      date_from: null,
      date_to: null,
      account: this.accounts ? this.accounts[0] : null,
    }
  }

  file!: any;
  responseType!: any;
  responseTypes!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5731695935601903_style!: any;
  dataMembers = ['accounts', 'formId', 'connector', 'item', 'file', 'responseType', 'responseTypes', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        accounts: null,
        formId: null,
        connector: null,
        item: null,
        file: null,
        responseType: "json",
        responseTypes: ["json"
          ,
          "pdf"
          ,
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "full-screen",
        }
        ,
        b_5731695935601903_style: {
          width: "300px",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("AccountStatementReport/title", "Account Statement Report"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3114_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3114, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1747314907724257_setData_3118_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1747314907724257_setData_3118, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1747314907724257_setData_3120_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1747314907724257_setData_3120, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1747314907724257_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1747314907724257_changed.executeFromDOM(this, event, scope);
  }

  async act_2421697723438155_script_3122_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2421697723438155_script_3122, alias=undefined
    this.connector.load()
  }

  async act_2421697723438155_script_3122_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2421697723438155_script_3122, alias=undefined
    return {
      value: () => this.act_2421697723438155_script_3122_getActionArgs_value($event),
    }
  }

  async evh_2421697723438155_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2421697723438155_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3114: actions.CloseModalAction;
    evh_7315092382398562_close_3113: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_3116: actions.CloseModalAction;
    evh_2248226175642056_close_3115: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1747314907724257_setData_3118: actions.SetDataAction;
    evh_1747314907724257_changed_3117: actions.EventHandlerImpl;
    act_1747314907724257_setData_3120: actions.SetDataAction;
    evh_1747314907724257_changed_3119: actions.EventHandlerImpl;
    evh_1747314907724257_changed: actions.EventHandlerGroup;
    act_2421697723438155_script_3122: actions.ScriptAction;
    evh_2421697723438155_click_3121: actions.EventHandlerImpl;
    evh_2421697723438155_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3114 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3114_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3113 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3114,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3113],
      }
    );
    const act_2248226175642056_closeModal_3116 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3115 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_3116,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3115],
      }
    );
    const act_1747314907724257_setData_3118 = new actions.SetDataAction(
      {
        actionArgs: this.act_1747314907724257_setData_3118_getActionArgs,
        events: [],
      }
    );
    const evh_1747314907724257_changed_3117 = new actions.EventHandlerImpl(
      {
        action: act_1747314907724257_setData_3118,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_1747314907724257_setData_3120 = new actions.SetDataAction(
      {
        actionArgs: this.act_1747314907724257_setData_3120_getActionArgs,
        events: [],
      }
    );
    const evh_1747314907724257_changed_3119 = new actions.EventHandlerImpl(
      {
        action: act_1747314907724257_setData_3120,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_1747314907724257_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_1747314907724257_changed_3117, evh_1747314907724257_changed_3119],
      }
    );
    const act_2421697723438155_script_3122 = new actions.ScriptAction(
      {
        actionArgs: this.act_2421697723438155_script_3122_getActionArgs,
        events: [],
      }
    );
    const evh_2421697723438155_click_3121 = new actions.EventHandlerImpl(
      {
        action: act_2421697723438155_script_3122,
        event: "click",
        displayName: "script",
      }
    );
    const evh_2421697723438155_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2421697723438155_click_3121],
      }
    );
    return {
      act_7315092382398562_closeModal_3114,
      evh_7315092382398562_close_3113,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_3116,
      evh_2248226175642056_close_3115,
      evh_2248226175642056_close,
      act_1747314907724257_setData_3118,
      evh_1747314907724257_changed_3117,
      act_1747314907724257_setData_3120,
      evh_1747314907724257_changed_3119,
      evh_1747314907724257_changed,
      act_2421697723438155_script_3122,
      evh_2421697723438155_click_3121,
      evh_2421697723438155_click,
    }
  }
}

Vue.component("AccountStatementReport", AccountStatementReport);

</script>