<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ImportInvoices"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpAlert
      variant="info"
      show
    >
      <ItpIcon
        fa="info-circle"
      >
      </ItpIcon>
      <ItpText
        wrap
      >
        A funkció külső állományokból importál számlákat.

      </ItpText>
    </ItpAlert>
    <hr>
    </hr>
    <DIV
      class="container"
    >
      <ItpForm
        :name="formName"
        :id="formId"
        v-if="loaded"
        @submit="evh_648747096704084_submit($event, {})"
      >
        <ItpFormGroup
          name="format"
          :label="'File format'|pgettext('ImportInvoices')"
          labelFor="format"
          required
        >
          <ItpFormSelect
            v-model="format"
            name="format"
            required
            :options="formats"
          >
          </ItpFormSelect>
        </ItpFormGroup>
        <ItpFormGroup
          name="file"
          :label="'Export file'|pgettext('ImportInvoices')"
          labelFor="file"
          :description="'The file can be obtained from the respective providers\'s application.'|pgettext('ImportInvoices')"
          required
          v-if="format"
        >
          <ItpFormFile
            v-model="file"
            name="file"
            :accept="format.accept"
            required
            :validation="b_4823665167724729_validation"
          >
          </ItpFormFile>
        </ItpFormGroup>
      </ItpForm>
      <ItpAlert
        show
        v-if="result && result.message"
      >
        <span>
          {{ result.message }}
        </span>
      </ItpAlert>
      <ItpFormTextarea
        name="details"
        v-model="result.details"
        monospace
        readonly
        v-if="result && result.details"
      >
      </ItpFormTextarea>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      :text="'Submit file'|pgettext('ImportInvoices')"
      type="submit"
      variant="primary"
      icon="fa-file-import"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ImportInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  file!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  result!: any;
  formats!: any;
  format!: any;

  async $$load_format() {
    return this.formats[0].value
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_4823665167724729_validation!: any;
  dataMembers = ['file', 'formId', 'formName', 'result', 'formats', 'format', 'ux', 'b_7315092382398562_modalBindings', 'b_4823665167724729_validation'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        file: null,
        formId: null,
        formName: "ImportInvoices",
        result: null,
        formats: [{
          value: "szamlazz_hu_csv",
          text: this.$fn.pgettext("ImportInvoicesFormat", "Szamlazz.hu CSV"),
          accept: ".csv",
        }
          ,
        {
          value: "ubl_xml",
          text: this.$fn.pgettext("ImportInvoicesFormat", "UBL XML"),
          accept: ".xml",
        }
          ,
        ]
        ,
        format: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_4823665167724729_validation: ["ext:xlsx"
          ,
          "required"
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("ImportInvoices|Title", "Import Invoices"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2402_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2402, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_648747096704084_setData_2408_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_648747096704084_setData_2408, alias=undefined
    return {
      path: "result",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_648747096704084_request_2406_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_648747096704084_request_2406, alias=submit
    return {
      operation: "create_invoices_from_file",
      data: { file: this.file, format: this.format },
      contentType: "multipart/form-data",
    }
  }

  async evh_648747096704084_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_648747096704084_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_2410_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_2410, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2402: actions.CloseModalAction;
    evh_7315092382398562_close_2401: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2404: actions.CloseComponentAction;
    evh_2248226175642056_close_2403: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_648747096704084_setData_2408: actions.SetDataAction;
    evh_648747096704084_success_2407: actions.EventHandlerImpl;
    act_648747096704084_request_2406: actions.RequestAction;
    evh_648747096704084_submit_2405: actions.EventHandlerImpl;
    evh_648747096704084_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_2410: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_2409: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2402 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2402_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2401 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2402,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2401],
      }
    );
    const act_2248226175642056_closeComponent_2404 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2403 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2404,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2403],
      }
    );
    const act_648747096704084_setData_2408 = new actions.SetDataAction(
      {
        actionArgs: this.act_648747096704084_setData_2408_getActionArgs,
        events: [],
      }
    );
    const evh_648747096704084_success_2407 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_setData_2408,
        event: "success",
        displayName: "setData",
      }
    );
    const act_648747096704084_request_2406 = new actions.RequestAction(
      {
        actionArgs: this.act_648747096704084_request_2406_getActionArgs,
        displayName: "submit",
        events: [evh_648747096704084_success_2407],
      }
    );
    const evh_648747096704084_submit_2405 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_request_2406,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_648747096704084_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_648747096704084_submit_2405],
      }
    );
    const act_1419464017721962_closeModal_2410 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_2410_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2409 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_2410,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2409],
      }
    );
    return {
      act_7315092382398562_closeModal_2402,
      evh_7315092382398562_close_2401,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2404,
      evh_2248226175642056_close_2403,
      evh_2248226175642056_close,
      act_648747096704084_setData_2408,
      evh_648747096704084_success_2407,
      act_648747096704084_request_2406,
      evh_648747096704084_submit_2405,
      evh_648747096704084_submit,
      act_1419464017721962_closeModal_2410,
      evh_1419464017721962_clickPreventStop_2409,
      evh_1419464017721962_clickPreventStop,
      submit: act_648747096704084_request_2406,
    }
  }
}

Vue.component("ImportInvoices", ImportInvoices);

</script>