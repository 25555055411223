"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateMeter = class CreateMeter extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formName', 'formId', 'submitAndClose', 'container', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.schemaDefaults('CreateMeter', { multiplier: 1 });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_container() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                self: this.$fn.urlFor('create_meter'),
                object: 'list',
                data: []
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formName: "meter",
            formId: null,
            submitAndClose: true,
            container: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create Meter"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2916_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_2922_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1040167445267876_navigate_2924_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.meter",
                    params: {
                        id: $event.data.response.data.id,
                    },
                },
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_2926_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "meters",
            };
        });
    }
    act_1040167445267876_rest_2920_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_script_2930_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            (this.setData('item', this.item.clone({
                id: null,
                rev: null,
                serial_number: null,
                links: null,
                etag: null,
                locked: null,
            })),
                this.setData('editor', this.item.editor()));
        });
    }
    act_1419464017721962_script_2930_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1419464017721962_script_2930_getActionArgs_value($event),
            };
        });
    }
    act_1419464017721962_reloadSlickgrid_2932_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "meters",
            };
        });
    }
    act_1419464017721962_rest_2928_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: this.container,
                resource: this.item,
            };
        });
    }
    evh_1419464017721962_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_click.executeFromDOM(this, event, scope);
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2916 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2916_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2915 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2916,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2915],
        });
        const act_2248226175642056_closeComponent_2918 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2917 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2918,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2917],
        });
        const act_1040167445267876_closeModal_2922 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_2922_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_2921 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_2922,
            event: "saved",
            displayName: "closeModal",
        });
        const act_1040167445267876_navigate_2924 = new core_1.actions.NavigateAction({
            actionArgs: this.act_1040167445267876_navigate_2924_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_2923 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_navigate_2924,
            event: "saved",
            displayName: "navigate",
        });
        const act_1040167445267876_reloadSlickgrid_2926 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_2926_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_saved_2925 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_2926,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_rest_2920 = new core_1.actions.RestAction({
            actionArgs: this.act_1040167445267876_rest_2920_getActionArgs,
            displayName: "create",
            events: [evh_1040167445267876_saved_2921, evh_1040167445267876_saved_2923, evh_1040167445267876_saved_2925],
        });
        const evh_1040167445267876_submit_2919 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_rest_2920,
            event: "submit",
            displayName: "create",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2919],
        });
        const act_1419464017721962_script_2930 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1419464017721962_script_2930_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_saved_2929 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_script_2930,
            event: "saved",
            displayName: "script",
        });
        const act_1419464017721962_reloadSlickgrid_2932 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1419464017721962_reloadSlickgrid_2932_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_saved_2931 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_reloadSlickgrid_2932,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const act_1419464017721962_rest_2928 = new core_1.actions.RestAction({
            actionArgs: this.act_1419464017721962_rest_2928_getActionArgs,
            displayName: "create2",
            events: [evh_1419464017721962_saved_2929, evh_1419464017721962_saved_2931],
        });
        const evh_1419464017721962_click_2927 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_rest_2928,
            event: "click",
            displayName: "create2",
        });
        const evh_1419464017721962_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_click_2927],
        });
        const act_2186679009640457_closeComponent_2934 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_2933 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeComponent_2934,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_2933],
        });
        return {
            act_7315092382398562_closeModal_2916,
            evh_7315092382398562_close_2915,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2918,
            evh_2248226175642056_close_2917,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_2922,
            evh_1040167445267876_saved_2921,
            act_1040167445267876_navigate_2924,
            evh_1040167445267876_saved_2923,
            act_1040167445267876_reloadSlickgrid_2926,
            evh_1040167445267876_saved_2925,
            act_1040167445267876_rest_2920,
            evh_1040167445267876_submit_2919,
            evh_1040167445267876_submit,
            act_1419464017721962_script_2930,
            evh_1419464017721962_saved_2929,
            act_1419464017721962_reloadSlickgrid_2932,
            evh_1419464017721962_saved_2931,
            act_1419464017721962_rest_2928,
            evh_1419464017721962_click_2927,
            evh_1419464017721962_click,
            act_2186679009640457_closeComponent_2934,
            evh_2186679009640457_clickPreventStop_2933,
            evh_2186679009640457_clickPreventStop,
            create: act_1040167445267876_rest_2920,
            create2: act_1419464017721962_rest_2928,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], CreateMeter.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateMeter.prototype, "onSize", null);
CreateMeter = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateMeter);
exports.default = CreateMeter;
vue_property_decorator_1.Vue.component("CreateMeter", CreateMeter);
