"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let HandoverProtocol = class HandoverProtocol extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formName', 'formId', 'labelAlign', 'handoverTypes', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onContract_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contractId");
    }
    onRevision_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("revisionId");
    }
    onHandover_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("handoverType");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid ?
                this.$fn.fetch('read_contract_protocol', {
                    asResource: true,
                    parameters: { id: this.oid }
                }) :
                this.$fn.fetch('get_contract_protocol_template', {
                    asResource: true,
                    parameters: {
                        contract_id: this.contractId,
                        revision_id: this.revisionId,
                        handover_type: this.handoverType,
                    }
                });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_handoverTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('HandoverType');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formName: "handoverProtocol",
            formId: null,
            labelAlign: "left",
            handoverTypes: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.$fn.pgettext("Contract", "Handover protocol"),
                    this.$fn.pgettext("HandoverType", this.item.handover_type, "Sentence"),
                ],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6444_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_closeModal_6450_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_script_6454_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.item.reset($event.data);
        });
    }
    act_1040167445267876_script_6454_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1040167445267876_script_6454_getActionArgs_value($event),
            };
        });
    }
    evh_1040167445267876_success_6453_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data;
        });
    }
    act_1040167445267876_crud_6452_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_5534025912102772_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_close.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_emit_6460_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data,
            };
        });
    }
    evh_5534025912102772_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_changed.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeComponent_6464_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1548630417156826_crud_6462_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: Object.assign(Object.assign({}, this.item), { protocol_status: 'cancelled' }),
            };
        });
    }
    act_1548630417156826_crud_6462_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: "Jegyőkönyv érvénytelenítése",
                message: "A jegyzőkönyv érvénytelenítés után már nem módosítható.",
                btnOk: "Érvénytelenítés",
                severity: "warning",
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeComponent_6468_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1419464017721962_crud_6466_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: Object.assign(Object.assign({}, this.item), { protocol_status: 'completed' }),
            };
        });
    }
    act_1419464017721962_crud_6466_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: "Jegyőkönyv véglegesítése",
                message: "A jegyzőkönyv véglegesítés után már nem módosítható.",
                btnOk: "Véglegesítés",
                severity: "warning",
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2186679009640457_script_6472_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.item.reset($event.data);
        });
    }
    act_2186679009640457_script_6472_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2186679009640457_script_6472_getActionArgs_value($event),
            };
        });
    }
    evh_2186679009640457_success_6471_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data;
        });
    }
    act_2186679009640457_crud_6470_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract_protocol",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5566617329548203_closeComponent_6474_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_5566617329548203_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6444 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6444_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6443 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6444,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6443],
        });
        const act_2248226175642056_closeModal_6446 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_6445 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_6446,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6445],
        });
        const act_8564662037462133_reloadComponentData_6448 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_6447 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_6448,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_6447],
        });
        const act_8564662037462133_closeModal_6450 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_8564662037462133_closeModal_6450_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_6449 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_6450,
            event: "deleted",
            displayName: "closeModal",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_6449],
        });
        const act_1040167445267876_script_6454 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1040167445267876_script_6454_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6453 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_script_6454,
            event: "success",
            when: this.evh_1040167445267876_success_6453_getWhen,
            displayName: "script",
        });
        const act_1040167445267876_crud_6452 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6452_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6453],
        });
        const evh_1040167445267876_submit_6451 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6452,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6451],
        });
        const act_5534025912102772_reloadComponentData_6456 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5534025912102772_reload_6455 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_reloadComponentData_6456,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5534025912102772_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_reload_6455],
        });
        const act_5534025912102772_closeComponent_6458 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_5534025912102772_close_6457 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_closeComponent_6458,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_5534025912102772_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_close_6457],
        });
        const act_5534025912102772_emit_6460 = new core_1.actions.EmitAction({
            actionArgs: this.act_5534025912102772_emit_6460_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_changed_6459 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_emit_6460,
            event: "changed",
            displayName: "emit",
        });
        const evh_5534025912102772_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_changed_6459],
        });
        const act_1548630417156826_closeComponent_6464 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1548630417156826_closeComponent_6464_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_6463 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_6464,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1548630417156826_crud_6462 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1548630417156826_crud_6462_getActionArgs,
            confirm: this.act_1548630417156826_crud_6462_getConfirm,
            displayName: "cancel",
            events: [evh_1548630417156826_success_6463],
        });
        const evh_1548630417156826_clickPreventStop_6461 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_crud_6462,
            event: "click.prevent.stop",
            displayName: "cancel",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6461],
        });
        const act_1419464017721962_closeComponent_6468 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1419464017721962_closeComponent_6468_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_6467 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_6468,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1419464017721962_crud_6466 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1419464017721962_crud_6466_getActionArgs,
            confirm: this.act_1419464017721962_crud_6466_getConfirm,
            displayName: "complete",
            events: [evh_1419464017721962_success_6467],
        });
        const evh_1419464017721962_clickPreventStop_6465 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_crud_6466,
            event: "click.prevent.stop",
            displayName: "complete",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6465],
        });
        const act_2186679009640457_script_6472 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2186679009640457_script_6472_getActionArgs,
            events: [],
        });
        const evh_2186679009640457_success_6471 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_script_6472,
            event: "success",
            when: this.evh_2186679009640457_success_6471_getWhen,
            displayName: "script",
        });
        const act_2186679009640457_crud_6470 = new core_1.actions.CRUDAction({
            actionArgs: this.act_2186679009640457_crud_6470_getActionArgs,
            displayName: "save",
            events: [evh_2186679009640457_success_6471],
        });
        const evh_2186679009640457_clickPreventStop_6469 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_crud_6470,
            event: "click.prevent.stop",
            displayName: "save",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_6469],
        });
        const act_5566617329548203_closeComponent_6474 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_5566617329548203_closeComponent_6474_getActionArgs,
            events: [],
        });
        const evh_5566617329548203_clickPreventStop_6473 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_closeComponent_6474,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_6473],
        });
        return {
            act_7315092382398562_closeModal_6444,
            evh_7315092382398562_close_6443,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_6446,
            evh_2248226175642056_close_6445,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_6448,
            evh_8564662037462133_reload_6447,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_6450,
            evh_8564662037462133_deleted_6449,
            evh_8564662037462133_deleted,
            act_1040167445267876_script_6454,
            evh_1040167445267876_success_6453,
            act_1040167445267876_crud_6452,
            evh_1040167445267876_submit_6451,
            evh_1040167445267876_submit,
            act_5534025912102772_reloadComponentData_6456,
            evh_5534025912102772_reload_6455,
            evh_5534025912102772_reload,
            act_5534025912102772_closeComponent_6458,
            evh_5534025912102772_close_6457,
            evh_5534025912102772_close,
            act_5534025912102772_emit_6460,
            evh_5534025912102772_changed_6459,
            evh_5534025912102772_changed,
            act_1548630417156826_closeComponent_6464,
            evh_1548630417156826_success_6463,
            act_1548630417156826_crud_6462,
            evh_1548630417156826_clickPreventStop_6461,
            evh_1548630417156826_clickPreventStop,
            act_1419464017721962_closeComponent_6468,
            evh_1419464017721962_success_6467,
            act_1419464017721962_crud_6466,
            evh_1419464017721962_clickPreventStop_6465,
            evh_1419464017721962_clickPreventStop,
            act_2186679009640457_script_6472,
            evh_2186679009640457_success_6471,
            act_2186679009640457_crud_6470,
            evh_2186679009640457_clickPreventStop_6469,
            evh_2186679009640457_clickPreventStop,
            act_5566617329548203_closeComponent_6474,
            evh_5566617329548203_clickPreventStop_6473,
            evh_5566617329548203_clickPreventStop,
            save: act_2186679009640457_crud_6470,
            cancel: act_1548630417156826_crud_6462,
            complete: act_1419464017721962_crud_6466,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "contractId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contractId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onContract_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "revisionId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('revisionId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onRevision_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "handoverType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('handoverType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onHandover_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], HandoverProtocol.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], HandoverProtocol.prototype, "onSize", null);
HandoverProtocol = __decorate([
    (0, vue_property_decorator_1.Component)()
], HandoverProtocol);
exports.default = HandoverProtocol;
vue_property_decorator_1.Vue.component("HandoverProtocol", HandoverProtocol);
