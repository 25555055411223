"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MainMenu = class MainMenu extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    get displayTestBadge() {
        if (this.loaded) {
            return _.includes(this.$app.document.location.hostname, 'test');
        }
        return null;
    }
    get displayDevBadge() {
        if (this.loaded) {
            return _.includes(this.$app.document.location.hostname, 'local');
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8512678877908410_showModal_1292_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: "outgoing",
                },
            };
        });
    }
    evh_8512678877908410_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8512678877908410_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5794187517758435_showModal_1294_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: "incoming",
                },
            };
        });
    }
    evh_5794187517758435_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5794187517758435_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6201675214105229_showModal_1296_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "deposit",
                },
            };
        });
    }
    evh_6201675214105229_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6201675214105229_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6373990564425123_showModal_1298_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "payment",
                },
            };
        });
    }
    evh_6373990564425123_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6373990564425123_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8819772728062230_showModal_1300_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionType: "banking",
                },
            };
        });
    }
    evh_8819772728062230_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8819772728062230_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2424316887484278_showModal_1302_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "AccountStatementReport",
            };
        });
    }
    evh_2424316887484278_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2424316887484278_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_1886107574314164_showModal_1304_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MCreateFixedAsset",
                props: {
                    assetType: "general",
                },
            };
        });
    }
    evh_1886107574314164_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1886107574314164_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7260361660686930_showModal_1306_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MCreateFixedAsset",
                props: {
                    assetType: "waste_bin",
                },
            };
        });
    }
    evh_7260361660686930_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7260361660686930_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5449511595610967_showModal_1308_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateMeter",
            };
        });
    }
    evh_5449511595610967_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5449511595610967_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8050584411731670_showModal_1310_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MeterDataExchange",
            };
        });
    }
    evh_8050584411731670_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8050584411731670_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7807097906137782_showModal_1312_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ReplaceMeter",
            };
        });
    }
    evh_7807097906137782_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7807097906137782_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2338624680052783_showModal_1314_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateContract",
            };
        });
    }
    evh_2338624680052783_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2338624680052783_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2596033535867385_showModal_1316_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateRental",
            };
        });
    }
    evh_2596033535867385_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2596033535867385_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6794737306373342_showModal_1318_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProperty",
            };
        });
    }
    evh_6794737306373342_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6794737306373342_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2660996057968802_showModal_1320_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateClient",
            };
        });
    }
    evh_2660996057968802_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2660996057968802_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7567745526909371_showModal_1322_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProduct",
            };
        });
    }
    evh_7567745526909371_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7567745526909371_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7228767045058026_showModal_1324_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Worksheet",
                props: {
                    size: "lg",
                },
            };
        });
    }
    evh_7228767045058026_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7228767045058026_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_298118714321807_script_1326_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$app.toggleConfirmPageClose($event);
        });
    }
    act_298118714321807_script_1326_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_298118714321807_script_1326_getActionArgs_value($event),
            };
        });
    }
    evh_298118714321807_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_298118714321807_change.executeFromDOM(this, event, scope);
        });
    }
    act_7106235911078350_logout_1328_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
        });
    }
    evh_7106235911078350_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7106235911078350_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5361165673534032_showModal_1330_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "AboutModal",
            };
        });
    }
    evh_5361165673534032_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5361165673534032_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1290 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1289 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1290,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1289],
        });
        const act_8512678877908410_showModal_1292 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8512678877908410_showModal_1292_getActionArgs,
            events: [],
        });
        const evh_8512678877908410_clickPrevent_1291 = new core_1.actions.EventHandlerImpl({
            action: act_8512678877908410_showModal_1292,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8512678877908410_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8512678877908410_clickPrevent_1291],
        });
        const act_5794187517758435_showModal_1294 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5794187517758435_showModal_1294_getActionArgs,
            events: [],
        });
        const evh_5794187517758435_clickPrevent_1293 = new core_1.actions.EventHandlerImpl({
            action: act_5794187517758435_showModal_1294,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5794187517758435_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5794187517758435_clickPrevent_1293],
        });
        const act_6201675214105229_showModal_1296 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6201675214105229_showModal_1296_getActionArgs,
            events: [],
        });
        const evh_6201675214105229_clickPrevent_1295 = new core_1.actions.EventHandlerImpl({
            action: act_6201675214105229_showModal_1296,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6201675214105229_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6201675214105229_clickPrevent_1295],
        });
        const act_6373990564425123_showModal_1298 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6373990564425123_showModal_1298_getActionArgs,
            events: [],
        });
        const evh_6373990564425123_clickPrevent_1297 = new core_1.actions.EventHandlerImpl({
            action: act_6373990564425123_showModal_1298,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6373990564425123_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6373990564425123_clickPrevent_1297],
        });
        const act_8819772728062230_showModal_1300 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8819772728062230_showModal_1300_getActionArgs,
            events: [],
        });
        const evh_8819772728062230_clickPrevent_1299 = new core_1.actions.EventHandlerImpl({
            action: act_8819772728062230_showModal_1300,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8819772728062230_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8819772728062230_clickPrevent_1299],
        });
        const act_2424316887484278_showModal_1302 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2424316887484278_showModal_1302_getActionArgs,
            events: [],
        });
        const evh_2424316887484278_clickPrevent_1301 = new core_1.actions.EventHandlerImpl({
            action: act_2424316887484278_showModal_1302,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2424316887484278_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2424316887484278_clickPrevent_1301],
        });
        const act_1886107574314164_showModal_1304 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1886107574314164_showModal_1304_getActionArgs,
            events: [],
        });
        const evh_1886107574314164_clickPrevent_1303 = new core_1.actions.EventHandlerImpl({
            action: act_1886107574314164_showModal_1304,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_1886107574314164_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1886107574314164_clickPrevent_1303],
        });
        const act_7260361660686930_showModal_1306 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7260361660686930_showModal_1306_getActionArgs,
            events: [],
        });
        const evh_7260361660686930_clickPrevent_1305 = new core_1.actions.EventHandlerImpl({
            action: act_7260361660686930_showModal_1306,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7260361660686930_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7260361660686930_clickPrevent_1305],
        });
        const act_5449511595610967_showModal_1308 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5449511595610967_showModal_1308_getActionArgs,
            events: [],
        });
        const evh_5449511595610967_clickPrevent_1307 = new core_1.actions.EventHandlerImpl({
            action: act_5449511595610967_showModal_1308,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5449511595610967_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5449511595610967_clickPrevent_1307],
        });
        const act_8050584411731670_showModal_1310 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8050584411731670_showModal_1310_getActionArgs,
            events: [],
        });
        const evh_8050584411731670_clickPrevent_1309 = new core_1.actions.EventHandlerImpl({
            action: act_8050584411731670_showModal_1310,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8050584411731670_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8050584411731670_clickPrevent_1309],
        });
        const act_7807097906137782_showModal_1312 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7807097906137782_showModal_1312_getActionArgs,
            events: [],
        });
        const evh_7807097906137782_clickPrevent_1311 = new core_1.actions.EventHandlerImpl({
            action: act_7807097906137782_showModal_1312,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7807097906137782_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7807097906137782_clickPrevent_1311],
        });
        const act_2338624680052783_showModal_1314 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2338624680052783_showModal_1314_getActionArgs,
            events: [],
        });
        const evh_2338624680052783_clickPrevent_1313 = new core_1.actions.EventHandlerImpl({
            action: act_2338624680052783_showModal_1314,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2338624680052783_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2338624680052783_clickPrevent_1313],
        });
        const act_2596033535867385_showModal_1316 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2596033535867385_showModal_1316_getActionArgs,
            events: [],
        });
        const evh_2596033535867385_clickPrevent_1315 = new core_1.actions.EventHandlerImpl({
            action: act_2596033535867385_showModal_1316,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2596033535867385_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2596033535867385_clickPrevent_1315],
        });
        const act_6794737306373342_showModal_1318 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6794737306373342_showModal_1318_getActionArgs,
            events: [],
        });
        const evh_6794737306373342_clickPrevent_1317 = new core_1.actions.EventHandlerImpl({
            action: act_6794737306373342_showModal_1318,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6794737306373342_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6794737306373342_clickPrevent_1317],
        });
        const act_2660996057968802_showModal_1320 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2660996057968802_showModal_1320_getActionArgs,
            events: [],
        });
        const evh_2660996057968802_clickPrevent_1319 = new core_1.actions.EventHandlerImpl({
            action: act_2660996057968802_showModal_1320,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2660996057968802_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2660996057968802_clickPrevent_1319],
        });
        const act_7567745526909371_showModal_1322 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7567745526909371_showModal_1322_getActionArgs,
            events: [],
        });
        const evh_7567745526909371_clickPrevent_1321 = new core_1.actions.EventHandlerImpl({
            action: act_7567745526909371_showModal_1322,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7567745526909371_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7567745526909371_clickPrevent_1321],
        });
        const act_7228767045058026_showModal_1324 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7228767045058026_showModal_1324_getActionArgs,
            events: [],
        });
        const evh_7228767045058026_clickPrevent_1323 = new core_1.actions.EventHandlerImpl({
            action: act_7228767045058026_showModal_1324,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7228767045058026_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7228767045058026_clickPrevent_1323],
        });
        const act_298118714321807_script_1326 = new core_1.actions.ScriptAction({
            actionArgs: this.act_298118714321807_script_1326_getActionArgs,
            events: [],
        });
        const evh_298118714321807_change_1325 = new core_1.actions.EventHandlerImpl({
            action: act_298118714321807_script_1326,
            event: "change",
            displayName: "script",
        });
        const evh_298118714321807_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298118714321807_change_1325],
        });
        const act_7106235911078350_logout_1328 = new core_1.actions.LogoutAction({
            actionArgs: this.act_7106235911078350_logout_1328_getActionArgs,
            events: [],
        });
        const evh_7106235911078350_clickPrevent_1327 = new core_1.actions.EventHandlerImpl({
            action: act_7106235911078350_logout_1328,
            event: "click.prevent",
            displayName: "logout",
        });
        const evh_7106235911078350_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7106235911078350_clickPrevent_1327],
        });
        const act_5361165673534032_showModal_1330 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5361165673534032_showModal_1330_getActionArgs,
            events: [],
        });
        const evh_5361165673534032_clickPrevent_1329 = new core_1.actions.EventHandlerImpl({
            action: act_5361165673534032_showModal_1330,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5361165673534032_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5361165673534032_clickPrevent_1329],
        });
        return {
            act_7315092382398562_reloadComponentData_1290,
            evh_7315092382398562_reload_1289,
            evh_7315092382398562_reload,
            act_8512678877908410_showModal_1292,
            evh_8512678877908410_clickPrevent_1291,
            evh_8512678877908410_clickPrevent,
            act_5794187517758435_showModal_1294,
            evh_5794187517758435_clickPrevent_1293,
            evh_5794187517758435_clickPrevent,
            act_6201675214105229_showModal_1296,
            evh_6201675214105229_clickPrevent_1295,
            evh_6201675214105229_clickPrevent,
            act_6373990564425123_showModal_1298,
            evh_6373990564425123_clickPrevent_1297,
            evh_6373990564425123_clickPrevent,
            act_8819772728062230_showModal_1300,
            evh_8819772728062230_clickPrevent_1299,
            evh_8819772728062230_clickPrevent,
            act_2424316887484278_showModal_1302,
            evh_2424316887484278_clickPrevent_1301,
            evh_2424316887484278_clickPrevent,
            act_1886107574314164_showModal_1304,
            evh_1886107574314164_clickPrevent_1303,
            evh_1886107574314164_clickPrevent,
            act_7260361660686930_showModal_1306,
            evh_7260361660686930_clickPrevent_1305,
            evh_7260361660686930_clickPrevent,
            act_5449511595610967_showModal_1308,
            evh_5449511595610967_clickPrevent_1307,
            evh_5449511595610967_clickPrevent,
            act_8050584411731670_showModal_1310,
            evh_8050584411731670_clickPrevent_1309,
            evh_8050584411731670_clickPrevent,
            act_7807097906137782_showModal_1312,
            evh_7807097906137782_clickPrevent_1311,
            evh_7807097906137782_clickPrevent,
            act_2338624680052783_showModal_1314,
            evh_2338624680052783_clickPrevent_1313,
            evh_2338624680052783_clickPrevent,
            act_2596033535867385_showModal_1316,
            evh_2596033535867385_clickPrevent_1315,
            evh_2596033535867385_clickPrevent,
            act_6794737306373342_showModal_1318,
            evh_6794737306373342_clickPrevent_1317,
            evh_6794737306373342_clickPrevent,
            act_2660996057968802_showModal_1320,
            evh_2660996057968802_clickPrevent_1319,
            evh_2660996057968802_clickPrevent,
            act_7567745526909371_showModal_1322,
            evh_7567745526909371_clickPrevent_1321,
            evh_7567745526909371_clickPrevent,
            act_7228767045058026_showModal_1324,
            evh_7228767045058026_clickPrevent_1323,
            evh_7228767045058026_clickPrevent,
            act_298118714321807_script_1326,
            evh_298118714321807_change_1325,
            evh_298118714321807_change,
            act_7106235911078350_logout_1328,
            evh_7106235911078350_clickPrevent_1327,
            evh_7106235911078350_clickPrevent,
            act_5361165673534032_showModal_1330,
            evh_5361165673534032_clickPrevent_1329,
            evh_5361165673534032_clickPrevent,
        };
    }
};
MainMenu = __decorate([
    (0, vue_property_decorator_1.Component)()
], MainMenu);
exports.default = MainMenu;
vue_property_decorator_1.Vue.component("MainMenu", MainMenu);
