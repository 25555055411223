<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WContactModal"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="contact"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <UcContactFieldset
        :contact="editor"
      >
      </UcContactFieldset>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.contact"
      :text="'Save'|gettext"
      type="submit"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WContactModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  @Prop({
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resource!: any;

  async $$load_resource() {
    return this.item || this.$fn.schemaDefaults('Create' + _.upperFirst(this.parent.object) + 'ContactCommand')
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  createTitle!: any;
  editTitle!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['resource', 'editor', 'createTitle', 'editTitle', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resource: null,
        editor: null,
        createTitle: this.$fn.pgettext("Contactes", "Add contact"),
        editTitle: this.$fn.pgettext("Contactes", "Edit contact"),
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.item == null ? this.createTitle : this.editTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2032_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2032, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_2038_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_2038, alias=undefined
    return {
      result: {
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_closeComponent_2040_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_2040, alias=undefined
    return {
      result: {
      }
      ,
    }
  }

  async act_1040167445267876_rest_2036_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_2036, alias=submit
    return {
      method: "save",
      container: this.parent.contacts,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2032: actions.CloseModalAction;
    evh_7315092382398562_close_2031: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2034: actions.CloseComponentAction;
    evh_2248226175642056_close_2033: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_2038: actions.CloseComponentAction;
    evh_1040167445267876_saved_2037: actions.EventHandlerImpl;
    act_1040167445267876_closeComponent_2040: actions.CloseComponentAction;
    evh_1040167445267876_deleted_2039: actions.EventHandlerImpl;
    act_1040167445267876_rest_2036: actions.RestAction;
    evh_1040167445267876_submit_2035: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_2042: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_2041: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2032 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2032_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2031 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2032,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2031],
      }
    );
    const act_2248226175642056_closeComponent_2034 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2033 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2034,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2033],
      }
    );
    const act_1040167445267876_closeComponent_2038 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_2038_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_2037 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_2038,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_closeComponent_2040 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_2040_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_deleted_2039 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_2040,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_rest_2036 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_2036_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_saved_2037, evh_1040167445267876_deleted_2039],
      }
    );
    const evh_1040167445267876_submit_2035 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_2036,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_2035],
      }
    );
    const act_1419464017721962_closeComponent_2042 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_2041 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_2042,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_2041],
      }
    );
    return {
      act_7315092382398562_closeModal_2032,
      evh_7315092382398562_close_2031,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2034,
      evh_2248226175642056_close_2033,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_2038,
      evh_1040167445267876_saved_2037,
      act_1040167445267876_closeComponent_2040,
      evh_1040167445267876_deleted_2039,
      act_1040167445267876_rest_2036,
      evh_1040167445267876_submit_2035,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_2042,
      evh_1419464017721962_clickPreventStop_2041,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_rest_2036,
    }
  }
}

Vue.component("WContactModal", WContactModal);

</script>