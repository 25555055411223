"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ReplaceMeter = class ReplaceMeter extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['formId', 'labelAlign', 'item', 'responseData', 'workflowStepsInfo', 'units', 'selectedOldMeter', 'old_reading_image', 'new_reading_image', 'ux', 'b_4963030162390099_dataSource', 'b_4246589045129675_dataSource'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.schemaDefaults('ReplaceMeter');
        });
    }
    $$load_units() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_meter_type_units');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            labelAlign: "right",
            item: null,
            responseData: null,
            workflowStepsInfo: {
                steps: ["rögzíti a leszerelt mérőóra állását",
                    "inaktiválja a leszerelt órát",
                    "inaktiválja a kapcsolódó bérleménynél beállított mért szolgáltatást",
                    "létrehoz egy új mérőórát a megadott sorozatszámmal",
                    "rögzíti az induló mérőállást",
                    "a kapcsolódó bérleménynél a leszerelt mérőóra helyett beállít egy mért szolgáltatást az új mérőórával",
                ],
            },
            units: null,
            selectedOldMeter: null,
            old_reading_image: null,
            new_reading_image: null,
            ux: null,
            b_4963030162390099_dataSource: {
                name: "suggest_meter",
                parameters: {
                    exclude_types: ["pulse",
                    ],
                },
            },
            b_4246589045129675_dataSource: {
                name: "suggest_meter",
                parameters: {
                    exclude_types: ["pulse",
                    ],
                },
            },
        });
    }
    get isReadOnly() {
        if (this.loaded) {
            return !!(this.responseData || (this.actions.submit && this.actions.submit.isRunning));
        }
        return null;
    }
    get oldMeterReadingUnitOptions() {
        if (this.loaded) {
            return this.selectedOldMeter ? this.units[this.selectedOldMeter.meter_type] : [];
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("ReplaceMeterModal.Title", "Replace Meter"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2960_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_setData_2966_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "responseData",
                value: $event.data.response.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1040167445267876_request_2964_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_meter_operation",
                data: this.item,
                parameters: {
                    op: "replacement",
                },
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_4963030162390099_setData_2968_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.old_meter.unit",
                value: $event.data.option.unit,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_4963030162390099_setData_2970_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedOldMeter",
                value: $event.data.option,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_4963030162390099_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4963030162390099_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_3855552110905499_setData_2972_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.old_meter.image",
                value: this.$fn.imageData($event.value),
                resolve: true,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_3855552110905499_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3855552110905499_changed.executeFromDOM(this, event, scope);
        });
    }
    act_4246589045129675_setData_2974_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.new_meter.serial",
                value: $event.data.option.serial_number,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_4246589045129675_setData_2974_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.option;
        });
    }
    act_4246589045129675_setData_2976_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.new_meter.id",
                value: $event.data.option.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_4246589045129675_setData_2976_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.option;
        });
    }
    act_4246589045129675_setData_2978_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.new_meter.unit",
                value: $event.data.option.unit,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_4246589045129675_setData_2978_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.option;
        });
    }
    evh_4246589045129675_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4246589045129675_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    evh_353388685162594_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_353388685162594_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2960 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2960_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2959 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2960,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2959],
        });
        const act_2248226175642056_closeComponent_2962 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2961 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2962,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2961],
        });
        const act_1040167445267876_setData_2966 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_2966_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_2965 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_2966,
            event: "success",
            displayName: "setData",
        });
        const act_1040167445267876_request_2964 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_2964_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_2965],
        });
        const evh_1040167445267876_submit_2963 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_2964,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_2963],
        });
        const act_4963030162390099_setData_2968 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4963030162390099_setData_2968_getActionArgs,
            events: [],
        });
        const evh_4963030162390099_optionSelected_2967 = new core_1.actions.EventHandlerImpl({
            action: act_4963030162390099_setData_2968,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_4963030162390099_setData_2970 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4963030162390099_setData_2970_getActionArgs,
            events: [],
        });
        const evh_4963030162390099_optionSelected_2969 = new core_1.actions.EventHandlerImpl({
            action: act_4963030162390099_setData_2970,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_4963030162390099_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4963030162390099_optionSelected_2967, evh_4963030162390099_optionSelected_2969],
        });
        const act_3855552110905499_setData_2972 = new core_1.actions.SetDataAction({
            actionArgs: this.act_3855552110905499_setData_2972_getActionArgs,
            events: [],
        });
        const evh_3855552110905499_changed_2971 = new core_1.actions.EventHandlerImpl({
            action: act_3855552110905499_setData_2972,
            event: "changed",
            displayName: "setData",
        });
        const evh_3855552110905499_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3855552110905499_changed_2971],
        });
        const act_4246589045129675_setData_2974 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4246589045129675_setData_2974_getActionArgs,
            when: this.act_4246589045129675_setData_2974_getWhen,
            events: [],
        });
        const evh_4246589045129675_optionSelected_2973 = new core_1.actions.EventHandlerImpl({
            action: act_4246589045129675_setData_2974,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_4246589045129675_setData_2976 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4246589045129675_setData_2976_getActionArgs,
            when: this.act_4246589045129675_setData_2976_getWhen,
            events: [],
        });
        const evh_4246589045129675_optionSelected_2975 = new core_1.actions.EventHandlerImpl({
            action: act_4246589045129675_setData_2976,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_4246589045129675_setData_2978 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4246589045129675_setData_2978_getActionArgs,
            when: this.act_4246589045129675_setData_2978_getWhen,
            events: [],
        });
        const evh_4246589045129675_optionSelected_2977 = new core_1.actions.EventHandlerImpl({
            action: act_4246589045129675_setData_2978,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_4246589045129675_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4246589045129675_optionSelected_2973, evh_4246589045129675_optionSelected_2975, evh_4246589045129675_optionSelected_2977],
        });
        const act_353388685162594_closeComponent_2980 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_353388685162594_clickPreventStop_2979 = new core_1.actions.EventHandlerImpl({
            action: act_353388685162594_closeComponent_2980,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_353388685162594_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_353388685162594_clickPreventStop_2979],
        });
        const act_1548630417156826_closeComponent_2982 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_2981 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_2982,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_2981],
        });
        return {
            act_7315092382398562_closeModal_2960,
            evh_7315092382398562_close_2959,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2962,
            evh_2248226175642056_close_2961,
            evh_2248226175642056_close,
            act_1040167445267876_setData_2966,
            evh_1040167445267876_success_2965,
            act_1040167445267876_request_2964,
            evh_1040167445267876_submit_2963,
            evh_1040167445267876_submit,
            act_4963030162390099_setData_2968,
            evh_4963030162390099_optionSelected_2967,
            act_4963030162390099_setData_2970,
            evh_4963030162390099_optionSelected_2969,
            evh_4963030162390099_optionSelected,
            act_3855552110905499_setData_2972,
            evh_3855552110905499_changed_2971,
            evh_3855552110905499_changed,
            act_4246589045129675_setData_2974,
            evh_4246589045129675_optionSelected_2973,
            act_4246589045129675_setData_2976,
            evh_4246589045129675_optionSelected_2975,
            act_4246589045129675_setData_2978,
            evh_4246589045129675_optionSelected_2977,
            evh_4246589045129675_optionSelected,
            act_353388685162594_closeComponent_2980,
            evh_353388685162594_clickPreventStop_2979,
            evh_353388685162594_clickPreventStop,
            act_1548630417156826_closeComponent_2982,
            evh_1548630417156826_clickPreventStop_2981,
            evh_1548630417156826_clickPreventStop,
            submit: act_1040167445267876_request_2964,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], ReplaceMeter.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ReplaceMeter.prototype, "onSize", null);
ReplaceMeter = __decorate([
    (0, vue_property_decorator_1.Component)()
], ReplaceMeter);
exports.default = ReplaceMeter;
vue_property_decorator_1.Vue.component("ReplaceMeter", ReplaceMeter);
