<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpText
    tag="h5"
    capitalize
    muted
    class="mb-0"
  >
    {{ resourceTitle }}
  </ItpText>
  <span
    class="m-0 p-0"
  >
    &middot;
  </span>
  <ItpText
    tag="h4"
    :text="resourceKey"
    class="mb-0"
  >
  </ItpText>
  <DIV
    :title="'Closed'|gettext"
    v-if="readonly"
  >
    <ItpIcon
      :icon="$config.lockIcon"
    >
    </ItpIcon>
  </DIV>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <ItpButton
      icon="material-symbols:edit-outline"
      :tooltip="'Edit'|gettext"
      variant="light"
      class="ml-auto mr-2"
      v-if="!isNew && !worksheet.isReadOnly"
      @click.prevent.stop="evh_6424600534600827_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!isNew"
      @click.prevent.stop="evh_8249915319319009_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpLink
      icon="bxs:file-pdf"
      :href="worksheet.preview_url"
      tooltip="Open PDF"
      target="_blank"
      use-auth
      button
      variant="light"
      show-spinner
      no-download-link
      :text="'Preview'|gettext"
      v-if="worksheet.preview_url"
    >
    </ItpLink>
    <b-dropdown
      right
      variant="light"
      no-caret
      class="border"
      v-if="!isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <ItpButton
        dropdown-item
        :text="'Copy'|pgettext('BtnCopyResource')"
        :tooltip="'Copy worksheet'|pgettext('Worksheet')"
        variant="default"
      >
      </ItpButton>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="worksheet"
        :tooltip="'Delete worksheet'|pgettext('Worksheet')"
        v-if="!isNew"
        @success="evh_1890060294221449_success($event, {})"
      >
      </BtnDeleteResource>
      <hr>
      </hr>
      <ItpButton
        dropdown-item
        :text="'History'|pgettext('BtnResourceHistory')"
        :tooltip="'Worksheet history'|pgettext('Worksheet')"
        variant="default"
      >
      </ItpButton>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcWorksheetHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  worksheet!: object;

  @Watch('worksheet')
  onWorksheet(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("worksheet")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          typeName: this.$fn.pgettext("Worksheet", "Worksheet"),
          newTransaction: this.$fn.pgettext("Worksheet", "New Worksheet"),
        }
        ,
        ux: null,
      },
    }
  }

  get readonly() {
    if (this.loaded) {

      return this.worksheet.locked
    }
    return null;
  }

  get isNew() {
    if (this.loaded) {

      return this.worksheet.isNew
    }
    return null;
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.typeName
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.isNew ? this.messages.newTransaction : this.worksheet.snapshot('number')
    }
    return null;
  }

  async act_6424600534600827_emit_5908_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6424600534600827_emit_5908, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_6424600534600827_close_5907_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_6424600534600827_close_5907, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_6424600534600827_showModal_5906_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6424600534600827_showModal_5906, alias=undefined
    return {
      name: "WorksheetEditor",
      props: {
        worksheet: _.cloneDeep(this.worksheet),
      }
      ,
    }
  }

  async evh_6424600534600827_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6424600534600827_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8249915319319009_emit_5910_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8249915319319009_emit_5910, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_8249915319319009_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8249915319319009_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1890060294221449_emit_5912_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1890060294221449_emit_5912, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_1890060294221449_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1890060294221449_success.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_6424600534600827_emit_5908: actions.EmitAction;
    evh_6424600534600827_close_5907: actions.EventHandlerImpl;
    act_6424600534600827_showModal_5906: actions.ShowModalAction;
    evh_6424600534600827_clickPreventStop_5905: actions.EventHandlerImpl;
    evh_6424600534600827_clickPreventStop: actions.EventHandlerGroup;
    act_8249915319319009_emit_5910: actions.EmitAction;
    evh_8249915319319009_clickPreventStop_5909: actions.EventHandlerImpl;
    evh_8249915319319009_clickPreventStop: actions.EventHandlerGroup;
    act_1890060294221449_emit_5912: actions.EmitAction;
    evh_1890060294221449_success_5911: actions.EventHandlerImpl;
    evh_1890060294221449_success: actions.EventHandlerGroup;
  }

  getActions() {
    const act_6424600534600827_emit_5908 = new actions.EmitAction(
      {
        actionArgs: this.act_6424600534600827_emit_5908_getActionArgs,
        events: [],
      }
    );
    const evh_6424600534600827_close_5907 = new actions.EventHandlerImpl(
      {
        action: act_6424600534600827_emit_5908,
        event: "close",
        when: this.evh_6424600534600827_close_5907_getWhen,
        displayName: "emit",
      }
    );
    const act_6424600534600827_showModal_5906 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6424600534600827_showModal_5906_getActionArgs,
        events: [evh_6424600534600827_close_5907],
      }
    );
    const evh_6424600534600827_clickPreventStop_5905 = new actions.EventHandlerImpl(
      {
        action: act_6424600534600827_showModal_5906,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6424600534600827_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6424600534600827_clickPreventStop_5905],
      }
    );
    const act_8249915319319009_emit_5910 = new actions.EmitAction(
      {
        actionArgs: this.act_8249915319319009_emit_5910_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_8249915319319009_clickPreventStop_5909 = new actions.EventHandlerImpl(
      {
        action: act_8249915319319009_emit_5910,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_8249915319319009_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8249915319319009_clickPreventStop_5909],
      }
    );
    const act_1890060294221449_emit_5912 = new actions.EmitAction(
      {
        actionArgs: this.act_1890060294221449_emit_5912_getActionArgs,
        events: [],
      }
    );
    const evh_1890060294221449_success_5911 = new actions.EventHandlerImpl(
      {
        action: act_1890060294221449_emit_5912,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_1890060294221449_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_1890060294221449_success_5911],
      }
    );
    return {
      act_6424600534600827_emit_5908,
      evh_6424600534600827_close_5907,
      act_6424600534600827_showModal_5906,
      evh_6424600534600827_clickPreventStop_5905,
      evh_6424600534600827_clickPreventStop,
      act_8249915319319009_emit_5910,
      evh_8249915319319009_clickPreventStop_5909,
      evh_8249915319319009_clickPreventStop,
      act_1890060294221449_emit_5912,
      evh_1890060294221449_success_5911,
      evh_1890060294221449_success,
      reload: act_8249915319319009_emit_5910,
    }
  }
}

Vue.component("UcWorksheetHeader", UcWorksheetHeader);

</script>