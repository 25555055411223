<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="MFixedAssetEditor"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="fixedAsset"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <MFixedAssetEditorFieldset
          :fixedAsset="fixedAsset"
          :formId="formId"
          :target="target"
        >
        </MFixedAssetEditorFieldset>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.fixedAsset || !forms.fixedAsset.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MFixedAssetEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args

    if (!this.target) {
      return data;
    }

    const fieldsToSubmit = this.targetConfig[this.target].fieldsToSubmit;
    if (fieldsToSubmit.indexOf('*') > -1) {
      return data;
    }

    return _.pick(data, fieldsToSubmit);
  }

  @Prop({
    type: Object,
  })
  fixedAsset!: object;

  @Watch('fixedAsset')
  onFixed_asset(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fixedAsset")
  }

  @Prop({
    type: String,
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  defaultTitle!: any;
  targetConfig!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        defaultTitle: this.$fn.pgettext("FixedAsset", "Edit General Information"),
        targetConfig: {
          general: {
            title: this.$fn.pgettext("FixedAsset", "Edit general data"),
            fieldsToSubmit: ["*"
              ,
            ]
            ,
          }
          ,
          wasteBin: {
            title: this.$fn.pgettext("FixedAsset", "Edit waste bin data"),
            fieldsToSubmit: ["id"
              ,
              "etag"
              ,
              "waste_bin"
              ,
            ]
            ,
          }
          ,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1570_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1570, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_1576_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_1576, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_1574_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_1574, alias=save
    return {
      objectType: "fixed_asset",
      op: "upsert",
      data: this.getPayload(this.fixedAsset),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1570: actions.CloseModalAction;
    evh_7315092382398562_close_1569: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_1572: actions.CloseComponentAction;
    evh_2248226175642056_close_1571: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_1576: actions.CloseComponentAction;
    evh_1040167445267876_success_1575: actions.EventHandlerImpl;
    act_1040167445267876_crud_1574: actions.CRUDAction;
    evh_1040167445267876_submit_1573: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_1578: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_1577: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1570 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1570_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1569 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1570,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1569],
      }
    );
    const act_2248226175642056_closeComponent_1572 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1571 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_1572,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1571],
      }
    );
    const act_1040167445267876_closeComponent_1576 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_1576_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1575 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_1576,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_crud_1574 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_1574_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_1575],
      }
    );
    const evh_1040167445267876_submit_1573 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_1574,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1573],
      }
    );
    const act_1419464017721962_closeComponent_1578 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1577 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_1578,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1577],
      }
    );
    return {
      act_7315092382398562_closeModal_1570,
      evh_7315092382398562_close_1569,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_1572,
      evh_2248226175642056_close_1571,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_1576,
      evh_1040167445267876_success_1575,
      act_1040167445267876_crud_1574,
      evh_1040167445267876_submit_1573,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_1578,
      evh_1419464017721962_clickPreventStop_1577,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_1574,
    }
  }
}

Vue.component("MFixedAssetEditor", MFixedAssetEditor);

</script>