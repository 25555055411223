<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpForm
    name="InvoiceGeneration"
    schema="CreateInvoiceGenerationTask"
    :readonly="item.locked"
    :id="formId"
    v-if="loaded && item"
    @submit="evh_2248226175642056_submit($event, {})"
  >
    <ItpPanel
      collapsible
      stateKey="InvoiceGenerator.panels.filters"
    >
      <ItpText
        strong
        muted
        block
        :text="'Filters'|pgettext('InvoiceGenerator')"
        slot="caption"
      >
      </ItpText>
      <ItpBox
        wrap
        direction="column"
        class="mt-3 p-2 w-100"
      >
        <ItpText
          strong
          block
          :text="'Product Invoice Groups'|pgettext('InvoiceGenerator')"
          class="mb-2"
        >
        </ItpText>
        <ItpFormCheckbox
          :name="item.id"
          :key="item.id"
          v-model="invoiceGroupFilters[item.id]"
          :label="item.name"
          v-for="item in productInvoiceGroups"
        >
        </ItpFormCheckbox>
      </ItpBox>
      <hr>
      </hr>
      <ItpBox
        wrap
        direction="column"
        class="mt-3 p-2 w-100"
      >
        <ItpText
          strong
          block
          :text="'Worksheets'|pgettext('InvoiceGenerator')"
          class="mb-2"
        >
        </ItpText>
        <ItpFormCheckbox
          name="include_worksheet"
          v-model="includeWorksheets"
          :label="'Include works from worksheets'|pgettext('InvoiceGenerator')"
        >
        </ItpFormCheckbox>
      </ItpBox>
      <hr>
      </hr>
      <ItpBox
        wrap
        direction="column"
        class="mt-3 p-2 w-100"
      >
        <ItpText
          strong
          block
          :text="'Other Filters'|pgettext('InvoiceGenerator')"
          class="mb-2"
        >
        </ItpText>
        <ItpSelect2
          name="selectedFilterType"
          v-model="selectedFilterType"
          :options="filterTypes"
          valueField="target"
          textField="text"
          :clearable="b_1045524669200057_clearable"
          bind-object
          class="my-2"
        >
        </ItpSelect2>
        <ItpFormSelect2
          name="selectedFilterValue"
          v-model="selectedFilterValue"
          bind-object
          :dataSource="selectedFilterType && selectedFilterType.dataSource"
          :optionTemplate="selectedFilterType && selectedFilterType.optionTemplate"
          :useOptionTemplateForSelectedOption="selectedFilterType && selectedFilterType.useOptionTemplateForSelectedOption"
          :options="selectedFilterType && selectedFilterType.options"
          :valueField="selectedFilterType && selectedFilterType.valueField || 'id'"
          :textField="selectedFilterType && selectedFilterType.textField || 'name'"
          textFieldSeparator="-"
          :disabled="!selectedFilterType"
          class="mb-2"
        >
        </ItpFormSelect2>
        <ItpRow
          form
          class="mb-2"
        >
          <ItpCol>
            <ItpFormSelect
              name="selectedOperation"
              v-model="selectedOperation"
              :options="operations"
              valueField="value"
              textField="text"
            >
            </ItpFormSelect>
          </ItpCol>
          <ItpCol>
            <ItpButton
              :title="'Add Filter'|pgettext('InvoiceGenerator')"
              :disabled="!canAddFilter"
              class="ml-auto"
              @click="evh_5891271350223090_click($event, {})"
            >
              <Icon
                icon="mdi:add-bold"
              >
              </Icon>
            </ItpButton>
          </ItpCol>
        </ItpRow>
        <ItpBox
          wrap
          direction="column"
          class="mt-3 w-100"
        >
          <DIV
            class="text-center"
            v-if="!filters.length"
          >
            <ItpText
              :text="'No filters'|pgettext('InvoiceGenerator')"
            >
            </ItpText>
          </DIV>
          <ItpBox
            :key="filter.id + filter.op"
            spacing="p-2"
            alignItems="baseline"
            class="border rounded"
            v-if="filters.length"
            v-for="filter in filters"
          >
            <ItpIcon
              :fa="filter.op === 'include' ? 'plus-square' : 'minus-square'"
              class="px-2"
            >
            </ItpIcon>
            <DIV
              v-if="!filter.value.object || !filterOptionTemplates[filter.value.object]"
            >
              {{ filter.value.name }}
            </DIV>
            <component
              :is="filterOptionTemplates[filter.value.object]"
              :option="filter.value"
              v-if="filter.value.object && filterOptionTemplates[filter.value.object]"
            >
            </component>
            <b-button-close
              :title="'Remove'|pgettext('InvoiceGenerator')"
              class="px-2"
              @click="evh_1641744087697442_click($event, {filter})"
            >
            </b-button-close>
          </ItpBox>
        </ItpBox>
      </ItpBox>
    </ItpPanel>
    <HR>
    </HR>
    <ItpPanel
      collapsible
      stateKey="InvoiceGenerator.panels.parameters"
    >
      <ItpText
        strong
        muted
        block
        :text="'Parameters'|pgettext('InvoiceGenerator')"
        slot="caption"
      >
      </ItpText>
      <ItpFormGroup
        name="billing_period_start"
        :label="'Billing Period Start'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="billing_period_start"
          v-model="item.billing_period_start"
          :placeholder="'Start of Period'|pgettext('InvoiceGenerator')"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="billing_period_end"
        :label="'Billing Period End'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="billing_period_end"
          v-model="item.billing_period_end"
          :placeholder="'End of Period'|pgettext('InvoiceGenerator')"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="invoice_date"
        :label="'Invoice Date'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="invoice_date"
          v-model="item.invoice_date"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="fulfillment_date"
        :label="'Fulfillment Date'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="fulfillment_date"
          v-model="item.fulfillment_date"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="due_date"
        :label="'Due Date'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="due_date"
          v-model="item.due_date"
          required
          :placeholder="'Due Date'|pgettext('InvoiceGenerator')"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="meter_readings_start_date"
        :label="'Meter Readings From'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
      >
        <ItpFormDatetime
          name="meter_readings_start_date"
          v-model="item.meter_readings_start_date"
          :placeholder="'Meter Readings From'|pgettext('InvoiceGenerator')"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="invoice_tags"
        :label="'Invoice Tags'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
      >
        <ItpSelect2
          name="invoice_tags"
          v-model="item.invoice_tags"
          taggable
          multiple
        >
        </ItpSelect2>
      </ItpFormGroup>
    </ItpPanel>
    <HR>
    </HR>
    <ItpPanel
      collapsible
      stateKey="InvoiceGenerator.panels.options"
    >
      <ItpText
        strong
        muted
        block
        :text="'Options'|pgettext('InvoiceGenerator')"
        slot="caption"
      >
      </ItpText>
      <fieldset>
        <ItpFormGroup>
          <ItpFormCheckbox
            name="include_zero_consumptions"
            v-model="item.include_zero_consumptions"
            :label="'Include zero consumptions'|pgettext('InvoiceGenerator')"
            required
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
      </fieldset>
      <fieldset>
        <ItpFormGroup
          :help="'Charge all consumptions, even minimal ones.'|gettext"
        >
          <ItpFormCheckbox
            name="ignore_meter_billing_thresholds"
            v-model="item.ignore_meter_billing_thresholds"
            :label="'Ignore meter billing thresholds.'|pgettext('InvoiceGenerator')"
            required
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
      </fieldset>
    </ItpPanel>
    <HR>
    </HR>
    <JobHandlerControl
      jobType="invoice_generation"
      load-current
      :payload="() => getPayload()"
      :text="'Generate Invoices'|pgettext('InvoiceGenerator')"
      icon="mdi:cog"
      :disabled="!canSubmit"
    >
    </JobHandlerControl>
  </ItpForm>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceGeneratorControls extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "InvoiceGeneratorPanel.formData");
    this.$$cache_filters = new ComponentValueCache(this, "filters", "InvoiceGeneratorPanel.filters");
    this.$$cache_includeWorksheets = new ComponentValueCache(this, "includeWorksheets", "InvoiceGeneratorPanel.includeWorksheets");
    this.$$cache_invoiceGroupFilters = new ComponentValueCache(this, "invoiceGroupFilters", "InvoiceGeneratorPanel.invoiceGroupFilters");
    this.$$cache_taskStatusDismissed = new ComponentValueCache(this, "taskStatusDismissed", "InvoiceGeneratorPanel.taskStatusDismissed");
  }

  beforeDestroy(...args: any[]) {
    this.poller.stop()
  }

  onPoll(...args: any[]) {
    console.log("Poll completed!");
  }

  getPayload(...args: any[]) {
    {
      {
        return {
          ...this.item,
          ..._.reduce(this.filters,
            (m, filter) => {
              const target = (filter.op === 'include' ? '' : 'exclude_') + filter.type;
              const arr = _.get(m, target, [])
              arr.push(filter.id)
              m[target] = arr;
              return m;
            }, {}
          ),
          product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
            if (!!v) {
              arr.push(k);
            }
            return arr;
          }, []
          ),
          exclude_product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
            if (!v) {
              arr.push(k);
            }
            return arr;
          }, []
          ),
          skip_worksheets: !this.includeWorksheets
        }
      }
    }
  }

  updateStatus(...args: any[]) {
    _.extend(this.currentGeneratorTask, args[0])
  }

  labelAlign!: any;
  currentGeneratorTask!: any;

  async $$load_currentGeneratorTask() {
    return this.$fn.fetch('read_invoice_generation_task', { parameters: { id: 'current' } })
  }

  poller!: any;

  async $$load_poller() {
    return this.$fn.poller({ callback: () => this.onPoll(), id: "InvoiceGenerator" })
  }

  item!: any;

  async $$load_item() {
    return {
      description: 'Invoice generation job',
      fulfillment_date: this.$fn.today(),
      due_date: null,
      billing_period_start: this.$fn.today(),
      billing_period_end: null,
      exchange_rates: [],
      service_type_flat_rate: true,
      service_type_measured: true,
      ignore_meter_billing_thresholds: false,
      meter_readings_start_date: null,
      invoice_tags: [],
      include_zero_consumptions: true
    }
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  groups!: any;

  async $$load_groups() {
    return this.$fn.fetch('list_group').then(p => p.data)
  }

  productInvoiceGroups!: any;

  async $$load_productInvoiceGroups() {
    return this.$fn.fetch('list_product_invoice_group')
  }

  filterOptionTemplates!: any;
  filterTypes!: any;

  async $$load_filterTypes() {
    return [
      {
        text: this.$fn.gettext('Clients'),
        target: 'tenants',
        dataSource: {
          name: 'suggest_client',
          parameters: {
            relation: ['customer'],
            with_contracts: true
          }
        },
        useOptionTemplateForSelectedOption: true,
        optionTemplate: '<UcSuggestClientOption :option="option"></UcSuggestClientOption>',
      },
      {
        text: this.$fn.gettext('Client Groups'),
        target: 'client_groups',
        options: _.filter(this.groups, { group_type: 'company' })
      },
      {
        text: this.$fn.gettext('Contracts'),
        target: 'contracts',
        dataSource: {
          name: 'suggest_contract',
          parameters: {
          }
        },
        useOptionTemplateForSelectedOption: true,
        optionTemplate: '<UcSuggestContractOption :option="option"></UcSuggestContractOption>',
      },
      {
        text: this.$fn.gettext('Rentals'),
        target: 'rentals',
        dataSource: {
          name: 'suggest_rental',
          parameters: {
          }
        },
        useOptionTemplateForSelectedOption: true,
        optionTemplate: '<UcSuggestRentalOption :option="option"></UcSuggestRentalOption>',
      },
      /*
      {
        text: gettext('Product Groups'),
        target: 'product_invoice_groups',
        options: productInvoiceGroups
      },
      */
      {
        text: this.$fn.gettext('Meter Groups'),
        target: 'meter_groups',
        options: _.filter(this.groups, { group_type: 'meter' })
      },
    ]
  }

  selectedFilterType!: any;
  selectedFilterValue!: any;
  selectedOperation!: any;
  filters!: any;

  async $$load_filters() {
    return []
  }

  includeWorksheets!: any;
  invoiceGroupFilters!: any;

  async $$load_invoiceGroupFilters() {
    return _.chain(this.productInvoiceGroups)
      .reduce((m, p) => (m[p.id] = true, m), {})
      .value()
  }

  operations!: any;
  taskStatusDismissed!: any;
  ux!: any;
  b_1045524669200057_clearable!: any;
  dataMembers = ['labelAlign', 'currentGeneratorTask', 'poller', 'item', 'formId', 'groups', 'productInvoiceGroups', 'filterOptionTemplates', 'filterTypes', 'selectedFilterType', 'selectedFilterValue', 'selectedOperation', 'filters', 'includeWorksheets', 'invoiceGroupFilters', 'operations', 'taskStatusDismissed', 'ux', 'b_1045524669200057_clearable'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: null,
        currentGeneratorTask: null,
        poller: null,
        item: null,
        formId: null,
        groups: null,
        productInvoiceGroups: null,
        filterOptionTemplates: {
          client: "UcSuggestClientOption",
          contract: "UcSuggestContractOption",
          rental: "UcSuggestRentalOption",
        }
        ,
        filterTypes: null,
        selectedFilterType: null,
        selectedFilterValue: null,
        selectedOperation: "include",
        filters: null,
        includeWorksheets: true,
        invoiceGroupFilters: null,
        operations: [{
          value: "include",
          text: this.$fn.pgettext("InvoiceGenerator", "Include"),
        }
          ,
        {
          value: "exclude",
          text: this.$fn.pgettext("InvoiceGenerator", "Exclude"),
        }
          ,
        ]
        ,
        taskStatusDismissed: false,
        ux: null,
        b_1045524669200057_clearable: false,
      },
    }
  }

  get canAddFilter() {
    if (this.loaded) {

      return this.selectedFilterType && this.selectedFilterValue && this.selectedOperation
    }
    return null;
  }

  get canSubmit() {
    if (this.loaded) {

      return this.forms.InvoiceGeneration &&
        this.forms.InvoiceGeneration.submittable &&
        (
          !this.currentGeneratorTask ||
          ['PENDING', 'STARTED'].indexOf(this.currentGeneratorTask.status) == -1
        )
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5182_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5182, alias=undefined
    return {
      path: "taskStatusDismissed",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2248226175642056_setData_5184_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5184, alias=undefined
    return {
      path: "currentGeneratorTask",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2248226175642056_setData_5184_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5184, alias=undefined
    return !!$event.data
  }

  async act_2248226175642056_crud_5180_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_2248226175642056_crud_5180, alias=submit
    return {
      objectType: "a_job",
      op: "upsert",
      data: { ...this.item, job_type: 'invoice_generation', ...this.getPayload() },
    }
  }

  async evh_2248226175642056_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_submit.executeFromDOM(this, event, scope);
  }

  async act_5891271350223090_script_5186_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5891271350223090_script_5186, alias=undefined
    this.filters.push({
      id: this.selectedFilterValue.id,
      type: this.selectedFilterType.target,
      op: this.selectedOperation,
      value: this.selectedFilterValue
    });
    this.filters = this.filters.slice();
    this.selectedFilterValue = null;
  }

  async act_5891271350223090_script_5186_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5891271350223090_script_5186, alias=undefined
    return {
      value: () => this.act_5891271350223090_script_5186_getActionArgs_value($event),
    }
  }

  async evh_5891271350223090_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5891271350223090_click.executeFromDOM(this, event, scope);
  }

  async act_1641744087697442_script_5188_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1641744087697442_script_5188, alias=undefined
    (
      _.remove(this.filters, p => p.id == $event.scope.filter.id && p.op === $event.scope.filter.op),
      this.filters = this.filters.slice()
    )
  }

  async act_1641744087697442_script_5188_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1641744087697442_script_5188, alias=undefined
    return {
      value: () => this.act_1641744087697442_script_5188_getActionArgs_value($event),
    }
  }

  async evh_1641744087697442_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1641744087697442_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5178: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5177: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2248226175642056_setData_5182: actions.SetDataAction;
    evh_2248226175642056_before_5181: actions.EventHandlerImpl;
    act_2248226175642056_setData_5184: actions.SetDataAction;
    evh_2248226175642056_success_5183: actions.EventHandlerImpl;
    act_2248226175642056_crud_5180: actions.CRUDAction;
    evh_2248226175642056_submit_5179: actions.EventHandlerImpl;
    evh_2248226175642056_submit: actions.EventHandlerGroup;
    act_5891271350223090_script_5186: actions.ScriptAction;
    evh_5891271350223090_click_5185: actions.EventHandlerImpl;
    evh_5891271350223090_click: actions.EventHandlerGroup;
    act_1641744087697442_script_5188: actions.ScriptAction;
    evh_1641744087697442_click_5187: actions.EventHandlerImpl;
    evh_1641744087697442_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5178 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5177 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5178,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5177],
      }
    );
    const act_2248226175642056_setData_5182 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5182_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_before_5181 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5182,
        event: "before",
        displayName: "setData",
      }
    );
    const act_2248226175642056_setData_5184 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5184_getActionArgs,
        when: this.act_2248226175642056_setData_5184_getWhen,
        events: [],
      }
    );
    const evh_2248226175642056_success_5183 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5184,
        event: "success",
        displayName: "setData",
      }
    );
    const act_2248226175642056_crud_5180 = new actions.CRUDAction(
      {
        actionArgs: this.act_2248226175642056_crud_5180_getActionArgs,
        displayName: "submit",
        events: [evh_2248226175642056_before_5181, evh_2248226175642056_success_5183],
      }
    );
    const evh_2248226175642056_submit_5179 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_crud_5180,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_2248226175642056_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_submit_5179],
      }
    );
    const act_5891271350223090_script_5186 = new actions.ScriptAction(
      {
        actionArgs: this.act_5891271350223090_script_5186_getActionArgs,
        events: [],
      }
    );
    const evh_5891271350223090_click_5185 = new actions.EventHandlerImpl(
      {
        action: act_5891271350223090_script_5186,
        event: "click",
        displayName: "script",
      }
    );
    const evh_5891271350223090_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5891271350223090_click_5185],
      }
    );
    const act_1641744087697442_script_5188 = new actions.ScriptAction(
      {
        actionArgs: this.act_1641744087697442_script_5188_getActionArgs,
        events: [],
      }
    );
    const evh_1641744087697442_click_5187 = new actions.EventHandlerImpl(
      {
        action: act_1641744087697442_script_5188,
        event: "click",
        displayName: "script",
      }
    );
    const evh_1641744087697442_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1641744087697442_click_5187],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5178,
      evh_7315092382398562_reload_5177,
      evh_7315092382398562_reload,
      act_2248226175642056_setData_5182,
      evh_2248226175642056_before_5181,
      act_2248226175642056_setData_5184,
      evh_2248226175642056_success_5183,
      act_2248226175642056_crud_5180,
      evh_2248226175642056_submit_5179,
      evh_2248226175642056_submit,
      act_5891271350223090_script_5186,
      evh_5891271350223090_click_5185,
      evh_5891271350223090_click,
      act_1641744087697442_script_5188,
      evh_1641744087697442_click_5187,
      evh_1641744087697442_click,
      submit: act_2248226175642056_crud_5180,
    }
  }
}

Vue.component("UcInvoiceGeneratorControls", UcInvoiceGeneratorControls);

</script>