<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    spacing="pr-3 py-1"
    alignItems="center"
    v-if="loaded"
  >
    <UcContractStatusBadge
      :value="contract.status"
    >
    </UcContractStatusBadge>
    <ItpBox
      class="ml-auto"
    >
      <ItpButton
        :key="state.value"
        size="sm"
        :variant="state.variant"
        :text="state.text"
        class="mx-2 border"
        v-if="canTransitionTo(state.value, contract.metadata)"
        v-for="state in states"
        @click.stop.prevent="evh_3943151158818434_clickStopPrevent($event, {state})"
      >
      </ItpButton>
      <ItpButton
        size="sm"
        variant="light"
        :text="'Unarchive...'|pgettext('SetContractStatus')"
        class="mx-2 border"
        v-if="contract.status === 'archived'"
        @click.stop.prevent="evh_2857579216270778_clickStopPrevent($event, {})"
      >
      </ItpButton>
    </ItpBox>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractStatusCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  canTransitionTo(...args: any[]) {
    const nextStatus = args[0]
    const md = args[1]
    if (md.next_statuses.length == 0) {
      return false;
    }
    return md.next_statuses.indexOf(nextStatus) > -1
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  states!: any;
  ux!: any;
  dataMembers = ['states', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        states: [{
          value: "approved",
          text: this.$fn.pgettext("SetContractStatus", "Approve..."),
          variant: "light",
        }
          ,
        {
          value: "signed",
          text: this.$fn.pgettext("SetContractStatus", "Sign..."),
          variant: "light",
        }
          ,
        {
          value: "rescinded",
          text: this.$fn.pgettext("SetContractStatus", "Rescind..."),
          variant: "light",
        }
          ,
        {
          value: "active",
          text: this.$fn.pgettext("SetContractStatus", "Activate..."),
          variant: "light",
        }
          ,
        {
          value: "terminated",
          text: this.$fn.pgettext("SetContractStatus", "Terminate..."),
          variant: "light",
        }
          ,
        {
          value: "ended",
          text: this.$fn.pgettext("SetContractStatus", "End..."),
          variant: "light",
        }
          ,
        {
          value: "archived",
          text: this.$fn.pgettext("SetContractStatus", "Archive..."),
          variant: "light",
        }
          ,
        ]
        ,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3943151158818434_emit_6854_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3943151158818434_emit_6854, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3943151158818434_close_6853_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3943151158818434_close_6853, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_3943151158818434_showModal_6852_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3943151158818434_showModal_6852, alias=undefined
    return {
      name: "ContractStatusTransition",
      props: {
        contract: this.contract,
        status: $event.scope.state.value,
      }
      ,
    }
  }

  async evh_3943151158818434_clickStopPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3943151158818434_clickStopPrevent.executeFromDOM(this, event, scope);
  }

  async act_2857579216270778_emit_6858_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2857579216270778_emit_6858, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2857579216270778_close_6857_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2857579216270778_close_6857, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_2857579216270778_showModal_6856_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2857579216270778_showModal_6856, alias=undefined
    return {
      name: "ContractStatusTransition",
      props: {
        contract: this.contract,
        status: "unarchive",
      }
      ,
    }
  }

  async evh_2857579216270778_clickStopPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2857579216270778_clickStopPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6850: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6849: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3943151158818434_emit_6854: actions.EmitAction;
    evh_3943151158818434_close_6853: actions.EventHandlerImpl;
    act_3943151158818434_showModal_6852: actions.ShowModalAction;
    evh_3943151158818434_clickStopPrevent_6851: actions.EventHandlerImpl;
    evh_3943151158818434_clickStopPrevent: actions.EventHandlerGroup;
    act_2857579216270778_emit_6858: actions.EmitAction;
    evh_2857579216270778_close_6857: actions.EventHandlerImpl;
    act_2857579216270778_showModal_6856: actions.ShowModalAction;
    evh_2857579216270778_clickStopPrevent_6855: actions.EventHandlerImpl;
    evh_2857579216270778_clickStopPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6850 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6849 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6850,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6849],
      }
    );
    const act_3943151158818434_emit_6854 = new actions.EmitAction(
      {
        actionArgs: this.act_3943151158818434_emit_6854_getActionArgs,
        events: [],
      }
    );
    const evh_3943151158818434_close_6853 = new actions.EventHandlerImpl(
      {
        action: act_3943151158818434_emit_6854,
        event: "close",
        when: this.evh_3943151158818434_close_6853_getWhen,
        displayName: "emit",
      }
    );
    const act_3943151158818434_showModal_6852 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3943151158818434_showModal_6852_getActionArgs,
        events: [evh_3943151158818434_close_6853],
      }
    );
    const evh_3943151158818434_clickStopPrevent_6851 = new actions.EventHandlerImpl(
      {
        action: act_3943151158818434_showModal_6852,
        event: "click.stop.prevent",
        displayName: "showModal",
      }
    );
    const evh_3943151158818434_clickStopPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_3943151158818434_clickStopPrevent_6851],
      }
    );
    const act_2857579216270778_emit_6858 = new actions.EmitAction(
      {
        actionArgs: this.act_2857579216270778_emit_6858_getActionArgs,
        events: [],
      }
    );
    const evh_2857579216270778_close_6857 = new actions.EventHandlerImpl(
      {
        action: act_2857579216270778_emit_6858,
        event: "close",
        when: this.evh_2857579216270778_close_6857_getWhen,
        displayName: "emit",
      }
    );
    const act_2857579216270778_showModal_6856 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2857579216270778_showModal_6856_getActionArgs,
        events: [evh_2857579216270778_close_6857],
      }
    );
    const evh_2857579216270778_clickStopPrevent_6855 = new actions.EventHandlerImpl(
      {
        action: act_2857579216270778_showModal_6856,
        event: "click.stop.prevent",
        displayName: "showModal",
      }
    );
    const evh_2857579216270778_clickStopPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2857579216270778_clickStopPrevent_6855],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6850,
      evh_7315092382398562_reload_6849,
      evh_7315092382398562_reload,
      act_3943151158818434_emit_6854,
      evh_3943151158818434_close_6853,
      act_3943151158818434_showModal_6852,
      evh_3943151158818434_clickStopPrevent_6851,
      evh_3943151158818434_clickStopPrevent,
      act_2857579216270778_emit_6858,
      evh_2857579216270778_close_6857,
      act_2857579216270778_showModal_6856,
      evh_2857579216270778_clickStopPrevent_6855,
      evh_2857579216270778_clickStopPrevent,
    }
  }
}

Vue.component("UcContractStatusCard", UcContractStatusCard);

</script>