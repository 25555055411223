<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpForm
    name="InvoiceGeneration"
    schema="CreateInvoiceGenerationTask"
    :readonly="item.locked"
    :id="formId"
    v-if="loaded && item"
    @submit="evh_2248226175642056_submit($event, {})"
  >
    <ItpFormGroup
      name="description"
      :label="'Description'|pgettext('InvoiceGenerator')"
      labelColsMd="6"
      contentColsMd="6"
      :labelAlign="labelAlign"
      required
      v-if="descriptionRequired"
    >
      <ItpFormInput
        name="description"
        v-model="item.description"
        required
      >
      </ItpFormInput>
    </ItpFormGroup>
    <HR
      v-if="descriptionRequired"
    >
    </HR>
    <ItpBox
      wrap
      direction="column"
      class="p-2 w-100"
    >
      <ItpText
        strong
        block
        :text="'Product Invoice Groups'|pgettext('InvoiceGenerator')"
        class="mb-2"
      >
      </ItpText>
      <ItpFormCheckbox
        :name="item.id"
        :key="item.id"
        v-model="invoiceGroupFilters[item.id]"
        :label="item.name"
        v-for="item in productInvoiceGroups"
      >
      </ItpFormCheckbox>
      <ItpFormCheckbox
        name="include_worksheet"
        v-model="includeWorksheets"
        :label="'Include works from worksheets'|pgettext('InvoiceGenerator')"
      >
      </ItpFormCheckbox>
    </ItpBox>
    <HR>
    </HR>
    <ItpPanel
      stateKey="InvoiceGenerator.panels.parameters"
    >
      <ItpText
        strong
        muted
        block
        :text="'Parameters'|pgettext('InvoiceGenerator')"
        slot="caption"
      >
      </ItpText>
      <ItpFormGroup
        name="billing_period_start"
        :label="'Billing Period Start'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="billing_period_start"
          v-model="item.billing_period_start"
          :placeholder="'Start of Period'|pgettext('InvoiceGenerator')"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="billing_period_end"
        :label="'Billing Period End'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="billing_period_end"
          v-model="item.billing_period_end"
          :placeholder="'End of Period'|pgettext('InvoiceGenerator')"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="invoice_date"
        :label="'Invoice Date'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="invoice_date"
          v-model="item.invoice_date"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="fulfillment_date"
        :label="'Fulfillment Date'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="fulfillment_date"
          v-model="item.fulfillment_date"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="due_date"
        :label="'Due Date'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormDatetime
          name="due_date"
          v-model="item.due_date"
          required
          :placeholder="'Due Date'|pgettext('InvoiceGenerator')"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="meter_readings_start_date"
        :label="'Meter Readings From'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
      >
        <ItpFormDatetime
          name="meter_readings_start_date"
          v-model="item.meter_readings_start_date"
          :placeholder="'Meter Readings From'|pgettext('InvoiceGenerator')"
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        name="invoice_tags"
        :label="'Invoice Tags'|pgettext('InvoiceGenerator')"
        labelColsMd="6"
        contentColsMd="6"
        :labelAlign="labelAlign"
      >
        <ItpSelect2
          name="invoice_tags"
          v-model="item.invoice_tags"
          taggable
          multiple
        >
        </ItpSelect2>
      </ItpFormGroup>
    </ItpPanel>
    <HR>
    </HR>
    <ItpPanel
      stateKey="InvoiceGenerator.panels.options"
    >
      <ItpText
        strong
        muted
        block
        :text="'Options'|pgettext('InvoiceGenerator')"
        slot="caption"
      >
      </ItpText>
      <fieldset>
        <ItpFormGroup>
          <ItpFormCheckbox
            name="include_zero_consumption"
            v-model="item.include_zero_consumption"
            :label="'Include zero consumptions'|pgettext('InvoiceGenerator')"
            required
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
      </fieldset>
      <fieldset>
        <ItpFormGroup
          :help="'Charge all consumptions, even minimal ones.'|gettext"
        >
          <ItpFormCheckbox
            name="ignore_meter_billing_thresholds"
            v-model="item.ignore_meter_billing_thresholds"
            :label="'Ignore meter billing thresholds.'|pgettext('InvoiceGenerator')"
            required
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
      </fieldset>
    </ItpPanel>
    <HR>
    </HR>
    <ItpButton
      type="submit"
      :form="forms.InvoiceGeneration && forms.InvoiceGeneration.id"
      :text="'Generate Invoices'|pgettext('InvoiceGenerator')"
      default
      :disabled="!canSubmit"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <TaskStatus
      :taskId="currentGeneratorTask.id"
      v-if="currentGeneratorTask"
    >
    </TaskStatus>
  </ItpForm>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetInvoiceGeneration extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "FieldsetInvoiceGeneration.formData");
    this.$$cache_includeWorksheets = new ComponentValueCache(this, "includeWorksheets", "FieldsetInvoiceGeneration.includeWorksheets");
    this.$$cache_invoiceGroupFilters = new ComponentValueCache(this, "invoiceGroupFilters", "FieldsetInvoiceGeneration.invoiceGroupFilters");
    this.$$cache_taskStatusDismissed = new ComponentValueCache(this, "taskStatusDismissed", "FieldsetInvoiceGeneration.taskStatusDismissed");
  }

  getPayload(...args: any[]) {
    {
      {
        return {
          contracts: this.contract ? [this.contract] : null,
          product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
            if (!!v) {
              arr.push(k);
            }
            return arr;
          }, []
          ),
          exclude_product_invoice_groups: _.reduce(this.invoiceGroupFilters, (arr, v, k) => {
            if (!v) {
              arr.push(k);
            }
            return arr;
          }, []
          ),
          skip_worksheets: !this.includeWorksheets
        }
      }
    }
  }

  updateStatus(...args: any[]) {
    _.extend(this.currentGeneratorTask, args[0])
  }

  @Prop({
    type: Boolean,
  })
  descriptionRequired!: boolean;

  @Watch('descriptionRequired')
  onDescription_required(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("descriptionRequired")
  }

  @Prop({
    type: String,
  })
  contract!: string;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  currentGeneratorTask!: any;
  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return {
      description: 'test',
      fulfillment_date: this.$fn.today(),
      due_date: null,
      billing_period_start: this.$fn.today(),
      billing_period_end: null,
      exchange_rates: [],
      service_type_flat_rate: true,
      service_type_measured: true,
      ignore_meter_billing_thresholds: false,
      meter_readings_start_date: null,
      invoice_tags: []
    }
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  productInvoiceGroups!: any;

  async $$load_productInvoiceGroups() {
    return this.$fn.fetch('list_product_invoice_group')
  }

  includeWorksheets!: any;
  invoiceGroupFilters!: any;

  async $$load_invoiceGroupFilters() {
    return _.chain(this.productInvoiceGroups)
      .reduce((m, p) => (m[p.id] = true, m), {})
      .value()
  }

  operations!: any;
  taskStatusDismissed!: any;
  ux!: any;
  dataMembers = ['currentGeneratorTask', 'labelAlign', 'item', 'formId', 'productInvoiceGroups', 'includeWorksheets', 'invoiceGroupFilters', 'operations', 'taskStatusDismissed', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        currentGeneratorTask: null,
        labelAlign: null,
        item: null,
        formId: null,
        productInvoiceGroups: null,
        includeWorksheets: true,
        invoiceGroupFilters: null,
        operations: [{
          value: "include",
          text: this.$fn.pgettext("InvoiceGenerator", "Include"),
        }
          ,
        {
          value: "exclude",
          text: this.$fn.pgettext("InvoiceGenerator", "Exclude"),
        }
          ,
        ]
        ,
        taskStatusDismissed: false,
        ux: null,
      },
    }
  }

  get canSubmit() {
    if (this.loaded) {

      return this.forms.InvoiceGeneration &&
        this.forms.InvoiceGeneration.submittable &&
        (
          !this.currentGeneratorTask ||
          ['PENDING', 'STARTED'].indexOf(this.currentGeneratorTask.status) == -1
        )
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5122_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5122, alias=undefined
    return {
      path: "taskStatusDismissed",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2248226175642056_setData_5124_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5124, alias=undefined
    return {
      path: "currentGeneratorTask",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2248226175642056_setData_5124_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5124, alias=undefined
    return !!$event.data
  }

  async act_2248226175642056_crud_5120_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_2248226175642056_crud_5120, alias=submit
    return {
      objectType: "a_job",
      op: "upsert",
      data: { ...this.item, job_type: 'invoice_generation', ...this.getPayload() },
    }
  }

  async evh_2248226175642056_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_submit.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5118: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5117: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2248226175642056_setData_5122: actions.SetDataAction;
    evh_2248226175642056_before_5121: actions.EventHandlerImpl;
    act_2248226175642056_setData_5124: actions.SetDataAction;
    evh_2248226175642056_success_5123: actions.EventHandlerImpl;
    act_2248226175642056_crud_5120: actions.CRUDAction;
    evh_2248226175642056_submit_5119: actions.EventHandlerImpl;
    evh_2248226175642056_submit: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5118 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5117 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5118,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5117],
      }
    );
    const act_2248226175642056_setData_5122 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5122_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_before_5121 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5122,
        event: "before",
        displayName: "setData",
      }
    );
    const act_2248226175642056_setData_5124 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5124_getActionArgs,
        when: this.act_2248226175642056_setData_5124_getWhen,
        events: [],
      }
    );
    const evh_2248226175642056_success_5123 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5124,
        event: "success",
        displayName: "setData",
      }
    );
    const act_2248226175642056_crud_5120 = new actions.CRUDAction(
      {
        actionArgs: this.act_2248226175642056_crud_5120_getActionArgs,
        displayName: "submit",
        events: [evh_2248226175642056_before_5121, evh_2248226175642056_success_5123],
      }
    );
    const evh_2248226175642056_submit_5119 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_crud_5120,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_2248226175642056_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_submit_5119],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5118,
      evh_7315092382398562_reload_5117,
      evh_7315092382398562_reload,
      act_2248226175642056_setData_5122,
      evh_2248226175642056_before_5121,
      act_2248226175642056_setData_5124,
      evh_2248226175642056_success_5123,
      act_2248226175642056_crud_5120,
      evh_2248226175642056_submit_5119,
      evh_2248226175642056_submit,
      submit: act_2248226175642056_crud_5120,
    }
  }
}

Vue.component("FieldsetInvoiceGeneration", FieldsetInvoiceGeneration);

</script>