"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Account = class Account extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid == null ? this.$fn.schemaDefaults('CreateAccountCommand') : this.$fn.fetch('read_account', { asResource: true, parameters: { id: this.oid } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.oid == null ?
                    this.$fn.gettext("New Cash or Bank Account") :
                    [this.item.snapshot("name"), this.$fn.gettext("Account")],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1374_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_1382_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_setData_1386_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1040167445267876_setData_1386_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1040167445267876_emit_1388_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "saved",
                value: $event.data,
            };
        });
    }
    act_1040167445267876_closeModal_1390_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_1392_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "accounts",
            };
        });
    }
    act_1040167445267876_crud_1384_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "account",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_reloadSlickgrid_1396_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "accounts",
            };
        });
    }
    evh_7789867021953041_saved(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_saved.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_1398_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1374 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1374_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1373 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1374,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1373],
        });
        const act_2248226175642056_closeModal_1376 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_1375 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_1376,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1375],
        });
        const act_8564662037462133_reloadComponentData_1378 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_1377 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_1378,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_1377],
        });
        const act_8564662037462133_closeComponent_1380 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_8564662037462133_deleted_1379 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeComponent_1380,
            event: "deleted",
            displayName: "closeComponent",
        });
        const act_8564662037462133_reloadSlickgrid_1382 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_1382_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_1381 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_1382,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_1379, evh_8564662037462133_deleted_1381],
        });
        const act_1040167445267876_setData_1386 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_1386_getActionArgs,
            when: this.act_1040167445267876_setData_1386_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_1385 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_1386,
            event: "success",
            displayName: "setData",
        });
        const act_1040167445267876_emit_1388 = new core_1.actions.EmitAction({
            actionArgs: this.act_1040167445267876_emit_1388_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1387 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_emit_1388,
            event: "success",
            displayName: "emit",
        });
        const act_1040167445267876_closeModal_1390 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_1390_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1389 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_1390,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_reloadSlickgrid_1392 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_1392_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1391 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_1392,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_crud_1384 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_1384_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_1385, evh_1040167445267876_success_1387, evh_1040167445267876_success_1389, evh_1040167445267876_success_1391],
        });
        const evh_1040167445267876_submit_1383 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_1384,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_1383],
        });
        const act_7789867021953041_closeModal_1394 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_7789867021953041_saved_1393 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_closeModal_1394,
            event: "saved",
            displayName: "closeModal",
        });
        const act_7789867021953041_reloadSlickgrid_1396 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_7789867021953041_reloadSlickgrid_1396_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_saved_1395 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_reloadSlickgrid_1396,
            event: "saved",
            displayName: "reloadSlickgrid",
        });
        const evh_7789867021953041_saved = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_saved_1393, evh_7789867021953041_saved_1395],
        });
        const act_1419464017721962_closeModal_1398 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_1398_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_1397 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_1398,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1397],
        });
        return {
            act_7315092382398562_closeModal_1374,
            evh_7315092382398562_close_1373,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_1376,
            evh_2248226175642056_close_1375,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_1378,
            evh_8564662037462133_reload_1377,
            evh_8564662037462133_reload,
            act_8564662037462133_closeComponent_1380,
            evh_8564662037462133_deleted_1379,
            act_8564662037462133_reloadSlickgrid_1382,
            evh_8564662037462133_deleted_1381,
            evh_8564662037462133_deleted,
            act_1040167445267876_setData_1386,
            evh_1040167445267876_success_1385,
            act_1040167445267876_emit_1388,
            evh_1040167445267876_success_1387,
            act_1040167445267876_closeModal_1390,
            evh_1040167445267876_success_1389,
            act_1040167445267876_reloadSlickgrid_1392,
            evh_1040167445267876_success_1391,
            act_1040167445267876_crud_1384,
            evh_1040167445267876_submit_1383,
            evh_1040167445267876_submit,
            act_7789867021953041_closeModal_1394,
            evh_7789867021953041_saved_1393,
            act_7789867021953041_reloadSlickgrid_1396,
            evh_7789867021953041_saved_1395,
            evh_7789867021953041_saved,
            act_1419464017721962_closeModal_1398,
            evh_1419464017721962_clickPreventStop_1397,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_crud_1384,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Account.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Account.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], Account.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Account.prototype, "onSize", null);
Account = __decorate([
    (0, vue_property_decorator_1.Component)()
], Account);
exports.default = Account;
vue_property_decorator_1.Vue.component("Account", Account);
