"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let TaskStatus = class TaskStatus extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['item', 'pollerInstance', 'ux', 'b_8564662037462133_variantMap', 'b_1889980785424776_options'];
    }
    beforeCreate() {
    }
    onPoll(...args) {
        return this.$fn
            .fetch('read_invoice_generation_task', { parameters: { id: this.taskId } })
            .then(data => {
            this.setData('item', data);
            return data;
        });
    }
    onTask_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("taskId");
    }
    $$load_pollerInstance() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.poller({ interval: 3000, callback: this.onPoll }).start();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            pollerInstance: null,
            ux: null,
            b_8564662037462133_variantMap: {
                PENDING: "warning",
                STARTED: "primary",
                FAILURE: "danger",
            },
            b_1889980785424776_options: {
                lineNumbers: true,
                matchBrackets: true,
                continueComments: "Enter",
            },
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_3343864469950044_script_4108_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.pollerInstance.manualPoll();
        });
    }
    act_3343864469950044_script_4108_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_3343864469950044_script_4108_getActionArgs_value($event),
            };
        });
    }
    evh_3343864469950044_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_4106 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_4105 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_4106,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_4105],
        });
        const act_3343864469950044_script_4108 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3343864469950044_script_4108_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_click_4107 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_script_4108,
            event: "click",
            displayName: "script",
        });
        const evh_3343864469950044_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_click_4107],
        });
        return {
            act_7315092382398562_reloadComponentData_4106,
            evh_7315092382398562_reload_4105,
            evh_7315092382398562_reload,
            act_3343864469950044_script_4108,
            evh_3343864469950044_click_4107,
            evh_3343864469950044_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], TaskStatus.prototype, "taskId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('taskId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], TaskStatus.prototype, "onTask_id", null);
TaskStatus = __decorate([
    (0, vue_property_decorator_1.Component)()
], TaskStatus);
exports.default = TaskStatus;
vue_property_decorator_1.Vue.component("TaskStatus", TaskStatus);
