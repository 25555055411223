<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Contract') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Contract number'|pgettext('Contract')"
        :content="contract.contract_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Contract type'|pgettext('Contract')"
      >
        <UcContractTypeBadge
          :value="contract.contract_type"
        >
        </UcContractTypeBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Status'|pgettext('Contract')"
      >
        <UcContractStatusCard
          :contract="contract"
          :reload="lastLoaded"
          @reload="evh_7349226261686150_reload($event, {})"
        >
        </UcContractStatusCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('Contract')"
        :content="contract.extras.description"
        v-if="contract.extras.description"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Folder number'|pgettext('Contract')"
        :content="contract.extras.folder_number"
        v-if="contract.extras.folder_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Reference number'|pgettext('Contract')"
        :content="contract.extras.reference_number"
        v-if="contract.extras.reference_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Lessor'|pgettext('Contract')"
        expandable
      >
        <ItpBox>
          <ItpLink
            :text="contract.company.name"
            :to="b_6379803495938895_to"
          >
          </ItpLink>
          <ItpButton
            :text="'Edit...'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_5937240531526321_clickPreventStop($event, {})"
          >
          </ItpButton>
        </ItpBox>
        <template
          slot="expander"
        >
          <UcContractCompanyCard
            :item="contract.company"
          >
          </UcContractCompanyCard>
        </template>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Tenant'|pgettext('Contract')"
        expandable
      >
        <ItpBox>
          <ItpLink
            :text="contract.counterparty.name"
            :to="b_8906220998092919_to"
          >
          </ItpLink>
          <ItpButton
            :text="'Edit...'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_7527485200259320_clickPreventStop($event, {})"
          >
          </ItpButton>
        </ItpBox>
        <template
          slot="expander"
        >
          <UcContractClientCard
            :item="contract.counterparty"
          >
          </UcContractClientCard>
        </template>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Rental'|pgettext('Contract')"
        expandable
        expanderCols="11"
      >
        <ItpBox>
          <ItpLink
            :text="contract.rental.name"
            :to="{
    name: 'app.rental',
    params: {
      id: contract.rental.id
    }
  }"
            v-if="contract.rental"
          >
          </ItpLink>
          <ItpButton
            :text="'Edit...'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_7127218473441393_clickPreventStop($event, {})"
          >
          </ItpButton>
        </ItpBox>
        <template
          slot="expander"
        >
          <UcContractRentalCard
            :rental="contract.rental"
          >
          </UcContractRentalCard>
          <UcContractRentalServices
            :rental="contract.rental"
            :reload="lastLoaded"
            @reload="evh_4198689235226718_reload($event, {})"
          >
          </UcContractRentalServices>
        </template>
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractGeneralInfo extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  ux!: any;
  b_6379803495938895_to!: any;

  async $$load_b_6379803495938895_to() {
    return {
      name: "app.company",
      params: {
        id: this.contract.company.id,
      }
      ,
    }
  }

  b_8906220998092919_to!: any;

  async $$load_b_8906220998092919_to() {
    return {
      name: "app.client",
      params: {
        id: this.contract.counterparty.id,
      }
      ,
    }
  }

  dataMembers = ['ux', 'b_6379803495938895_to', 'b_8906220998092919_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_6379803495938895_to: null,
        b_8906220998092919_to: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_6578_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_6578, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_6577_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6577, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_6576_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6576, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "general",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7349226261686150_emit_6580_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7349226261686150_emit_6580, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7349226261686150_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7349226261686150_reload.executeFromDOM(this, event, scope);
  }

  async act_5937240531526321_emit_6584_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5937240531526321_emit_6584, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5937240531526321_close_6583_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_5937240531526321_close_6583, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_5937240531526321_showModal_6582_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5937240531526321_showModal_6582, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "company",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_5937240531526321_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5937240531526321_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7527485200259320_emit_6588_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7527485200259320_emit_6588, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7527485200259320_close_6587_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7527485200259320_close_6587, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_7527485200259320_showModal_6586_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7527485200259320_showModal_6586, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "counterparty",
        contract: _.cloneDeep(this.contract),
      }
      ,
    }
  }

  async evh_7527485200259320_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7527485200259320_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7127218473441393_emit_6592_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7127218473441393_emit_6592, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7127218473441393_close_6591_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7127218473441393_close_6591, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_7127218473441393_showModal_6590_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7127218473441393_showModal_6590, alias=undefined
    return {
      name: "ContractEditor",
      props: {
        target: "rental",
        contract: this.contract.rental
          ? _.cloneDeep(this.contract)
          : _.extend(_.cloneDeep(this.contract), {
            rental: {
              id: null,
              name: null,
              number: null,
              description: null,
              house_number: null,
              inactive: false,
              location_code: null,
              lot_number: null,

            }
          }),
      }
      ,
    }
  }

  async evh_7127218473441393_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7127218473441393_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_4198689235226718_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4198689235226718_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6574: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6573: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_6578: actions.EmitAction;
    evh_8320016629450276_close_6577: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6576: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6575: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_7349226261686150_emit_6580: actions.EmitAction;
    evh_7349226261686150_reload_6579: actions.EventHandlerImpl;
    evh_7349226261686150_reload: actions.EventHandlerGroup;
    act_5937240531526321_emit_6584: actions.EmitAction;
    evh_5937240531526321_close_6583: actions.EventHandlerImpl;
    act_5937240531526321_showModal_6582: actions.ShowModalAction;
    evh_5937240531526321_clickPreventStop_6581: actions.EventHandlerImpl;
    evh_5937240531526321_clickPreventStop: actions.EventHandlerGroup;
    act_7527485200259320_emit_6588: actions.EmitAction;
    evh_7527485200259320_close_6587: actions.EventHandlerImpl;
    act_7527485200259320_showModal_6586: actions.ShowModalAction;
    evh_7527485200259320_clickPreventStop_6585: actions.EventHandlerImpl;
    evh_7527485200259320_clickPreventStop: actions.EventHandlerGroup;
    act_7127218473441393_emit_6592: actions.EmitAction;
    evh_7127218473441393_close_6591: actions.EventHandlerImpl;
    act_7127218473441393_showModal_6590: actions.ShowModalAction;
    evh_7127218473441393_clickPreventStop_6589: actions.EventHandlerImpl;
    evh_7127218473441393_clickPreventStop: actions.EventHandlerGroup;
    act_4198689235226718_reloadComponentData_6594: actions.ReloadComponentDataAction;
    evh_4198689235226718_reload_6593: actions.EventHandlerImpl;
    evh_4198689235226718_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6574 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6573 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6574,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6573],
      }
    );
    const act_8320016629450276_emit_6578 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_6578_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_6577 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_6578,
        event: "close",
        when: this.evh_8320016629450276_close_6577_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_6576 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6576_getActionArgs,
        events: [evh_8320016629450276_close_6577],
      }
    );
    const evh_8320016629450276_clickPreventStop_6575 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6576,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6575],
      }
    );
    const act_7349226261686150_emit_6580 = new actions.EmitAction(
      {
        actionArgs: this.act_7349226261686150_emit_6580_getActionArgs,
        events: [],
      }
    );
    const evh_7349226261686150_reload_6579 = new actions.EventHandlerImpl(
      {
        action: act_7349226261686150_emit_6580,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_7349226261686150_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7349226261686150_reload_6579],
      }
    );
    const act_5937240531526321_emit_6584 = new actions.EmitAction(
      {
        actionArgs: this.act_5937240531526321_emit_6584_getActionArgs,
        events: [],
      }
    );
    const evh_5937240531526321_close_6583 = new actions.EventHandlerImpl(
      {
        action: act_5937240531526321_emit_6584,
        event: "close",
        when: this.evh_5937240531526321_close_6583_getWhen,
        displayName: "emit",
      }
    );
    const act_5937240531526321_showModal_6582 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5937240531526321_showModal_6582_getActionArgs,
        events: [evh_5937240531526321_close_6583],
      }
    );
    const evh_5937240531526321_clickPreventStop_6581 = new actions.EventHandlerImpl(
      {
        action: act_5937240531526321_showModal_6582,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_5937240531526321_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5937240531526321_clickPreventStop_6581],
      }
    );
    const act_7527485200259320_emit_6588 = new actions.EmitAction(
      {
        actionArgs: this.act_7527485200259320_emit_6588_getActionArgs,
        events: [],
      }
    );
    const evh_7527485200259320_close_6587 = new actions.EventHandlerImpl(
      {
        action: act_7527485200259320_emit_6588,
        event: "close",
        when: this.evh_7527485200259320_close_6587_getWhen,
        displayName: "emit",
      }
    );
    const act_7527485200259320_showModal_6586 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7527485200259320_showModal_6586_getActionArgs,
        events: [evh_7527485200259320_close_6587],
      }
    );
    const evh_7527485200259320_clickPreventStop_6585 = new actions.EventHandlerImpl(
      {
        action: act_7527485200259320_showModal_6586,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7527485200259320_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7527485200259320_clickPreventStop_6585],
      }
    );
    const act_7127218473441393_emit_6592 = new actions.EmitAction(
      {
        actionArgs: this.act_7127218473441393_emit_6592_getActionArgs,
        events: [],
      }
    );
    const evh_7127218473441393_close_6591 = new actions.EventHandlerImpl(
      {
        action: act_7127218473441393_emit_6592,
        event: "close",
        when: this.evh_7127218473441393_close_6591_getWhen,
        displayName: "emit",
      }
    );
    const act_7127218473441393_showModal_6590 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7127218473441393_showModal_6590_getActionArgs,
        events: [evh_7127218473441393_close_6591],
      }
    );
    const evh_7127218473441393_clickPreventStop_6589 = new actions.EventHandlerImpl(
      {
        action: act_7127218473441393_showModal_6590,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7127218473441393_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7127218473441393_clickPreventStop_6589],
      }
    );
    const act_4198689235226718_reloadComponentData_6594 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_4198689235226718_reload_6593 = new actions.EventHandlerImpl(
      {
        action: act_4198689235226718_reloadComponentData_6594,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_4198689235226718_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_4198689235226718_reload_6593],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6574,
      evh_7315092382398562_reload_6573,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_6578,
      evh_8320016629450276_close_6577,
      act_8320016629450276_showModal_6576,
      evh_8320016629450276_clickPreventStop_6575,
      evh_8320016629450276_clickPreventStop,
      act_7349226261686150_emit_6580,
      evh_7349226261686150_reload_6579,
      evh_7349226261686150_reload,
      act_5937240531526321_emit_6584,
      evh_5937240531526321_close_6583,
      act_5937240531526321_showModal_6582,
      evh_5937240531526321_clickPreventStop_6581,
      evh_5937240531526321_clickPreventStop,
      act_7527485200259320_emit_6588,
      evh_7527485200259320_close_6587,
      act_7527485200259320_showModal_6586,
      evh_7527485200259320_clickPreventStop_6585,
      evh_7527485200259320_clickPreventStop,
      act_7127218473441393_emit_6592,
      evh_7127218473441393_close_6591,
      act_7127218473441393_showModal_6590,
      evh_7127218473441393_clickPreventStop_6589,
      evh_7127218473441393_clickPreventStop,
      act_4198689235226718_reloadComponentData_6594,
      evh_4198689235226718_reload_6593,
      evh_4198689235226718_reload,
    }
  }
}

Vue.component("UcContractGeneralInfo", UcContractGeneralInfo);

</script>