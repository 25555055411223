<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    class="page d-flex flex-column"
    v-if="item"
  >
    <ItpBadge
      :text="item.status"
      :value="item.status"
      :variantMap="b_8564662037462133_variantMap"
    >
    </ItpBadge>
    <ItpButton
      text="poll"
      @click="evh_3343864469950044_click($event, {})"
    >
    </ItpButton>
    <CodeMirror
      :options="b_1889980785424776_options"
      v-model="item.result_text"
      v-if="item.result_text"
    >
    </CodeMirror>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class TaskStatus extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  onPoll(...args: any[]) {
    return this.$fn
      .fetch('read_invoice_generation_task', { parameters: { id: this.taskId } })
      .then(data => {
        this.setData('item', data);
        return data;
      });
  }

  @Prop({
    required: true,
    type: String,
  })
  taskId!: string;

  @Watch('taskId')
  onTask_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("taskId")
  }

  item!: any;
  pollerInstance!: any;

  async $$load_pollerInstance() {
    return this.$fn.poller({ interval: 3000, callback: this.onPoll }).start()
  }

  ux!: any;
  b_8564662037462133_variantMap!: any;
  b_1889980785424776_options!: any;
  dataMembers = ['item', 'pollerInstance', 'ux', 'b_8564662037462133_variantMap', 'b_1889980785424776_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        pollerInstance: null,
        ux: null,
        b_8564662037462133_variantMap: {
          PENDING: "warning",
          STARTED: "primary",
          FAILURE: "danger",
        }
        ,
        b_1889980785424776_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
        }
        ,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_script_4108_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3343864469950044_script_4108, alias=undefined
    this.pollerInstance.manualPoll()
  }

  async act_3343864469950044_script_4108_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3343864469950044_script_4108, alias=undefined
    return {
      value: () => this.act_3343864469950044_script_4108_getActionArgs_value($event),
    }
  }

  async evh_3343864469950044_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4106: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4105: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3343864469950044_script_4108: actions.ScriptAction;
    evh_3343864469950044_click_4107: actions.EventHandlerImpl;
    evh_3343864469950044_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4106 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4105 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4106,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4105],
      }
    );
    const act_3343864469950044_script_4108 = new actions.ScriptAction(
      {
        actionArgs: this.act_3343864469950044_script_4108_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_click_4107 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_script_4108,
        event: "click",
        displayName: "script",
      }
    );
    const evh_3343864469950044_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_click_4107],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4106,
      evh_7315092382398562_reload_4105,
      evh_7315092382398562_reload,
      act_3343864469950044_script_4108,
      evh_3343864469950044_click_4107,
      evh_3343864469950044_click,
    }
  }
}

Vue.component("TaskStatus", TaskStatus);

</script>