<template>
<ItpPage
  no-header
  contentClass="p-0"
  no-primary-button
  no-close-button
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Client"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <MaterialTabs
    :closeButton="modal"
    :tabManager="tabManager"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <ItpBox
      alignItems="center"
      class="mr-3"
      slot="tabs-start"
    >
      <b-breadcrumb
        class="m-0 bg-white"
      >
        <b-breadcrumb-item
          :text="'Clients'|gettext"
          :to="b_4615685607825208_to"
        >
        </b-breadcrumb-item>
        <b-breadcrumb-item
          active
        >
          <strong>
            {{ item.snapshot('name') }}
          </strong>
        </b-breadcrumb-item>
      </b-breadcrumb>
      <ItpButton
        variant="default"
        icon="fa-sync-alt"
        :text="'Reload'|gettext"
        :spinning="actions.reload.isRunning"
        class="px-3"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
      <BtnSyncResource
        :resource="item"
        size="sm"
        class="ml-auto mr-2"
        v-if="loaded"
        @success="evh_6705964765214521_success($event, {})"
      >
      </BtnSyncResource>
    </ItpBox>
    <b-tab
      :title="'General'|gettext"
      class="container float-left"
    >
      <ItpRow>
        <ItpCol
          w="6"
        >
          <UcClientGeneral
            :client="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_5477420070495986_reload($event, {})"
          >
          </UcClientGeneral>
          <UcContactList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_6337614691287098_reload($event, {})"
          >
          </UcContactList>
          <UcEmployeeList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_2406129194387753_reload($event, {})"
          >
          </UcEmployeeList>
        </ItpCol>
        <ItpCol
          w="6"
        >
          <UcAddressList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_4071170783051919_reload($event, {})"
          >
          </UcAddressList>
          <UcClientBankAccounts
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_7713630524832656_reload($event, {})"
          >
          </UcClientBankAccounts>
          <UcCommentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
          >
          </UcCommentsList>
          <UcAttachmentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
          >
          </UcAttachmentsList>
        </ItpCol>
      </ItpRow>
    </b-tab>
    <b-tab
      :title="'Invoices'|pgettext('Client')"
      class="container-fluid h-100"
    >
      <UcClientInvoices
        :clientId="item.id"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(1)"
      >
      </UcClientInvoices>
    </b-tab>
    <b-tab
      :title="'Payments'|pgettext('Client')"
      class="container-fluid h-100"
    >
      <UcClientPayments
        :clientId="item.id"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(2)"
      >
      </UcClientPayments>
    </b-tab>
    <b-tab
      :title="'Statement'|pgettext('Client')"
      class="container-fluid h-100"
    >
      <UcClientStatement
        :clientId="item.id"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(3)"
      >
      </UcClientStatement>
    </b-tab>
  </MaterialTabs>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "client",
    path: "/clients/:id",
    component: "Client",
    params: [{
      name: "id",
      prop: "oid",
    }
      ,
    ]

  }
)

@Component()
export default class Client extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  tabManager!: any;

  async $$load_tabManager() {
    return new this.$app.utils.LazyTabManager()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_client', { parameters: { id: this.oid }, asResource: true })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_4615685607825208_to!: any;
  dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        tabManager: null,
        item: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          noHeader: true,
          contentClass: "p-0",
          noPrimaryButton: true,
          noCloseButton: true,
          size: "full-screen",
        }
        ,
        b_4615685607825208_to: {
          name: "app.clients",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid == null ? this.$fn.gettext("Client") : `${this.item.snapshot("name")}`,
        icon: this.$config.client.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1670_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1670, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_6705964765214521_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6705964765214521_success.executeFromDOM(this, event, scope);
  }

  async evh_5477420070495986_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
  }

  async evh_6337614691287098_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6337614691287098_reload.executeFromDOM(this, event, scope);
  }

  async evh_2406129194387753_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2406129194387753_reload.executeFromDOM(this, event, scope);
  }

  async evh_4071170783051919_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4071170783051919_reload.executeFromDOM(this, event, scope);
  }

  async evh_7713630524832656_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7713630524832656_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1670: actions.CloseModalAction;
    evh_7315092382398562_close_1669: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1672: actions.CloseModalAction;
    evh_2248226175642056_close_1671: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8320016629450276_reloadComponentData_1674: actions.ReloadComponentDataAction;
    evh_8320016629450276_clickPreventStop_1673: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_6705964765214521_reloadComponentData_1676: actions.ReloadComponentDataAction;
    evh_6705964765214521_success_1675: actions.EventHandlerImpl;
    evh_6705964765214521_success: actions.EventHandlerGroup;
    act_5477420070495986_reloadComponentData_1678: actions.ReloadComponentDataAction;
    evh_5477420070495986_reload_1677: actions.EventHandlerImpl;
    evh_5477420070495986_reload: actions.EventHandlerGroup;
    act_6337614691287098_reloadComponentData_1680: actions.ReloadComponentDataAction;
    evh_6337614691287098_reload_1679: actions.EventHandlerImpl;
    evh_6337614691287098_reload: actions.EventHandlerGroup;
    act_2406129194387753_reloadComponentData_1682: actions.ReloadComponentDataAction;
    evh_2406129194387753_reload_1681: actions.EventHandlerImpl;
    evh_2406129194387753_reload: actions.EventHandlerGroup;
    act_4071170783051919_reloadComponentData_1684: actions.ReloadComponentDataAction;
    evh_4071170783051919_reload_1683: actions.EventHandlerImpl;
    evh_4071170783051919_reload: actions.EventHandlerGroup;
    act_7713630524832656_reloadComponentData_1686: actions.ReloadComponentDataAction;
    evh_7713630524832656_reload_1685: actions.EventHandlerImpl;
    evh_7713630524832656_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1670 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1670_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1669 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1670,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1669],
      }
    );
    const act_2248226175642056_closeModal_1672 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1671 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1672,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1671],
      }
    );
    const act_8320016629450276_reloadComponentData_1674 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_8320016629450276_clickPreventStop_1673 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadComponentData_1674,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_1673],
      }
    );
    const act_6705964765214521_reloadComponentData_1676 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6705964765214521_success_1675 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_reloadComponentData_1676,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const evh_6705964765214521_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_6705964765214521_success_1675],
      }
    );
    const act_5477420070495986_reloadComponentData_1678 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5477420070495986_reload_1677 = new actions.EventHandlerImpl(
      {
        action: act_5477420070495986_reloadComponentData_1678,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5477420070495986_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5477420070495986_reload_1677],
      }
    );
    const act_6337614691287098_reloadComponentData_1680 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6337614691287098_reload_1679 = new actions.EventHandlerImpl(
      {
        action: act_6337614691287098_reloadComponentData_1680,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_6337614691287098_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6337614691287098_reload_1679],
      }
    );
    const act_2406129194387753_reloadComponentData_1682 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2406129194387753_reload_1681 = new actions.EventHandlerImpl(
      {
        action: act_2406129194387753_reloadComponentData_1682,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2406129194387753_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2406129194387753_reload_1681],
      }
    );
    const act_4071170783051919_reloadComponentData_1684 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_4071170783051919_reload_1683 = new actions.EventHandlerImpl(
      {
        action: act_4071170783051919_reloadComponentData_1684,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_4071170783051919_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_4071170783051919_reload_1683],
      }
    );
    const act_7713630524832656_reloadComponentData_1686 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7713630524832656_reload_1685 = new actions.EventHandlerImpl(
      {
        action: act_7713630524832656_reloadComponentData_1686,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7713630524832656_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7713630524832656_reload_1685],
      }
    );
    return {
      act_7315092382398562_closeModal_1670,
      evh_7315092382398562_close_1669,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1672,
      evh_2248226175642056_close_1671,
      evh_2248226175642056_close,
      act_8320016629450276_reloadComponentData_1674,
      evh_8320016629450276_clickPreventStop_1673,
      evh_8320016629450276_clickPreventStop,
      act_6705964765214521_reloadComponentData_1676,
      evh_6705964765214521_success_1675,
      evh_6705964765214521_success,
      act_5477420070495986_reloadComponentData_1678,
      evh_5477420070495986_reload_1677,
      evh_5477420070495986_reload,
      act_6337614691287098_reloadComponentData_1680,
      evh_6337614691287098_reload_1679,
      evh_6337614691287098_reload,
      act_2406129194387753_reloadComponentData_1682,
      evh_2406129194387753_reload_1681,
      evh_2406129194387753_reload,
      act_4071170783051919_reloadComponentData_1684,
      evh_4071170783051919_reload_1683,
      evh_4071170783051919_reload,
      act_7713630524832656_reloadComponentData_1686,
      evh_7713630524832656_reload_1685,
      evh_7713630524832656_reload,
      reload: act_8320016629450276_reloadComponentData_1674,
    }
  }
}

Vue.component("Client", Client);

</script>