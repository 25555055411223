"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MaterialTabs = class MaterialTabs extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onClose_button(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("closeButton");
    }
    onTab_manager(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("tabManager");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    act_7315092382398562_emit_1886_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "input",
                value: {
                    tabIndex: $event.originalEvent,
                },
            };
        });
    }
    act_7315092382398562_script_1888_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.tabManager.visited($event.originalEvent, true);
        });
    }
    act_7315092382398562_script_1888_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_7315092382398562_script_1888_getActionArgs_value($event),
            };
        });
    }
    act_7315092382398562_script_1888_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!this.tabManager;
        });
    }
    evh_7315092382398562_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_input.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_emit_1890_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "close",
            };
        });
    }
    evh_1548630417156826_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_emit_1886 = new core_1.actions.EmitAction({
            actionArgs: this.act_7315092382398562_emit_1886_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_input_1885 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_emit_1886,
            event: "input",
            displayName: "emit",
        });
        const act_7315092382398562_script_1888 = new core_1.actions.ScriptAction({
            actionArgs: this.act_7315092382398562_script_1888_getActionArgs,
            when: this.act_7315092382398562_script_1888_getWhen,
            events: [],
        });
        const evh_7315092382398562_input_1887 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_script_1888,
            event: "input",
            displayName: "script",
        });
        const evh_7315092382398562_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_input_1885, evh_7315092382398562_input_1887],
        });
        const act_1548630417156826_emit_1890 = new core_1.actions.EmitAction({
            actionArgs: this.act_1548630417156826_emit_1890_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_click_1889 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_emit_1890,
            event: "click",
            displayName: "emit",
        });
        const evh_1548630417156826_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_click_1889],
        });
        return {
            act_7315092382398562_emit_1886,
            evh_7315092382398562_input_1885,
            act_7315092382398562_script_1888,
            evh_7315092382398562_input_1887,
            evh_7315092382398562_input,
            act_1548630417156826_emit_1890,
            evh_1548630417156826_click_1889,
            evh_1548630417156826_click,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], MaterialTabs.prototype, "closeButton", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('closeButton'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MaterialTabs.prototype, "onClose_button", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], MaterialTabs.prototype, "tabManager", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('tabManager'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MaterialTabs.prototype, "onTab_manager", null);
MaterialTabs = __decorate([
    (0, vue_property_decorator_1.Component)()
], MaterialTabs);
exports.default = MaterialTabs;
vue_property_decorator_1.Vue.component("MaterialTabs", MaterialTabs);
