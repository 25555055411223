<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Template"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="d-flex flex-column h-100 mr-3 py-3"
    v-if="item"
  >
    <DIV
      class="w-100 d-flex align-items-center mb-3"
    >
      <H4
        class="mr-3 mb-0"
      >
        {{ item.snapshot('name') }}
      </H4>
      <ItpButton
        variant="primary"
        :spinning="actions.save.isRunning"
        icon="fa-save"
        :text="'Save'|pgettext('Templates')"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
      <ItpResourceMeta
        :item="item"
        class="ml-auto"
      >
      </ItpResourceMeta>
    </DIV>
    <DIV
      class="h-100 overflow-hidden"
    >
      <splitpanes
        class="default-theme"
      >
        <pane
          min-size="30"
        >
          <CardTabs>
            <b-tab
              :title="'General'|pgettext('Templates')"
              class="h-100 position-relative overflow-auto"
            >
              <ItpForm
                name="template"
                id="frmTemplate"
              >
                <ItpFormGroup
                  name="name"
                  :label="'Name'|pgettext('Templates')"
                  row
                  labelColsMd="4"
                  contentColsMd="8"
                >
                  <ItpFormInput
                    name="name"
                    size="md"
                    v-model="item.name"
                  >
                  </ItpFormInput>
                </ItpFormGroup>
                <ItpFormGroup
                  name="notes"
                  :label="'Notes'|pgettext('Templates')"
                  row
                  labelColsMd="4"
                  contentColsMd="8"
                >
                  <ItpFormTextarea
                    name="notes"
                    size="md"
                    v-model="item.notes"
                  >
                  </ItpFormTextarea>
                </ItpFormGroup>
                <HR
                  v-if="item.template_type === 'mjml'"
                >
                </HR>
                <ItpFormGroup
                  name="subject"
                  :label="'Subject'|pgettext('email')"
                  row
                  labelColsMd="4"
                  contentColsMd="8"
                  :description="'The subject of the email sent to the client. This is a template definition, interpolate expressions can be used here.'|pgettext('Templates')"
                  v-if="item.template_type === 'mjml'"
                >
                  <ItpFormInput
                    name="subject"
                    size="md"
                    v-model="item.subject"
                  >
                  </ItpFormInput>
                </ItpFormGroup>
              </ItpForm>
            </b-tab>
            <b-tab
              :title="'Body template'|pgettext('Templates')"
              class="h-100 position-relative"
            >
              <CodeMirror
                mode="html"
                :options="b_3418904655244121_options"
                v-model="item.content"
              >
              </CodeMirror>
            </b-tab>
          </CardTabs>
        </pane>
        <pane
          min-size="30"
        >
          <CardTabs>
            <b-tab
              :title="'Preview'|pgettext('Templates')"
              class="h-100 position-relative"
            >
              <DIV
                class="d-flex flex-column h-100"
              >
                <ItpIFrame
                  :html="previewData"
                  class="h-100 w-100 border-0 mb-3"
                  v-if="sampleData"
                >
                </ItpIFrame>
                <DIV
                  class="h-100 d-flex"
                  v-if="!sampleData"
                >
                  <b-alert
                    show
                    variant="primary"
                    class="m-auto"
                  >
                    {{ 'Generate HTML previews using the data loaded/entered at the Data tab.'|pgettext('Templates') }}
                  </b-alert>
                </DIV>
                <DIV
                  class="w-100 form-row"
                  v-if="item && item.template_type === 'mjml'"
                >
                  <DIV
                    class="col"
                  >
                    <ItpFormInput
                      type="email"
                      name="email"
                      v-model="emailAddress"
                    >
                    </ItpFormInput>
                  </DIV>
                  <DIV
                    class="col-auto"
                  >
                    <ItpButton
                      icon="fa-envelope"
                      :text="'Send email'|pgettext('Templates')"
                      variant="primary"
                      :spinning="actions.sendmail.isRunning"
                      :disabled="!previewData"
                      @click.prevent.stop="evh_8399737542760576_clickPreventStop($event, {})"
                    >
                    </ItpButton>
                  </DIV>
                </DIV>
              </DIV>
              <ItpButton
                icon="fa-file-invoice"
                :text="'Render'|pgettext('Templates')"
                variant="primary"
                :spinning="actions.renderPreview.isRunning"
                :disabled="!sampleData"
                class="position-absolute m-3"
                :style="b_8695782439888834_style"
                @click.prevent.stop="evh_8695782439888834_clickPreventStop($event, {})"
              >
              </ItpButton>
            </b-tab>
            <b-tab
              :title="'Data'|pgettext('Templates')"
              class="h-100 overflow-hidden d-flex flex-column"
            >
              <ItpFormSelect2
                name="reference_number"
                v-model="sampleDataSearchResult"
                :dataSource="b_2407519033660513_dataSource"
                valueField="id"
                textField="data.reference_number"
                :placeholder="'Search data'|pgettext('Templates')"
                class="mb-3"
              >
              </ItpFormSelect2>
              <CodeMirror
                mode="json"
                :options="b_5419303415570863_options"
                v-model="sampleData"
                class="h-100"
              >
              </CodeMirror>
            </b-tab>
          </CardTabs>
        </pane>
      </splitpanes>
    </DIV>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "templates.template",
    path: ":id",
    component: "Template",
    params: [{
      name: "id",
      prop: "resourceId",
    }
      ,
    ]

  }
)

@Component()
export default class Template extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_sampleData = new ComponentValueCache(this, "sampleData", "Template.sampleData");
    this.$$cache_emailAddress = new ComponentValueCache(this, "emailAddress", "Template.emailAddress");
  }

  @Prop({
    required: true,
    type: String,
  })
  resourceId!: string;

  @Watch('resourceId')
  onResource_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("resourceId")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_template', { asResource: true, parameters: { id: this.resourceId } })
  }

  sampleDataSearchResult!: any;
  sampleData!: any;
  previewData!: any;
  emailAddress!: any;

  async $$load_emailAddress() {
    return this.$app.auth.user.email
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_3418904655244121_options!: any;
  b_8695782439888834_style!: any;
  b_2407519033660513_dataSource!: any;
  b_5419303415570863_options!: any;
  dataMembers = ['item', 'sampleDataSearchResult', 'sampleData', 'previewData', 'emailAddress', 'ux', 'b_7315092382398562_modalBindings', 'b_3418904655244121_options', 'b_8695782439888834_style', 'b_2407519033660513_dataSource', 'b_5419303415570863_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        sampleDataSearchResult: null,
        sampleData: "{ }",
        previewData: null,
        emailAddress: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "full-screen",
        }
        ,
        b_3418904655244121_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
          extraKeys: {
            "Ctrl-Q": "toggleComment",
          }
          ,
        }
        ,
        b_8695782439888834_style: {
          top: 0,
          right: 0,
        }
        ,
        b_2407519033660513_dataSource: {
          name: "get_suggestions",
          parameters: {
            type: "dunning-letter",
          }
          ,
        }
        ,
        b_5419303415570863_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
          extraKeys: {
            "Ctrl-Q": "toggleComment",
          }
          ,
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.item && this.item.id ?
          this.$fn.gettext('Create template') :
          `${this.$fn.gettext('Edit template')} / ${this.item.name}`,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4150_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4150, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_setData_4154_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8320016629450276_setData_4154, alias=undefined
    return {
      path: "item",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_8320016629450276_success_4153_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_success_4153, alias=undefined
    return $event.data.response.data
  }

  async act_8320016629450276_emit_4156_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_4156, alias=undefined
    return {
      event: "TemplateChanged",
      value: $event.data.response.data,
    }
  }

  async evh_8320016629450276_success_4155_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_success_4155, alias=undefined
    return $event.data.response.data
  }

  async act_8320016629450276_request_4152_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8320016629450276_request_4152, alias=save
    return {
      operation: "update_template",
      data: this.item,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8399737542760576_notification_4160_getActionArgs($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_8399737542760576_notification_4160, alias=undefined
    return {
      message: this.$fn.pgettext("Templates", "Email has been sent."),
    }
  }

  async act_8399737542760576_request_4158_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8399737542760576_request_4158, alias=sendmail
    return {
      operation: "sendmail_template_test",
      data: {
        template_type: this.item.template_type,
        template: this.item.content,
        data: JSON.parse(this.sampleData),
        subject: this.item.subject,
        email: this.emailAddress
      },
    }
  }

  async evh_8399737542760576_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8399737542760576_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8695782439888834_setData_4164_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8695782439888834_setData_4164, alias=undefined
    return {
      path: "previewData",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8695782439888834_request_4162_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8695782439888834_request_4162, alias=renderPreview
    return {
      operation: "render_template_test_html",
      data: {
        template_type: this.item.template_type,
        template: this.item.content,
        subject: this.item.subject,
        data: JSON.parse(this.sampleData)
      },
    }
  }

  async evh_8695782439888834_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8695782439888834_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4150: actions.CloseModalAction;
    evh_7315092382398562_close_4149: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_8320016629450276_setData_4154: actions.SetDataAction;
    evh_8320016629450276_success_4153: actions.EventHandlerImpl;
    act_8320016629450276_emit_4156: actions.EmitAction;
    evh_8320016629450276_success_4155: actions.EventHandlerImpl;
    act_8320016629450276_request_4152: actions.RequestAction;
    evh_8320016629450276_clickPreventStop_4151: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_8399737542760576_notification_4160: actions.NotificationAction;
    evh_8399737542760576_success_4159: actions.EventHandlerImpl;
    act_8399737542760576_request_4158: actions.RequestAction;
    evh_8399737542760576_clickPreventStop_4157: actions.EventHandlerImpl;
    evh_8399737542760576_clickPreventStop: actions.EventHandlerGroup;
    act_8695782439888834_setData_4164: actions.SetDataAction;
    evh_8695782439888834_success_4163: actions.EventHandlerImpl;
    act_8695782439888834_request_4162: actions.RequestAction;
    evh_8695782439888834_clickPreventStop_4161: actions.EventHandlerImpl;
    evh_8695782439888834_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4150 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4150_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4149 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4150,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4149],
      }
    );
    const act_8320016629450276_setData_4154 = new actions.SetDataAction(
      {
        actionArgs: this.act_8320016629450276_setData_4154_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_success_4153 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_setData_4154,
        event: "success",
        when: this.evh_8320016629450276_success_4153_getWhen,
        displayName: "setData",
      }
    );
    const act_8320016629450276_emit_4156 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_4156_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_success_4155 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_4156,
        event: "success",
        when: this.evh_8320016629450276_success_4155_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_request_4152 = new actions.RequestAction(
      {
        actionArgs: this.act_8320016629450276_request_4152_getActionArgs,
        displayName: "save",
        events: [evh_8320016629450276_success_4153, evh_8320016629450276_success_4155],
      }
    );
    const evh_8320016629450276_clickPreventStop_4151 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_request_4152,
        event: "click.prevent.stop",
        displayName: "save",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_4151],
      }
    );
    const act_8399737542760576_notification_4160 = new actions.NotificationAction(
      {
        actionArgs: this.act_8399737542760576_notification_4160_getActionArgs,
        events: [],
      }
    );
    const evh_8399737542760576_success_4159 = new actions.EventHandlerImpl(
      {
        action: act_8399737542760576_notification_4160,
        event: "success",
        displayName: "notification",
      }
    );
    const act_8399737542760576_request_4158 = new actions.RequestAction(
      {
        actionArgs: this.act_8399737542760576_request_4158_getActionArgs,
        displayName: "sendmail",
        events: [evh_8399737542760576_success_4159],
      }
    );
    const evh_8399737542760576_clickPreventStop_4157 = new actions.EventHandlerImpl(
      {
        action: act_8399737542760576_request_4158,
        event: "click.prevent.stop",
        displayName: "sendmail",
      }
    );
    const evh_8399737542760576_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8399737542760576_clickPreventStop_4157],
      }
    );
    const act_8695782439888834_setData_4164 = new actions.SetDataAction(
      {
        actionArgs: this.act_8695782439888834_setData_4164_getActionArgs,
        events: [],
      }
    );
    const evh_8695782439888834_success_4163 = new actions.EventHandlerImpl(
      {
        action: act_8695782439888834_setData_4164,
        event: "success",
        displayName: "setData",
      }
    );
    const act_8695782439888834_request_4162 = new actions.RequestAction(
      {
        actionArgs: this.act_8695782439888834_request_4162_getActionArgs,
        displayName: "renderPreview",
        events: [evh_8695782439888834_success_4163],
      }
    );
    const evh_8695782439888834_clickPreventStop_4161 = new actions.EventHandlerImpl(
      {
        action: act_8695782439888834_request_4162,
        event: "click.prevent.stop",
        displayName: "renderPreview",
      }
    );
    const evh_8695782439888834_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8695782439888834_clickPreventStop_4161],
      }
    );
    return {
      act_7315092382398562_closeModal_4150,
      evh_7315092382398562_close_4149,
      evh_7315092382398562_close,
      act_8320016629450276_setData_4154,
      evh_8320016629450276_success_4153,
      act_8320016629450276_emit_4156,
      evh_8320016629450276_success_4155,
      act_8320016629450276_request_4152,
      evh_8320016629450276_clickPreventStop_4151,
      evh_8320016629450276_clickPreventStop,
      act_8399737542760576_notification_4160,
      evh_8399737542760576_success_4159,
      act_8399737542760576_request_4158,
      evh_8399737542760576_clickPreventStop_4157,
      evh_8399737542760576_clickPreventStop,
      act_8695782439888834_setData_4164,
      evh_8695782439888834_success_4163,
      act_8695782439888834_request_4162,
      evh_8695782439888834_clickPreventStop_4161,
      evh_8695782439888834_clickPreventStop,
      save: act_8320016629450276_request_4152,
      sendmail: act_8399737542760576_request_4158,
      renderPreview: act_8695782439888834_request_4162,
    }
  }
}

Vue.component("Template", Template);

</script>