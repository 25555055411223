"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "dunningLetter",
    path: "/dunning-letters/:id",
    component: "DunningLetter"
});
let DunningLetter = class DunningLetter extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'templates', 'emailTemplates', 'htmlTemplates', 'sendmail', 'printdoc', 'ux', 'b_7315092382398562_modalBindings', 'b_7495010493811270_style', 'b_6865942025371558_style', 'b_3273883411533724_options', 'b_2771751501651205_columns'];
    }
    beforeCreate() {
    }
    onId(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("id");
    }
    onPayload(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payload");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.payload || (this.id !== null
                ? this.$fn.fetch('read_dunning_letter', { asResource: true, parameters: { id: this.id } })
                : this.$fn.schemaDefaults('DunningLetter'));
        });
    }
    $$load_templates() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_template');
        });
    }
    $$load_emailTemplates() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.filter(this.templates, {
                object_type: 'dunning_letter',
                template_type: 'mjml'
            });
        });
    }
    $$load_htmlTemplates() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.filter(this.templates, {
                object_type: 'dunning_letter',
                template_type: 'html'
            });
        });
    }
    $$load_sendmail() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                email: _.isArray(this.item.customer_data.emails)
                    ? _.join(this.item.customer_data.emails, '; ')
                    : this.item.customer_data.emails,
                template: _.get(_.first(this.emailTemplates), 'id')
            };
        });
    }
    $$load_printdoc() {
        return __awaiter(this, void 0, void 0, function* () {
            return { template: _.get(_.first(this.htmlTemplates), 'id') };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            templates: null,
            emailTemplates: null,
            htmlTemplates: null,
            sendmail: null,
            printdoc: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "xl",
            },
            b_7495010493811270_style: {
                minWidth: "250px",
            },
            b_6865942025371558_style: {
                minWidth: "250px",
            },
            b_3273883411533724_options: [{
                    value: null,
                    text: "-",
                },
                {
                    value: "sent",
                    text: this.$fn.pgettext("DunningLetterStatus", "Sent"),
                },
                {
                    value: "delivered",
                    text: this.$fn.pgettext("DunningLetterStatus", "Delivered"),
                },
                {
                    value: "error",
                    text: this.$fn.pgettext("DunningLetterStatus", "Error"),
                },
            ],
            b_2771751501651205_columns: [{
                    name: this.$fn.gettext("Invoice number"),
                    field: "invoice_number",
                },
                {
                    name: this.$fn.gettext("Completion date"),
                    field: "invoice_data.fulfillment_date",
                    textAlign: "right",
                    type: "date",
                },
                {
                    name: this.$fn.gettext("Due date"),
                    field: "invoice_data.due_date",
                    textAlign: "right",
                    type: "date",
                },
                {
                    name: this.$fn.gettext("Amount"),
                    field: "invoice_data.amount",
                    textAlign: "right",
                    type: "number",
                },
                {
                    name: this.$fn.gettext("Paid"),
                    field: "invoice_data.paid",
                    textAlign: "right",
                    type: "number",
                },
                {
                    name: this.$fn.gettext("Debt"),
                    field: "invoice_data.debt",
                    textAlign: "right",
                    type: "number",
                },
            ],
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: ([this.item.snapshot('reference_number') || this.$fn.gettext('Dunning Letter')]),
                icon: this.$config.dunningLetter.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5024_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_3943151158818434_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3943151158818434_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8518026866742051_closeComponent_5032_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_8518026866742051_reloadSlickgrid_5034_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_8518026866742051_request_5030_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_dunning_letter",
                data: this.item,
            };
        });
    }
    act_8518026866742051_request_5030_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Please confirm delete."),
                btnOk: this.$fn.gettext("Delete"),
            };
        });
    }
    evh_8518026866742051_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8518026866742051_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_2492577004902623_script_5036_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.sendMailDropdown.hide(true);
        });
    }
    act_2492577004902623_script_5036_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_2492577004902623_script_5036_getActionArgs_value($event),
            };
        });
    }
    act_2492577004902623_reloadSlickgrid_5040_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    evh_2492577004902623_completed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2492577004902623_completed.executeFromDOM(this, event, scope);
        });
    }
    act_4810867692521497_script_5042_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.generatePdfDropdown.hide(true);
        });
    }
    act_4810867692521497_script_5042_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_4810867692521497_script_5042_getActionArgs_value($event),
            };
        });
    }
    act_4810867692521497_reloadSlickgrid_5046_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    evh_4810867692521497_completed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4810867692521497_completed.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_setData_5050_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: new this.$fn.Resource($event.data.response.data),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7789867021953041_success_5049_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.response.data;
        });
    }
    act_7789867021953041_form_5052_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "dunningLetter",
                reset: true,
            };
        });
    }
    act_7789867021953041_reloadSlickgrid_5054_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_7789867021953041_request_5048_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_dunning_letter",
                data: this.item,
            };
        });
    }
    evh_7789867021953041_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5024 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5024_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5023 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5024,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5023],
        });
        const act_2248226175642056_closeComponent_5026 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_5025 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_5026,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_5025],
        });
        const act_3943151158818434_reloadComponentData_5028 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reloadData",
            events: [],
        });
        const evh_3943151158818434_clickPreventStop_5027 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_reloadComponentData_5028,
            event: "click.prevent.stop",
            displayName: "reloadData",
        });
        const evh_3943151158818434_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3943151158818434_clickPreventStop_5027],
        });
        const act_8518026866742051_closeComponent_5032 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_8518026866742051_closeComponent_5032_getActionArgs,
            events: [],
        });
        const evh_8518026866742051_success_5031 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_closeComponent_5032,
            event: "success",
            displayName: "closeComponent",
        });
        const act_8518026866742051_reloadSlickgrid_5034 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8518026866742051_reloadSlickgrid_5034_getActionArgs,
            events: [],
        });
        const evh_8518026866742051_success_5033 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_reloadSlickgrid_5034,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_8518026866742051_request_5030 = new core_1.actions.RequestAction({
            actionArgs: this.act_8518026866742051_request_5030_getActionArgs,
            confirm: this.act_8518026866742051_request_5030_getConfirm,
            displayName: "delete",
            events: [evh_8518026866742051_success_5031, evh_8518026866742051_success_5033],
        });
        const evh_8518026866742051_clickPreventStop_5029 = new core_1.actions.EventHandlerImpl({
            action: act_8518026866742051_request_5030,
            event: "click.prevent.stop",
            displayName: "delete",
        });
        const evh_8518026866742051_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8518026866742051_clickPreventStop_5029],
        });
        const act_2492577004902623_script_5036 = new core_1.actions.ScriptAction({
            actionArgs: this.act_2492577004902623_script_5036_getActionArgs,
            events: [],
        });
        const evh_2492577004902623_completed_5035 = new core_1.actions.EventHandlerImpl({
            action: act_2492577004902623_script_5036,
            event: "completed",
            displayName: "script",
        });
        const act_2492577004902623_reloadComponentData_5038 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2492577004902623_completed_5037 = new core_1.actions.EventHandlerImpl({
            action: act_2492577004902623_reloadComponentData_5038,
            event: "completed",
            displayName: "reloadComponentData",
        });
        const act_2492577004902623_reloadSlickgrid_5040 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2492577004902623_reloadSlickgrid_5040_getActionArgs,
            events: [],
        });
        const evh_2492577004902623_completed_5039 = new core_1.actions.EventHandlerImpl({
            action: act_2492577004902623_reloadSlickgrid_5040,
            event: "completed",
            displayName: "reloadSlickgrid",
        });
        const evh_2492577004902623_completed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2492577004902623_completed_5035, evh_2492577004902623_completed_5037, evh_2492577004902623_completed_5039],
        });
        const act_4810867692521497_script_5042 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4810867692521497_script_5042_getActionArgs,
            events: [],
        });
        const evh_4810867692521497_completed_5041 = new core_1.actions.EventHandlerImpl({
            action: act_4810867692521497_script_5042,
            event: "completed",
            displayName: "script",
        });
        const act_4810867692521497_reloadComponentData_5044 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_4810867692521497_completed_5043 = new core_1.actions.EventHandlerImpl({
            action: act_4810867692521497_reloadComponentData_5044,
            event: "completed",
            displayName: "reloadComponentData",
        });
        const act_4810867692521497_reloadSlickgrid_5046 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_4810867692521497_reloadSlickgrid_5046_getActionArgs,
            events: [],
        });
        const evh_4810867692521497_completed_5045 = new core_1.actions.EventHandlerImpl({
            action: act_4810867692521497_reloadSlickgrid_5046,
            event: "completed",
            displayName: "reloadSlickgrid",
        });
        const evh_4810867692521497_completed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4810867692521497_completed_5041, evh_4810867692521497_completed_5043, evh_4810867692521497_completed_5045],
        });
        const act_7789867021953041_setData_5050 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_5050_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_5049 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_5050,
            event: "success",
            when: this.evh_7789867021953041_success_5049_getWhen,
            displayName: "setData",
        });
        const act_7789867021953041_form_5052 = new core_1.actions.FormAction({
            actionArgs: this.act_7789867021953041_form_5052_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_5051 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_form_5052,
            event: "success",
            displayName: "form",
        });
        const act_7789867021953041_reloadSlickgrid_5054 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_7789867021953041_reloadSlickgrid_5054_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_5053 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_reloadSlickgrid_5054,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_7789867021953041_request_5048 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_5048_getActionArgs,
            displayName: "save",
            events: [evh_7789867021953041_success_5049, evh_7789867021953041_success_5051, evh_7789867021953041_success_5053],
        });
        const evh_7789867021953041_submit_5047 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_5048,
            event: "submit",
            displayName: "save",
        });
        const evh_7789867021953041_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_submit_5047],
        });
        const act_1548630417156826_closeComponent_5056 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_5055 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_5056,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_5055],
        });
        return {
            act_7315092382398562_closeModal_5024,
            evh_7315092382398562_close_5023,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_5026,
            evh_2248226175642056_close_5025,
            evh_2248226175642056_close,
            act_3943151158818434_reloadComponentData_5028,
            evh_3943151158818434_clickPreventStop_5027,
            evh_3943151158818434_clickPreventStop,
            act_8518026866742051_closeComponent_5032,
            evh_8518026866742051_success_5031,
            act_8518026866742051_reloadSlickgrid_5034,
            evh_8518026866742051_success_5033,
            act_8518026866742051_request_5030,
            evh_8518026866742051_clickPreventStop_5029,
            evh_8518026866742051_clickPreventStop,
            act_2492577004902623_script_5036,
            evh_2492577004902623_completed_5035,
            act_2492577004902623_reloadComponentData_5038,
            evh_2492577004902623_completed_5037,
            act_2492577004902623_reloadSlickgrid_5040,
            evh_2492577004902623_completed_5039,
            evh_2492577004902623_completed,
            act_4810867692521497_script_5042,
            evh_4810867692521497_completed_5041,
            act_4810867692521497_reloadComponentData_5044,
            evh_4810867692521497_completed_5043,
            act_4810867692521497_reloadSlickgrid_5046,
            evh_4810867692521497_completed_5045,
            evh_4810867692521497_completed,
            act_7789867021953041_setData_5050,
            evh_7789867021953041_success_5049,
            act_7789867021953041_form_5052,
            evh_7789867021953041_success_5051,
            act_7789867021953041_reloadSlickgrid_5054,
            evh_7789867021953041_success_5053,
            act_7789867021953041_request_5048,
            evh_7789867021953041_submit_5047,
            evh_7789867021953041_submit,
            act_1548630417156826_closeComponent_5056,
            evh_1548630417156826_clickPreventStop_5055,
            evh_1548630417156826_clickPreventStop,
            reloadData: act_3943151158818434_reloadComponentData_5028,
            delete: act_8518026866742051_request_5030,
            save: act_7789867021953041_request_5048,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], DunningLetter.prototype, "id", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('id'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DunningLetter.prototype, "onId", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], DunningLetter.prototype, "payload", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payload'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DunningLetter.prototype, "onPayload", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "xl",
    }),
    __metadata("design:type", String)
], DunningLetter.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DunningLetter.prototype, "onSize", null);
DunningLetter = __decorate([
    (0, vue_property_decorator_1.Component)()
], DunningLetter);
exports.default = DunningLetter;
vue_property_decorator_1.Vue.component("DunningLetter", DunningLetter);
