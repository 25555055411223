var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "NAVOnlineInvoiceInterfaceSettings",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  attrs: {
                    name: _vm.formName,
                    readonly: _vm.resource.locked,
                    labelAlign: _vm.$config.forms.defaultLabelAlign,
                    id: _vm.formId
                  },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpRow",
                    [
                      _c(
                        "ItpCol",
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "base_url",
                                label: _vm._f("pgettext")(
                                  "Base URL",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "base_url",
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The root URL of the service.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.editor.base_url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editor, "base_url", $$v)
                                  },
                                  expression: "editor.base_url"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "tax_number",
                                label: _vm._f("pgettext")(
                                  "Tax number",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "tax_number",
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The first 8 digits of the tax number.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.editor.tax_number,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editor, "tax_number", $$v)
                                  },
                                  expression: "editor.tax_number"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "login",
                                label: _vm._f("pgettext")(
                                  "Login",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "login",
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The technical user's login name.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.editor.login,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editor, "login", $$v)
                                  },
                                  expression: "editor.login"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("HR"),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "password_hash",
                                label: _vm._f("pgettext")(
                                  "Password hash",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                )
                              }
                            },
                            [
                              _c("ItpFormTextarea", {
                                attrs: {
                                  name: "password_hash",
                                  placeholder: _vm._f("pgettext")(
                                    "The generated password's SHA-512 hash.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  ),
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.resource.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.resource, "password", $$v)
                                  },
                                  expression: "resource.password"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "password",
                                label: _vm._f("pgettext")(
                                  "Password",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "password",
                                  name: "password",
                                  placeholder: _vm._f("pgettext")(
                                    "The generated password.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.change_password.password,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.change_password,
                                      "password",
                                      $$v
                                    )
                                  },
                                  expression: "change_password.password"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "password",
                                label: _vm._f("pgettext")(
                                  "Confirm password",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "password",
                                  name: "verify_password",
                                  placeholder: _vm._f("pgettext")(
                                    "The generated password.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.change_password.verify_password,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.change_password,
                                      "verify_password",
                                      $$v
                                    )
                                  },
                                  expression: "change_password.verify_password"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("HR"),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "xml_sign_key",
                                label: _vm._f("pgettext")(
                                  "XML sign key",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "xml_sign_key",
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The technical user's XML sign key.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.editor.xml_sign_key,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editor, "xml_sign_key", $$v)
                                  },
                                  expression: "editor.xml_sign_key"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "exchange_key",
                                label: _vm._f("pgettext")(
                                  "XML exchange key",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                ),
                                required: ""
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "exchange_key",
                                  required: "",
                                  placeholder: _vm._f("pgettext")(
                                    "The technical user's XML exchange key.",
                                    "NAVOnlineInvoiceInterfaceSettings"
                                  )
                                },
                                model: {
                                  value: _vm.editor.exchange_key,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editor, "exchange_key", $$v)
                                  },
                                  expression: "editor.exchange_key"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "9",
                                name: "add_unknown_clients",
                                label: _vm._f("pgettext")(
                                  "Add unknown clients",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                )
                              }
                            },
                            [
                              _c("ItpFormCheckbox", {
                                attrs: { name: "add_unknown_clients" },
                                model: {
                                  value: _vm.editor.add_unknown_clients,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editor,
                                      "add_unknown_clients",
                                      $$v
                                    )
                                  },
                                  expression: "editor.add_unknown_clients"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                labelColsMd: "3",
                                contentColsMd: "2",
                                name: "lookback_period",
                                label: _vm._f("pgettext")(
                                  "Lookback period (days)",
                                  "NAVOnlineInvoiceInterfaceSettings"
                                )
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "lookback_period",
                                  required: ""
                                },
                                model: {
                                  value: _vm.editor.lookback_period,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editor, "lookback_period", $$v)
                                  },
                                  expression: "editor.lookback_period"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms[_vm.formName] && _vm.forms[_vm.formName].id,
                  text: _vm._f("pgettext")("Save", "Button"),
                  type: "submit",
                  variant: "primary",
                  default: "",
                  spinning: _vm.actions.submit && _vm.actions.submit.isRunning
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("pgettext")("Close", "Button"),
                  variant: "default",
                  disabled: _vm.actions.submit && _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }