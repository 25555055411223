<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    name="revision_date"
    :label="'Contract date'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.revision_number == 0"
  >
    <ItpFormDatetime
      name="revision_date"
      v-model="contract.lifecycle.revision_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="revision_date"
    :label="'Revision date'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    v-if="contract.revision_number > 0"
  >
    <ItpFormDatetime
      name="revision_date"
      v-model="contract.lifecycle.revision_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="valid_from"
    :label="'Validity'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="8"
    :labelAlign="labelAlign"
    contentClass="form-row"
  >
    <DIV
      class="col"
    >
      <ItpFormDatetime
        name="valid_from"
        v-model="contract.lifecycle.valid_from"
      >
      </ItpFormDatetime>
    </DIV>
    <DIV
      class="col"
    >
      <ItpFormDatetime
        name="valid_to"
        v-model="contract.lifecycle.valid_to"
      >
      </ItpFormDatetime>
    </DIV>
  </ItpFormGroup>
  <ItpFormGroup
    name="date_signed"
    :label="'Date signed'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="date_signed"
      v-model="contract.lifecycle.date_signed"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="effective_date"
    :label="'Effective date'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="effective_date"
      v-model="contract.lifecycle.effective_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="date_of_occupancy"
    :label="'Date of occupancy'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="date_of_occupancy"
      v-model="contract.lifecycle.date_of_occupancy"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="auto_renewal"
    :label="'Auto renewal'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormCheckbox
      name="auto_renewal"
      v-model="contract.lifecycle.auto_renewal"
    >
    </ItpFormCheckbox>
  </ItpFormGroup>
  <ItpFormGroup
    name="notice_period"
    :label="'Notice period'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
    contentClass="input-group"
  >
    <ItpFormInput
      type="number"
      name="notice_period"
      v-model="contract.lifecycle.notice_period"
    >
    </ItpFormInput>
    <DIV
      class="input-group-append"
    >
      <span
        class="input-group-text"
      >
        {{ 'days'|pgettext('InputGroupAddon') }}
      </span>
    </DIV>
  </ItpFormGroup>
  <ItpFormGroup
    name="next_indexation_date"
    :label="'Next indexation date'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="next_indexation_date"
      v-model="contract.lifecycle.next_indexation_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="termination_date"
    :label="'Terminaton date'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="termination_date"
      v-model="contract.lifecycle.termination_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    name="termination_effective_date"
    :label="'Terminaton effective date'|pgettext('Contract')"
    labelColsMd="4"
    contentColsMd="4"
    :labelAlign="labelAlign"
  >
    <ItpFormDatetime
      name="termination_effective_date"
      v-model="contract.lifecycle.termination_effective_date"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetContractLifecycle extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  billing_frequency!: any;

  async $$load_billing_frequency() {
    return this.contract.billing_cycle != null ?
      {
        value: this.contract.billing_cycle.split(" ")[0],
        unit: this.contract.billing_cycle.split(" ")[1]
      } : {
        value: null,
        unit: null
      }
  }

  billingFrequencyOptions!: any;
  contractPaymentTermTypes!: any;

  async $$load_contractPaymentTermTypes() {
    return this.$fn.getEnumValuesFromSchema('ContractPaymentTermTypes')
  }

  ux!: any;
  dataMembers = ['currencies', 'billing_frequency', 'billingFrequencyOptions', 'contractPaymentTermTypes', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        currencies: null,
        billing_frequency: null,
        billingFrequencyOptions: [{
          name: "day",
          text: this.$fn.gettext("day"),
        }
          ,
        {
          name: "month",
          text: this.$fn.gettext("month"),
        }
          ,
        ]
        ,
        contractPaymentTermTypes: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6666: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6665: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6666 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6665 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6666,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6665],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6666,
      evh_7315092382398562_reload_6665,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetContractLifecycle", FieldsetContractLifecycle);

</script>