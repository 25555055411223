<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ContractStatusTransition"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="contract"
      autofocus="first"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <ItpFormGroup
          name="date"
          :label="options.date"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          required
          v-if="options.displayDate"
        >
          <ItpFormDatetime
            name="date"
            v-model="item.date"
            required
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="effective_date"
          :label="options.effective_date"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          required
          v-if="options.effective_date"
        >
          <ItpFormDatetime
            name="effective_date"
            v-model="item.effective_date"
            required
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="notes"
          :label="'Notes'|pgettext('ContractStatusTransition')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          :required="options.notesRequired"
          :description="options.notesHelpText"
        >
          <ItpFormTextarea
            name="notes"
            v-model="item.notes"
            :required="options.notesRequired"
          >
          </ItpFormTextarea>
        </ItpFormGroup>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.contract || !forms.contract.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ContractStatusTransition extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getCurrentOptions(...args: any[]) {
    switch (args[0]) {
      case 'approved':
        return {
          title: this.$fn.pgettext("Contract", "Approve"),
          date: this.$fn.pgettext("Contract", "Approval date"),
          dname: 'approval_date',
          displayDate: true
        };

      case 'signed':
        return {
          title: this.$fn.pgettext("Contract", "Sign"),
          date: this.$fn.pgettext("Contract", "Signature date"),
          dname: 'signature_date',
          displayDate: true
        };

      case 'rescinded':
        return {
          title: this.$fn.pgettext("Contract", "Rescind"),
          date: this.$fn.pgettext("Contract", "Rescission date"),
          dname: 'rescission_date',
          displayDate: true
        };

      case 'active':
        return {
          title: this.$fn.pgettext("Contract", "Activate"),
          date: this.$fn.pgettext("Contract", "Valid from"),
          dname: 'valid_from',
          displayDate: true
        };

      case 'terminated':
        return {
          title: this.$fn.pgettext("Contract", "Terminate"),
          date: this.$fn.pgettext("Contract", "Termination date"),
          effective_date: this.$fn.pgettext("Contract", "Termination effective date"),
          dname: 'termination_date',
          displayDate: true
        };

      case 'ended':
        return {
          title: this.$fn.pgettext("Contract", "End"),
          date: this.$fn.pgettext("Contract", "Ending date"),
          dname: 'end_date',
          displayDate: true
        };

      case 'archived':
        return {
          title: this.$fn.pgettext("Contract", "Archive"),
          date: this.$fn.pgettext("Contract", "Archive date"),
          dname: 'archive_date',
          displayDate: true
        };

      case 'unarchive':
        return {
          title: this.$fn.pgettext("Contract", "Unarchive"),
          displayDate: false,
          notesRequired: true,
          notesHelpText: this.$fn.pgettext('Contract', 'The reason for unarchiving must be justified.')
        };
    }
  }

  defaultData(...args: any[]) {
    const [status, contract] = args;

    switch (status) {
      case 'active': {
        return { date: contract.lifecycle.valid_from || this.$fn.today() };
      }

      case 'unarchive': {
        return { notes: null };
      }

      case 'terminated':
      case 'ended':
      case 'archived': {
        return { date: contract.lifecycle.valid_to || this.$fn.today() };
      }
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    required: true,
    type: String,
  })
  status!: string;

  @Watch('status')
  onStatus(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("status")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  options!: any;

  async $$load_options() {
    return this.getCurrentOptions(this.status)
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  item!: any;

  async $$load_item() {
    return _.extend({
      id: this.contract.id,
      rev: this.contract.revision_number,
      status: this.status,
      date: this.$fn.today(),
      effective_date: null,
      notes: null,
      $etag: this.contract.etag
    }, this.defaultData(this.status, this.contract))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['options', 'formId', 'labelAlign', 'item', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        options: null,
        formId: null,
        labelAlign: "right",
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: ([this.contract.contract_number, this.options.title]),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6830_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6830, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_6836_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_6836, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_request_6834_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_6834, alias=submit
    return {
      operation: "update_contract_status",
      data: _.set(this.item, this.options.dname, this.item.date),
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6830: actions.CloseModalAction;
    evh_7315092382398562_close_6829: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6832: actions.CloseComponentAction;
    evh_2248226175642056_close_6831: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_6836: actions.CloseModalAction;
    evh_1040167445267876_success_6835: actions.EventHandlerImpl;
    act_1040167445267876_request_6834: actions.RequestAction;
    evh_1040167445267876_submit_6833: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_6838: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_6837: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6830 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6830_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6829 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6830,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6829],
      }
    );
    const act_2248226175642056_closeComponent_6832 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6831 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6832,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6831],
      }
    );
    const act_1040167445267876_closeModal_6836 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_6836_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6835 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_6836,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_6834 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_6834_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_6835],
      }
    );
    const evh_1040167445267876_submit_6833 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_6834,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6833],
      }
    );
    const act_1419464017721962_closeComponent_6838 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_6837 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_6838,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6837],
      }
    );
    return {
      act_7315092382398562_closeModal_6830,
      evh_7315092382398562_close_6829,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6832,
      evh_2248226175642056_close_6831,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_6836,
      evh_1040167445267876_success_6835,
      act_1040167445267876_request_6834,
      evh_1040167445267876_submit_6833,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_6838,
      evh_1419464017721962_clickPreventStop_6837,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_6834,
    }
  }
}

Vue.component("ContractStatusTransition", ContractStatusTransition);

</script>