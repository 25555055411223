"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ProductPrice = class ProductPrice extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'currencies', 'formName', 'formId', 'labelAlign', 'isReadOnly', 'ux', 'b_7315092382398562_modalBindings', 'b_5074589820235598_dataSource'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onProduct(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("product");
    }
    onIs_purchase_price(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("is_purchase_price");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid
                ? this.$fn.fetch('read_product_price', { parameters: { id: this.oid } })
                : this.$fn.schemaDefaults('CreateProductPriceCommand', {
                    product_id: this.product.id, is_purchase_price: this.is_purchase_price
                });
        });
    }
    $$load_currencies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_currency');
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_isReadOnly() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.locked;
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            currencies: null,
            formName: "productService",
            formId: null,
            labelAlign: "right",
            isReadOnly: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
            b_5074589820235598_dataSource: {
                name: "suggest_client",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.oid ? this.$fn.gettext('Edit Price') : this.$fn.gettext('Add Price'),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3290_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_3296_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    ok: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_request_3294_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: this.item.id ? 'update_product_price' : 'create_product_price',
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_2140198529698299_closeModal_3298_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_2140198529698299_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2140198529698299_success.executeFromDOM(this, event, scope);
        });
    }
    act_2434514588044777_closeModal_3300_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    evh_2434514588044777_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2434514588044777_success.executeFromDOM(this, event, scope);
        });
    }
    act_2186679009640457_closeModal_3302_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    cancel: true,
                },
            };
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3290 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3290_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3289 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3290,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3289],
        });
        const act_2248226175642056_closeComponent_3292 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_3291 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_3292,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3291],
        });
        const act_1040167445267876_closeModal_3296 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_3296_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_3295 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_3296,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_request_3294 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_3294_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_3295],
        });
        const evh_1040167445267876_submit_3293 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_3294,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3293],
        });
        const act_2140198529698299_closeModal_3298 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2140198529698299_closeModal_3298_getActionArgs,
            events: [],
        });
        const evh_2140198529698299_success_3297 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_closeModal_3298,
            event: "success",
            displayName: "closeModal",
        });
        const evh_2140198529698299_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_success_3297],
        });
        const act_2434514588044777_closeModal_3300 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2434514588044777_closeModal_3300_getActionArgs,
            events: [],
        });
        const evh_2434514588044777_success_3299 = new core_1.actions.EventHandlerImpl({
            action: act_2434514588044777_closeModal_3300,
            event: "success",
            displayName: "closeModal",
        });
        const evh_2434514588044777_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2434514588044777_success_3299],
        });
        const act_2186679009640457_closeModal_3302 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_2186679009640457_closeModal_3302_getActionArgs,
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_3301 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeModal_3302,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_3301],
        });
        return {
            act_7315092382398562_closeModal_3290,
            evh_7315092382398562_close_3289,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_3292,
            evh_2248226175642056_close_3291,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_3296,
            evh_1040167445267876_success_3295,
            act_1040167445267876_request_3294,
            evh_1040167445267876_submit_3293,
            evh_1040167445267876_submit,
            act_2140198529698299_closeModal_3298,
            evh_2140198529698299_success_3297,
            evh_2140198529698299_success,
            act_2434514588044777_closeModal_3300,
            evh_2434514588044777_success_3299,
            evh_2434514588044777_success,
            act_2186679009640457_closeModal_3302,
            evh_2186679009640457_clickPreventStop_3301,
            evh_2186679009640457_clickPreventStop,
            submit: act_1040167445267876_request_3294,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], ProductPrice.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ProductPrice.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
        default: null,
    }),
    __metadata("design:type", Object)
], ProductPrice.prototype, "product", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('product'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ProductPrice.prototype, "onProduct", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], ProductPrice.prototype, "is_purchase_price", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('is_purchase_price'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ProductPrice.prototype, "onIs_purchase_price", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ProductPrice.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ProductPrice.prototype, "onSize", null);
ProductPrice = __decorate([
    (0, vue_property_decorator_1.Component)()
], ProductPrice);
exports.default = ProductPrice;
vue_property_decorator_1.Vue.component("ProductPrice", ProductPrice);
