<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ImportBankStatement"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpAlert
      variant="info"
      show
    >
      <ItpIcon
        fa="info-circle"
      >
      </ItpIcon>
      <ItpText
        wrap
      >
        A funkció a banki alkalmazásból letöltött állományokból importál banki bizonylatokat.

      </ItpText>
    </ItpAlert>
    <hr>
    </hr>
    <DIV
      class="container"
    >
      <ItpForm
        :name="formName"
        :id="formId"
        v-if="loaded"
        @submit="evh_648747096704084_submit($event, {})"
      >
        <ItpFormGroup
          name="format"
          :label="'Statement format'|pgettext('ImportBankStatement')"
          labelFor="format"
          required
        >
          <ItpFormSelect
            v-model="format"
            name="format"
            required
            :options="bankStatementFormats"
          >
          </ItpFormSelect>
        </ItpFormGroup>
        <ItpFormGroup
          name="payment_number"
          :label="'Payment number'|pgettext('ImportBankStatement')"
          labelFor="payment_number"
          required
        >
          <ItpFormInput
            v-model="payment_number"
            name="payment_number"
            required
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="file"
          :label="'Statement file'|pgettext('ImportBankStatement')"
          labelFor="file"
          :description="'The file must be downloaded from the respective bank\'s application.'|pgettext('ImportBankStatement')"
          required
        >
          <ItpFormFile
            v-model="file"
            name="file"
            accept=".xlsx"
            required
            :validation="b_2359849487788347_validation"
          >
          </ItpFormFile>
        </ItpFormGroup>
      </ItpForm>
      <ItpAlert
        :show="resultText && !actions.submit.isRunning"
      >
        <p>
          {{ resultText }}
        </p>
      </ItpAlert>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="formId"
      :text="'Submit file'|pgettext('ImportBankStatement')"
      type="submit"
      variant="primary"
      icon="fa-file-import"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ImportBankStatement extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  file!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  resultText!: any;
  downloadImportBankStatementFileURL!: any;

  async $$load_downloadImportBankStatementFileURL() {
    return this.$fn.urlFor('meters/get_sensor_data_exchange', { format: 'xlsx' })
  }

  bankStatementFormats!: any;
  format!: any;

  async $$load_format() {
    return this.bankStatementFormats[0].value
  }

  payment_number!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2359849487788347_validation!: any;
  dataMembers = ['file', 'formId', 'formName', 'resultText', 'downloadImportBankStatementFileURL', 'bankStatementFormats', 'format', 'payment_number', 'ux', 'b_7315092382398562_modalBindings', 'b_2359849487788347_validation'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        file: null,
        formId: null,
        formName: "ImportBankStatement",
        resultText: null,
        downloadImportBankStatementFileURL: null,
        bankStatementFormats: [{
          value: "unicredit",
          text: this.$fn.pgettext("BankStatementFormat", "UniCredit XLSX"),
        }
          ,
        ]
        ,
        format: null,
        payment_number: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_2359849487788347_validation: ["ext:xlsx"
          ,
          "required"
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("ImportBankStatement|Title", "Import Bank Statement"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3134_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3134, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_648747096704084_setData_3140_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_648747096704084_setData_3140, alias=undefined
    return {
      path: "resultText",
      value: this.$fn.pgettext("ImportBankStatement", "Successful import."),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_648747096704084_request_3138_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_648747096704084_request_3138, alias=submit
    return {
      operation: "create_payment_from_statement",
      data: { file: this.file, format: this.format, payment_number: this.payment_number },
      contentType: "multipart/form-data",
    }
  }

  async evh_648747096704084_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_648747096704084_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_3142_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_3142, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3134: actions.CloseModalAction;
    evh_7315092382398562_close_3133: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3136: actions.CloseComponentAction;
    evh_2248226175642056_close_3135: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_648747096704084_setData_3140: actions.SetDataAction;
    evh_648747096704084_success_3139: actions.EventHandlerImpl;
    act_648747096704084_request_3138: actions.RequestAction;
    evh_648747096704084_submit_3137: actions.EventHandlerImpl;
    evh_648747096704084_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_3142: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_3141: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3134 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3134_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3133 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3134,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3133],
      }
    );
    const act_2248226175642056_closeComponent_3136 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3135 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3136,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3135],
      }
    );
    const act_648747096704084_setData_3140 = new actions.SetDataAction(
      {
        actionArgs: this.act_648747096704084_setData_3140_getActionArgs,
        events: [],
      }
    );
    const evh_648747096704084_success_3139 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_setData_3140,
        event: "success",
        displayName: "setData",
      }
    );
    const act_648747096704084_request_3138 = new actions.RequestAction(
      {
        actionArgs: this.act_648747096704084_request_3138_getActionArgs,
        displayName: "submit",
        events: [evh_648747096704084_success_3139],
      }
    );
    const evh_648747096704084_submit_3137 = new actions.EventHandlerImpl(
      {
        action: act_648747096704084_request_3138,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_648747096704084_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_648747096704084_submit_3137],
      }
    );
    const act_1419464017721962_closeModal_3142 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_3142_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_3141 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_3142,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3141],
      }
    );
    return {
      act_7315092382398562_closeModal_3134,
      evh_7315092382398562_close_3133,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3136,
      evh_2248226175642056_close_3135,
      evh_2248226175642056_close,
      act_648747096704084_setData_3140,
      evh_648747096704084_success_3139,
      act_648747096704084_request_3138,
      evh_648747096704084_submit_3137,
      evh_648747096704084_submit,
      act_1419464017721962_closeModal_3142,
      evh_1419464017721962_clickPreventStop_3141,
      evh_1419464017721962_clickPreventStop,
      submit: act_648747096704084_request_3138,
    }
  }
}

Vue.component("ImportBankStatement", ImportBankStatement);

</script>